import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { NULL } from "sass";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
dayjs.extend(weekday);

export class PsmAnalyzeCompiledFkAnalysePerformedIdEvents extends Event {

    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        console.log("Entrei aqui", value)
        console.log("Entrei aqui 2", formValues)
        await axios.get('/api/v1/psm/verifyAnalyseCompiled', {
            params: {
                idDefect: value,
            }
        }).then((response: any) => {
            const dataRes = response.data
            this.setFieldValue("psm_analyze_compiled_name", dataRes.data)
        })
            .catch((e) => {
                console.log('Err LLL<', e)
            })
    }


}