import classNames from "classnames";
import React, { useState } from "react";
import { withRouter } from "react-router";
import { Button, Panel, Table, Loader, Modal, Whisper, Tooltip, Notification, Form, Col, FormGroup, Input, InputGroup, Checkbox, SelectPicker, Row, Popover } from "rsuite";
import TablePagination from "rsuite/lib/Table/TablePagination";
import qs from "query-string";
import axios from "../utilities/axios";
import { error } from "./errorList/main";
import { FilterableCell } from "./customTable/FilterableCell";
// import { diff } from "json-diff";
import * as ImportHolderComponent from './customImportsBrowserlize/ExportCustomHolder';
import * as ExportHolderBrowserlizeComponent from "./customExportBroserlize/ExportCustomHolder"
import { UserContext } from "./authProvider/main";
import _ from "lodash";
import { ErrorPopover } from "./errorPopover/main";
import * as mask from "inputmask";
import { TModal } from "./modalTabs/main";
import { ExportImportSteps } from "./customTable/ExportImportSteps";
import { SchemaToParsedCell } from "./customTable/SchemaToParsedCell";
import { Permission } from "./Permission";
import { NecTable } from "./customTable/NecTable";
import { ColumnEditor } from "./ColumnEditor";
import pluralize from "pluralize";
import { CustomExtensions } from "../custom/CustomExtensions";
import { CustomModal } from "../components/customModal/CustomModal"
import checkPermissionPage from "../utilities/checkPermissionPage";
const { Column, HeaderCell, Cell } = Table;

// import * as Events from "../events/EventHolder";

// interface IBrowserlize {
//     form: any;
//     values?: any;
//     name: string;
//     activeModule: any;
// }
const defaultPerPage = 10;
class Browserlize extends React.Component<any, {}> {
    static contextType = UserContext;
    public searchRef: any = null;
    public browserable: any = [];
    public state: any = {
        data: [],
        filters: [],
        selectedFilters: [],
        orders: [],
        checked: [],
        searchs: {},
        operators: {},
        totalWidth: 0,
        loading: true,
        columnEditorOpen: false,
        genericSearchValue: "",
        sortColumn: null,
        sortType: null,
        isModalOpen: false,
        isExportModalOpen: false,
        importStep: 0,
        perPage: defaultPerPage,
        totalPages: 4,
        totalItems: 0,
        page: 1,
        eventsClass: null,
        isImportModalOmie: false,
        isModalLabs: false,
        isModalLabsAdd: false,
        typeEquipment: [],
        typeEquipmentDisable: true,
        Retifier: null,
        typeRetifier: [],
        typeEquipmentSelect: [],
        retifierSelect: null,
        typeRetifierSelect: null,
        typeEquipmentAutomation: [],
        typeEquipmentAutomationSelect: [],
        typeTeste: [],
        idLabs: "",
        disableSelect: true,
        loadSelect: false,
        dataOmie: [],
        nivel2Filter: {
            1: [{
                value: 1,
                label: "AC / AC"
            }, {
                value: 2,
                label: "DC / AC"
            }],
            2: [{
                value: 3,
                label: "AC / DC"
            },
            ],
            3: [{
                value: 4,
                label: "3 - 100A"
            }, {
                value: 5,
                label: "101 - 300A"
            }, {
                value: 6,
                label: "+300A"
            }],
            4: [{
                value: 0,
                label: "NENHUM"
            }],
            5: [{
                value: 0,
                label: "NENHUM"
            }],
            6: [{
                value: 0,
                label: "NENHUM"
            }],
            7: [{
                value: 0,
                label: "NENHUM"
            }],
            8: [{
                value: 0,
                label: "NENHUM"
            }],
            9: [{
                value: 7,
                label: "3 - 50A"
            },
            {
                value: 8,
                label: "51 - 150A"
            },
            {
                value: 9,
                label: "+150A"
            }
            ],
        },
        nivel3Filter: {
            1: [{
                value: 1,
                label: "Ponte Retificadora"
            }, {
                value: 2,
                label: "Diodo"
            }, {
                value: 3,
                label: "Tiristor"
            }, {
                value: 4,
                label: "Tiristor / Diodo"
            }, {
                value: 5,
                label: "IGBT"
            }],
            2: [{
                value: 0,
                label: "NENHUM"
            }],
            3: [{
                value: 3,
                label: "Tiristor"
            }, {
                value: 4,
                label: "Tiristor / Diodo"
            }, {
                value: 5,
                label: "IGBT"
            }],
            4: [{
                value: 0,
                label: "NENHUM"
            }],
            5: [{
                value: 0,
                label: "NENHUM"
            }],
            6: [{
                value: 0,
                label: "NENHUM"
            }],
            7: [{
                value: 0,
                label: "NENHUM"
            }],
            8: [{
                value: 0,
                label: "NENHUM"
            }],
            9: [{
                value: 0,
                label: "NENHUM"
            }],
        },
        potenciModal: [{
            value: 1,
            label: "0,37 KW - 10 KW"
        }, {
            value: 2,
            label: "11 KW - 89 KW"
        }, {
            value: 3,
            label: "90 KW - 200 KW"
        }, {
            value: 4,
            label: "'+ 201 KW"
        }],
        potenciSelect: "",
    };

    public elementRef: any = null;
    public eventsClass: any = null;

    updateEventsClass = () => {
        const $GLOBALS = this.props.form.$GLOBALS;
        if ($GLOBALS) {
            // console.log('etste ::: ', pluralize.singular(_.camelCase($GLOBALS.table)))
            const eventName = _.upperFirst(pluralize.singular(_.camelCase($GLOBALS.table))) + "BrowseEvents";
            const eventHolder: any = CustomExtensions;
            if (eventHolder[eventName]) {
                this.eventsClass = new eventHolder[eventName]();
                this.setState({ eventsClass: this.eventsClass });
            }
        }
    };

    componentDidMount() {
        this.elementRef = React.createRef();
        this.browserable = Object.values(this.props.form).filter((item: any) => {
            return item.canBrowse === true;
        });
        this.searchRef = React.createRef();
        this.updateEventsClass();
        // alert("MOUNTED");

        var params = qs.parse(this.props.history.location.search);
        if (params) {
            var searchs: any = {};
            var operators: any = {};
            for (var i in params) {
                var param = params[i];
                if (i !== "page" && i !== "perPage" && i !== "sortType" && i !== "sortColumn" && i.substr(i.length - 3, i.length) !== "_op") {
                    searchs[i] = param;
                }
                // //
                if (i.substr(i.length - 3, i.length) === "_op") {
                    // //
                    operators[i.replace("_op", "")] = param;
                }
                if (i === "sortType") {
                    this.setState({ sortType: params[i] });
                }
                if (i === "sortColumn") {
                    // console.log("paramns :::: ",params[i])
                    this.setState({ sortColumn: params[i] });
                }
            }
            if (this.props.form.$GLOBALS.table === 'person_contacts') {
                searchs = '';
            }
            this.setState({ searchs, operators }, () => {
                // this.lastData = {searchs: this.state.searchs,operators: this.state.operators};
                if (params && params.page && typeof params.page === "string") {
                    if (params.perPage && typeof params.perPage === "string") {
                        this.search(params.page, params.perPage);
                    } else {
                        this.search(params.page);
                    }
                } else {
                    this.search();
                }
            });
        }

        // this.search();
    }

    componentDidUpdate(props: any, state: any) {
        if (this.props.location.search !== props.location.search) {
            //
            // alert("a");
            var params = qs.parse(this.props.history.location.search);
            this.setState({ sortType: null, sortColumn: null });
            if (params) {
                var searchs: any = {};
                var operators: any = {};
                for (var i in params) {
                    var param = params[i];
                    if (i !== "page" && i !== "perPage" && i !== "sortType" && i !== "sortColumn" && i.substr(i.length - 3, i.length) !== "_op") {
                        searchs[i] = param;
                    }
                    if (i.substr(i.length - 3, i.length) === "_op") {
                        operators[i.replace("_op", "")] = param;
                    }
                    if (i === "sortType") {
                        this.setState({ sortType: params[i] });
                    }
                    if (i === "sortColumn") {
                        // console.log("Paramns update ::: ", params[i])
                        this.setState({ sortColumn: params[i] });
                    }
                }
            }
            // this.setState({})
            // this.search(1, 20, false);
        }
        if (this.state.totalWidth === 0 && this.elementRef.current.clientWidth > 0) {
            this.setState({ totalWidth: this.elementRef.current.clientWidth });
        }

        if (Object.keys(props.form).length > 0 && this.state.filters.length <= 0 && props.form?.$GLOBALS?.table && this.props.form) {
            // let cachedFilters: undefined | string[] | null = _.flatten(
            //     this.context?.data?.user_settings
            //         ?.filter((item: any) => {
            //             return item.user_setting_key === props.form.$GLOBALS.table + "-filters";
            //         })
            //         .map((item: any) => {
            //             return item.user_setting_parsed_values;
            //         }),
            // );
            // let cachedOrders: undefined | string[] | null = _.flatten(
            //     this.context?.data?.user_settings
            //         ?.filter((item: any) => {
            //             return item.user_setting_key === props.form.$GLOBALS.table + "-order";
            //         })
            //         .map((item: any) => {
            //             return item.user_setting_parsed_values;
            //         }),
            // );
            // // //
            // //({ cachedFilters, cachedOrders, userSettings: this.context?.data?.user_settings });
            // let filters = [];
            // if (cachedFilters) {
            //     try {
            //         filters = cachedFilters;
            //     } catch (e) {
            //         filters = [];
            //         cachedFilters = undefined;
            //     }
            // }
            // if (cachedFilters === null || cachedFilters === undefined || cachedFilters.length === 0) {
            //     this.browserable = Object.values(this.props.form).filter((item: any) => {
            //         return item.canBrowse === true;
            //     });
            //     if (cachedOrders && cachedOrders.length > 0) {
            //         this.browserable = [];
            //         for (var cachedIndex in cachedOrders) {
            //             this.browserable.push(this.props.form[cachedOrders[cachedIndex]]);
            //         }
            //         // this.browserable = this.browserable.sort((a: any, b: any) => {
            //         //     return a.order < b.order ? -1 : 1;
            //         // });
            //     } else {
            //         this.browserable = this.browserable.sort((a: any, b: any) => {
            //             return a.order < b.order ? -1 : 1;
            //         });
            //     }
            //     filters = this.browserable
            //         .map((item: any) => {
            //             if (item) {
            //                 return item.name;
            //             }
            //             return undefined;
            //         })
            //         .filter((item: any) => {
            //             return item !== undefined;
            //         });
            // }
            // if (filters.length) {
            //     this.setState({ filters,selectedFilters: filters });
            // }
        }
    }

    onRead = (id: string) => {
        // alert(id);
        if (this.props.onRead) {
            this.props.onRead(id);
        }
    };
    onDelete = (id: string) => {
        if (this.props.onDelete) {
            this.props.onDelete(id, () => {
                this.search();
            });
        }
    };
    onEditLabs = (id: string) => {
        if (this.props.onEdit) {
            // console.log("Testando editar");
            //this.props.onEdit(id);
        }
    }

    modalLabsEdit = async (id: string, idPsm: number) => {
        // console.log('Id teste ::: ', idPsm)
        if (idPsm < 2) {
            Notification.error({
                title: "Erro!",
                description: 'A sua PSM não se encontra na situação correta para assumir',
                placement: "topEnd",
            });
            return;
        }
        localStorage.setItem('searchHistoric', this.props.location.search)
        axios
            .get("/api/v1/type_equipments/select")
            .then((res) => {
                //console.log("Testando response ::: ", res.data.items.sort());
                //console.log("Testando lodash ::: ", _.orderBy(res.data.items, ['value', 'label'], ['asc']))
                this.setState({
                    typeEquipment: _.orderBy(res.data.items, ['value', 'label'], ['asc']),
                    idLabs: id,
                    typeEquipmentDisable: false,

                })
            })
        // const typeEquipment = [{
        //     value: 1,
        //     label: "Inversor"
        // },{
        //     value: 2,
        //     label: "Retificador"
        // },{
        //     value: 3,
        //     label: "Softstarter"
        // },{
        //     value: 4,
        //     label: "IHM"
        // },
        // {
        //     value: 5,
        //     label: "PLC"
        // },
        // {
        //     value: 6,
        //     label: "Fonte"
        // },
        // {
        //     value: 7,
        //     label: "Cartões Eletronicos"
        // },
        // {
        //     value: 8,
        //     label: "Servomotor"
        // },
        // {
        //     value: 9,
        //     label: "Simocode"
        // },
        // {
        //     value: 10,
        //     label: "Célula de Potência"
        // }
        // ];



        var templateLab: any = [];
        await axios
            .get("/api/v1/lab/check-template/" + id)
            .then((res) => {
                templateLab = res.data.query
            })
        if (templateLab.psm_template_type == null) {
            this.setState({ isModalLabs: true }, this.onSubmitSearch);
        } else {
            this.props.onEdit(id);
        }
    }

    modalLabsADD = () => {
        const typeEquipment = [{
            value: 1,
            label: "Inversor"
        }, {
            value: 2,
            label: "Retificador"
        }, {
            value: 3,
            label: "Softstarter"
        }, {
            value: 4,
            label: "IHM"
        },
        {
            value: 5,
            label: "PLC"
        },
        {
            value: 6,
            label: "Fonte"
        },
        {
            value: 7,
            label: "Cartões Eletronicos"
        },
        {
            value: 8,
            label: "Servomotor"
        },
        {
            value: 9,
            label: "Simocode"
        },
        {
            value: 10,
            label: "Célula de Potência"
        }
        ];
        this.setState({ isModalLabsAdd: true, typeEquipment: typeEquipment }, this.onSubmitSearch);

    }



    assumeLab = async (data: { id: number, retifier: number, typeEquipment: number, typeRetifier: number, potenci: any }) => {
        //console.log("Potencia",data.retifier)
        localStorage.setItem('searchHistoric', this.props.location.search)
        await axios
            .get("/api/v1/lab/save-template/" + JSON.stringify(data) + "/" + data.id)
            .then((res) => {
                if (res.status === 200) {
                    this.props.onEdit(data.id)
                }
            })
    }
    // verifyState = (value: any) => {
    //     if(value == 4 ){ 
    //         this.setState({
    //             disableSelect: false,
    //             loadSelect: false
    //         })
    //         console.log("Entrou function")
    //     }
    // }


    onEdit = (id: string) => {
        if (this.props.onEdit) {
            this.props.onEdit(id);
        }
    };

    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (this.props.form !== nextProps.form) {
            return true;
        }
        if (this.state.searchs !== nextState.searchs) {
            return true;
        }
        if (this.state.operators !== nextState.operators) {
            return true;
        }
        if (this.state.sortType !== nextState.sortType) {
            return true;
        }
        if (this.state.sortColumn !== nextState.sortColumn) {
            return true;
        }
        if (this.state.perPage !== nextState.perPage) {
            return true;
        }
        if (this.state.page !== nextState.page) {
            return true;
        }
        if (this.state.totalWidth !== nextState.totalWidth) {
            return true;
        }
        if (this.state.filters !== nextState.filters) {
            return true;
        }
        if (this.state.selectedFilters !== nextState.selectedFilters) {
            return true;
        }
        if (this.state.loading !== nextState.loading) {
            return true;
        }
        if (this.state.data !== nextState.data) {
            return true;
        }
        return false;
    }

    renderActionImport() {
        const crudName = _.startCase(
            _.camelCase(
                pluralize.singular(
                    this.props.match.params.crud
                        .split("-")
                        .join("_")
                        .split("_")
                        .map((item: string) => pluralize.singular(item))
                        .join("_"),
                ),
            ),
        )
            .split(" ")
            .join("");
        const customNameComponent = `${_.upperFirst(crudName)}Browserlize`;
        const viewHolder: any = ImportHolderComponent;
        const view = viewHolder[customNameComponent];
        if (view) {
            return (
                <>
                    {React.createElement(view, {
                        ...this.props
                    })}
                </>
            )
        }
    }

    renderActionExport() {
        const crudName = _.startCase(
            _.camelCase(
                pluralize.singular(
                    this.props.match.params.crud
                        .split("-")
                        .join("_")
                        .split("_")
                        .map((item: string) => pluralize.singular(item))
                        .join("_"),
                ),
            ),
        )
            .split(" ")
            .join("");
        const customNameComponent = `${_.upperFirst(crudName)}ExportBrowserlize`;
        const viewHolder: any = ExportHolderBrowserlizeComponent;
        const view = viewHolder[customNameComponent];
        if (view) {
            return (
                <>
                    {React.createElement(view, {
                        ...this.props
                    })}
                </>
            )
        }
    }

    renderActions = (rowData: any) => {
        //console.log("Entrou");
        var idColumnName = this.props.form.$GLOBALS.prefix.toLocaleLowerCase() + "_id";
        // console.log('Id ColumName ::: ', idColumnName);
        // console.log('props novo ::::', this.props);
        // console.log('RowData ::: ',rowData);
        const { edit: canEdit } = this.props.form.$GLOBALS;
        var { table: prefix } = this.props.form.$GLOBALS;
        if (this.props.overrideActions) {
            return this.props.overrideActions(rowData);
        }
        return (
            <>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Permission name={prefix + "-read"}>
                        <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Visualizar</Tooltip>}>
                            <i onClick={() => this.onRead(rowData[idColumnName])} className="fas text-primary fa-fw mr-2 clickable fa-eye"></i>
                        </Whisper>
                    </Permission>

                    {canEdit !== false && this.props.name !== "labs" && (
                        <Permission name={prefix + "-edit"}>
                            <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Editar</Tooltip>}>
                                <i onClick={() => this.onEdit(rowData[idColumnName])} className="fas text-warning fa-fw mr-2 clickable fa-pencil-alt"></i>
                            </Whisper>
                            {this.props.actions ? this.props.actions(rowData[idColumnName], rowData) : null}
                        </Permission>
                    )}

                    {canEdit !== false && this.props.name === "labs" && (
                        <Permission name={prefix + "-edit"}>
                            <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Editar</Tooltip>}>
                                <i onClick={() => this.modalLabsEdit(rowData[idColumnName], rowData[`psm_status_fk_situation_id`])} className="fas text-warning fa-fw mr-2 clickable fa-pencil-alt"></i>
                            </Whisper>
                            {this.props.actions ? this.props.actions(rowData[idColumnName], rowData) : null}
                        </Permission>
                    )}

                    {this.props.form.$GLOBALS.delete !== false && rowData.is_delete_safe !== true && (
                        <Permission name={prefix + "-delete"}>
                            <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Excluir</Tooltip>}>
                                <i onClick={() => this.onDelete(rowData[idColumnName])} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>
                            </Whisper>
                        </Permission>
                    )}
                </div>
            </>
        );
    };

    public lastData = { searchs: this.state.searchs, operators: this.state.operators };

    onExitSearch = (key: string, value: string, operator: string) => {
        // alert(key,)

        let { searchs, operators } = this.state;
        if (value === undefined) {
            delete searchs[key];
            if (operators[key]) {
                delete operators[key];
            }
        } else {
            searchs[key] = value;
            operators[key] = operator;
        }
        // var hasDiff = false;
        // if (this.lastData.searchs !== searchs) {
        //     hasDiff = true;
        // }

        // if (this.lastData.operators !== operators) {
        //     hasDiff = true;
        // }
        // var hasOnlyLike = true;
        // for (var i in operators) {
        //     if (operators[i] !== "like") {
        //         hasOnlyLike = false;
        //         break;
        //     }
        // }
        // var hasOnlyEmptyText = true;
        // for (var sIndex in searchs) {
        //     if (searchs[sIndex].length > 0) {
        //         hasOnlyEmptyText = false;
        //         break;
        //     }
        // }
        console.log('Aqui onExit :::> ')
        this.setState({ searchs, operators: operators }, () => {
            // if (hasOnlyEmptyText == false) {
            this.search();
            // }
        });

        // before(props: any, state: any) {
        //     //(this.state.searchs, state.searchs,this.state.operators, state.operators);
        //     if (diff(this.state.searchs, state.searchs) !== undefined || diff(this.state.operators, state.operators) !== undefined) {
        //         this.search();
        //     }
        //     return true;
        // }
        // this.setState(
        //     (oldValues: any) => {
        //         var searchs = { ...oldValues.searchs };
        //         var operators = { ...oldValues.operators };
        //         oldState = oldValues.searchs;
        //         if (value && value.length) {
        //             searchs[key] = value;
        //         } else if (searchs[key] !== undefined) {
        //             delete searchs[key];
        //         }
        //         return { searchs };
        //     },
        //     () => {
        //         if (diff(this.state.searchs, oldState) !== undefined) {
        //             this.search();
        //         }
        //     },
        // );
    };

    getVisibleHeadersWithOrder = (showHidden = true) => {
        if (this.state.filters && this.state.filters.length > 0) {
            return _.map(this.state.filters, (item) => {
                return this.props.form[item];
            });
        }
        // console.log("Entrei aqui", this.props.form)
        return _.filter(
            // _.sortBy(Object.values(this.state.form), (item: any) => {

            //     return this.state.columnOrder.indexOf(item.name);
            // }),
            // (item: any) => {
            //     return item.label?.length > 0;
            // },
            //Alteração nas ordens das colunas nos grids
            _.orderBy(this.props.form, "browserOrder", "asc"), (item) => {
                return item.label?.length > 0;
            }
        );
        // return _.filter(this.props.form, (item, index) => {
        //     return index !== "$GLOBALS" && item.canBrowse === true;
        // });
    };

    onResize = (size: any, name: string) => {
        // alert(JSON.stringify({size,name}));
        localStorage.setItem(this.props.form.$GLOBALS.prefix + "-size-" + name, size);
    };

    onCheckAll = () => {
        var checked = [];
        var value = this.state.checked.length == 0 ? false : this.state.checked.length == this.state.data.length;
        if (value == false) {
            for (var i in this.state.data) {
                checked.push(this.state.data[i][this.props.form.$GLOBALS.prefix.toLocaleLowerCase() + "_id"]);
            }
        }
        this.setState({ checked: checked });
    };

    onToggleCheck = (id: any, situationId: any, rowData: any) => {
        var checked = this.state.checked;
        var index = checked.indexOf(id);
        if (index > -1) {
            checked.splice(index, 1);
        } else {

            checked.push(id);
        }
        this.updateEventsClass();
        this.setState({ checked: checked });
    };

    renderHeaders = () => {
        var output = [];
        if (this.props.modal !== true) {
            output.push(
                <Column key={"start"} align="left" width={40} fixed="left">
                    <HeaderCell>#{/* <Checkbox onChange={this.onCheckAll} /> */}</HeaderCell>
                    <Cell>
                        {(rowData: any) => {
                            let rowId = rowData[this.props.form.$GLOBALS.prefix.toLocaleLowerCase() + "_id"];
                            let situation: number = 0;

                            if (this.props.form.$GLOBALS.prefix === 'psm') {
                                if (this.props.form.$GLOBALS.table === 'dibis') {
                                    situation = rowData[`${this.props.form.$GLOBALS.prefix.toLocaleLowerCase()}_status_fk_${pluralize.singular(this.props.form.$GLOBALS.table)}_situation_id`];
                                } else {
                                    if (this.props.form.$GLOBALS.prefix === 'psm') {
                                        situation = rowData[`${this.props.form.$GLOBALS.prefix.toLocaleLowerCase()}_status_fk_situation_id`];
                                    } else {
                                        situation = rowData[`${this.props.form.$GLOBALS.prefix.toLocaleLowerCase()}_fk_${pluralize.singular(this.props.form.$GLOBALS.table)}_situation_id`];
                                    }
                                }
                            }
                            return (
                                <div>
                                    <Checkbox onChange={() => this.onToggleCheck(rowId, situation, rowData)} defaultChecked={this.state.checked.includes(rowId, rowData)} />
                                </div>
                            );
                        }}
                    </Cell>
                </Column>,
            );
        }
        var filteredBrowserable = this.getVisibleHeadersWithOrder();
        // console.log({filteredBrowserable})

        var indexName = this.props.form.$GLOBALS.prefix + "_id";
        for (let i in filteredBrowserable) {
            let browse: any = filteredBrowserable[i];
            //(this.state.totalWidth / this.browserable.length) - (12 * this.browserable.length);
            if (!browse) {
                continue;
            }
            if (!browse.canBrowse) {
                continue;
            }

            if (this.state.filters.includes(browse.name) || this.state.filters.filter((item: any) => item != null).length <= 0) {

                // let width: any = _.clamp((this.state.totalWidth - 130) / filteredBrowserable.length, browse.name.length * 9.4, this.state.totalWidth);
                let width = browse.browserWidth ? (browse.browserWidth * 100) : _.clamp((this.state.totalWidth - 130) / filteredBrowserable.length, browse.name.length * 9.4, this.state.totalWidth);
                if (localStorage.getItem(this.props.form.$GLOBALS.prefix + "-size-" + browse.name)) {
                    width = parseInt(localStorage.getItem(this.props.form.$GLOBALS.prefix + "-size-" + browse.name) ?? "0");
                }

                output.push(
                    <Column
                        key={i}
                        align="left"
                        resizable
                        onResize={(size) => {
                            this.onResize(size, browse.name);
                        }}
                        width={width}>
                        <HeaderCell className="filterable">
                            <FilterableCell
                                field={browse}
                                isIndex={browse.name === indexName}
                                sortType={this.state.sortType !== undefined && this.state.sortColumn === browse.name ? this.state.sortType : null}
                                operator={this.state.operators[browse.name] ?? (browse.type === "numeric" ? "eq" : "like")}
                                onSortColumn={(value: string, operator: string = "like") => this.onSortColumn(browse.name, value, operator)}
                                value={this.state.searchs[browse.name] ?? undefined}
                                onExit={(value: string, operator: string) => this.onExitSearch(browse.name, value, operator)}>
                                <div dangerouslySetInnerHTML={{ __html: filteredBrowserable[i].label }}></div>
                            </FilterableCell>
                        </HeaderCell>
                        <Cell style={{ whiteSpace: 'pre-wrap' }}
                            dataKey={browse.name.toLocaleLowerCase()}>
                            {(rowData: any) => {
                                return SchemaToParsedCell.parseFieldToValue(browse, rowData, true);
                            }}
                        </Cell>
                    </Column>
                );


            }
        }
        output.push(
            <Column key={"end"} align="right" width={100} fixed="right">
                <HeaderCell>Ações</HeaderCell>
                <Cell className="link-group">
                    {(rowData: any) => {
                        return this.renderActions(rowData);
                    }}
                </Cell>
            </Column>,
        );
        return output;
    };

    search = (page: number | string = 1, perPage: string | number | null = null, apply: boolean = true) => {

        if (perPage === null) {
            perPage = localStorage.getItem("perPage") ?? defaultPerPage;
        }
        var operators: any = {};
        for (var i in this.state.operators) {
            operators[i + "_op"] = this.state.operators[i];
        }
        const uses = _.transform(this.props.uses ?? {}, function (result: any, val: any, key: any) {
            result[key.toLowerCase()] = val;
        });
        // //
        //
        this.setState({ loading: true });
        var queryObj = {
            page,
            perPage,
            ...this.state.searchs,
            ...operators,
            sortType: this.state.sortType,
            sortColumn: this.state.sortColumn,
        };
        //
        for (var queryIndex in queryObj) {
            if (queryObj[queryIndex] === null) {
                delete queryObj[queryIndex];
            }
        }
        localStorage.setItem("perPage", typeof perPage == "number" ? perPage.toString() : perPage);
        // console.log('my props :::: ',this.props);

        var urlQuery = qs.stringify(queryObj);
        //
        if (apply) {
            if (this.props.modal !== true) {
                this.props.history.push(this.props.history.location.pathname + "?" + urlQuery);
            }
        }
        if (this.props.form.$GLOBALS && this.props.form.$GLOBALS.table) {
            axios
                .get("/api/v1/" + this.props.form.$GLOBALS.table + "/list/", {
                    params: {
                        ...this.props.uses,
                        page,
                        perPage,
                        ...this.state.searchs,
                        ...operators,
                        sortType: this.state.sortType,
                        sortBy: this.state.sortColumn,
                        ...uses,
                        searchValue: this.props.searchValue,
                    },
                })
                .then((res) => {
                    axios
                        .get("/api/v1/me")
                        .then((meResponse) => {
                            const filterSetting = _.find(meResponse.data.user.user_settings, {
                                user_setting_key: this.props.form.$GLOBALS.table + "-filters",
                            });
                            var filters: string[] = [];
                            if (filterSetting && filterSetting.user_setting_value) {
                                filters = JSON.parse(filterSetting.user_setting_value);
                            }
                            // alert(JSON.stringify(filters));
                            this.setState({
                                loading: false,
                                data: res.data.items,
                                totalItems: res.data.pagination.total,
                                perPage: res.data.pagination.perPage ?? perPage,
                                page: res.data.pagination.page,
                                filters,
                            });
                        })
                        .catch(() => { });
                })
                .catch((e) => {
                    console.error(e);
                    error.add(e.message);
                    // //
                    if (e.response) {
                        Notification.error({
                            title: "Erro!",
                            description: e.response.data?.errors ? e.response.data.errors.join(", ") : e.message,
                            placement: "bottomEnd",
                        });
                    }
                    this.setState({ loading: false, data: [], perPage });
                })
                .finally(function () { });
        }
    };

    escolhetemplatelab = async (idTemplate: any) => {
        //select-add-template

        await axios
            .get("/api/v1/lab/select-add-template/" + JSON.stringify(idTemplate))
            .then((res) => {
                if (res.status === 200) {
                    this.props.history.push(`/dashboard/${this.props.module}/labs/${res.data.template_id.layout}/add`)
                }
            })

    }

    header = () => {
        //console.log("Oi")
        // console.log("teste", this.props.module)
        let $GLOBALS = this.props.form.$GLOBALS;
        var { table: prefix } = this.props.form.$GLOBALS;
        const { add } = $GLOBALS;
        return (
            <>
                <div className="d-flex justify-contents-between align-items-center">
                    <div></div>
                    <div>
                        <h5 className="d-flex align-items-center">
                            {$GLOBALS.icon ? <i className={classNames("fas fa-fw mr-4", "fa-" + $GLOBALS.icon)}></i> : <ErrorPopover title="Missing icon">icon</ErrorPopover>}
                            Listagem de {$GLOBALS.plural_name ? $GLOBALS.plural_name : <ErrorPopover title="Missing singular/plural name">{this.props.form.$GLOBALS.table}</ErrorPopover>}
                        </h5>
                    </div>
                    <div>
                        {$GLOBALS.canImport === true && (
                            <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Importar</Tooltip>}>
                                <Button color="violet" className="mr-2" onClick={() => this.setState({ isExportModalOpen: true })}>
                                    <i className="fas fa-fw fa-file-import  "></i>
                                </Button>
                            </Whisper>
                        )}

                        <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Configurações</Tooltip>}>
                            <Button color="blue" className="mr-2" onClick={() => this.setState({ columnEditorOpen: true }, this.onSubmitSearch)}>
                                <i className="fas fa-fw fa-cogs"></i>
                            </Button>
                        </Whisper>
                        {this.renderActionExport()}
                        {this.renderActionImport()}
                        {this.props.headers !== false && add !== false && this.props.module != 'laboratorio' && (
                            <Permission name={prefix + "-add"}>
                                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Adicionar</Tooltip>}>
                                    <Button color="green" onClick={() => this.props.history.push(`/dashboard/${this.props.module}/${this.props.name}/add`)}>
                                        <i className="fas fa-fw fa-plus"></i>
                                    </Button>
                                </Whisper>
                            </Permission>
                        )}
                        {/* {this.props.headers !== false && add !== false && this.props.module =='laboratorio' && (
                        <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Adicionar</Tooltip>}>
                            <Button color="green" onClick={this.modalLabsADD}>
                                <i className="fas fa-fw fa-plus"></i>
                            </Button>
                        </Whisper>
                    )} */}
                    </div>
                </div>
            </>
        );
    };

    filterData = () => {
        var mappedData = this.browserable.map((item: any) => {
            return {
                label: item.label
                    ? item.label
                        .replace(/<[^>]*>?/gm, "")
                        .split("&nbsp;")
                        .join("")
                    : item.name,
                value: item.name,
            };
        });

        return mappedData;
    };

    // saveFilters = (value: any) => {
    //     this.setState({ loading: true });
    //     axios
    //         .post("/api/v1/me/" + this.props.form.$GLOBALS.table + "-filters", {
    //             value,
    //         })
    //         .then(function (res) {})
    //         .finally(() => {
    //             // this.setState({isModalOpen: false});
    //             setTimeout(() => {
    //                 window.location.reload();
    //             }, 1000);
    //         })
    //         .catch((e) => {
    //             this.setState({ loading: false });
    //         });
    // };

    // saveOrders = (order: any) => {
    //     this.setState({ orders: order, loading: true });
    //     axios
    //         .post("/api/v1/me/" + this.props.form.$GLOBALS.table + "-order", {
    //             value: order,
    //         })
    //         .then(function (res) {})
    //         .finally(() => {
    //             this.setState({ loading: false });
    //         })
    //         .catch((e) => {});
    // };

    // selectFilter = (value: any) => {
    //     this.setState(
    //         (oldValues: any) => {
    //             var newFilters = [...oldValues.selectedFilters];
    //             if (newFilters.includes(value.value)) {
    //                 if (newFilters.length <= 1) {
    //                     Notification.error({
    //                         title: "Erro!",
    //                         description: "É obrigatório ao menos uma coluna ativa!",
    //                         placement: "bottomEnd",
    //                     });
    //                 } else {
    //                     newFilters.splice(newFilters.indexOf(value.value), 1);
    //                 }
    //             } else {
    //                 newFilters.push(value.value);
    //             }
    //             return { selectedFilters: newFilters };
    //         },
    //         () => {
    //             // this.saveFilters();
    //         },
    //     );
    // };

    onSortColumn = (sortColumn: string | null, sortType: string | null, operator: string = "like") => {
        // alert(sortColumn+ sortType)
        if (sortType === null) {
            sortType = "asc";
        }
        if (this.state.sortType === "desc" && sortType === "asc" && sortColumn === this.state.sortColumn) {
            sortType = null;
            sortColumn = null;
        }
        // ////
        this.setState({ sortColumn, sortType }, () => {
            this.search();
        });
    };

    applyMask = () => {
        var rows = this.state.data;
        var data = this.filterData();
        for (var i in rows) {
            var row = rows[i];
            for (let name in row) {
                var isBrowsable =
                    data.filter((item: any) => {
                        return item.value === name;
                    }).length > 0;

                if (isBrowsable) {
                    var field = this.props.form[name];
                    try {
                        if (field.mask && field.mask.length > 0) {
                            row[name] = mask.default.format(row[name], { mask: field.mask });
                        }
                    } catch (e) { }
                }
            }
        }
        return rows;
    };

    onRowDoubleClick = (row: any, index: any) => {
        if (this.props.onRowDoubleClick) {
            localStorage.setItem('searchHistoric', this.props.location.search)
            if (this.props.name == 'labs' && row.psm_template_type == null) {
                Notification.warning({
                    title: "Aviso",
                    description: 'Favor clicar em editar para assumir a PSM!',
                    placement: "topEnd",
                });
                return;
            }
            this.props.onRowDoubleClick(row, index, this.context);
        }
    };

    renderTable = () => {
        if (this.state.loading === true) {
            return (
                <div style={{ height: 400, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Loader size={"lg"} />
                </div>
            );
        }
        var maskedData = this.applyMask();
        // console.log("masked ::: ",maskedData)
        return (
            <div>
                <NecTable
                    height={400 + 25 * (this.state.perPage - 10)}
                    sortColumn={this.state.sortColumn}
                    sortType={this.state.sortType}
                    // minHeight={1000}
                    onRowDoubleClick={this.onRowDoubleClick}
                    data={maskedData}
                    renderEmpty={() => <div className="rs-table-body-info o-2">Nenhum item encontrado.</div>}>
                    {this.renderHeaders()}
                </NecTable>
                {this.eventsClass && this.eventsClass["renderFooter"] && <div className="table-bottom-items">{this.eventsClass["renderFooter"](this.props, this.state)}</div>}
                <TablePagination
                    onChangePage={(p: any) => this.search(p, this.state.perPage)}
                    onChangeLength={(p: any) => this.search(1, p)}
                    lengthMenu={[
                        {
                            value: 10,
                            label: 10,
                        },
                        {
                            value: 15,
                            label: 15,
                        },
                        {
                            value: 20,
                            label: 20,
                        },
                        {
                            value: 40,
                            label: 40,
                        },
                    ]}
                    activePage={this.state.page}
                    displayLength={this.state.perPage}
                    total={this.state.totalItems}></TablePagination>
            </div>
        );
    };

    closeAndSaveFilters = (filters: any) => {
        this.setState({ filters, columnEditorOpen: false });
        // this.saveFilters(this.state.selectedFilters);
        // //console.log(this.state.selectedFilters);
        // this.setState({ isModalOpen: false });
        // setTimeout(() => {
        //     window.location.reload();
        // }, 500);
    };

    onSelectFilter = (value: any, nivel: any) => {
        //    axios.get('') 
        if (value) {
            if (nivel == 'nivel1') {
                this.setState({
                    dataNivel2: { value: 0, label: "Nenhum" },
                    dataNivel3: { value: 0, label: "Nenhum" },

                })
                this.setState({ dataNivel2: this.state.nivel2Filter[value] })
                // console.log("nivel2",value,nivel)
            }
            if (nivel == 'nivel2') {
                this.setState({
                    dataNivel3: { value: 0, label: "Nenhum" }
                })
                this.setState({ dataNivel3: this.state.nivel3Filter[value] })
                //console.log("nivel3",value,nivel)
            }
            this.search();
        }
    }
    onSubmitSearch = () => {
        const ref: any = this.searchRef;
        var searchs: any = {};
        // const data = this.filterData();
        // for(var i in data) {
        //     searchs[data[i].value] = ref.current.value;
        // }
        searchs["searchAll"] = ref.current.value;
        this.setState(
            {
                searchs,
            },
            () => {
                this.search();
            },
        );
        // alert(ref.current.value);
    };
    render() {
        //    console.log('Props do Browserlize ::::: ',  this.props.form.$GLOBALS.table)
        return (
            <>
                <CustomModal
                    show={this.state.isModalLabsAdd}
                    onCancel={() => {
                        this.setState({
                            isModalLabsAdd: false,
                        }, this.onSubmitSearch)
                    }}
                    title={"Layout PSM"}
                    body={<>
                        <div style={{ width: 550 }}>
                            <div style={{ marginTop: 5 }}>
                                <label>NIVEL 1</label>
                                <SelectPicker
                                    style={{ width: '100%' }}
                                    data={this.state.typeEquipment}
                                    onSelect={(value: any, item, event) => this.onSelectFilter(value, 'nivel1')}
                                    onChange={(value: string) => this.setState({ typeEquipmentSelect: value })} />
                            </div>
                            <div style={{ marginTop: 15 }}>
                                <label>NIVEL 2</label>
                                <SelectPicker
                                    disabled={this.state.typeEquipmentSelect <= 0 || this.state.typeEquipmentSelect == null}
                                    style={{ width: '100%' }}
                                    data={this.state.dataNivel2}
                                    onSelect={(value: any, item, event) => this.onSelectFilter(value, 'nivel2')}
                                    onChange={(value: string, event) => this.setState({ retifierSelect: value })}
                                />
                            </div>
                            <div style={{ marginTop: 15 }}>
                                <label>NIVEL 3 </label>
                                <SelectPicker
                                    disabled={this.state.retifierSelect <= 0 || this.state.retifierSelect == null}
                                    style={{ width: '100%' }}
                                    defaultValue={{
                                        value: 0,
                                        label: "NENHUM"
                                    }}
                                    data={this.state.dataNivel3}
                                    onChange={(value: string, event) => this.setState({ typeRetifierSelect: value })} />
                            </div>
                        </div>
                    </>}
                    tableOmie={false}
                    showConfirm={true}
                    cancelColor={"red"}
                    confirmText={"Adicionar"}
                    onConfirm={() => this.escolhetemplatelab({ typeEquipment: this.state.typeEquipmentSelect, retifier: this.state.retifierSelect, typeRetifier: this.state.typeRetifierSelect, id: this.state.idLabs })}
                    confirmColor={"green"}
                    cancelText={"Fechar"}
                    onCloseButton={true}
                />
                <CustomModal
                    show={this.state.isModalLabs}
                    surfaceReport={true}
                    onCancel={() => {
                        this.setState({
                            isModalLabs: false,
                        }, this.onSubmitSearch)
                    }}
                    title={"Assumir PSM"}
                    body={<>
                        <div style={{ width: 550 }}>
                            <div style={{ marginTop: 5 }}>
                                <label>Tipo de Equipamento</label>
                                <SelectPicker
                                    style={{ width: '100%' }}
                                    disabled={this.state.typeEquipmentDisable}
                                    data={this.state.typeEquipment}
                                    onSelect={(value: any, item, event) => this.onSelectFilter(value, 'nivel1')}
                                    onChange={(value: string) => this.setState({ typeEquipmentSelect: value })} />
                            </div>
                            {(this.state.typeEquipmentSelect == 1 || this.state.typeEquipmentSelect == 2) &&
                                <div style={{ marginTop: 15 }}>
                                    <label>Potência</label>
                                    <SelectPicker
                                        disabled={this.state.typeEquipmentSelect <= 0 || this.state.typeEquipmentSelect == null}
                                        style={{ width: '100%' }}
                                        data={this.state.potenciModal}
                                        onChange={(value: string) => this.setState({ potenciSelect: value })}
                                    />
                                </div>}
                            {((this.state.typeEquipmentSelect !== 4)
                                && (this.state.typeEquipmentSelect !== 5)
                                && (this.state.typeEquipmentSelect !== 6)
                                && (this.state.typeEquipmentSelect !== 7)
                                && (this.state.typeEquipmentSelect !== 8)
                                && (this.state.typeEquipmentSelect !== 10)) &&
                                <div style={{ marginTop: 15 }}>

                                    <label>{(this.state.typeEquipmentSelect !== 3) && (this.state.typeEquipmentSelect !== 11) && (this.state.typeEquipmentSelect !== 9) ? "Retificador" : "Corrente"}</label>

                                    <SelectPicker
                                        disabled={this.state.typeEquipmentSelect <= 0 || this.state.typeEquipmentSelect == null}
                                        style={{ width: '100%' }}
                                        data={this.state.dataNivel2}
                                        onSelect={(value: any, item, event) => this.onSelectFilter(value, 'nivel2')}
                                        onChange={(value: string, event) => this.setState({ retifierSelect: value })}
                                    />
                                </div>}
                            {((this.state.retifierSelect !== 2)
                                && (this.state.typeEquipmentSelect !== 3)
                                && (this.state.typeEquipmentSelect !== 4)
                                && (this.state.typeEquipmentSelect !== 5)
                                && (this.state.typeEquipmentSelect !== 6)
                                && (this.state.typeEquipmentSelect !== 7)
                                && (this.state.typeEquipmentSelect !== 8)
                                && (this.state.typeEquipmentSelect !== 9)
                                && (this.state.typeEquipmentSelect !== 10)
                                && (this.state.typeEquipmentSelect !== 11)
                            ) &&
                                <div style={{ marginTop: 15 }}>
                                    <label>Tipo de Retificador </label>
                                    <SelectPicker
                                        disabled={this.state.retifierSelect <= 0 || this.state.retifierSelect == null}
                                        style={{ width: '100%' }}
                                        defaultValue={{
                                            value: 0,
                                            label: "NENHUM"
                                        }}
                                        data={this.state.dataNivel3}
                                        onChange={(value: string, event) => this.setState({ typeRetifierSelect: value })} />
                                </div>}
                        </div>
                    </>}
                    tableOmie={false}
                    showConfirm={true}
                    cancelColor={"red"}
                    confirmText={"Assumir"}
                    onConfirm={() => this.assumeLab({ typeEquipment: this.state.typeEquipmentSelect, retifier: this.state.retifierSelect, typeRetifier: this.state.typeRetifierSelect, id: this.state.idLabs, potenci: this.state.potenciSelect })}
                    confirmColor={"green"}
                    cancelText={"Fechar"}
                    onCloseButton={true}
                />
                <div className="w-100" ref={this.elementRef}>
                    <Panel bordered className="rs-no-body mb-4">
                        {this.header()}
                    </Panel>
                    <Panel bordered className="rs-no-body mb-4">
                        <Form onSubmit={this.onSubmitSearch}>
                            <FormGroup>
                                <InputGroup style={{ width: "100%" }}>
                                    <Input inputRef={this.searchRef} placeholder={"Pesquisar por..."} />
                                    <InputGroup.Button onClick={this.onSubmitSearch}>
                                        <i className="fas fa-search fa-fw mr-2"></i>
                                        Pesquisar
                                    </InputGroup.Button>
                                </InputGroup>
                            </FormGroup>
                        </Form>
                    </Panel>
                    <Panel bordered bodyFill className="rs-no-body">
                        {this.renderTable()}
                    </Panel>
                    {/* {this.state.isExportModalOpen} */}
                    <Modal show={this.state.isExportModalOpen} size={"lg"} className="centered">
                        <Modal.Header onHide={() => this.setState({ isExportModalOpen: false })} closeButton={true}>
                            <Modal.Title>
                                <i className="fas fa-fw mr-2 fa-cog" style={{ color: "#0096a0", fontSize: 24 }}></i>
                                Exportação/Importação
                            </Modal.Title>
                        </Modal.Header>
                        <TModal.Tabs
                            tabs={[
                                { key: "importacao", label: "Importação" },
                                // { key: "exportacao", label: "Exportação" },
                            ]}>
                            <TModal.Tab tab={"importacao"}>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ExportImportSteps
                                                onComplete={() => {
                                                    this.setState({ isExportModalOpen: false });
                                                    this.search();
                                                }}
                                                crud={this.props.form.$GLOBALS.table}
                                                step={this.state.importStep}
                                                onStepChange={(step: number) => this.setState({ importStep: step })}
                                            />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </TModal.Tab>
                            <TModal.Tab tab={"exportacao"}>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-md-12">exportacao</div>
                                    </div>
                                </Modal.Body>
                            </TModal.Tab>
                        </TModal.Tabs>
                    </Modal>

                    <ColumnEditor
                        onHide={() => this.setState({ columnEditorOpen: false }, this.onSubmitSearch)}
                        onSave={this.closeAndSaveFilters}
                        page={this.props.form.$GLOBALS.table}
                        isOpen={this.state.columnEditorOpen}></ColumnEditor>
                </div>
            </>
        );
    }
}

export default withRouter(Browserlize);
