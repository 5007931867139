import { Event } from "../Event";



export class PsmNewEquipmentValueEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        var dataPsm: any = this.globalProps.values
        let percentRef = (dataPsm.psm_final_value * 100) / value;
        await this.setState("loadings", [
            "psm_refer_equipment_new"
        ]);
        this.setFieldValue("psm_refer_equipment_new", percentRef)


        this.setState("loadings", [])
    }
}
