import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";
import { Panel, Loader, Input, FormGroup, ControlLabel, List, InputGroup, Notification, Tag, TagPicker } from "rsuite";
import InputGroupButton from "rsuite/lib/InputGroup/InputGroupButton";
import axios from "../utilities/axios";


class ParametersPage extends React.Component<any, {}> {
    public state = {
        loading: true,
        loadings: [] as string[],
        dirty: [] as string[],
        fields: {} as any,
        values: {} as any,
        formData: {} as any,
    };

    componentDidMount() {
        axios
            .get("/api/v1/parameters",{
                params: {
                    getUser: this.props.user ?? undefined
                }
            })
            .then((res) => {
                this.setState({
                    loading: false,
                    fields: res.data.fields,
                    values: res.data.values,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    updateFormDataByField = (field: string, value: any) => {
        const formData = _.cloneDeep(this.state.formData);
        const dirty = _.cloneDeep(this.state.dirty);
        if (dirty.indexOf(field) == -1) {
            dirty.push(field);
        }
        formData[field] = value;
        this.setState({ formData, dirty });
    };

    saveParameter = (field: string) => {
        const loadings = _.cloneDeep(this.state.loadings);
        if (loadings.indexOf(field) == -1) {
            loadings.push(field);
        }
        this.setState({ loadings });
        axios
            .post("/api/v1/parameters/" + field, { value: this.state.formData[field],getUser: this.props.user ?? undefined })
            .then(() => {
                const loadings = _.without(this.state.loadings, field);
                const dirty = _.without(_.cloneDeep(this.state.dirty), field);
                this.setState({ loadings, dirty });
                Notification.success({
                    title: "Sucesso",
                    description: "Parametro salvo com sucesso",
                });
            })
            .catch(() => {
                const loadings = _.without(this.state.loadings, field);
                this.setState({ loadings });
                Notification.error({
                    title: "Error",
                    description: "Falha ao salvar parametro",
                });
            });
    };

    renderFieldByType = (field : any, key: string, value: any) => {
        switch (field.type) {
            case "text":
                return <Input defaultValue={value} onChange={(value) => this.updateFormDataByField(key, value)} />;
            case 'tags':
                return <TagPicker className="w-100" data={_.map(value,(item) => {
                    return {
                        label: item,
                        value: item
                    }
                }) ?? []} creatable defaultValue={value} onChange={(value) => this.updateFormDataByField(key, value)} />
        }
    };

    renderFieldGroupForEachType = () => {
        var { fields, values } = this.state;
        const listItems = _.map(fields, (field, key) => {
            const value = values[key];
            return (
                <List.Item>
                    <FormGroup>
                        <ControlLabel>{field.label}</ControlLabel>
                        <InputGroup>
                            {this.renderFieldByType(field, key, value)}
                            <InputGroupButton
                                onClick={() => this.saveParameter(key)}
                                loading={this.state.loadings.includes(key)}
                                color={this.state.dirty.includes(key) ? "green" : undefined}
                                disabled={!this.state.dirty.includes(key)}>
                                Salvar
                            </InputGroupButton>
                        </InputGroup>
                    </FormGroup>
                </List.Item>
            );
        });
        return <List className="w-100">{listItems}</List>;
    };

    render() {
        if(this.props.fieldsOnly == true) {
            return this.renderFieldGroupForEachType();
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Panel bordered style={{ backgroundColor: "#fff" }} className="rs-no-body d-flex allow-overflow w-100" expanded>
                            {this.state.loading == true && <Loader center inline size="lg" content="Loading..." />}
                            {this.state.loading == false && this.renderFieldGroupForEachType()}
                        </Panel>
                    </div>
                </div>
                {/* <Svg404 /> */}
            </div>
        );
    }
}

export default withRouter(ParametersPage);
