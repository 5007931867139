import axios from "../../utilities/axios";
import { Event } from "../Event";

class RootProductEvent extends Event {
    //init onAdd
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        // alert("oops");
        if (!formValues.product_intern_reference) {
            return;
        }
        if (formValues.product_intern_reference.length > 0) {
            return;
        }
        var defMaterialCod = formValues.product_fk_material_definition_id ? (formValues.product_fk_material_definition_id.toString() as string).padStart(2, "0") : "00";
        var tipoMaterialCod = formValues.product_fk_material_types_id ? (formValues.product_fk_material_types_id.toString() as string).padStart(2, "0") : "00";
        var codSeqSistema = "00000";
        var fabricanteCod = formValues.product_manufacturer_code ? (formValues.manufacturer_id.toString() as string).padStart(3, "0") : "00";
        axios.get("/api/v1/products/latest").then((res) => {
            if (res && res.data && res.data.next) {
                codSeqSistema = (res.data.next.toString() as string).padStart(5, "0");
            }
            var refInterna = `${defMaterialCod}.${tipoMaterialCod}.${codSeqSistema}.${fabricanteCod}`;
            setField("product_intern_reference", refInterna);
        });
    };
    //end
}


export class ProductFkMaterialTypesIdEvents extends RootProductEvent { }
export class ProductFkMaterialDefinitionIdEvents extends RootProductEvent { }
export class ProductManufacturerCodeEvents extends RootProductEvent { }

export class ProductBarCodeEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        // setField('person_is_active',false);
        // setState('loadings',[
        //     'person_is_active'
        // ])
        // setField('person_name','abas')
        // return;
        try{
            if(this.globalProps.match.url.includes('/add')){
                if (value && value.length > 12) {

                    axios
                        .get("/api/v1/consulta-codigo-de-barras/" + value, {params: {
                            table: 'products',
                            column: 'product_bar_code',
                            product_bar_code: value
                        }})
                        .then(function (res) {
                            var { data } = res.data;
        
                            if (data.status === "ERROR") {
                                setState("errors", {
                                    product_bar_code: res.data.error ?? "Código de Barras Inválido",
                                });
                                setState("loadings", []);
                                return;
                            }
                        })
                        .catch((e) => {
                            setState("loadings", []);
                        })
                        .finally(() => {
                            // setState("loadings", []);
                        });
                }
            }
        }catch(e){console.log('Error :: ', e)}
    };
}

export class ProductEvents extends Event {
    public onLoad = (value: any) => {
        var classificacaoFiscal = this.values.product_fk_fiscal_classification_id;
        if (classificacaoFiscal !== null) {
            this.setFieldValue("product_fk_fiscal_classification_id", this.values.product_fk_fiscal_classification_id);
            this.setState("loadings", ["product_fk_fiscal_classification_id",]);
            // console.log("Props produtos", this)
            this.setFieldValue("product_fk_fiscal_classification_id", this.values.product_fk_fiscal_classification_id);
            this.setState("loadings", []);
        }
        
        if(this.globalProps.match.url.includes('/add')){
            this.setField("product_similarities", {
                hidden: true
            })
        }

        try {
            if (!['', 0, '0', null, undefined].includes(this.values.product_id_omie)) {
                axios
                    .get(`/api/v1/products/get-products/${this.values.product_id_omie}/omie`)
                    .then((res) => {
                        let valuesProducts = res.data.response;
                        for (let i in valuesProducts) {
                            if (valuesProducts[i] !== null) {
                                if (valuesProducts[i].codigo_local_estoque == 2398047389) {
                                    this.setFieldValue("product_stock_limeira", valuesProducts[i].saldo);
                                }
                                if (valuesProducts[i].codigo_local_estoque == 4987314829) {
                                    this.setFieldValue("product_stock_maringa", valuesProducts[i].saldo);
                                }
                                if (valuesProducts[i].codigo_local_estoque == 2124003533) {
                                    this.setFieldValue("product_stock_serra", valuesProducts[i].saldo);
                                }
                            }
                        }
                    })
            }

        }catch(e){console.log('Errpr ::: ',e)}




        
    }

}