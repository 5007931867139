import axios from "../../utilities/axios";
import { Event } from "../Event";

export class SellerCardClientRegisteredEvents extends Event {  

    public onChange = (value: any) => {
        // alert("MUDOU!");
        if (value !== null) {
            // alert(value === '1')
            if(value === '1') {
                this.setFieldProperty('seller_card_fk_city_id','hidden',true);
                this.setFieldProperty('seller_card_fk_state_id','hidden',true);
                this.setFieldProperty('seller_card_client_name','hidden',true);
            } else {
                this.setFieldProperty('seller_card_fk_city_id','hidden',false);
                this.setFieldProperty('seller_card_fk_state_id','hidden',false);
                this.setFieldProperty('seller_card_client_name','hidden',false);
            }
            // this.setState({ loadings: ["seller_card_client_name", "seller_card_fk_city_id", "seller_card_fk_state_id", "seller_card_fk_activity_sector_id"] });
            // axios
            //     .get("/api/v1/person/" + value)
            //     .then((res) => {
            //         this.setFieldValue('seller_card_fk_city_id',{label: res.data.item.city.city_name,value: res.data.item.city.city_id.toString()})
            //         this.setFieldValue('seller_card_fk_state_id',{label: res.data.item.state.state_name,value: res.data.item.state.state_id.toString()})
            //         this.setFieldProperty('seller_card_fk_city_id','disabled',true);
            //     })
            //     .catch((e) => {})
            //     .finally(() => {
            //         this.setState({ loadings: [] });
            //     });
        }
    };
}


export class SellerCardFkPersonIdEvents extends Event {
    public onChange = (value: any) => {
        // alert("MUDOU!");
        if (value !== null) {
            this.setState({ loadings: ["seller_card_client_name", "seller_card_fk_city_id", "seller_card_fk_state_id", "seller_card_fk_activity_sector_id"] });
            axios
                .get("/api/v1/person/" + value)
                .then((res) => {
                    this.setFieldValue('seller_card_fk_city_id',{label: res.data.item.city.city_name,value: res.data.item.city.city_id.toString()})
                    this.setFieldValue('seller_card_fk_state_id',{label: res.data.item.state.state_name,value: res.data.item.state.state_id.toString()})
                    this.setFieldValue('seller_card_client_name', res.data.item.person_fantasy)
                    this.setFieldProperty('seller_card_fk_city_id','hidden',true);
                })
                .catch((e) => {})
                .finally(() => {
                    this.setState({ loadings: [] });
                });
        }
    };
}
