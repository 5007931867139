import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PurchaseRequisitionAllocatadeRequisitionPsmEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        let isTypePsm = value[0].psm_os_number.substring(0, 2);
        isTypePsm = isTypePsm.substring(1);
        const isCrud = isTypePsm == 'L' ? 'commercials' : 'dibi';
        const isSelectSituationPsm = isTypePsm == 'L' ? 'situation' : 'dibi_situation';

        // console.log("Rodei aqui no ON CHANGE", value)

        axios.get(`/api/v1/purchase/generate-code/` + value[0][`psm_fk_company_id`])
            .then((res) => {
                this.setFieldValue('purchase_requisition_os_number', res.data.code);
            })
 
        this.setState('loadings', [
            'purchase_requisition_number_psm_os_number', 
            'purchase_requisition_fk_psm_id',
            'purchase_requisition_fk_business_unity_id',
            'purchase_requisition_fk_person_id',
            'purchase_requisition_psm_dibi_situation',
            'purchase_requisition_comercial_status',
            'purchase_requisition_purpose_product',
            'purchase_requisition_priority',
            'purchase_requisition_deadline',
            'purchase_requisition_relations',
            'purchase_requisition_requisition_date',
            'purchase_requisition_fk_company_id',
            'purchase_requisition_fk_situation_id',
            'purchase_requisition_fk_dibi_situation_id',
            'purchase_requisition_manual_creation',
            'purchase_requisition_fk_sub_situation_id',
            'purchase_requisition_relation_fk_select_id'
        ])
        const newObject = [];
        for (const i in value) {
            let isValidType = false;
            for (const t in value[i]) {
                if (t.includes('_relation_product')) {
                    isValidType = true;
                    continue;
                }
            }
            // console.log("value  ::::::::>", value[i])
            // purchase_requisition_relation_fk_select_id
            const isTypeTableProduct = isValidType ? 'product' : 'service';
            newObject.push({
                purchase_requisition_relation_item_rc: Number(i) + 1,
                purchase_requisition_relation_not_registered: value[i][`psm_relation_${isTypeTableProduct}_registered`],
                purchase_requisition_relation_installed: value[i][`psm_relation_${isTypeTableProduct}_instal`],
                purchase_requisition_relation_fk_select_id: value[i][`psm_relation_${isTypeTableProduct}_fk_select_id`],
                purchase_requisition_relation_intern_reference: value[i][`psm_relation_${isTypeTableProduct}_intern_reference`],
                purchase_requisition_relation_item_description: value[i][`psm_relation_${isTypeTableProduct}_item_description`],
                purchase_requisition_relation_manufacturer_code: value[i][`psm_relation_${isTypeTableProduct}_manufacture_code`],
                purchase_requisition_relation_quantity_total: value[i][`psm_relation_${isTypeTableProduct}_quantity`],
                purchase_requisition_relation_table_product_id: value[i][`psm_relation_${isTypeTableProduct}_id`],
                purchase_requisition_relation_product_table_name: `psm_relation_${isTypeTableProduct}s`,
                // purchase_requisition_relation_observation: value[i][`psm_relation_${isTypeTableProduct}_obs`],
                // purchase_requisition_relation_table_product_id: value[i][`psm_relation_${isTypeTableProduct}_fk_select_id`],
                purchase_requisition_relation_psm_id: value[0][`psm_id`]
            })
            // console.log ("Eu to aqui 1 ::::::::",  value[i][`psm_relation_product_id`]);
            // console.log ("Eu to aqui 2 ::::::::", value[i]);
        }
        // console.log("valor no newObject", newObject)
        this.setFieldValue('purchase_requisition_relations', newObject)
        // console.log("valor do newObject", newObject)
        const typeOpen = this.globalProps.values.purchase_requisition_type_open
        // console.log("Value do typeOpen", typeOpen)
        axios.get(`/api/v1/${isCrud}/` + value[0]['psm_id'])
            .then((res) => {
                const itens = res.data.item;
                // console.log("Value do typeOpen 2", typeOpen)

                if (typeOpen != '2'){
                    // console.log("irm herehre::>", {itens});                
                    this.setFieldValue('purchase_requisition_fk_psm_id', itens.psm_id);
                    this.setFieldValue('purchase_requisition_number_psm_os_number', itens.psm_os_number);     
                    this.setFieldValue('purchase_requisition_fk_business_unity_id', itens.psm_fk_business_unity_id);  
                    this.setFieldValue('purchase_requisition_fk_person_id', itens.psm_clients_fk_person_id);
                    this.setFieldValue(`purchase_requisition_fk_${isCrud === 'commercials' ? 'situation' : 'dibi_situation'}_id`, itens[`${isSelectSituationPsm}`][`${isSelectSituationPsm}_id`]);
                    // this.setFieldValue(``,)
                    if (itens.psm_comercial_status !== null) {
                        this.setFieldValue('purchase_requisition_comercial_status', itens.psm_comercial_status);
                    }
                    if (itens.psm_purpose_product !== null) {
                        this.setFieldValue('purchase_requisition_purpose_product',itens.psm_purpose_product);
                    }
                    this.setFieldValue('purchase_requisition_priority', itens.psm_priority);
                    this.setFieldValue('purchase_requisition_delivery_date', itens?.psm_final_date_delivery);
                    this.setFieldValue('purchase_requisition_fk_company_id', itens.psm_fk_company_id);
                }                
            })

            .finally(() => {
                this.setState('loadings', [])
            })
    }
}