import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col, Whisper, InputGroup, Tooltip } from "rsuite";
import { Event } from "../Event";
import { UserContext } from "../../components/authProvider/main";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal"
import classNames from "classnames";
import { link } from "fs";
import { MailerModal } from "../../components/mailer/MailerModal";

// var existRm: boolean = false;

class PsmFooterComponent extends React.Component<any, {}> {
    // static async onSave(data: Array<Record<string, any>>, id: string | number, guid: any, req: any,) {
    //     console.log("Im heres ::::::::>")
    // }
    static contextType?: React.Context<any> | undefined = UserContext;
    public state = {
        show: false,
        showEmail: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        started: false,
        loadingClockIn: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false,
        to: [],
        cc: [],
        cco: [],
        files: [],
        isOpen: false,
        emailSubject: "",
        psmId: [],
        modalLoading: false,
    };
    sendMail = () => {
        this.setState({ loading: true });
        axios
            .get("/api/mail/test")
            .then((res) => {
                if (res.status === 200 && res.data.status) {
                    Notification.success({
                        title: "Sucesso!",
                        description: "Email de (testes) enviado com sucesso!",
                        duration: 3500,
                    });
                    // if(window.location.host.includes('localhost')) {
                    //     window.open("http://localhost:25567"+res.data.output.path, "_blank");
                    // } else {
                    //     window.open(res.data.output.path, "_blank");
                    // }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    onClick = (type: "tecnica" | "orcamento" = "orcamento") => {
        this.setState({ loading: true });
        axios
            .get("/api/v1/print/estimates/" + this.props.printId, {
                params: {
                    type,
                },
            })
            .then((res) => {
                if (res.status === 200 && res.data.status) {
                    // console.log("Olha o output:::::",res.data)
                    // console.log("Olha o document:::::",res)
                    if (window.location.host.includes("192.168.15.48")) {
                        window.open("http://192.168.15.48:25567" + res.data.output.path, "_blank");
                    } else if (window.location.host.includes("localhost")) {
                        window.open("http://localhost:25567" + res.data.output.path, "_blank");
                    } else {
                        window.open(res.data.output.path, "_blank");
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    gerarRevisao = () => {
        this.setState({ loading: true });
        // console.log("Meu console",this)
        axios
            .get("/api/v1/psm/save-revisao/" + this.props.printId)
            .then((res) => {
                if (res.status === 200 && res.data.status) {
                    // console.log("Save revisao",res)
                    this.props.onSubmit(
                        () => {
                            this.setState({ loading: true });
                            // alert("SAVED !");
                            var currentRouteSplited = this.props.history.location.pathname.split("/");
                            var currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");

                            axios
                                .get("/api/v1/psm/revisao/" + this.props.printId)
                                .then((res) => {
                                    // console.log("Somente revisão", res)
                                    if (res.status === 200 && res.data.status) {
                                        // console.log(this.props.history);
                                        this.props.history.push(currentRoute + "/" + res.data.result + "?force=true");
                                        // window.open("http://localhost:25567" + res.data.output.path, "_blank");
                                        // alert(JSON.stringify(res.data.output.path));
                                    }
                                })
                                .finally(() => {
                                    this.setState({ loading: false });
                                });
                        },
                        () => {
                            this.setState({ loading: false });
                        },
                    );
                } else {
                    throw new Error("Error");
                }
            })
            .catch((err) => {
                this.setState({ loading: false });
            })
            .finally(() => { });
    };

    gerarRevisaoComercial = () => {
        axios
            .get(`/api/v1/commercial/save-revision/${this.props.printId}`)
            .then((res) => {
                if (res.status === 200 && res.data.status) {
                    this.props.onSubmit(
                        () => {
                            this.setState({ loading: true });
                            axios
                                .get("/api/v1/commercial/revisao/" + this.props.printId)
                                .then((res) => {
                                    if (res.status === 200 && res.data.status) {
                                        this.props.history.push(0);
                                    }
                                })
                                .finally(() => {
                                    this.setState({ loading: false });
                                });
                        },
                        () => {
                            this.setState({ loading: false });
                        },
                    );
                } else {
                    Notification.warning({
                        title: "Aviso",
                        description: res.data.message,
                        duration: 3500
                    })
                    return
                }
            })
            .catch((err) => {
                this.setState({ loading: false });
            })
            .finally(() => { });
    }

    compairValuesComercialRevision = () => {
        this.setState({ loading: true })
        axios
            .post(`/api/v1/commercial/compair-revision`, {
                id: this.props.printId,
                data: this.props.values.formValues
            })
            .then((res) => {
                if (res.data.status == 200) {
                    this.setState({ show: true });
                } else {
                    this.props.onSubmit()
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    savePsm = () => {
        this.props.onSubmit()
    }

    concluiPsm = () => {
        this.setState({
            loading: true,
        });
        axios
            .get("/api/v1/psm/revisaoUpdate/" + this.props.printId)
            .then((res => {
                if (res.status === 200 && res.data.status) {
                    // console.log("aaaaaaaaa",res.data.result);
                    this.props.history.go(0)
                }
            }))
            .finally(() => {
                this.setState({ loading: false });
            });

    }

    alteraSituacao = () => {
        this.setState({ showSituation: true })
        // axios
        //     .get("/api/v1/psm/mudandoStatus/" + this.props.printId)
        //     .then((res => {
        //         if (res.status === 200 && res.data.status) {
        //             this.setState({ situacaoModal: res.data.tiposLast });
        //             // console.log(res.data.tiposLast);
        //             //this.props.history.go(0)
        //         }
        //     }))
        //     .finally(() => {
        //         this.setState({ loading: false });
        //     });
    }

    trocaSituacao = (value: any = null) => {
        this.setState({ loading: true });
        axios
            .get("/api/v1/estimates/status/" + value + "/" + this.props.printId)
            .then((res => {
                if (res.status === 200 && res.data.status) {
                    this.setState({ situacaoModal: res.data.statusNew });
                    // console.log(res.data.statusNew);

                    this.props.history.go(0)

                }
            }))
            .finally(() => {
                this.setState({ loading: false });
            });

    }
    aprovedAndReproved = (status: 'aprovar' | 'reprovar' = 'aprovar') => {
        const view = this.props.location.pathname.includes('dibis') ? 'dibis' : 'psms';
        console.log({ view })
        // console.log("valor do this props na labs", this.props)
        const isProspect: boolean = this.props.values.formValues.psm_client_is_prospect;
        // let id: string | number = status === 'reprovar' ? (this.props.values.formValues.psm_status_fk_situation_id + 1) : this.props.values.formValues.psm_status_fk_situation_id;
        // console.log({id})
        if (isProspect == true && status == 'aprovar') {
            Notification.error({
                title: "Erro!",
                description: "Não foi possível aprovar pois o cliente é do tipo Prospect!",
                placement: "bottomEnd",
                duration: 3500,
            });
        } else {
            this.setState({ loading: true });
            axios
                .post(`/api/v1/situation/controller/psm/${this.props.match.params.crud}/${this.props.match.params.id}/${this.props.values.formValues.psm_status_fk_situation_id}/${status}`, { data: this.props.values.formValues })
                .then((res) => {
                    if (res.data.status === 201) {
                        let labels: string = "";
                        for (const item of res.data.errors) {
                            labels += `> ${item.label}, `;
                        }
                        labels = labels.slice(0, -2)
                        Notification.error({
                            title: "Campos obrigatorios para avançar situação!",
                            description: labels,
                            placement: "bottomEnd",
                            duration: 3500,
                        });
                        this.setState({ loading: false });
                        return;
                    }
                    if (res.data.status === 200) {
                        this.props.onSubmit(
                            () => {
                                this.setState({ loading: true });
                                // alert("SAVED !");
                                var currentRouteSplited = this.props.history.location.pathname.split("/");
                                var currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");

                                axios
                                    .get("/api/v1/estimates/statu/" + status + "/" + this.props.printId + "/" + view, { params: { text: this.state.reprovetionText } })
                                    .then((res => {
                                        if (res.status === 200 && res.data.status) {
                                            this.props.history.go(0);
                                        }
                                    }))
                            },
                            () => {
                                this.setState({ loading: false });
                            },
                        );
                    }
                })
                .catch((err) => {
                    console.log('error :::: ', err)
                })
        }
    }

    disclaimLabs = () => {
        let psmId = this.props.location.pathname.split("/").pop()
        // console.log(this.props)
        this.setState({
            loading: true
        });
        axios
            .post("/api/v1/lab/disclaim-lab/", {
                id: psmId,
            })
            .then((res) => {
                // console.log(res.data)
                this.setState({ loading: false });
                window.location.assign('/dashboard/laboratorio/labs?page=1&perPage=10');
            })
            .finally(() => {
                this.setState({
                    loading: false
                });
            })
    }

    disclaimModal = () => {
        this.setState({
            showDisclaimModal: true
        });
    }


    clockIn = (id: any) => {
        this.setState({ loading: true });
        //TODO: chamar api ao em vez disso aqui né porra
        // setTimeout(() => {
        axios
            .get("/api/v1/clock-in/" + id)
            .then((response) => {
                Notification.success({
                    title: "Sucesso!",
                    description: "Horário apontado com sucesso!",
                    duration: 3500
                });
                this.setState({ started: !this.state.started, loading: false });
                if (this.state.started == false) {
                    this.props.history.go(0);
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            })
            .catch(() => {
                Notification.error({
                    title: "Erro!",
                    description: "Não foi possível apontar horario da PSM",
                    duration: 3500
                });
                this.setState({ loading: false });
            });
        // }, 2000);
    };

    componentDidUpdate(prevProps: any) {
        if (this.props.printId !== prevProps.printId && this.props.printId !== undefined) {
            this.loadClockInStatus();
        }
    }
    componentDidMount() {
        this.loadClockInStatus();

        const reloadTrigger = localStorage.getItem('reloadTrigger');

        if (reloadTrigger === 'true') {
            // setTimeout(() => {
            //     localStorage.removeItem('reloadTrigger');
             
            //     const divNumNf = document.getElementById('psm_num_nf_id');
            //     if (divNumNf) {
            //         const inputNumNf = divNumNf.querySelector('input');
            //         if (inputNumNf) {
            //             inputNumNf.value = ''; 
            //         }
            //     }

            //     const divAccessKey = document.getElementById('psm_acess_key');
            //     if (divAccessKey) {
            //         const inputAccessKey = divAccessKey.querySelector('input');
            //         if (inputAccessKey) {
            //             inputAccessKey.value = ''; 
            //         }
            //     }

            //     const divNitem = document.getElementById('psm_nitem');
            //     if (divNitem) {
            //         const inputNitem = divNitem.querySelector('input');
            //         if (inputNitem) {
            //             inputNitem.value = ''; 
            //         }
            //     }
                
            //     const div = document.getElementById('psm_custom_nf_incorporate');
            //     if (div) {
            //         const button = div.querySelector('button');
            //         if (button) {
            //             button.click();
            //         }
            //     }
            //     this.forceUpdate()
            // }, 1500)
        }

    }

    loadClockInStatus = () => {
        // this.setState({ loadingClockIn: true })
        // axios
        //     .get("/api/v1/clock-in/status/" + this.props.printId)
        //     .then((response) => {
        //         //alert(response.data.aprovado)
        //         this.setState({ started: response.data.aprovado });
        //         // this.loaded = true;
        //     })
        //     .catch((error) => { })
        //     .finally(() => {
        //         this.setState({ loadingClockIn: false });
        //     });
    }

    printChekList = () => {
        // console.log('Props print :::', this.props.printId);
        this.setState({ loading: true })
        axios
            .get("/api/v1/receipts/chek-list/" + this.props.printId)
            .then((res) => {
                if (res.data && res.data.url) {
                    if (res.data && res.data.url) {
                        window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    printEtiquetaReceipts = () => {
        var oldValues = this.props.values.oldValues
        var anexoEmbalagem = oldValues.psm_packaging_attachment[0]
        // console.log("anexos:::" , anexoEmbalagem)
        var anexoEquipamento = oldValues.psm_equipment_attachment[0]
        var anexoEtiqueta = oldValues.psm_label_attachments[0]
        // console.log("embalagem::::" , anexoEmbalagem)

        if (anexoEmbalagem === undefined || anexoEquipamento === undefined ||
            anexoEtiqueta === undefined) {
            // console.log("TODOS SÃO DIFERENTES DE UNDEFINED")
            Notification.error({
                title: "Erro",
                description: "Favor anexar fotos para Prosseguir.",
                duration: 5000
            });
            //   window.location.hash = '/dashboard/compras-estoque/receipts/' + this.props.values.oldValues.psm_id + "?tab=attachments"
            // this.props.history.push('/dashboard/compras-estoque/receipts/' + this.props.values.oldValues.psm_id + "?tab=attachments")
        } else {

            this.setState({ loading: true });
            if ([1, 2].includes(this.props.values.formValues.psm_status_fk_situation_id)) {


                axios
                    .post(`/api/v1/situation/controller/psm/receipts/${this.props.values.oldValues.psm_id}/${this.props.values.formValues.psm_status_fk_situation_id}`, { data: this.props.values.formValues })
                    .then((res) => {
                        console.log(res);
                        if (res.data.status === 201) {
                            let labels: string = "";
                            for (const item of res.data.errors) {
                                labels += `> ${item.label}, `;
                            }
                            labels = labels.slice(0, -2)
                            Notification.error({
                                title: "Campos obrigatorios para avançar situação!",
                                description: labels,
                                placement: "bottomEnd",
                                duration: 3500,
                            });
                            this.setState({ loading: false });
                            return;
                        }
                        if (res.data.status === 200) {
                            this.props.onSubmit(
                                () => {
                                    this.setState({ loading: true });
                                    var currentRouteSplited = this.props.history.location.pathname.split("/");
                                    var currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");
                                    axios
                                        .get("/api/v1/receipts/etiqueta/" + this.props.values.oldValues.psm_id)
                                        .then((res) => {
                                            if (this.props.values.formValues.psm_checked_print_checklist == true) {
                                                // console.log("Entrou no IF :::::>> ", this.props.values.formValues.psm_checked_print_checklist)
                                                Notification.warning({
                                                    title: "Alerta Impressão!",
                                                    description: "A impressão da etiqueta não atualizará a situação dessa PSM, ela deverá ser avançada manualmente!",
                                                    duration: 10000
                                                });
                                            } else {
                                                // console.log("Entrou no ELSE ::::>> ", this.props.values.formValues.psm_checked_print_checklist)
                                                Notification.warning({
                                                    title: "Alerta Impressão!",
                                                    description: "A impressão da etiqueta atualizará a situação dessa PSM para: AGUARDANDO ANÁLISE",
                                                    duration: 10000
                                                });
                                            }

                                            setTimeout(() => {
                                                if (res.data && res.data.url) {
                                                    window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                                                    this.props.history.go(0)
                                                }
                                            }, 4000);
                                        })
                                        .catch((e) => {
                                            Notification.error({
                                                title: "[" + e.response.status + "] Erro",
                                                description: e.response.data.message ? e.response.data.message : e.message,
                                                duration: 3500
                                            });
                                        })
                                        .finally(() => {
                                            this.setState({ loading: false });
                                        });
                                },
                                () => {
                                    this.setState({ loading: false });
                                },
                            );
                        }
                    })
                    .catch((err) => {
                        console.log('error :::: ', err)
                    })
            } else {
                axios
                    .get("/api/v1/receipts/etiqueta/" + this.props.values.oldValues.psm_id)
                    .then((res) => {
                        console.log({ res })
                        if (res.data && res.data.url) {
                            window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                            this.props.history.go(0)
                        }
                    })
                    .catch((e) => {
                        Notification.error({
                            title: "[" + e.response.status + "] Erro",
                            description: e.response.data.message ? e.response.data.message : e.message,
                            duration: 3500
                        });
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            }
        }
    }

    unlinkNf = async () => {
        await axios.get(`/api/v1/logs/unlinks/unlinknf/${this.props.values.formValues.psm_id}/${this.props.values.formValues.psm_acess_key}`).then((res) => {
            console.log("res :>", res)
            if (res.status == 200) {
                localStorage.setItem('reloadTrigger', 'true');
                this.props.history.go(0);
            }
        });
    };



    printPropositure = (type: 'commercials' | 'technical') => {
        if ([0, '0', null, undefined].includes(this.props.values.formValues.psm_show_budget)) {
            Notification.warning({
                title: "Aviso!",
                description: "Selecionar como deseja que seja exibido o orçamento!",
                duration: 3500
            });
            return;
        }

        this.props.onSubmit(
            () => {
                this.setState({ loading: true });

                axios
                    .get("/api/v1/commercial/print/propositure/" + this.props.printId + '/' + type)
                    .then((res) => {
                        if (res.data && res.data.url) {
                            window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                        }
                    })
                    .catch((e) => {
                        Notification.error({
                            title: "[" + e.response.status + "] Erro",
                            description: e.response.data.message ? e.response.data.message : e.message,
                            duration: 3500
                        });
                    })
                    .finally(() => {
                        // this.setState({ loading: false });
                    });

            },
            () => {
                this.setState({ loading: false });
            },
        );

    }

    trocaStatus = async (status: 'avanca' | 'voltar' = 'avanca') => {
        // console.log(this.props.state.checked)
        const view = this.props.location.pathname.includes('dibis') ? 'dibis' : 'psms';
        if (!this.props.match.params.id) {
            Notification.error({
                title: "Erro!",
                duration: 3500
            });
            return;
        }
        if (status == 'voltar' && this.props.values.formValues.psm_status_fk_situation_id == 3) {
            Notification.error({
                title: "Erro!",
                description: 'Não é possivel retornar situação para aguardando análise, para retornar favor DESATRIBUIR',
                duration: 3500
            });
            return;
        }
        if (status == "voltar" && this.props.values.formValues.psm_status_fk_situation_id == 10) {

            var existRm: boolean = false;
            await axios.get('/api/v1/psm/verifyrm', {
                params: {
                    psmNumber: this.props.values.formValues.psm_os_number,
                }
            }).then((response: any) => {
                // console.log("valor do response", response)
                existRm = true;
            })
                .catch((e) => {
                    console.log(e)
                })
            console.log("Valor do existRm", existRm)
            if (existRm) {
                Notification.error({
                    title: "Erro!",
                    description: 'Não foi possível voltar a PSM pois existe RMs que foram criadas para essa PSM',
                    duration: 3500,
                });
                return;
            }
        }

        this.setState({ loading: true });
        if (status == 'avanca') {
            axios
                .post(`/api/v1/situation/controller/psm/${this.props.match.params.crud}/${this.props.match.params.id}/${this.props.values.formValues.psm_status_fk_situation_id}`, { data: this.props.values.formValues })
                .then((res) => {
                    if (res.data.status === 201) {
                        let labels: string = "";
                        for (const item of res.data.errors) {
                            labels += `> ${item.label}, `;
                        }
                        labels = labels.slice(0, -2)
                        Notification.error({
                            title: "Campos obrigatorios para avançar situação!",
                            description: labels,
                            placement: "bottomEnd",
                            duration: 3500,
                        });
                        this.setState({ loading: false });
                        return;
                    }
                    if (res.data.status === 200) {
                        this.props.onSubmit(
                            () => {
                                this.setState({ loading: true });
                                // alert("SAVED !");
                                var currentRouteSplited = this.props.history.location.pathname.split("/");
                                var currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");

                                axios
                                    .get(`/api/v1/situation/${status}/${view == 'psms' ? this.props.values.oldValues.psm_status_fk_situation_id : this.props.values.oldValues.psm_status_fk_dibi_situation_id}/${this.props.match.params.id}/${view}`)
                                    .then((async res => {
                                        if (res.status === 200 && res.data.status == 200) {
                                            await Notification.success({
                                                title: "Sucesso!",
                                                description: res.data.message,
                                                duration: 3500,
                                            });
                                            setTimeout(() => {
                                                this.props.history.push(`/dashboard/${this.props.match.params.module}/${this.props.match.params.crud}/${this.props.match.params.id}?tab=Main`);
                                                this.props.history.go(0)
                                            }, 3500);

                                        }
                                        if (res.data.status == 201) {
                                            Notification.warning({
                                                title: "Atenção!",
                                                description: res.data.message,
                                                duration: 3500
                                            });
                                        }
                                    }))
                                    .finally(() => {
                                        this.setState({ loading: false });
                                    });
                            },
                            () => {
                                this.setState({ loading: false });
                            },
                        );
                    }
                })
                .catch((err) => {
                    console.log('error :::: ', err)
                })
        }
        if (status == 'voltar') {
            axios
                .get(`/api/v1/situation/${status}/${view == 'psms' ? this.props.values.oldValues.psm_status_fk_situation_id : this.props.values.oldValues.psm_status_fk_dibi_situation_id}/${this.props.match.params.id}/${view}`)
                .then((async res => {
                    if (res.status === 200 && res.data.status == 200) {
                        await Notification.success({
                            title: "Sucesso!",
                            description: res.data.message,
                            duration: 3500,
                        });
                        setTimeout(() => {
                            this.props.history.push(`/dashboard/${this.props.match.params.module}/${this.props.match.params.crud}/${this.props.match.params.id}?tab=Main`);
                            this.props.history.go(0)
                        }, 3500);


                    }
                    if (res.data.status == 201) {
                        Notification.warning({
                            title: "Atenção!",
                            description: res.data.message,
                            duration: 3500
                        });
                    }
                }))
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    }


    enviarOrcamentos = () => {
        //console.log("Olha",this.props.state.checked)
        // if (this.props.state.checked.length <= 0) {
        //     Notification.error({
        //         title: "Erro!",
        //         description: "Nenhum orçamento selecionado!",
        //     });
        //     return;
        // }
        console.log('my props :::: ', this.props)
        this.setState({ loading: true });
        // this.setState({ isOpen: true})
        axios
            .get(`/api/utils/email/get-commercials/${this.props.printId}`)
            .then((res) => {
                // console.log(res.data)
                // const files = [];
                // for (var i in res.data.files) {
                //     const file = res.data.files[i];
                //     files.push({
                //         url: file,
                //         file: {
                //             name: file.split("/").pop(),
                //         },
                //         status: "success",
                //         name: file.split("/").pop(),
                //     });
                // }
                // this.setState({
                //     // files,
                //     // emailSubject: "Orçamentos PSM's " + Object.values(res.data.codes).join(", "),
                // cco: this.context.data.user_parameters["mail.default.cco"] === undefined ? ["Sem email padrão"] : this.context.data.user_parameters["mail.default.cco"],
                // cc: this.context.data.user_parameters["mail.default.cc"] === undefined ? ["Sem email padrão"] : this.context?.data?.user_parameters["mail.default.cc"],
                //     // to: [...Object.values(res.data.contacts), this.context.data.user_parameters["mail.default.from"] === undefined ? [""] : this.context.data.user_parameters["mail.default.from"]],
                //     isOpen: true,
                //     psmId: this.props.state.checked,
                // });

                this.setState({
                    isOpen: true,
                    cco: this.context.data.user_parameters["mail.default.cco"] === undefined ? ["Sem email padrão"] : this.context.data.user_parameters["mail.default.cco"],
                    cc: this.context.data.user_parameters["mail.default.cc"] === undefined ? ["Sem email padrão"] : this.context?.data?.user_parameters["mail.default.cc"],
                    to: [...Object.values(res.data.contacts), this.context.data.user_parameters["mail.default.from"] === undefined ? [""] : this.context.data.user_parameters["mail.default.from"]],
                    // to: res.data.contacts
                })
            })
            .catch((e) => {
                if (e.response.status == 406) {
                    Notification.error({
                        title: "Erro!",
                        description: e.response.data.message,
                        duration: 3500
                    });
                } else {
                    console.error(e);
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    // afterSend = () => {
    //     this.setState({ loading: true });
    //     axios.post('/api/v1/bulk-update/estimate', {
    //         data: {
    //             psm_status: 3
    //         },
    //         ids: this.props.state.checked
    //     }).then((res) => {
    //         Notification.success({
    //             title: "Sucesso!",
    //             description: "Orçamentos atualizados com sucesso!",
    //         })
    //     }).catch(() => {

    //     }).finally(() => {
    //         this.setState({ loading: false });
    //     })
    //     // alert("ATUALIZANDO STATUS DAS PSMS " + JSON.stringify(this.props.state.checked));
    // };

    render() {

        if (['approvals', 'reprovings'].includes(this.props.match.params.crud)) {
            return (<>
                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showSituation: false,
                        })
                    }}
                    body={<>
                        <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                        <Button loading={this.state.loading} color='blue' style={{ marginLeft: 20 }} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                    </>}
                    cancelColor={"red"}
                    showConfirm={false}
                    cancelText={"Fechar"}
                    onCloseButton={true}
                    content={""}
                    returnText={"Voltar Situação"}
                    advancedText={"Avançar Situação"}
                    title={"Avança situação de ordem de serviço"}

                    show={this.state.showSituation}
                />

                {/* <Button loading={this.state.loading} color={"blue"} onClick={() => this.alteraSituacao()} className="ml-4">
                    <i className="fas fa-tasks fa-fw mr-2"></i>
                    Mudar Situação
                </Button> */}

            </>)
        }

        if ((this.props.match.params.module == 'laboratorio') && (this.props.match.params.crud !== 'commercials')) {
            return (<>
                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showSituation: false,
                        })
                    }}
                    body={<>
                        {/* {console.log("Valor do this global", existRm)} */}
                        {(this.props.values.formValues.psm_status_fk_situation_id !== 3) && (
                            <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                        )}
                        <Button loading={this.state.loading} color='blue' style={{ marginLeft: 20 }} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                    </>}
                    cancelColor={"red"}
                    showConfirm={false}
                    cancelText={"Fechar"}
                    onCloseButton={true}
                    content={""}
                    returnText={"Voltar Situação"}
                    advancedText={"Avançar Situação"}
                    title={"Avança situação de ordem de serviço"}

                    show={this.state.showSituation}
                />
                <CustomModal
                    show={this.state.showDisclaimModal}
                    tableOmie={false}
                    title={<><i className="fas fa-exclamation-triangle" style={{ marginTop: "7px", marginRight: "10px" }}></i> Desatribuir Lab </>}
                    body={<>
                        <div>Quando clicado em desatribuir, a lab voltara ao seu estado inicial. Deseja desatribuir a lab?</div>
                    </>}
                    showConfirm={true}
                    confirmText={"Desatribuir"}
                    onConfirm={() => this.disclaimLabs()}
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({ showDisclaimModal: false, loading: false })
                    }}
                />

                {(this.props.values.formValues.psm_status_fk_situation_id < 6 || [10, 11, 12, 17].includes(this.props.values.formValues.psm_status_fk_situation_id)) && (<>
                    <Button loading={this.state.loading} color={"blue"} onClick={() => this.alteraSituacao()} className="ml-4">
                        <i className="fas fa-tasks fa-fw mr-2"></i>
                        Mudar Situação
                    </Button>
                    {this.props.values.formValues.psm_status_fk_situation_id < 4 && (
                        <Button loading={this.state.loading} onClick={() => this.disclaimModal()} color={"red"} className="ml-4">
                            <i className="fas fa-exclamation-triangle fa-fw mr-2"></i>
                            Desatribuir
                        </Button>
                    )}
                </>)}

            </>)
        }
        if (this.props.match.params.crud == 'commercials') {
            return (<>

                <MailerModal
                    // afterSend={this.afterSend}
                    files={this.state.files}
                    emailSubject={this.state.emailSubject}
                    onHide={() => this.setState({ isOpen: false })}
                    cc={this.state.cc}
                    cco={this.state.cco}
                    loading={this.state.modalLoading}
                    to={this.state.to}
                    isOpen={this.state.isOpen}
                    psmId={this.state.psmId}>
                    <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Selecionar contatos do cliente</Tooltip>}>
                        <InputGroup.Button onClick={() => this.setState({ isAdvancedModalOpen: true })} color="blue">
                            <i className="fas fa-fw fa-search"></i>
                        </InputGroup.Button>
                    </Whisper>
                </MailerModal>
                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({ show: false });

                        this.props.onSubmit(() => {
                            this.setState({ show: false });
                        });
                    }}
                    showbotaoclose={true}
                    funcaoclose={() => {
                        this.setState({ show: false });
                    }}
                    onConfirm={this.gerarRevisaoComercial}
                    title={"Valores alterados!"}
                    cancelText={"Apenas gravar"}
                    cancelColor={"blue"}
                    confirmColor={"orange"}
                    confirmText={"Gerar revisão"}
                    content="Os valores da proposta foram alterados, deseja gerar revisão?"
                    show={this.state.show}
                />
                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            reproveShowSituation: false,
                        })
                    }}
                    body={
                        <Input componentClass="textarea" size="lg" onBlur={(event: any) => this.setState({ reprovetionText: event.target.value })} style={{ marginBottom: 20 }} />
                    }
                    onConfirm={() => this.aprovedAndReproved('reprovar')}
                    cancelColor={"red"}
                    showConfirm={true}
                    hiddenInput={true}
                    confirmText={"Gravar"}
                    onCloseButton={true}
                    content={""}
                    confirmColor={"green"}
                    title={"Informe o motivo da reprovação da PSM"}
                    show={this.state.reproveShowSituation}
                />
                <Button loading={this.state.loading} onClick={() => this.compairValuesComercialRevision()} color={"green"} className="ml-4">
                    <i className="fas fa-save fa-fw mr-2"></i>
                    Gravar
                </Button>
                {(this.props.values.formValues.psm_status_fk_situation_id == 9) && (<>
                    <Button loading={this.state.loading} color={"green"} onClick={() => this.aprovedAndReproved('aprovar')} className="ml-4">
                        <i className="fas fa-check-circle fa-fw mr-2"></i>
                        Aprovar
                    </Button>
                </>)}
                {this.props.values.formValues.psm_status_fk_situation_id == 9 && (<>
                    <Button loading={this.state.loading} color={"red"} onClick={() => this.setState({ reproveShowSituation: true })} className="ml-4">
                        <i className="fas fa-times-circle fa-fw mr-2"></i>
                        Reprovar
                    </Button>
                </>)}
                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showSituation: false,
                        })
                    }}
                    body={<>

                        {/* {(this.props.values.formValues.material_requisitions.length == 0) && (<> */}
                        <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                        {/* </> */}

                        {(![9, 13, 10, 11].includes(this.props.values.formValues.psm_status_fk_situation_id)) && (<>
                            <Button loading={this.state.loading} color='blue' style={{ marginLeft: 20 }} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                        </>)}
                    </>}
                    cancelColor={"red"}
                    showConfirm={false}
                    cancelText={"Fechar"}
                    onCloseButton={true}
                    content={""}
                    returnText={"Voltar Situação"}
                    advancedText={"Avançar Situação"}
                    title={"Avança situação de ordem de serviço"}

                    show={this.state.showSituation}
                />
                {(![12, 17].includes(this.props.values.formValues.psm_status_fk_situation_id)) && (this.props.values.formValues.psm_status_fk_situation_id <= 11) && (this.props.values.formValues.psm_status_fk_situation_id >= 6) && (this.props.values.formValues.psm_status_fk_situation_id !== 10) && (this.props.values.formValues.psm_status_fk_situation_id !== 11) && (<>
                    <Button loading={this.state.loading} color={"blue"} onClick={() => this.alteraSituacao()} className="ml-4">
                        <i className="fas fa-tasks fa-fw mr-2"></i>
                        Mudar Situação
                    </Button>
                </>)}

                <Button loading={this.state.loading} color={"violet"} onClick={() => this.printPropositure("commercials")} className="ml-4">
                    <i className="fas fa-check-circle fa-fw mr-2"></i>
                    Imprimir Proposta
                </Button>
                <Button loading={this.state.loading} color={"violet"} onClick={() => this.printPropositure("technical")} className="ml-4">
                    <i className="fas fa-check-circle fa-fw mr-2"></i>
                    Imprimir Proposta Técnica
                </Button>
                {/* {this.props.values.formValues.psm_status_fk_situation_id >= 8 && (<>
                    <Button color={"blue"} loading={this.state.loading} style={{ marginLeft: 10 }} onClick={this.enviarOrcamentos}>
                        <i className="fas fa-paper-plane fa-fw mr-2"></i>Enviar orçamentos
                    </Button>
                </>)} */}
            </>)
        }

        return (
            <div>
                {this.props.match.params.crud == "receipts" && (<>
                    <ConfirmModal
                        loading={this.state.loading}
                        onCancel={() => {
                            this.setState({
                                showSituation: false,
                            })
                        }}
                        body={<>
                            {[1, 2, 13].includes(this.props.values.formValues.psm_status_fk_situation_id) && (
                                <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                            )}
                            {this.props.values.formValues.psm_status_fk_situation_id == 1 &&
                                this.props.values.formValues.psm_checked_print_checklist !== false &&
                                (
                                    <Button loading={this.state.loading} color='blue' style={{ marginLeft: 20 }} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                                )}
                        </>}
                        cancelColor={"red"}
                        showConfirm={false}
                        cancelText={"Fechar"}
                        onCloseButton={true}
                        content={""}
                        returnText={"Voltar Situação"}
                        advancedText={"Avançar Situação"}
                        title={"Avança situação de ordem de serviço"}

                        show={this.state.showSituation}
                    />
                    <Button loading={this.state.loading} onClick={() => this.savePsm()} color={"green"} className="ml-4">
                        <i className="fas fa-save fa-fw mr-2"></i>
                        Gravar
                    </Button>
                    {(this.props.values.formValues.psm_status_fk_situation_id < 3 || this.props.values.formValues.psm_status_fk_situation_id == 13) && (<>
                        <Button loading={this.state.loading} color={"blue"} onClick={() => this.alteraSituacao()} className="ml-4">
                            <i className="fas fa-tasks fa-fw mr-2"></i>
                            Mudar Situação
                        </Button>
                    </>)}

                    {(this.props.values.formValues.psm_manual_note == false && this.props.values.formValues.psm_status_fk_situation_id < 16 || this.props.values.formValues.psm_status_fk_situation_id > 16) && (<>
                        <Button loading={this.state.loading} color={"orange"} onClick={() => this.unlinkNf()} className="ml-4">
                            <i className="fas fa-minus-square fa-fw mr-2"></i>
                            Desvincular NF
                        </Button>
                    </>)}

                    <Button loading={this.state.loading} onClick={this.printEtiquetaReceipts} color={"blue"} className="ml-4">
                        <i className="fas fa-print fa-fw mr-2"></i>
                        Imprimir Etiqueta

                    </Button>
                    <Button loading={this.state.loading} style={{ marginLeft: '10px' }} color="blue" onClick={this.printChekList}>
                        <i className="fas fa-print fa-fw mr-2"></i>
                        Imprimir Check-List
                    </Button>

                </>)}


                {/* <Button loading={this.state.loading} onClick={() => this.concluiPsm()} color={"orange"} className="ml-4">
                    <i className="fas fa-check-square fa-fw mr-2"></i>
                    Concluir
                </Button> */}
                {this.props.match.params.crud !== "receipts" && (
                    <>
                        <Button loading={this.state.loading} onClick={() => this.onClick("orcamento")} color={"blue"} className="ml-4">
                            <i className="fas fa-print fa-fw mr-2"></i>
                            Proposta comercial
                        </Button>
                        <Button loading={this.state.loading} color={"blue"} onClick={() => this.onClick("tecnica")} className="ml-4">
                            <i className="fas fa-print fa-fw mr-2"></i>
                            Proposta técnica
                        </Button>
                        {/* <Button loading={this.state.loading} color={"blue"} onClick={() => this.setState({ showEmail: true })} className="ml-4">
                        <i className="fas fa-envelope fa-fw mr-2"></i>
                        Enviar email (exemplo)
                    </Button> */}
                        <Button loading={this.state.loading} color={"blue"} onClick={() => this.alteraSituacao()} className="ml-4">
                            <i className="fas fa-tasks fa-fw mr-2"></i>
                            Mudar Situação
                        </Button>
                        <Button loading={this.state.loading} color={"green"} onClick={() => this.aprovedAndReproved('aprovar')} className="ml-4">
                            <i className="fas fa-check-circle fa-fw mr-2"></i>
                            Aprovar
                        </Button>
                        <Button loading={this.state.loading} color={"red"} onClick={() => this.setState({ reproveShowSituation: true })} className="ml-4">
                            <i className="fas fa-times-circle fa-fw mr-2"></i>
                            Reprovar
                        </Button>
                    </>)}


                {/* <Button loading={this.state.loading} color={"orange"} onClick={() => this.setState({ show: true })} className="ml-4">
                    <i className="fas fa-pencil-alt fa-fw mr-2"></i>
                    Gerar revisão
                </Button> */}
            </div>
        );
    }
}

const PsmFooterWithRouter = withRouter(PsmFooterComponent);

export class PsmFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <PsmFooterWithRouter onSubmit={onSubmit} printId={this.globalState.oldValues.psm_id} values={this.globalState} />
            </>
        );
    };
}
