import axios from "../../utilities/axios";
import { Button, Modal } from "rsuite";
import { Event } from "../Event";

export class ProductSimilaritiesEvents extends Event {
    public actions = (rowData: any) => {
        return (
            <>
                <i
                    className="fas fa-fw mr-2 clickable fa-external-link-alt"
                    onClick={() => {
                        if (rowData.product_similarity_fk_product_similar_id) {
                            window.location.href = "/dashboard/configuracoes/products/" + rowData.product_similarity_fk_product_similar_id;
                        }
                    }}></i>
            </>
        );
    };

    public onAdd = () => {
        if (this.globalState.formValues?.product_id) {
            this.setFieldState("product_similarities", {
                show: true,
            });
        }
        ////console.log(this.globalState.formValues)
    };

    public onConfirm = () => {
        const lastItem = this.globalState.formValues.product_similarities[this.globalState.formValues.product_similarities.length - 1];
        const id = this.globalState.formValues?.product_id;
        // alert(JSON.stringify())
        // alert()
        if (lastItem && id) {
            axios
                .get("/api/v1/products/vincula-similaridade/" + lastItem.product_similarity_fk_product_similar_id + "/" + id)
                .then((res) => {})
                .catch(() => {})
                .finally(() => {
                    this.setFieldState("product_similarities", { show: false });
                });
        } else {
            this.setFieldState("product_similarities", { show: false });
        }
        //alert(JSON.stringify(this.globalState.formValues.product_similarities)+" --- CONFIRMOU")
    };

    public onCancel = () => {
        this.setFieldState("product_similarities", {
            show: false,
        });
        try {
            // var oldProductSimilaritiesValue = this.globalState.formValues.product_similarities;
            // this.setFieldValue("seller_cards", oldProductSimilaritiesValue.splice(oldProductSimilaritiesValue.length - 1, 1));
        } catch (e) {}
        // alert("CANCELOU");
    };

    public onRender = (fieldStates: any) => {
        return (
            <>
                <Modal show={fieldStates.show === true}>
                    <Modal.Header>Atenção</Modal.Header>
                    <Modal.Body>Deseja adicionar o produto atual á similaridade do produto adicionado ?</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.onConfirm}>Confirmar</Button>
                        <Button onClick={this.onCancel}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };
}
