import React from "react";
import { withRouter } from "react-router";
import LabsEditAddPage from "./LabsEditAddPage";
class ReadPageLabs extends React.Component<any, {}> {
    render() {
        return <LabsEditAddPage view={true} />
    }
}

export default withRouter(ReadPageLabs);
