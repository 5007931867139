import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PsmInclusionProductCountFkSelectIdEvents extends Event {

    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if(value){
            this.setState ("loadings", [
                "psm_inclusion_product_count_intern_reference",
                "psm_inclusion_product_count_photo_product",
                "psm_inclusion_product_count_product_id",
                "psm_inclusion_product_count_item_description"

            ]);
            axios.get("/api/v1/products/"+value).then((res)=>{
              
                if (res && res.data && res.data.item) {
                    this.setFieldValue ("psm_inclusion_product_count_intern_reference", res.data.item.product_intern_reference);
                    this.setFieldValue("psm_inclusion_product_count_product_id", JSON.stringify(res.data.item.product_id));
                    this.setFieldValue("psm_inclusion_product_count_item_description", res.data.item.product_description);      
                }
    
            })
            .catch((e) => {})
            .finally(() => {
                this.setState("loadings", []);
            }); 

        }
    }
}