import React from "react";
import { Button , Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { CustomModal } from "../../customModal/CustomModal";


export class PrintTagProductRequisition extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
    }

    componentDidMount = async() =>{
   
    }

    isPrintTag = () => {
        const params = {
            material_requisition_logistic_fk_material_requisition_id: this.props.parentValues.material_requisition_logistic_fk_material_requisition_id,
            material_approved_grid_quantity_met: this.props.values.material_approved_grid_quantity_met,
            material_approved_grid_date: this.props.values.material_approved_grid_date
        }
        axios
            .get(`/api/v1/material-requisiton/print-tag/`, {
                params: {
                    ...params
                }
            })
            .then((res) => {
                if (res.data && res.data.url) {
                    if (res.data && res.data.url) {
                        window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (<>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Button color="blue" size={"lg"} loading={this.state.loading} style={{marginTop: 20, width: "200px"}} onClick={() => this.isPrintTag()}>
                    <i className="fas fa-file-import"></i> Imprimir Etiqueta
                </Button>
            </div>
        </>
        )
    }
}