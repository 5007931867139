// import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PsmProductRegistrationEvents extends Event {
    
    
    public onInit = (value: any, setField: any, setState: any,values: any,...args : any) => {
        this.setState ([
            "psm_product_registration_number_propositure",
            "psm_product_registration_iten",
            

        ]);
        this.setFieldValue("psm_product_registration_number_propositure", value.psm_os_number);
        this.setFieldValue("psm_product_registration_iten", JSON.stringify(value.psm_id));
    }

}