import React from "react";
import { SidebarItem } from "./item";
import Logo from "../../assets/mind.png";
import classNames from "classnames";
import { withRouter } from "react-router";
import { Input, InputGroup, Placeholder } from "rsuite";
// import axios from "axios";
import _ from "lodash";
import { AuthContext, ModuleContext } from "../authProvider/main";
import axios from "../../utilities/axios";
import { Permission } from "../Permission";
const { Paragraph } = Placeholder;

interface IMenus {
    title: string;
    icon: string;
    to: string;
    active?: boolean;
    items?: any[];
    color?: string;
}

interface SidebarState {
    menus: IMenus[];
    search: string;
    isLoading: boolean;
}

// const flatten : any= (obj : any, roots : any = [], sep = '.') => Object.keys(obj).reduce((memo : any, prop : any) => Object.assign({}, memo, Object.prototype.toString.call(obj[prop]) === '[object Object]' ? flatten(obj[prop], roots.concat([prop])) : {[roots.concat([prop]).join(sep)]: obj[prop]}), {})
const flatten = (items: any, final: any = []) => {
    for (var i in items) {
        if (items[i].items) {
            final = flatten(items[i].items, final)
            items[i].hadItems = true;
            delete items[i].items;
        }
        final.push(items[i])
    }
    return final
}

class Sidebar extends React.Component<any, SidebarState> {
    static contextType = ModuleContext;

    public state: SidebarState = {
        menus: [],
        search: "",
        isLoading: true,
    };

    public lastModule = null;
    public inputRef: any;

    constructor(props: any) {
        super(props);
        this.inputRef = React.createRef();
    }

    mapMenuSafely = (menu: any) => {
        if (menu.items) {
            menu.items = menu.items.map(this.mapMenuSafely);
        }
        if (menu.items && menu.items.length <= 0) {
            menu.items = undefined;
        }
        return { items: menu.items, title: menu.menu_name, icon: menu.menu_icon, to: menu.menu_slug, color: menu.menu_color };
    };

    loadMenus = () => {
        this.setState({ menus: [], isLoading: true });
        if (this.context.name) {
            axios
                .get("/api/v1/menus/list", {
                    params: {
                        sortBy: "menu_order",
                        sortType: "asc",
                        all: true,
                        module: this.context.name,
                    },
                })
                .then((res) => {
                    if (res && res.data && res.data.items) {
                        var mappedMenu = res.data.items.map(this.mapMenuSafely);
                        console.log({ mappedMenu });
                        this.setState({ menus: mappedMenu, isLoading: false });
                    }
                });
        }
    };

    componentDidUpdate(props: any) {
        if (this.lastModule !== this.context.name) {
            this.lastModule = this.context.name;
            this.loadMenus();
        }
    }

    componentDidMount() {
        this.loadMenus();
        if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.placeholder = "Pesquisar por...";
        }
    }

    goTo = (to: string, newTab = false) => {
        var menuTarget = this.context.name;
        var target = ("/dashboard/" + menuTarget + "/" + to).toLocaleLowerCase();
        if (to[0] === "/") {
            target = to.replace("{module}", menuTarget).toLocaleLowerCase();
            if (newTab) {
                window.open(target, "_blank")?.focus();
            } else {
                this.props.history.push(target);
            }
        } else {
            if (newTab) {
                window.open(target, "_blank")?.focus();
            } else {
                this.props.history.push(target);
            }
        }
        if (!newTab) {
            this.context.setPage(to);
        }
    };

    onMenuClick = (event: any, menu: any) => {
        // //console.log({ event });
        if (event) {
            if (event.button === 1) {
                this.goTo(menu.to, true);
                return;
            }
        }
        this.goTo(menu.to, false);
    };


    parseMenus = () => {
        if (this.state.search && this.state.search.length > 0) {
            return flatten(_.cloneDeep(this.state.menus));
        }
        return this.state.menus;
    }

    renderMenus = () => {
        if (this.state.isLoading) {
            return (
                <div className="is-loading">
                    <Paragraph />
                    <Paragraph />
                    <Paragraph />
                    <Paragraph />
                    <Paragraph />
                    <Paragraph />
                </div>
            );
        }
        // var match = this.props;
        // ////
        var output = [];
        var parsedMenus = this.parseMenus();
        const activePage = this.context.page ?? window.location.pathname.split('/').pop();
        // console.log({ parsedMenus })
        for (var i in parsedMenus) {
            let menu: any = parsedMenus[i];
            var targetPerm: any = menu.to.toLocaleLowerCase();
            if (targetPerm[0] === "/") {
                targetPerm = targetPerm
                    .substr(1, targetPerm.length - 1)
                    .split("/")
                    .shift();
            }
            if (this.state.search && this.state.search.length > 0) {
                if (menu.hadItems === true) {
                    continue;
                }
                if (!menu.title.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) && !menu.to?.split('_').join(' ').toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase())) {
                    continue;
                }
            }

            var permission = targetPerm.split("-").join("_") + "-browse";
            // console.log({permission})
            if (menu.items && menu.items.length > 0) {
                // console.log('menu itens ::: ', menu.items)
                const isChildrenActive = menu.items.filter((item: any) => {
                    return item.to === activePage
                }).length > 0
                output.push(<>
                    <SidebarItem
                        color={menu.color}
                        items={menu.items}
                        key={i}
                        isOpen={isChildrenActive}
                        title={menu.title}
                        history={this.props.history}
                        // children={[{title:'a'}]}
                        // onClick={(e: any) => this.onMenuClick(e, menu)}
                        // icon={classNames("fas", "fa-" + menu.icon)}
                        page={activePage}
                        active={activePage === menu.to}
                    />
                </>,
                );

            } else {
                output.push(
                    <Permission name={(targetPerm.split("-").join("_") + "-browse").toLocaleLowerCase()}>
                        <SidebarItem
                            color={menu.color}
                            items={menu.items}
                            key={i}
                            history={this.props.history}
                            title={menu.title}
                            page={activePage}
                            onClick={(e: any) => this.onMenuClick(e, menu)}
                            icon={classNames("fas", "fa-" + menu.icon)}
                            active={activePage === menu.to}
                        />
                    </Permission>,
                );
            }
        }
        return output;
    };

    render() {
        if (!this.context.name) {
            return null;
        }
        var shouldWidthHidden = false;
        if (this.props.location.pathname === "/dashboard") {
            shouldWidthHidden = true;
        }

        return (
            <ModuleContext.Consumer>
                {(module) => {
                    return (
                        <AuthContext.Consumer>
                            {(auth) => {
                                if (auth.token === null) {
                                    return null;
                                }
                                // alert(this.context.crud);
                                return (
                                    <div className={classNames("sidebar", shouldWidthHidden ? "sidebar-hidden" : "")}>
                                        <div className="logo">
                                            <img alt="Logo" src={Logo}></img>
                                        </div>
                                        <div className="sidebar-search-bar">
                                            <InputGroup>
                                                <InputGroup.Addon>
                                                    <i className="fas fa-search"></i>
                                                </InputGroup.Addon>
                                                <Input inputRef={this.inputRef} value={this.state.search} onChange={(search: any) => this.setState({ search })}></Input>
                                                {this.state.search.length > 0 && (
                                                    <InputGroup.Button onClick={() => this.setState({ search: "" })}>
                                                        <i className="fas fa-times"></i>
                                                    </InputGroup.Button>
                                                )}
                                            </InputGroup>
                                        </div>
                                        {/* {JSON.stringify(flatten(this.state.menus))} */}
                                        <div className="item-list">{this.renderMenus()}</div>
                                    </div>
                                );
                            }}
                        </AuthContext.Consumer>
                    );
                }}
            </ModuleContext.Consumer>
        );
    }
}

export default withRouter(Sidebar);
