import axios from "../../utilities/axios";
import { Event } from "../Event";
import { calcPercent } from "../../utilities/CalcUtils";

export class PsmHourValueEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        var dataPsm: any = this.globalProps.values;
        await this.setState("loadings",[
            "psm_considered_value",
            'psm_total_product_service',
            "psm_final_value"
        ]);
        if(value > 0){
           

            this.setFieldValue('psm_considered_value', dataPsm.psm_conclusion_hours_finish * value);
            this.setFieldValue('psm_total_product_service', (value * dataPsm.psm_conclusion_hours_finish) + (dataPsm.psm_total_value_products + dataPsm.psm_value_service_recommended));


            if((dataPsm.psm_discount_percent !== 0) ||(dataPsm.psm_discount_percent !== null)){
                let totalValue = (value * dataPsm.psm_conclusion_hours_finish) + (dataPsm.psm_total_value_products + dataPsm.psm_value_service_recommended);
                let result: any = calcPercent(totalValue, dataPsm.psm_discount_percent, 0);
                this.setFieldValue('psm_final_value', result.valorTotal);
            }else{
                this.setFieldValue('psm_final_value', (value * dataPsm.psm_conclusion_hours_finish) + dataPsm.psm_total_value_products);
            }

            
        }

        if(value === 0){
            this.setFieldValue('psm_considered_value', 0);
            this.setFieldValue('psm_total_product_service', (dataPsm.psm_total_value_products + dataPsm.psm_value_service_recommended));
            this.setFieldValue('psm_final_value', dataPsm.psm_total_value_products);

        }

        this.setState("loadings", [])
    }
}