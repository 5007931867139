import React from "react";
import { withRouter } from "react-router";
import { ModuleContext } from "../components/authProvider/main";
import { LoginAnimationSvg } from "../svg/LoginAnimationSvg";
import LogoPng from "../assets/mind.png";
import { ModuleBlock } from "./modules/ModuleBlock";
import { CSSTransition } from "react-transition-group";
import config from "../globals";
import { ModuleContainer } from "./modules/ModuleContainer";
import { Helmet } from "react-helmet";

class ModulesPage extends React.Component<any, {}> {
    static contextType = ModuleContext;

    public constructed = false;

    public state = {
        isMounted: false,
    };

    public nodeRef: any = null;

    constructor(props: any) {
        super(props);
        this.nodeRef = React.createRef();
    }

    componentDidUpdate() {
    }

    // componentWillUnmount = () => {
    //     this.setState({isMounted:false})
    // }

    componentDidMount = () => {
        this.setState({ isMounted: true });
        // if (this.props.match.path === "/dashboard" && (this.context.name !== null || this.context.name !== undefined)) {
        //     // this.context.setModule(null,null);
        // }
    };

    goTo(link: string) {
        // this.context.setModule(link.split('/').pop());
        this.props.history.push(link);
    }

    renderModules = (variableName = "modules") => {
        var output = [];
        let index = 0;
        for (var i in config[variableName]) {
            let moduleData = config[variableName][i];
            index += 1.5;
            output.push(
                <ModuleBlock
                    key={i}
                    visible={moduleData.visible}
                    color={moduleData.color}
                    delay={index}
                    onClick={() => this.goTo("/dashboard/" + moduleData.url)}
                    icon={moduleData.icon}
                    name={moduleData.label}
                />
            );
        }
        return output;
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>MXDrive - Módulos</title>
                </Helmet>

                <CSSTransition nodeRef={this.nodeRef} in={this.state.isMounted} timeout={200} unmountOnExit classNames="fade">
                    <div className="modules-page">
                        <LoginAnimationSvg />
                        <div className="side-logo">
                            <img src={LogoPng} alt="" />
                        </div>
                        <ModuleContainer size={2} title="BU's">
                            {this.renderModules("bussiness_unities")}
                        </ModuleContainer>
                        <ModuleContainer size={3} title="Geral">
                            {this.renderModules()}
                        </ModuleContainer>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default withRouter(ModulesPage);
