import classNames from "classnames";
import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { Panel, Notification, Button, Modal, Whisper, Tooltip, SelectPicker, Icon } from "rsuite";
import { ModuleContext, UserContext } from "../../components/authProvider/main";
import { ErrorPopover } from "../../components/errorPopover/main";
import Formalize from "../../components/Formalize";
import axios from "../../utilities/axios";
// import ace from "brace";
// import "brace/mode/json";
//@ts-ignore
// import { JsonEditor as Editor } from "jsoneditor-react";
// import "jsoneditor-react/es/editor.min.css";
import PlaceholderParagraph from "rsuite/lib/Placeholder/PlaceholderParagraph";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal";
// import _ from "lodash";

class LabsEditAddPage extends React.Component<any, {}> {
    static contextType = ModuleContext;

    public state: any = {
        form: {},
        errors: {},
        values: {psm_template_type:this.props.match.params.templatetype},
        isModalOpen: false,
        isDebugModalOpen: false,
        modalMessage: "",
        isLoading: true,
        canBlock: true,
        isFormDirty: false,
        cache: {},
        isConfirmModalOpen: false,
        mountedValues: {},
        id: this.props.match.params.id !== undefined ? this.props.match.params.id : null,
        typeEquipment: [],
        Retifier: null,
        typeRetifier: [],
        typeEquipmentSelect: [],
        retifierSelect: null,
        typeRetifierSelect: null,
        typeEquipmentAutomation: [],
        typeEquipmentAutomationSelect: [],
        isModalLabsAdd: false,
        loadedCurrentTabs:['Main'],
        showScroll: false,
        lastSearch: '',
        layout:"",
        nivel2Filter : {
            1:[{
                   value: 1,
                   label: "AC / AC"
               },{
                   value: 2,
                   label: "DC / AC"
               }],
            2:[{
                    value: 3,
                    label: "AC / DC"
                },
             ],
             3:[{
                value: 4,
                label: "3 - 100A"
            },{
                value: 5,
                label: "101 - 300A"
            },{
                value: 6,
                label: "+300A"
            }],
            4:[{value : 0,
                label: "NENHUM"}],
            5:[{value : 0,
                label: "NENHUM"}],
            6:[{value : 0,
                label: "NENHUM"}],
            7:[{value : 0,
                label: "NENHUM"}],
            8:[{value : 0,
                label: "NENHUM"}],
            9:[{
                    value: 7,
                    label: "3 - 50A"
                },
                {
                    value: 8,
                    label: "51 - 150A"
                },
                {
                    value: 9,
                    label: "+150A"
                }
            ],
        },
   nivel3Filter : {
         1:[{
			value: 1,
            label: "Ponte"
        },{
            value: 2,
            label: "Diodo"
        },{
            value: 3,
            label: "Tiristor"
        },{
            value: 4,
            label: "Tiristor / Diodo"
        },{
            value: 5,
            label: "IGBT"
        }],
         2:[{value : 0,
            label: "NENHUM"}],
         3:[{
            value: 3,
            label: "Tiristor"
        },{
            value: 4,
            label: "Tiristor / Diodo"
        },{
            value: 5,
            label: "IGBT"
        }],
         4:[{value : 0,
            label: "NENHUM"}],
         5:[{value : 0,
            label: "NENHUM"}],
         6:[{value : 0,
            label: "NENHUM"}],
         7:[{value : 0,
            label: "NENHUM"}],
         8:[{value : 0,
            label: "NENHUM"}],
         9:[{value : 0,
            label: "NENHUM"}],
        },
        
    };

    requestCrudLabs = async (id: any) => {
        var crud: any = "";
        const convertLabelCrud:any = {
            1:'labs',
            2:'layout_two_tables',
            3:'layout_three_tables',
            4:'layout_four_tables',
            5:'layout_five_tables',
            6:'layout_six_tables',
            7:'layout_seven_tables',
            8:'layout_eight_tables',
            9:'layout_nine_tables',
            10:'layout_ten_tables',
            11:'layout_eleven_tables',
            12:'layout_twelve_tables'
        }
        if(id){
        try{
            await axios 
            .get("/api/v1/lab/check-template/" + id) 
            .then((res) => {
                crud = convertLabelCrud[res.data.query.psm_template_type]
            })
        }catch(e){
            // console.log("LOG AQUI", this.props.match.params)
            crud = this.props.match.params.templatetype !== undefined ? convertLabelCrud[this.props.match.params.templatetype] : 'null';
        }
    }else{
        // console.log("LOG AQUI", this.props.match.params)
            crud = this.props.match.params.templatetype !== undefined ? convertLabelCrud[this.props.match.params.templatetype] : 'null';
    }
        return crud
    }

    public _isMounted = false;
    public crud = 'labs'//await this.requestCrudLabs(this.props.match.params.id);
    public isEdit = this.props.view === true ? false : this.props.match.params.id ? true : false;
    public id = this.props.match.params.id !== undefined ? this.props.match.params.id : null;
    // public mountedValues = {};
    public listener: any = null;
    public location: any = null;

    public saveCallbacks: { error: Function; success: Function; type: "callback" }[] = [];

    public addCallback = (callbackMethod: Function, errorCallbackMethod: Function = () => {}) => {
        this.saveCallbacks.push({
            success: callbackMethod,
            error: errorCallbackMethod,
            type: "callback",
        });
        return this.saveCallbacks.length - 1;
    };
    public executeSaveCallbacks = (values: any) => {
        this.saveCallbacks.forEach((callback) => {
            // console.log("Executing callback", callback);
            if (typeof callback === "object" && callback.type == "callback" && typeof callback.success == "function") {
                callback.success(values);
            }
        });
        this.saveCallbacks = [];
    };
    public executeErrorSaveCallbacks = (values: any) => {
        this.saveCallbacks.forEach((callback) => {
            // console.log("Executing callback", callback);
            if (typeof callback === "object" && callback.type == "callback" && typeof callback.error == "function") {
                callback.error(values);
            }
        });
        this.saveCallbacks = [];
    };





    checkScrollTop = () => {
        if (window.scrollY > 100){
            return(<>
                <Icon icon='chevron-circle-up' size="lg"/>
            </>)
         } 
    }

    componentDidMount() {
        window.addEventListener('scroll', this.checkScrollTop)

        this.setState({
            lastSearch: localStorage.getItem('searchHistoric')
        })

        this._isMounted = true;

        window.onbeforeunload = (event) => {
            if (this.state.isFormDirty === true) {
                event.preventDefault();
            }
        };
        this.listener = this.props.history.block((location: any) => {
            if (this.state.canBlock === true && this.state.isFormDirty === true) {
                // if(location.pathname)
                // console.log("TARGET",location);

                if (location.pathname !== this.props.history.location.pathname) {
                    if (location.search.includes("force=true")) {
                        return true;
                    }
                    if (this.state.isConfirmModalOpen === false) {
                        this.setState({ isConfirmModalOpen: true });
                        if (location.pathname) {
                            this.location = location.pathname;
                        }
                        // //console.log(location);
                        return false;
                    }
                }
            }
            return true;
            // const confirmed = window.confirm("Deseja mesmo sair ?");
            // // alert(confirmed);
            // if(confirmed == true) {
            //     if(location) {
            //         // location.retry();
            //     }
            // } else {
            //     return false;
            //     // location.
            // }
            // alert(JSON.stringify(location)+'-'+action);
        });
        // window.onbeforeunload = (e) => {
        //     if(window.confirm("deseja mesmo ?")) {

        //     } else {
        //         e.preventDefault();
        //     }

        // }
        // let unblock = this.props.history.block((tx : any) => {
        //     // let url = tx.location.pathname;
        //     if(window.confirm("Deseja mesmo sair "+url+"?")) {
        //         unblock();
        //         if(tx.retry) {
        //             tx.retry();
        //         }
        //     }
        // })
        // this.props.history.setRouteLeaveHook(this.props.history,() => {
        //     alert("UEÀ");
        // })
        // this.props.history.listen((location: any, action : any) => {
        //     // location is an object like window.location
        //     if(!location.pathname.includes('add')) {
        //         this.loadfields();
        //         alert("CHANGED");
        //     }
        //     // alert(JSON.stringify({action, p:location.pathname, s:location.state}));
        // });
        this.loadfields();
    }

    componentWillUnmount() {
        this.listener();
        this._isMounted = false;
        window.onbeforeunload = (event) => {};
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.currentValues = {};
            this.crud = this.props.match.params.crud;
            // console.log(this.crud)
            this.isEdit = this.props.view === true ? false : this.props.match.params.id ? true : false;
            this.id = this.props.match.params.id !== undefined ? this.props.match.params.id : null;
            this.setState(
                {
                    form: {},
                    errors: {},
                    values: {},
                    isModalOpen: false,
                    isDebugModalOpen: false,
                    modalMessage: "",
                    isLoading: true,
                    canBlock: true,
                    isFormDirty: false,
                    cache: {},
                    isConfirmModalOpen: false,
                    mountedValues: {},
                    id: this.props.match.params.id !== undefined ? this.props.match.params.id : null,
                },
                () => {
                    this.loadfields();
                },
            );
        }
    }

    // shouldComponentUpdate(props: any, state: any) {
    //     if (diff(state.form, this.state.form) === undefined && state.isLoading === this.state.isLoading && state.errors === this.state.errors) {
    //         return false;
    //     }
    //     return true;
    // }

    loadfields = async () => {
        var crudLabs = await this.requestCrudLabs(this.props.match.params.id)
        if(!crudLabs){
            crudLabs = 'labs'
        }
        this.setState({layout:crudLabs})
        axios
            .get("/api/v1/" + crudLabs + "/fields")
            .then((res) => {
                if (res.data && res.data.fields) {
                    if (this._isMounted) {
                        // console.log(res.data);
                        var stateObj = { form: res.data.fields, isLoading: true };

                        if (this.isEdit || this.props.view === true) {
                            this.loadModelData(res.data.fields);
                        } else {
                            stateObj.isLoading = false;
                        }
                        // console.log("aaaa",stateObj);
                        this.setState(stateObj);
                    }
                }
            })
            .finally(() => {
                this._isMounted = true;
            });
        
    };

    loadModelData = (fields: any) => {
        axios.get("/api/v1/" + this.state.layout+ "/" + this.id).then((res) => {
            // console.log(res.data)
            if (res.data && res.data.item) {
                this.setState({ id: this.id, mountedValues: res.data.item, values: res.data.item, isLoading: false });
            }
        });
    };

    public currentValues = {};
    public guid: string | null = null;
    onSubmit = (values: any, guid: string | null = null, cache: any = null, shouldContinue: boolean = false) => {
        this.setState({loadedCurrentTabs:['Main']})
        var url = "/api/v1/" + this.crud + "/" + (this.isEdit ? this.id+"/"+this.state.layout : "add");
        const primaryKey = this.state.form.$GLOBALS.prefix + "_id";

        if (guid) {
            this.guid = guid;
        }
        if (shouldContinue && guid === null) {
            guid = this.guid;
        }
        this.currentValues = values;
        this.setState({ isLoading: true, values, cache });
        for (var fieldName in values) {
            var value = values[fieldName];
            if (value) {
                if (fieldName === "guid") {
                } else {
                    if (this.state.form[fieldName] && this.state.form[fieldName].type === "select") {
                        if (typeof value == "object") {
                            if (value["label"] && value["value"]) {
                                values[fieldName] = value["value"];
                            }
                        }
                    }
                }
            }
        }
        var output = { ...values, guid };
        for (var i in output) {
            if (i == "psm_review_logs") {
                delete output[i];
            }
            if (i == "psm_change_logs") {
                delete output[i];
            }
        }
        var loadedValues = this.state.mountedValues;
        for (var i in loadedValues) {
            if (i == "psm_review_logs") {
                delete loadedValues[i];
            }
            if (i == "psm_change_logs") {
                delete loadedValues[i];
            }
        }
        axios
            .post(url, {
                data: output,
                loadedValues,
                continue: shouldContinue === true ? true : undefined,
            })
            .then((res) => {
                this.setState({ canBlock: false });
                Notification.success({
                    title: "Sucesso!",
                    description: "Registro " + (this.isEdit ? "atualizado" : "cadastrado") + " com sucesso!",
                    placement: "bottomEnd",
                });
                if (res.data.result && res.data.result[0] && res.data.result[0][primaryKey]) {
                    if (this.saveCallbacks?.length > 0) {
                        this.executeSaveCallbacks(res.data.result[0]);
                    }
                    this.props.history.replace("/dashboard/" + this.context.name + "/" + this.crud + "/" + res.data.result[0][primaryKey]);
                    this.isEdit = true;
                    this.id = res.data.result[0][primaryKey];
                    this.loadfields();
                } else {
                    if (this.saveCallbacks?.length > 0) {
                        this.executeSaveCallbacks(res.data.result[0]);
                    }
                    this.props.history.push("/dashboard/" + this.context.name + "/" + this.crud);
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            })
            .catch((e) => {
                // console.log("ERRORS", e.response);
                this.executeErrorSaveCallbacks({ ...values, guid });
                if (e?.response?.status) {
                    if (e.response.status === 406) {
                        if (e.response.data.errors) {
                            this.setState({ errors: e.response.data.errors });
                        }
                        if (e.response.data.messages) {
                            for (var i in e.response.data.messages) {
                                Notification.error({
                                    title: "Erro!",
                                    description: e.response.data.messages[i],
                                    placement: "bottomEnd",
                                    duration: 9000,
                                });
                            }
                        }
                    }
                    if (e.response.status === 403) {
                        for (var errorIndex in e.response.data.errors) {
                            Notification.error({
                                title: "Erro!",
                                duration: 10000,
                                description: e.response.data.errors[errorIndex],
                                placement: "bottomEnd",
                            });
                        }
                    }
                    if (e.response.status === 409) {
                        this.setState({ isModalOpen: true, modalMessage: "Houve uma alteração nesse registro enquanto você alterava, deseja mesmo continuar com a alteração ?" });
                    }
                } else {
                    throw e;
                }
            });
    };

    onConfirm = () => {
        this.listener();
        this.props.history.push(`${this.location}${this.state.lastSearch}`);
    };

    onCancel = () => {
        // this.listener();
        this.setState({ isConfirmModalOpen: false });
    };

    onClickDebug = () => {
        this.setState({ isDebugModalOpen: true });
        // alert("CLICKED");
    };

    onCloseDebug = () => {
        this.setState({ isLoading: true }, () => {
            this.setState({ isDebugModalOpen: false, isLoading: false });
        });
    };

    onBack = () => {
        this.props.history.push(`/dashboard/${this.context.name}/${this.crud}${this.state.lastSearch}`)
    }

    renderTitleName = () => {
        var GLOBALS = this.state.form["$GLOBALS"] ? this.state.form["$GLOBALS"] : { icon: null, singular_name: null };
        let numberExibtion: any = this.state.id;
        if(this.state.values.psm_os_number){
            numberExibtion = this.state.values.psm_os_number
        }
        if (this.isEdit) {
            return (
                <>
                    Editando {GLOBALS.singular_name ? GLOBALS.singular_name : <ErrorPopover title="Missing singular/plural name">{this.crud}</ErrorPopover>} #{numberExibtion}
                </>
            );
        } else {
            if (this.props.view) {
                return (
                    <>
                        Visualizando {GLOBALS.singular_name ? GLOBALS.singular_name : <ErrorPopover title="Missing singular/plural name">{this.crud}</ErrorPopover>} #{numberExibtion}
                    </>
                );
            } else {
                return <>Cadastro de {GLOBALS.plural_name ? GLOBALS.plural_name : <ErrorPopover title="Missing singular/plural name">{this.crud}</ErrorPopover>}</>;
            }
        }
    };

    onSelectFilter = (value:any,nivel:any) => {
        //    axios.get('') 
        if(value){   
            if(nivel == 'nivel1'){
                this.setState({
                dataNivel2:{value:0,label:"Nenhum"},
                dataNivel3:{value:0,label:"Nenhum"},
                    
            })
                this.setState({dataNivel2:this.state.nivel2Filter[value]})
               // console.log("nivel2",value,nivel)
            }
            if(nivel == 'nivel2'){
                this.setState({
                    dataNivel3:{value:0,label:"Nenhum"}
                })
                this.setState({dataNivel3:this.state.nivel3Filter[value]})
                console.log("nivel3",value,nivel)
            }
                //this.search();
            }
        }

        escolhetemplatelab = async (idTemplate : any) =>{
            await axios
            .get("/api/v1/lab/select-add-template/"+JSON.stringify(idTemplate))
            .then((res) => {
                if(res.status === 200){
                    this.setState({isModalLabsAdd:false})
                    this.props.history.push(`/dashboard/laboratorio/labs/${res.data.template_id.layout}/add`)
                }
            })
           
        }

        modalLabsADD =() => {
            const typeEquipment = [{
                value: 1,
                label: "Inversor"
            },{
                value: 2,
                label: "Retificador"
            },{
                value: 3,
                label: "Softstarter"
            },{
                value: 4,
                label: "IHM"
            },
            {
                value: 5,
                label: "PLC"
            },
            {
                value: 6,
                label: "Fonte"
            },
            {
                value: 7,
                label: "Cartões Eletronicos"
            },
            {
                value: 8,
                label: "Servomotor"
            },
            {
                value: 9,
                label: "Simocode"
            },
            ];
            this.setState({ isModalLabsAdd: true,typeEquipment : typeEquipment});
            
        }    

    render() {
        var GLOBALS = this.state.form["$GLOBALS"] ? this.state.form["$GLOBALS"] : { icon: null, singular_name: null };
        return (
            <>
                {this.checkScrollTop()}
                <Icon icon='chevron-circle-up' size="lg"/>
                <div style={{ zIndex: 1 }} className="container">
                    <Helmet>
                        <title>MXDrive {this.state.form?.$GLOBALS?.singular_name ? "- Cadastro de " + this.state.form?.$GLOBALS?.singular_name : this.crud}</title>
                    </Helmet>
             <CustomModal
                show={this.state.isModalLabsAdd}
                onCancel={() => {
                    this.setState({
                        isModalLabsAdd: false,
                    })
                }}
                title={"Layout PSM"}
                body={<>
                <div style={{width:550}}>
                    <div style={{marginTop:5}}>
                            <label>NIVEL 1</label>
                            <SelectPicker 
                            style={{width:'100%'}}
                            data={this.state.typeEquipment} 
                            onSelect={(value:any, item , event) => this.onSelectFilter(value,'nivel1')}
                            onChange={(value: string) => this.setState({typeEquipmentSelect: value})}/> 
                   </div> 
                  <div style={{marginTop:15}}> 
                            <label>NIVEL 2</label>
                            <SelectPicker 
                            disabled={this.state.typeEquipmentSelect <= 0 || this.state.typeEquipmentSelect == null}
                            style={{width:'100%'}}
                            data={this.state.dataNivel2}
                            onSelect={(value:any, item , event) => this.onSelectFilter(value,'nivel2')}
                            onChange={(value: string, event) => this.setState({retifierSelect : value})}
                            /> 
                      </div> 
                     <div style={{marginTop:15}}>
                            <label>NIVEL 3 </label>
                            <SelectPicker          
                            disabled={this.state.retifierSelect <= 0 || this.state.retifierSelect == null}
                            style={{width:'100%'}}                   
                            defaultValue={{value : 0,
                                label: "NENHUM"}}
                            data={this.state.dataNivel3}
                            onChange={(value: string, event) => this.setState({typeRetifierSelect : value})}/> 
                     </div> 
                   </div>                  
                </>}
                tableOmie={false}
                showConfirm={true}
                cancelColor={"red"}
                confirmText={"Adicionar"}
                onConfirm={() => this.escolhetemplatelab({ typeEquipment: this.state.typeEquipmentSelect, retifier: this.state.retifierSelect, typeRetifier: this.state.typeRetifierSelect})}
                confirmColor={"green"}
                cancelText={"Fechar"}
                onCloseButton={true}
             />
                    <Modal show={this.state.isModalOpen} size={"sm"} className="centered">
                        <Modal.Header>
                            <Modal.Title>
                                <i className="fas fa-fw mr-2 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                                Atenção!
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.state.modalMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button appearance="primary" onClick={() => this.onSubmit(this.currentValues, null, null, true)}>
                                Confirmar
                            </Button>
                            <Button color="red" onClick={() => this.setState({ isModalOpen: false })}>
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Panel bordered className="rs-no-body mb-4 w-100">
                        <div className="d-flex justify-contents-between align-items-center">
                            <div></div>
                            <div>
                                <h5 className="d-flex align-items-center">
                                    <i className={classNames("fas", "fa-fw mr-4", "fa-" + GLOBALS.icon)}></i>
                                    {this.renderTitleName()}
                                </h5>
                            </div>
                            <div>
                                <UserContext.Consumer>
                                    {(user: any) => {
                                        if (user.data?.user_fk_role_id === 2) {
                                            return (
                                                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">DEBUG</Tooltip>}>
                                                    <Button onClick={this.onClickDebug} className="mr-2" color={"violet"}>
                                                        <i className="fas fa-fw fa-bug"></i>
                                                    </Button>
                                                </Whisper>
                                            );
                                        }
                                        return null;
                                    }}
                                </UserContext.Consumer>
                                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Adicionar</Tooltip>}>
                                    <Button color={"green"} onClick={this.modalLabsADD}>
                                        <i className="fas fa-fw fa-plus"></i>
                                    </Button>
                                </Whisper>
                                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Voltar</Tooltip>}>
                                    <Button className="ml-2" color={"blue"} onClick={() => this.onBack()}>
                                        <i className="fas fa-fw fa-angle-left"></i>
                                    </Button>
                                </Whisper>
                            </div>
                        </div>
                    </Panel>
                    
                    {this.state.isLoading === false && (
                        <>
                        
                        <Formalize
                            addCallback={this.addCallback}
                            onDirty={(name: string) => {
                                this.setState({ isFormDirty: true });
                            }}
                            guid={this.guid}
                            cache={this.state.cache}
                            history={this.props.history}
                            match={this.props.match}
                            readOnly={this.props.view === true}
                            isEdit={this.isEdit}
                            values={this.state.values}
                            errors={this.state.errors}
                            onSubmit={this.onSubmit}
                            form={this.state.form}
                            onChangeTab={(item: any) => {
                                this.setState({ values: item,isLoading:true },()=>{
                                    this.setState({isLoading:false})
                                });
                            }}
                            onCheckTab={(item: any) => {                               
                                if(!this.state.loadedCurrentTabs?.includes(item)){
                                    this.state.loadedCurrentTabs.push(item);
                                    return true 
                                }else{
                                    return false
                                }
                            }}
                        />
                        </>
                    )}
                     {/* { JSON.stringify(this.state.teste)} */}
                    {/* {JSON.stringify(this.state.errors)} */}
                    {this.state.isLoading === true && (
                        <Panel bordered className="rs-no-body mb-4 w-100">
                            <PlaceholderParagraph rows={15} />
                        </Panel>
                    )}

                    <Modal size={"lg"} overflow={false} show={this.state.isDebugModalOpen}>
                        <Modal.Header onHide={() => this.setState({ isDebugModalOpen: false })}>Editor DEBUG</Modal.Header>
                        <Modal.Body>
                            {/* <Editor htmlElementProps={{ style: { height: 400 } }} ace={ace} mode={"code"} value={this.state.form} onChange={(form: any) => this.setState({ form })} /> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="green" onClick={this.onCloseDebug}>
                                <i className="fas fa-fw fa-check"></i> Confirmar
                            </Button>
                            <Button color="red" onClick={() => this.setState({ isDebugModalOpen: false })}>
                                <i className="fas fa-fw fa-times"></i> Cancelar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <ConfirmModal onCancel={this.onCancel} onConfirm={this.onConfirm} show={this.state.isConfirmModalOpen} />
                    {/* {this.state.isConfirmModalOpen ? 'Y':'N'} */}
                    {/* {this.state.isFormDirty ? 'SIM':'NÃO'} */}
                    {/* {JSON.stringify(this.state.cache)} */}
                </div>
            </>
        );
    }
}

export default withRouter(LabsEditAddPage);
