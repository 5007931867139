import dayjs from "dayjs";
import React from "react";
import { Avatar, Divider, Loader, Nav, Navbar, Panel, Steps, Timeline } from "rsuite";
import { UserContext } from "../../components/authProvider/main";
import UserInbox from "../../components/userInbox/UserInbox";
import axios from "../../utilities/axios";
import ParametersPage from "../ParametersPage";


// import { Container } from './styles';

class ViewProfilePage extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;

    public state = {
        activeTab: "notificacoes",
        loading: false,
    };

    public getAbbreviationFromName = (fullName: string | null) => {
        if (fullName) {
            var name = fullName.split(" ");
            if (name.length > 1) {
                var abbreviation = name[0].charAt(0).toUpperCase() + name[1].charAt(0).toUpperCase();
                return abbreviation;
            } else {
                return name[0].charAt(0).toUpperCase() + name[0].charAt(1).toUpperCase();
            }
        }
        return null;
    };

    public renderByTab = (tabName: string) => {
        if (this.state.loading) {
            return (
                <div className="d-flex justify-content-center align-items-center h-100">
                    <Loader size="lg" />
                </div>
            );
        }
        switch (tabName) {
            case "notificacoes":
                return (
                    <div>
                        <UserInbox />
                        {/* <Timeline align="left">
                            <Timeline.Item>{dayjs(this.context.data?.user_created_at).format("DD/MM/YYYY HH:mm")} - Usuário cadastrado</Timeline.Item>
                        </Timeline> */}
                    </div>
                );
            case "parametros":
                return (
                    <div>
                        <ParametersPage user={true} fieldsOnly={true} />
                    </div>
                );
            default:
                return null;
        }
    };

    public setTab = (tabName: string) => {
        if (tabName !== this.state.activeTab) {
            this.setState({ loading: false });
            switch (tabName) {
                case "parametros":
                    // this.setState({ loading: true });
                    // axios
                    //     .get("/api/v1/parameters")
                    //     .then((response) => {
                    //         console.log(response);
                    //     })
                    //     .finally(() => {
                    //         this.setState({ loading: false });
                    //     });
                    break;
            }
            this.setState({ activeTab: tabName });
        }
    };

    render() {
        return (
            <div className="container">
                <Navbar className="col-md-12 pill-tabs" style={{ marginLeft: 170 }}>
                    <Navbar.Body>
                        <Nav activeKey={this.state.activeTab}>
                            <Nav.Item eventKey={"notificacoes"} onSelect={() => this.setTab("notificacoes")}>
                                Notificações
                            </Nav.Item>
                            <Nav.Item eventKey={"parametros"} onSelect={() => this.setTab("parametros")}>
                                Parametros
                            </Nav.Item>
                        </Nav>
                    </Navbar.Body>
                </Navbar>
                <Panel bordered className="rs-no-body mb-4 w-100 h-100 h-100-body">
                    <div className="avatar-container">
                        <div className="avatar-image-container">
                            <div className="d-flex justify-content-center">
                                <Avatar style={{ background: "#00899b", height: 120, width: 120, fontSize: 60, lineHeight: 60 }} circle>
                                    {this.getAbbreviationFromName(this.context?.data?.user_name)}
                                </Avatar>
                            </div>
                            <div className="d-flex justify-content-center">
                                <h2>{this.context?.data?.user_name}</h2>
                            </div>
                        </div>
                        <Panel className="mb-4 w-100">{this.renderByTab(this.state.activeTab)}</Panel>
                    </div>
                </Panel>
            </div>
        );
    }
}

export default ViewProfilePage;
