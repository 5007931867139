import { ControlLabel, FormControl, FormGroup, HelpBlock, Input, Table, Tooltip, Whisper } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { MaskInput } from '../../formfields/MaskInput';
import { useState } from 'react';
import _ from 'lodash';

const { Column, HeaderCell, Cell, Pagination } = Table;

interface CustomRedCellProps {
    rowData: any,
    dataKey: string,
    view: string
}

const CustomRedCell = ({ rowData, dataKey, view, ...props }: CustomRedCellProps) => {
    const errorCodes: any = rowData['error'];

    const errorFieldMap: any = {
        1: 'cnpj',
        2: 'id',
        3: 've',
        4: 'vi',
        5: 'key',
        6: 've',

        //field error for id incorrect
        7: 'client',
        8: 'city',
        9: 'uf',
        10: 'hub',
        11: 'microregion',
        12: 've',
        13: 'vi',
        14: 'key'
    };




    let color = '#98FB98';
    let isEditable = false;
    let isValid = true;
    let codeMessage = '';


    // const errorMessagesMap: any = {
    //     've': 'Este vendedor não é Vendedor Externo. Favor Verificar',
    //     'vi': 'Este vendedor não é Vendedor Interno. Favor Verificar',
    //     'key': 'Este vendedor não é Key Account. Favor Verificar',
    //     'id': 'ID ou CNPJ Incorreto. Favor Verificar',
    //     'cnpj': 'CNPJ Incorreto. Favor Verificar',
    // };

    const errorMessagesMap: any = (() => {
        const baseMessages = {
            've': 'Este vendedor não é Vendedor Externo. Favor Verificar',
            'vi': 'Este vendedor não é Vendedor Interno. Favor Verificar',
            'key': 'Este vendedor não é Key Account. Favor Verificar',
            'id': 'ID ou CNPJ Incorreto. Favor Verificar',
            'cnpj': 'CNPJ Incorreto. Favor Verificar',
        };

        // Supondo que errorCodes esteja acessível aqui
        if (errorCodes.some((code: number) => [12, 13, 14].includes(code))) {
            return {
                ...baseMessages,
                've': 'Erro! Não é permitido dois ou mais vendedores do mesmo cliente na mesma linha.',
                'vi': 'Erro! Não é permitido dois ou mais vendedores do mesmo cliente na mesma linha.',
                'key': 'Erro! Não é permitido dois ou mais vendedores do mesmo cliente na mesma linha.',
            };
        }

        return baseMessages;
    })();

    for (let code of errorCodes) {

        if (errorFieldMap[code] == view) {
            color = '#FF6347';
            isValid = false;

            if (['ve', 'vi', 'key', 'id', 'cnpj'].includes(view)) {
                codeMessage = errorMessagesMap[view];
            }

            isEditable = true;
            break;
        }
    }

    const [inputValue, setInputValue] = useState(rowData[dataKey]);

    const handleChange = (value: string) => {
        setInputValue(value);
        rowData[dataKey] = value;
    };


    const formattedValue = view === 'cnpj' ? formatCNPJ(rowData[dataKey]) : rowData[dataKey];

    function formatCNPJ(value: string) {
        if (!['', null, undefined].includes(value)) {
            if (value.length > 11) {
                return value
                    .replace(/\D/g, '')
                    .replace(/^(\d{2})(\d)/, '$1.$2')
                    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
                    .replace(/\.(\d{3})(\d)/, '.$1/$2')
                    .replace(/(\d{4})(\d)/, '$1-$2');
            } else {
                return value
                    .replace(/\D/g, '') // Remove tudo o que não é dígito
                    .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona ponto entre o terceiro e o quarto dígitos
                    .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona ponto entre o sexto e o sétimo dígitos
                    .replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Adiciona hífen entre o nono e o décimo dígitos
            }
        }
    }

    if (view == 'cnpj') {
        return (
            // <Cell {...props} className="Teste" style={{ backgroundColor: 'snow', marginTop: '-13px' }}>
            //     <Whisper trigger="focus" speaker={<Tooltip>{codeMessage}</Tooltip>} placement="top">
            //         <MaskInput
            //             disabled={true}
            //             size="md"
            //             value={inputValue}
            //             onChangeEnd={(value: any, e: any) => {
            //                 if (e !== null) {
            //                     rowData[view] = e?.target?.value;
            //                 }
            //             }}
            //             onChange={handleChange}
            //             mask={'99.999.999/9999-99'}
            //         />
            //     </Whisper>
            // </Cell>
            <Cell {...props} style={{ backgroundColor: 'snow', marginTop: '-13px' }}>
                <Whisper trigger="hover" speaker={<Tooltip>{codeMessage}</Tooltip>} placement="top">
                    <Input
                        style={{ color: 'black', backgroundColor: color }}
                        size="md"
                        disabled={true} // Aqui você usa a variável `isEditable`
                        value={formattedValue} // Usando o valor formatado aqui
                    />
                </Whisper>
            </Cell>
        );
    } else {
        return (
            <Cell {...props} style={{ backgroundColor: 'snow', marginTop: '-13px' }}>
                <Whisper trigger="hover" speaker={<Tooltip>{codeMessage}</Tooltip>} placement="top">
                    <Input
                        style={{ color: 'black', backgroundColor: color }}
                        size="md"
                        disabled={!isEditable}
                        defaultValue={inputValue}
                        onChange={(value: string) => {
                            handleChange(value);
                        }}
                    />
                </Whisper>
            </Cell>
        );
    }
};

export default CustomRedCell;
