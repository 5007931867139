import React from "react";
import { Button , Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { CustomModal } from "../../customModal/CustomModal";


export class ImportAdd extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        isImportModalOmie: false,
        isModalConfirm: false,
        omieData: [],
        OmieItens: [],
        notificationText: 'Lembre-se de salvar todos os dados antes de fazer a importação!!',
    }

    componentDidMount = async() =>{
        await axios.get("/api/v1/products/select").then((res)=>{
           // console.log("aqui:: ", res.data.items);
            this.setState({ data: res.data.items })

        })
        // console.log("Meu props :::: ", this.props.name)
    }
    updateData = async(value : any) =>{
        await axios.get("/api/v1/products/select?search="+value).then((res)=>{
            // console.log("aqui:: ", res.data.items);
             this.setState({ data: res.data.items })
 
         })
    }

    public select: any = {};

    onChange = (item: any, valuesOmie: any) => {
        this.props.onChange()
    };


    isVerifyGridsCompiled = () => {
        this.setState({ isModalConfirm: true});
        let psmId = this.props.location.pathname.split("/").pop()

        axios
            .get(`api/v1/lab/check-tables/${psmId}`)
            .then((res: any) => {
                // console.log({res})
                if(res.data.checkData){
                    this.setState({notificationText: 'Já existem dados importados na aba Análise Compilada, deseja importar novamente?'})
                }
            })
            .catch((e) => {console.log(e)})
    }

    isImportData = () => {
        this.setState({loading: true});
        // console.log("props :::: ",this.props.history);
        let valueConditionRepair = this.props.values.psm_conclusion_repair_conditional;
        let istemplateTypeId = this.props.values.psm_template_type;
        let psmId = this.props.location.pathname.split("/").pop()

        // console.log({psmId});
        // console.log({istemplateTypeId})
        axios
            .post("/api/v1/lab/import-compiled",{
                templateId: istemplateTypeId,
                psm_id: psmId
            })
            .then((res => {
                if(res.data.checkProducts == false){
                    Notification.error({
                        title: "error!",
                        description: 'Foi informada que a condição de reaparo se encontra como "NÃO"',
                        duration: 6500,
                    });
                }
            }))
            .finally(() => {
                // this.props.onSubmit()
                this.props.history.push(`/dashboard/laboratorio/labs/${psmId}?tab=Main`);
                this.props.history.go(0);
                this.setState({ loading: false });
            }); 
    }

    isImportProduct = () => {
        this.setState({loading:true})
        // console.log(this.props);
        axios
            .post("/api/v1/commercial/import-product/", {id:this.props.values.psm_id, psm: this.props.values.psm_os_number})
            .then((res) => {
                if(res.data.checkProducts == true){
                    Notification.success({
                        title: "Importado com Sucesso",
                        description: 'Importação dos produtos realizada!!',
                        duration: 3500,
                    });
                }
                setTimeout(this.props.history.go(0),7000)
                //this.props.history.go(0)
            })
            .finally(() => {
                this.setState({ loading: false });
            }); 
    }

    renderModalConfirm = () => {
        return(<>
            <CustomModal
                show={this.state.isModalConfirm}
                tableOmie={false}
                onCancel={() => {
                    this.setState({
                        isModalConfirm: false,
                    })
                }}
                title={"Deseja realizar a Importação de dados?"}
                body={<>
                    <div style={{fontSize: '18px'}}>{this.state.notificationText}</div>
                </>}
                loading={this.state.loading}
                onConfirm={() => this.isImportData()}
            />
        </>)
    }

    render() {
        const accomplishFunction = this.props.name === 'psm_custom_push' ? () => this.isImportProduct() : () => this.isVerifyGridsCompiled()
        return (<>
                {this.renderModalConfirm()}
                <Button color="violet" size={"lg"} loading={this.state.loading} style={{marginTop: 5, width: "265px"}} onClick={accomplishFunction}>
                    <i className="fas fa-file-import"></i> {this.props.name === 'psm_custom_push' ? 'Importar Produtos e Serviços' : 'Importar Dados'}
                </Button>
        </>
            
        )
    }
}