import classNames from "classnames";
import React from "react";
import { Popover, Whisper } from "rsuite";
import { FilterPopover } from "./FilterPopover";

export class FilterableCell extends React.Component<any, {}> {
    public state = {
        isFilterOpen: false,
        fieldValue: this.props.value ? this.props.value : "",
        tempFieldValue: this.props.value ? this.props.value : "",
        sort: this.props.sortType ? this.props.sortType : null,
        operator: this.props.operator ? this.props.operator : this.props.field?.type === "numeric" ? "eq" : "like",
        advanced: localStorage.getItem("advanced-filter-mode") === "S" ? true : false,
    };

    onExit = () => {
        this.setState({ fieldValue: this.state.tempFieldValue }, () => {
            if (this.props.onExit) {
                this.props.onExit(this.state.tempFieldValue, this.state.operator);
            }
        });
    };

    onPressEnter = () => {
        this.setState({ fieldValue: this.state.tempFieldValue }, () => {
            if (this.props.onExit) {
                this.props.onExit(this.state.fieldValue, this.state.operator);
            }
        });
    };

    onSort = () => {
        // alert(this.state.sort === 'asc');
        if (this.props.onSortColumn) {
            if (this.state.sort === null) {
                this.setState({ sort: "asc" }, () => {
                    this.props.onSortColumn(this.state.sort);
                });
            } else if (this.state.sort === "asc") {
                this.setState({ sort: "desc" }, () => {
                    this.props.onSortColumn(this.state.sort);
                });
            } else {
                this.setState({ sort: null }, () => {
                    this.props.onSortColumn(this.state.sort);
                });
            }
        }
    };

    onSortGrid = () => {
        if (this.props.onSortColumn) {
            // console.log("entrou onSortGrid", this.props.field.name);
            if (this.state.sort === null) {
                this.setState({ sort: "asc" }, () => {
                    this.props.onSortColumn(this.state.sort, this.props.field.name);
                });
            } else if (this.state.sort === "asc") {
                this.setState({ sort: "desc" }, () => {
                    this.props.onSortColumn(this.state.sort, this.props.field.name);
                });
            } else {
                this.setState({ sort: null }, () => {
                    this.props.onSortColumn(this.state.sort, this.props.field.name);
                });
            }
        }
    };

    sortClass = () => {
        if (this.state.sort === null) return "fa-filter";
        if (this.state.sort === "asc") return "fa-sort-amount-down-alt";
        if (this.state.sort === "desc") return "fa-sort-amount-up";
    };

    onToggleMode = () => {
        localStorage.setItem("advanced-filter-mode", !this.state.advanced === true ? "S" : "N");
        this.setState({ advanced: !this.state.advanced });
    };

    render() {
        try {
            return (
                <div className="filterable-header">
                    {this.props.children}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Whisper
                            trigger="click"
                            onExit={this.onExit}
                            placement={"bottom"}
                            speaker={
                                <Popover title="Filtrar por...">
                                    {this.state.advanced === true && (
                                        <FilterPopover
                                            isIndex={this.props.isIndex}
                                            advanced={this.state.advanced}
                                            operator={this.state.operator}
                                            hasAdvanced={this.props.hasAdvanced}
                                            field={this.props.field}
                                            onToggleMode={this.onToggleMode}
                                            value={this.state.fieldValue}
                                            onChange={(v: string, operator: any) => this.setState({ tempFieldValue: v?.toLocaleUpperCase(), operator: operator })}
                                            onExit={this.onExit}></FilterPopover>
                                    )}
                                    {this.state.advanced !== true && (
                                        <FilterPopover
                                            isIndex={this.props.isIndex}
                                            advanced={this.state.advanced}
                                            operator={this.state.operator}
                                            field={this.props.field}
                                            hasAdvanced={this.props.hasAdvanced}
                                            onToggleMode={this.onToggleMode}
                                            value={this.state.fieldValue}
                                            onChange={(v: string, operator: any) => this.setState({ tempFieldValue: v, operator: operator })}
                                            onExit={this.onExit}></FilterPopover>
                                    )}
                                </Popover>
                            }>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <small style={{ opacity: 0.6 }}>{this.state.fieldValue}</small>
                                {this.props.field.type !== "grid" && (
                                    <i className="fas fa-search clickable ml-2" onClick={() => this.setState({ isFilterOpen: !this.state.isFilterOpen })}></i>
                                )}
                            </div>
                        </Whisper>
                        {this.props.field.type !== "grid" && <i className={classNames("fas clickable ml-4", this.sortClass())} onClick={() => this.props.hasAdvanced === false ? this.onSortGrid() : this.onSort()}></i>}
                    </div>
                </div>
            );
        } catch (e : any) {
            if(e) {
                return e.message;
            }
        }
    }
}
