import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";

export class DibiEvents extends Event {
    public settings = {
        saveButton: false,
    };
    public onEvents = {
        onChange: {
            listeners: ["psm_fk_business_unity_id", "psm_fk_company_id"],
            action: "montaCodigo",
        },
    };
    public montaCodigo = (value: any) => {
        if (this.values.psm_fk_business_unity_id && this.values.psm_fk_company_id) {
            const loadings: string[] = [...this.globalState.loadings];
            console.log("valor do this.values", this.values)
            loadings.push("psm_os_number");
            if (this.values.psm_os_number) {
                this.setState({ loadings });
                axios
                    .get("/api/v1/psm/next-code-new", {
                        params: {
                            bu: this.values.psm_fk_business_unity_id,
                            unidade: this.values.psm_fk_company_id,
                            osNumber: this.values.psm_os_number
                        },
                    })
                    .then((res) => {
                        if (res.data?.code) {
                            this.setFieldValue("psm_os_number", res.data?.code);
                        }
                    })
                    .finally(() => {
                        this.setState({ loadings: [] });
                    });
            } else {
                this.setState({ loadings });
                axios
                    .get("/api/v1/psm/next-code", {
                        params: {
                            bu: this.values.psm_fk_business_unity_id,
                            unidade: this.values.psm_fk_company_id,
                        },
                    })
                    .then((res) => {
                        if (res.data?.code) {
                            this.setFieldValue("psm_os_number", res.data?.code);
                        }
                    })
                    .finally(() => {
                        this.setState({ loadings: [] });
                    });
            }
        }
        // this.setFieldValue('psm_os_number','LPA0100000')
        // alert(this.values.psm_fk_business_unit_id)
        // alert(value);
    };

    // public onSave  = async (value: any) => {
    //     console.log ("Valor que vem no Value :::::::::::>", value);
    //     console.log ("Valor que vem no This Props :::::::::::>", this);
    // }


    public onInit = () => {
        const loadings: string[] = [];

        const { module: activeModule } = this.history.match.params;

        // this.setState("loadings", [

        // ]);

        if (activeModule === "vendas-dibi") {
            // this.setFieldValue("psm_fk_business_unity_id", { value: "9", label: "DIBI" }); //sempre ligar ao id do banco 
            this.setFieldValue("psm_fk_business_unity_id", 9);
        }
        // this.setFieldValue('psm_purpose_product',{ value: "2", label: "REVENDA" })
        // this.setFieldValue('psm_purpose_product_copy',{ value: "2", label: "REVENDA" })
        // this.setFieldValue("psm_shipment_fk_type_frete_id", 4);
        // this.sectFieldValue("psm_sub_status", {value: "10", label: "AGUARDANDO SEPARAÇÃO DOS PRODUTOS"});
        this.setFieldValue("psm_taxes", "TODOS OS IMPOSTOS ESTÃO INCLUSOS");

        this.setFieldValue("psm_proposal_due_date", "10 DIAS");
        loadings.push("psm_user");

        this.setState({ loadings });
        if (!this.history.match.params.id) {
            this.setFieldValue("psm_status_fk_dibi_situation_id", 1)
        }

        axios
            .get("/api/v1/me")
            .then((subRes) => {
                var subData = subRes.data;
                //console.log(subData);
                if (subData) {
                    this.setFieldValue("psm_user", subData.user.user_name);
                }
            })
            .catch((error) => { })
            .finally(() => {
                const loadings = [...this.globalState.loadings];
                loadings.splice(loadings.indexOf("psm_user"));
                this.setState({ loadings });
            });

    }

    // static async converterData(dataAmericana: string) {
    //     if (dataAmericana && dataAmericana.length > 8) {

    //         const partes = dataAmericana?.split('-');
    //         const splitData = partes[2]?.split('T');

    //         const mes = partes[1];
    //         const dia = splitData[0];
    //         const ano = partes[0];
    //         const dataBrasileira = `${dia}/${mes}/${ano}`;
    //         return dataBrasileira;
    //     }
    //     if (dataAmericana && dataAmericana.length == 8) {
    //         console.log("dataAmericana :>", dataAmericana)

    //         const mes = dataAmericana.substring(4, 6);
    //         const dia = dataAmericana.substring(6, 8);
    //         const ano = dataAmericana.substring(0, 4);
    //         const dataBrasileira = `${dia}/${mes}/${ano}`;
    //         return dataBrasileira;
    //     }
    // }

    public onLoad = async (value: any) => {
        this.setState("loadings", [
            "psm_fk_macro_region_id",
            "psm_microrregiao",
            "psm_fk_activity",
            "psm_commercial_cell",
            "psm_sector_commercial",
            "psm_internal",
            "psm_external",
            "psm_commercial_fk_person_contact_id",
            "psm_carriers_fk_person_id",
            "psm_fk_payment_term_id",
            "psm_keyacoount",
            "psm_relation_products",
            "psm_relation_services",
            "psm_fk_activity_sector_id"
        ]);
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this, 'dibi')
        var dadosPsm: any = this.globalProps.values



        let variavelName = dadosPsm.psm_clients_fk_person_id != null ? "psm_clients_fk_person_id" : "psm_fk_person_id"

        axios.get(`/api/v1/people/${dadosPsm?.psm_clients_fk_person_id}`)
            .then((res) => {

                if ((dadosPsm.psm_carriers_fk_person_id == undefined) && (dadosPsm.psm_carriers_fk_person_id == null)) {
                    if (res.data.item.person_shipping_fk_person_id !== null) {
                        this.setFieldValue("psm_carriers_fk_person_id", res.data.item.person_shipping_fk_person_id)
                    }
                }
                if (dadosPsm.psm_fk_payment_term_id == null) {
                    if (res.data.item?.person_fk_payment_term_id !== null) {
                        this.setFieldValue("psm_fk_payment_term_id", res.data.item?.person_fk_payment_term_id);
                    }
                }
                this.setFieldValue("psm_fk_hub", res.data.item.person_fk_macro_region_id);
                // if(dadosPsm.psm_microrregiao == null){

                this.setFieldValue("psm_microrregiao", res.data.item.person_microregion);
                // }

                if (!['', null, undefined].includes(res.data.item.person_fk_activity_sector_id)) {
                    this.setFieldValue("psm_fk_activity_sector_id", res.data.item.person_fk_activity_sector_id);
                }

                if (!['', null, undefined].includes(res.data.item.person_fk_macro_region_id)) {
                    this.setFieldValue("psm_fk_macro_region_id", res.data.item.person_fk_macro_region_id);
                }

                this.setFieldValue("psm_commercial_cell", res.data.item.person_contact_cellphone);

                this.setFieldValue("psm_email_send_nfs", res.data.item.person_email_nfs);

                this.setFieldValue("psm_partial_billing", res.data.item.person_accept_income);
                this.setFieldValue("psm_receiving_limit_nfse", res.data.item.person_get_nfse);
                this.setFieldValue("psm_measuring_sheet_frs ", res.data.item.person_use_frs);

                this.setFieldValue("psm_obs_accept_income", res.data.item.person_obs_accept_income);
                this.setFieldValue("psm_obs_receiving_limit", res.data.item.person_obs_get_nfse);
                this.setFieldValue("psm_obs_measuring_sheet", res.data.item.person_obs_use_frs);


                if (dadosPsm.psm_clients_fk_person_id !== null) {
                    this.setFieldValue("psm_internal", '');
                    this.setFieldValue("psm_external", '');
                    this.setFieldValue("psm_keyacoount", '');
                    for (var k in res.data.item.person_sellers) {
                        if (res.data.item.person_sellers[k].seller.seller_type_of_seller !== 'K') {
                            if (res.data.item.person_sellers[k].seller.seller_is_extern == false) {
                                this.setFieldValue("psm_internal", res.data.item.person_sellers[k].seller.seller_name);
                                if (dadosPsm.psm_from_fk_seller_id == null) {
                                    this.setFieldValue("psm_from_fk_seller_id", res.data.item.person_sellers[k].seller.seller_id);
                                }
                            }
                            if (res.data.item.person_sellers[k].seller.seller_is_extern == true) {
                                this.setFieldValue("psm_external", res.data.item.person_sellers[k].seller.seller_name);
                            }
                        }
                        if (res.data.item.person_sellers[k].seller.seller_type_of_seller === 'K') {
                            this.setFieldValue("psm_keyacoount", res.data.item.person_sellers[k].seller.seller_name);
                        }
                    }
                }
            })
            .catch((e) => { })

        if (dadosPsm.psm_taxes == null) {
            this.setFieldValue("psm_taxes", "TODOS OS IMPOSTOS JÁ ESTÃO INCLUSOS.")
        }
        if (this.globalProps.match.url.includes('/add') != true) {
            if (dadosPsm?.person?.person_fk_activity_sector_id != null || dadosPsm?.person?.person_fk_activity_sector_id != undefined) {
                this.setFieldValue("psm_fk_activity_sector_id", dadosPsm.person.person_fk_activity_sector_id);
            }
        }
        //Data de Criação
        if (dadosPsm.psm_date == null || dadosPsm.psm_date == undefined) {
            let psmDate = dayjs().subtract(3, 'hour').format('YYYY-MM-DD HH:mm')

            this.setFieldValue("psm_date", psmDate);
        } else if (this.globalProps.match.url.includes('/add') == true) {
            this.setFieldValue("psm_date", dayjs().subtract(3, 'hour').format('YYYY-MM-DD HH:mm'));
        }

        if (dadosPsm.psm_status_fk_dibi_situation_id == '3') {
            this.setField("psm_deadline", {
                readonly: true
            })
            this.setField("psm_from_fk_seller_id", {
                readonly: true
            })
        }
        const isProspect = this.globalProps.values.person?.person_type_is_prospect !== true ? false : true;

        if ([true].includes(isProspect)) {
            this.setFieldValue('psm_client_is_prospect', true)
        } else {
            this.setFieldValue('psm_client_is_prospect', false)
        }



        if (this.globalProps.values?.psm_status_fk_dibi_situation_id >= 3 && this.globalProps.values?.psm_status_fk_dibi_situation_id < 10) {
            this.setField("psm_liberation", {
                readonly: true
            })
            this.setField("psm_relation_products", {
                gridDelete: false
            })
        }




        var budgetClient = dadosPsm.psm_clients_fk_person_id;
        this.globalProps.values.psm_clients_fk_person_id = budgetClient;
        if (budgetClient === null) {
            this.setFieldValue("psm_clients_fk_person_id", dadosPsm.psm_fk_person_id)
        }



        this.setFieldValue("psm_fk_activity", dadosPsm.person?.person_fk_activity_sector_id)


        try {
            const maskDate = function (value: string) {
                if (value.includes('/')) {
                    value = value.split('/').join('')
                }
                let day = value.substring(0, 2);
                let month = value.substring(2, 4);
                let year = value.substring(4, 8);
                return `${day}-${month}-${year}`
            }


            if ([null, undefined, ''].includes(dadosPsm.psm_show_budget)) {
                this.setFieldValue('psm_billing_type', dadosPsm.psm_show_budget == 1 ? 'SERVIÇO' : 'SERVIÇO + PRODUTO');
            }
            // console.log('Entrei aqui ::: ', dadosPsm.psm_final_date_delivery)

            if ([11, 4].includes(dadosPsm.psm_status_fk_situation_id)) {
                // if (dadosPsm.psm_status_fk_situation_id == 11) {
                this.setFieldValue("psm_final_date_delivery", '');
                this.setFieldValue('psm_lead_time', '');
            }

            if (![null, undefined, ''].includes(dadosPsm.psm_final_date_delivery)) {
                let dateNow: any = dayjs().format("YYYY-MM-DD");
                let dateDelivery: any = dadosPsm.psm_final_date_delivery;
                dateDelivery = dayjs(dateDelivery, 'YYYY-MM-DD').format('YYYY-MM-DD')
                let diffDate = dayjs(dateDelivery, "YYYY-MM-DD").diff(dateNow, 'day');
                let resultDiff = function (dateDiff: any) {
                    // console.log("Entrei aqui :::>O*&", dateDelivery)
                    if (dateDiff == 0) {
                        return "Hoje é o dia da Entrega";
                    }
                    if (Math.sign(dateDiff) == 1) {
                        return `Faltam ${dayjs(dateDelivery, "YYYY-MM-DD").diff(dateNow, 'day')} dias`
                    } else {
                        return `Passaram ${dayjs(dateDelivery, "YYYY-MM-DD").diff(dateNow, 'day')} dias`
                    }
                }
                // this.setFieldValue("psm_final_date_delivery", dadosPsm.psm_final_date_delivery)
                this.setFieldValue("psm_lead_time", dadosPsm.psm_status_fk_situation_id !== 16 ? resultDiff(diffDate) : 'PSM Finalizada');
            }

        } catch (e) { console.log('error ::: ', e) }


        if (this.globalProps.match.url.includes('/add') !== true && dadosPsm.psm_status_fk_dibi_situation_id == '4') {
            this.setField("psm_approve_date", { label: "Data da Reprovação" })
        }


        //Data da Situação
        if ((this.globalProps.match.url.includes('/add') != true) && (dadosPsm.psm_status_logs.length != 0) && (dadosPsm.psm_situation_date == null || dadosPsm.psm_situation_date == "")) {

            let i = dadosPsm.psm_status_logs.length - 1
            let date = dadosPsm.psm_status_logs[i].psm_status_log_date

            this.setFieldValue("psm_situation_date", date);
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 3000)
    };
}

// export class PsmFinalDeliveryChangeEvents extends Event {

// }

export class DibiApprovalEvents extends Event {
    public onLoad = async () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this, 'dibi')

        // if (this.globalProps.values.psm_situation_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_situation_date);
        //     this.setFieldValue("psm_situation_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_situation_date", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_approve_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_approve_date);
        //     this.setFieldValue("psm_approve_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_approve_date", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_final_date_delivery) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_date_delivery);
        //     this.setFieldValue("psm_final_date_delivery", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_date_delivery", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_final_delivery_change) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_delivery_change);
        //     this.setFieldValue("psm_final_delivery_change", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_delivery_change", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_date);
        //     this.setFieldValue("psm_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_date", dataCorreta)
        //     }
        // }

    }
}

export class DibiCloseEvents extends Event {
    public onLoad = async () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this, 'dibi')

        // if (this.globalProps.values.psm_situation_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_situation_date);
        //     this.setFieldValue("psm_situation_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_situation_date", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_approve_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_approve_date);
        //     this.setFieldValue("psm_approve_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_approve_date", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_final_date_delivery) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_date_delivery);
        //     this.setFieldValue("psm_final_date_delivery", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_date_delivery", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_final_delivery_change) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_delivery_change);
        //     this.setFieldValue("psm_final_delivery_change", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_delivery_change", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_date);
        //     this.setFieldValue("psm_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_date", dataCorreta)
        //     }
        // }

    }
}

export class DibiCollectEvents extends Event {
    public onLoad = async () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this, 'dibi')

        // if (this.globalProps.values.psm_situation_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_situation_date);
        //     this.setFieldValue("psm_situation_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_situation_date", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_approve_date) {

        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_approve_date);
        //     this.setFieldValue("psm_approve_date", '')

        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_approve_date", dataCorreta)

        //     }
        // }

        // if (this.globalProps.values.psm_final_date_delivery) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_date_delivery);
        //     this.setFieldValue("psm_final_date_delivery", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_date_delivery", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_final_delivery_change) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_delivery_change);
        //     this.setFieldValue("psm_final_delivery_change", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_delivery_change", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_date);
        //     this.setFieldValue("psm_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_date", dataCorreta)
        //     }
        // }

    }
}

export class DibiInvoicesEvents extends Event {
    public onLoad = async () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this, 'dibi')

        // if (this.globalProps.values.psm_situation_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_situation_date);
        //     this.setFieldValue("psm_situation_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_situation_date", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_approve_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_approve_date);
        //     this.setFieldValue("psm_approve_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_approve_date", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_final_date_delivery) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_date_delivery);
        //     this.setFieldValue("psm_final_date_delivery", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_date_delivery", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_final_delivery_change) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_delivery_change);
        //     this.setFieldValue("psm_final_delivery_change", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_delivery_change", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_date);
        //     this.setFieldValue("psm_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_date", dataCorreta)
        //     }
        // }

    }
}

export class DibiPackagesEvents extends Event {
    public onLoad = async () => {
        requiredColumns(this.globalProps.values.psm_status_fk_dibi_situation_id, this, 'dibi')

        // if (this.globalProps.values.psm_situation_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_situation_date);
        //     this.setFieldValue("psm_situation_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_situation_date", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_approve_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_approve_date);
        //     this.setFieldValue("psm_approve_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_approve_date", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_final_date_delivery) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_date_delivery);
        //     this.setFieldValue("psm_final_date_delivery", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_date_delivery", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_final_delivery_change) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_final_delivery_change);
        //     this.setFieldValue("psm_final_delivery_change", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_final_delivery_change", dataCorreta)
        //     }
        // }

        // if (this.globalProps.values.psm_date) {
        //     const dataCorreta = await DibiEvents.converterData(this.globalProps.values.psm_date);
        //     this.setFieldValue("psm_date", '')
        //     if (dataCorreta !== null) {
        //         this.setFieldValue("psm_date", dataCorreta)
        //     }
        // }

    }
}