import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from "rsuite";
export class ProductEquipmentTypeEvents extends Event {
    public buscaUnidadeDeMedida = (sigla: string, setField: Function) => {
        this.setState("loadings", ["product_fk_measure_unit_id"]);
        axios
            .get("/api/v1/measure-unit/" + sigla)
            .then((res) => {
                if (res && res.data && res.data.item) {
                    setField("product_fk_measure_unit_id", res.data.item);
                }
            })
            .catch((res) => {
                Notification.error({
                    title: "Erro!",
                    description: "Não foi possivel encontrar a unidade de medida (" + sigla + ")",
                    duration: 3000,
                });
            })
            .finally(() => {
                this.setState("loadings", []);
            });
    };

    public onChange = (value: string | number | null, setField: any) => {
        // alert(value);
        if (value) {
            if (value.toString() === "1") {
                // this.setState('loadings',['product_stage']);
                this.buscaUnidadeDeMedida("PC", setField);
                //setField("product_stage", undefined);
                setField("product_control_stock", false);
            } else if (value.toString() === "3") {
                this.buscaUnidadeDeMedida("UN", setField);

                setField("product_control_stock", true);
            } else if (value.toString() === "2") {
                setField("product_control_stock", true);
            } else {
                //setField("product_stage", undefined);

                setField("product_control_stock", false);
            }
            // alert(value);
        }
    };
}
