import React from "react";
import { SelectPicker,FormControl,HelpBlock,ControlLabel,FormGroup,Form,Button, Modal, Table, Toggle, Row, Grid, Col, Input,FlexboxGrid, Loader } from "rsuite";
import axios from "../../utilities/axios";

// const { Column, HeaderCell, Cell } = Table;


export class ObservationClient extends React.Component<any, {}> {
    public state = {
        loading: true      
    }    

   
    render() {
        if (this.props.show === false) {
            return null;
        }
        return (
            <Modal show={true} className="rs-modal-lg"> 
                <Modal.Header closeButton={false}>
                    <h4>{this.props.title ?? "Deseja mesmo sair?"}</h4>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    {!this.props.dataModal &&(<Loader backdrop content="loading..." vertical />)}
                        <div style={{ display: "flex" }}>                       
                            <FormGroup style={{ width: "250px" }}>
                                <ControlLabel>Possui transportadora principal?</ControlLabel>
                                <Toggle defaultChecked={this.props.dataModal["person_has_shipper"]} checked={this.props.dataModal["person_has_shipper"]} style={{ marginLeft: "30%"}} size="lg" />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Transportadora Principal</ControlLabel>
                                <Input value={this.props.dataModal["person_has_shipper"] == true ? (this.props?.loadedselect['label'] ? this.props?.loadedselect['label'] : this.props.dataModal['person_shipping_fk_person_id']) : ""} style={{ width: "280px" }} className="rs-input" readOnly={true} />
                            </FormGroup>
                        </div>
                        <div style={{ display: "flex" }}>
                            <FormGroup style={{ width: "250px" }}>
                                <ControlLabel>Aceita faturamento parcial?</ControlLabel>
                                <Toggle defaultChecked={this.props.dataModal["person_accept_income"]} checked={this.props.dataModal["person_accept_income"]} style={{ marginLeft: "30%" }} size="lg"/>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Observação (aceita faturamento parcial?)</ControlLabel>
                                <Input value={this.props.dataModal['person_obs_accept_income']} style={{ width: "280px" }} className="rs-input" readOnly={true} />
                            </FormGroup>
                        </div>
                        <div style={{ display: "flex" }}>
                            <FormGroup style={{ width: "250px" }}>
                                <ControlLabel>Possui limite p/ recebimento de NFS-e?</ControlLabel>
                                <Toggle defaultChecked={this.props.dataModal["person_get_nfse"]} checked={this.props.dataModal["person_get_nfse"]} style={{ marginLeft: "30%" }} size="lg"  />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Observação (limite para recebimento de NFS-e?)</ControlLabel>
                                <Input value={this.props.dataModal['person_obs_get_nfse']} style={{ width: "280px" }} className="rs-input" readOnly={true} />
                            </FormGroup>
                        </div>
                        <div style={{ display: "flex" }}>
                            <FormGroup style={{ width: "250px" }}>
                                <ControlLabel>Utiliza folha de medição FRS?</ControlLabel>
                                <Toggle defaultChecked={this.props.dataModal["person_use_frs"]} checked={this.props.dataModal["person_use_frs"]} style={{ marginLeft: "30%" }} size="lg"/>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Observação (limite para recebimento de FRS?)</ControlLabel>
                                <Input value={this.props.dataModal['person_obs_use_frs']} style={{ width: "280px" }} className="rs-input" readOnly={true} />
                            </FormGroup>
                        </div>
                      
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{ marginTop: 30 }}>
                    {this.props.showConfirm !== false && (
                        <Button loading={this.props.loading} onClick={this.props.onConfirm} color={this.props.confirmColor ?? "red"}>
                            {this.props.confirmText ?? "Confirmar"}
                        </Button>
                    )}
                    <Button onClick={this.props.onCancel} color={this.props.cancelColor ?? undefined}>
                        {this.props.cancelText ?? "Cancelar"}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
