import { Event } from "../Event";

export class IhmFaultPhraseFkServicePerformedIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any,formValues:any) => {
        //alert("TESTE "+JSON.stringify())
       // console.log("PASSOU EVENT",formValues)
      //  this.setState({items:'teste'})
   // this.setFieldProperty('ihm_fault_phrase_fk_service_performed_id','options',[{value:'1',label:'teste1221'}])
      //  setField('ihm_fault_phrase_fk_service_performed_id',{value:'1',label:'teste'})
    }
  

}