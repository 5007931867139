import { Event } from '../Event';


export class PsmNumNfIdEvents extends Event {
    public state = {
        loading: false
    }
    public onChange = (value:any, setField: any, setState: any, values: string) => {
        if(value.length < 10){
            console.log({value})
            this.setFieldValue("psm_num_nf_id", ("0000000000" + value).slice(-10) );
        }
    }
}

