import axios from "../../../utilities/axios";
import React from "react";
import _ from "lodash";
import {  Button, Notification } from "rsuite";
import { ControlLabel, FormGroup, Input, InputGroup } from "rsuite";

export class PsmRequestAlteration extends React.Component<any, {}> {
    isClickCheck = async () => {
        var checkCody = this.props.values.psm_check_cody;
        var checkManufacture = this.props.values.psm_check_manufacture;
        var checkSerial = this.props.values.psm_check_serial;

        var objFields = [{active: checkSerial == true ? "false" : "true", label: "Série"}, 
            {active: checkManufacture == true ? "false" : "true", label: "Fabricante"}, 
            {active: checkCody == true ? "false" : "true", label: "Código do Fabricante"}]

        if((checkCody == "true" && checkManufacture == "true" && checkSerial == "true")){
            Notification.info({
                title: "Selecione um Check!",
                description: `Selecione ao menos um "check", para que possa fazer a solicitação`,
                duration: 3520,
            });
        }else{
            let usersIds: any = []
            await axios
                .get("api/v1/users/select")
                .then((res) => {
                    let data = res.data.items
                    for(var id in data){
                        if(data[id].role?.role_id == 2){
                            usersIds[id] = data[id].value
                        }
                    }
                })

            for(var l in objFields){
                if(objFields[l].active == "true"){
                    let menssagem = `Solicitou alteração da PSM ${this.props.values.psm_os_number}, referente ao campo: ${objFields[l].label}`;
                    let type = "warning"
                    await axios   
                        .post("/api/redis/send/message", {ids: usersIds, message: menssagem, type: type})
                        .then((res) => {
                            // console.log(res)
                            if(res.data.status == 200){
                                Notification.success({
                                    title: "Adcionado!!",
                                    description: `${menssagem}`,
                                    duration: 3520,
                                });
                            }
                        })
                }
            }
        }
      
    };
    render() {
        return (<>
            <Button style={{marginTop: 23, marginRight: 15}} onClick={() => this.isClickCheck()} color={"blue"} className="ml-4">
                <i className="fas fa-bell" style={{marginRight: "10px", color: "white"}}></i>Solicitar Alteração
            </Button>
        </>)
    }
}
