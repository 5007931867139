import { Notification } from "rsuite";
import { Event } from "../Event";
// import React from "react";
import _ from "lodash";
import dayjs from "dayjs";
import axios from "../../utilities/axios";


export class MaterialApprovedGridEvents extends Event {
    public onLoad = async (value: any, setField: any, setState: any, formValues: any, globalData: any,) => {

        // if(this.globalProps.values.material_requisition_fk_material_requisition_situation_id >= 3){
        //     this.setField("material_requisition_liberation", {
        //         readonly: true
        //     })
        // }ƒ

        // await axios
        //     .get(`/api/v1/material-requisition/verify-is-manual/${this.globalProps.parentValues.material_requisition_logistic_fk_material_requisition_id}`)
        //     .then((res) => {
        //         if (res.data.status == 200) {
                    
        //             console.log("res :::>", res)
        //             if (res.data.situation >= 3) {
        //                 this.setField('material_approved_grid_series', {
        //                     readonly: true
        //                 });

        //             }
        //         }
        //     })
        //     .catch((error) => {
        //         console.error("Erro na requisição:", error);
        //         this.setState({ loading: false });
        //     });


        this.setState('loadings', [
            'material_approved_g          rid_user_name',
            'material_approved_grid_date'
        ])
        const currentDate = dayjs().format('YYYY-MM-DD HH:MM');
        const hourDate = dayjs().format("HH:mm:ss")
        this.setFieldValue('material_approved_grid_date', currentDate);
        this.setFieldValue('material_approved_grid_hour', hourDate);

        axios
            .get("/api/v1/me")
            .then((subRes) => {
                var subData = subRes.data;
                //console.log(subData);
                if (subData) {
                    this.setFieldValue("material_approved_grid_user_name", subData.user.user_name);
                }
            })
            .catch((error) => { })
            .finally(() => {
                this.setState('loadings', [])
            });
    }
}