import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmEquipmentSerieFkProductIdEvents extends Event {
    public state = {
        loading: false,
    }
    public onChange = async (value: any, setField: any, setState: any, values: string) => {
        var valuesPsm: any = this.globalProps.values;
        var interReferenceValue: any = "";

        if(value == null){
            await this.setState("loadings",[
                "psm_receipt_equipment_description",
                "psm_equipment_manufacturer_id",
                "psm_receipt_equipment_manufacturer",
                "psm_intern_reference_fk_product_id"
            ]);

            this.setFieldValue("psm_receipt_equipment_description","");
            this.setFieldValue("psm_equipment_manufacturer_id", "");
            this.setFieldValue("psm_receipt_equipment_manufacturer", "");
            this.setState("loadings",[
                "psm_intern_reference_fk_product_id"
            ]);
            this.setFieldValue("psm_intern_reference_fk_product_id", "");
            this.setState("loadings", []);

        }

        if(value > 0){
            this.setState("loadings",[
                "psm_receipt_equipment_description",
                "psm_equipment_manufacturer_id",
                "psm_receipt_equipment_manufacturer",
                "psm_intern_reference_fk_product_id"
            ]);

            await axios
                .get("/api/v1/product/" + value)
                .then((res) => {
                    var item = res.data.item;
                    console.log("Item ::: ", typeof(item.product_intern_reference))
                    interReferenceValue = item.product_intern_reference

                    console.log({item});
                    if(item){
                        this.setFieldValue("psm_receipt_equipment_description", item?.product_description);
                        this.setFieldValue("psm_equipment_manufacturer_id", item?.product_manufacturer_code);
                        this.setFieldValue("psm_receipt_equipment_manufacturer", item.manufacturer?.manufacturer_name);
                        this.setState("loadings",[
                            "psm_intern_reference_fk_product_id"
                        ]);
                        this.setFieldValue("psm_intern_reference_fk_product_id", parseInt(value));
                        this.setState("loadings", []);
                    }
                })
                .finally(() => {
                    this.setState("loadings", []);
                });

            //evento para que haja o calculo da garantia da PSM.
            console.log("Meu state ::: ", interReferenceValue);
            axios
                .get(`/api/v1/psm/expiration-date/${interReferenceValue}/${valuesPsm.psm_fk_person_id}`)
                .then((res) =>{
                    console.log("Response das PSMs :::", res.data.item)
                })
        }
    }
}