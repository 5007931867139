import axios from "../../../utilities/axios";
import React from "react";
import {
  Button,
  Table,
  Modal,
  Input,
  Row,
  Col,
  Whisper,
  Tooltip,
  Panel,
  Divider,
  SelectPicker,
  InputNumber,
  Notification,
  FormGroup,
  Form,
  ControlLabel,
  InputGroup,
  FormControl,
  HelpBlock,
} from "rsuite";
import _, { camelCase, values } from "lodash";
import dayjs from "dayjs";
import { Permission } from "../../Permission";
import Formalize from "../../Formalize";
import { ReveiwModal } from "../../customModal/ReveiwModal";

const { Column, HeaderCell, Cell } = Table;

export class BridgeRectifier extends React.Component<any, {}> {
  public state : any = {
    show: false,
    loading: false,
    showSituation: false,
    reviewLogDate: [],
    nameUser: [],
    dateModal: [],
    dadosModal: [],
    dadosModalR: [],
    dadosModalS: [],
    dadosModalT: [],
    dataCombo: [],
    quantityNumber: "",
    formValue: {
        
    },
    forModal: [],
  };

  onRead = (rowReview: string) => {
    var rowPage = this.props.form;
    var rowView = JSON.parse(rowReview);
    if (rowReview) {
      var arrayInicial = [];
      for (var k in rowView) {
        var indiceDados: any = Object.keys(rowView[k]);
        if (rowPage[indiceDados[0]]) {
          var nomeCampoPage = rowPage[indiceDados[0]].label;
          var valorInicial = [rowView[k]];
          var valorFinal: any = Object.values(valorInicial[0]);
          indiceDados = nomeCampoPage;
          arrayInicial.push({
            Indice: indiceDados,
            valueOld: valorFinal[0].valueOld,
            valueNew: valorFinal[0].valueNew,
          });
        }
      }
    }
  };
  openAdd = (quantidade: any) => {
      if(quantidade > 0 ){
        this.setState({show: true});

        let dados: any = [];

        for( var i = 0; i < quantidade; i++){ 
            var faseRPositiva = "DC +/ R";
            var faseSPositiva = "DC +/ S";
            var faseTPositiva = "DC +/ T";

            var faseRNegativa = "DC -/ R";
            var faseSNegativa = "DC -/ S";
            var faseTNegativa = "DC -/ T";

            var obj = {dados : {obj_faseR : {fasePositivaValue :faseRPositiva + (i + 1), fasenegativaValue : faseRNegativa + (i + 1)}, obj_faseS : {fasePositivaValue : faseSPositiva + (i + 1), fasenegativaValue: faseSNegativa + (i + 1)}, obj_faseT : {fasePositivaValue : faseTPositiva + (i + 1), fasenegativaValue: faseTNegativa + (i + 1)}}}
            dados.push(obj);
        }
        this.setState({
            dadosModal: dados
        })
        // this.renderColunsModal(dados);
      }else{
        Notification.error({
            title: "Erro!",
            description: "Por favor informar uma quantidade",
        });
      }
      
  }

  openModal = (rowData: any) => {
    var { table: prefix } = this.props.form.$GLOBALS;
    if (this.props.overrideActions) {
      return this.props.overrideActions(rowData);
    }
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Permission name={prefix + "-read"}>
          <Whisper
            trigger="hover"
            delayHide={0}
            placement="top"
            speaker={<Tooltip>Visualizar</Tooltip>}
          >
            <i
              onClick={() => this.onRead(rowData.psm_review_log_changed_values)}
              className="fas text-primary fa-fw mr-2 clickable fa-eye"
            ></i>
          </Whisper>
        </Permission>
      </div>
    );
  };

  renderColumns = () => {
    var output = [];
    var CamposColumn = ["Fase Positiva", "Valor", "Unid", "Fase Negativa", "Valor", "Unid", "Condição"];
    for ( var i in CamposColumn) { 
        output.push(
        <Column key={i} width={150} resizable>
                <HeaderCell> <div dangerouslySetInnerHTML={{ __html: CamposColumn[i] }}></div> </HeaderCell>
                <Cell dataKey={camelCase(CamposColumn[i])} />
        </Column>)
    };
    //console.log({output})
    return output;
  }

//   renderColunsModal = (data: any) => {
//     const dataCombo = [{
//         label:"Ω",
//         value: 1
//     },{
//         label:"KΩ",
//         value: 2
//     },{
//         label:"MΩ",
//         value: 2
//     },{
//         label:"GΩ",
//         value: 2
//     }]
//      const dadosCondition = [{
//          label: "Ok",
//          value: 1
//      },{
//          label: "DANIFICADO",
//          value: 2
//      }]
//     var faseName = ["Fase R", "Fase S", "Fase T"];
//     var dividerOutput: any = [];
//     var output = [];

//       for(var i in faseName){
//           //console.log("Dados", faseName[i]);
//           dividerOutput.push(
//             <Divider><h4> <div dangerouslySetInnerHTML={{ __html: faseName[i] }}></div> </h4></Divider>
//           )
//       }
//     for(var l in data[0]){
//         for (var k in data){
//             console.log(dividerOutput[k])
//             //console.log(data[k].dados.obj_faseR);
//             output.push(
//                 <>
//                 {dividerOutput[k]}
//                 <Col xs={4} style={{marginTop: 10, marginLeft: 25}}>
//                     <div> 
//                         <label> <div dangerouslySetInnerHTML={{ __html: data[k].dados.obj_faseR.faseRPositivaValue }}></div>
//                             <InputNumber placeholder="Digite um numero"/>
//                         </label>
//                     </div>
//                 </Col>
//                 <Col xs={4} style={{marginTop: 10, marginLeft: "40px"}}>
//                     <div>
//                         <div>Unid</div>
//                         <SelectPicker data={dataCombo} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
//                     </div>
//                 </Col>
//                 <Col xs={4} style={{marginTop: 10, marginLeft: 10}}>
//                     <div>
//                         <label> <div dangerouslySetInnerHTML={{ __html: data[k].dados.obj_faseR.faseRnegativaValue }}></div>
//                             <InputNumber placeholder="Digite um numero"/>
//                         </label>
//                     </div>
//                 </Col>
//                 <Col xs={4} style={{marginTop: 10, marginLeft: 15}}>
//                     <div>
//                         <div>Unid</div>
//                         <SelectPicker data={dataCombo} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
//                     </div>
//                 </Col>
//                 <Col xs={5} style={{marginTop: 10, marginLeft: 15}}>
//                     <div>
//                         <div>Condição</div>
//                         <SelectPicker data={dadosCondition} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
//                     </div>
//                 </Col> 
//                 </>
//             )
//         }
//     }
//     console.log(output);
//     return this.setState({teste: output})
    
//   }

    onCancel = () => {
        this.setState({
        show: false,
        });
    }

    handleObject = (value: any) => { //delcio
        // console.log("Teste 2 ", value)
        //this.setState({formData:{0:"ASDFSDFSDFS"}})
       // this.setState({reviewLogDate:[{}]})
    }

  public elementRef: any = null;

  componentDidMount() {
    this.renderColumns();
  }

  render() {
    var allFase = this.state.dadosModal;
    const dataCombo = [{
        label:"Ω",
        value: 1
    },{
        label:"KΩ",
        value: 2
    },{
        label:"MΩ",
        value: 2
    },{
        label:"GΩ",
        value: 2
    }]
     const dadosCondition = [{
         label: "Ok",
         value: 1
     },{
         label: "DANIFICADO",
         value: 2
     }]
    // CamposColumn.map((item:any) => {
    //     console.log("valores", Object.assign({},item.dados));
    // });
    //console.log({allFase});
    return (
      <>
        <Modal show={this.state.show} className="rs-modal-customLg">
            <Modal.Header onHide={this.props.funcaoclose}>
                <h4>Ponte Retificadora</h4>
            </Modal.Header>
            <Modal.Body>
            <Form
                onChange={formValue => {
                    //console.log(formValue);
                    this.setState({ formValue: formValue });
                    this.handleObject(formValue);
                }}
                formValue={this.state.formValue}
            >
                <Divider><h4>Fase R</h4></Divider>
                <Row style={{maxWidth: "100%"}}>
                    {allFase.map((item: any) => (
                        <>
                            <Col xs={4} style={{marginTop: 10, marginLeft: 25}}>
                                <div> 
                                <FormGroup>
                                    <label> {item.dados.obj_faseR.fasePositivaValue}
                                        <FormControl style={{width: "160px"}} name={item.dados.obj_faseR.fasePositivaValue} accepter={InputNumber} placeholder="Digite um numero" />
                                    </label>
                                </FormGroup>
                                </div>
                            </Col>
                        <Col xs={4} style={{marginTop: 10, marginLeft: "40px"}}>
                            <div>
                                <FormGroup>
                                    <div>Unid</div>
                                    <FormControl accepter={SelectPicker} data={dataCombo} name="SelectPicker" style={{width: "180px"}} placeholder="Selecione"></FormControl>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col xs={4} style={{marginTop: 10, marginLeft: 10}}>
                            <div>
                                <FormGroup>
                                        <label> {item.dados.obj_faseR.fasenegativaValue}
                                            <FormControl style={{width: "160px"}} name={item.dados.obj_faseR.fasenegativaValue} accepter={InputNumber} placeholder="Digite um numero" />
                                        </label>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col xs={4} style={{marginTop: 10, marginLeft: 15}}>
                            <div>
                                <div>Unid</div>
                                <SelectPicker data={dataCombo} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
                            </div>
                        </Col>
                        <Col xs={5} style={{marginTop: 10, marginLeft: 15}}>
                            <div>
                                <div>Condição</div>
                                <SelectPicker data={dadosCondition} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
                            </div>
                        </Col> 
                        </>
                    ))}
                </Row>
           
            <Divider><h4>Fase S</h4></Divider>
                <Row style={{maxWidth: 1500}}>
                {allFase.map((item: any) => (
                        <>
                        <Col xs={4} style={{marginTop: 10, marginLeft: 25}}>
                            <div> 
                               <label> {item.dados.obj_faseS.fasePositivaValue}
                                   <InputNumber placeholder="Digite um numero"/>
                               </label>
                            </div>
                        </Col>
                        <Col xs={4} style={{marginTop: 10, marginLeft: "40px"}}>
                            <div>
                                <div>Unid</div>
                                <SelectPicker data={dataCombo} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
                            </div>
                        </Col>
                        <Col xs={4} style={{marginTop: 10, marginLeft: 10}}>
                            <div>
                               <label> {item.dados.obj_faseS.fasenegativaValue}
                                   <InputNumber placeholder="Digite um numero"/>
                               </label>
                            </div>
                        </Col>
                        <Col xs={4} style={{marginTop: 10, marginLeft: 15}}>
                            <div>
                                <div>Unid</div>
                                <SelectPicker data={dataCombo} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
                            </div>
                        </Col>
                        <Col xs={5} style={{marginTop: 10, marginLeft: 15}}>
                            <div>
                                <div>Condição</div>
                                <SelectPicker data={dadosCondition} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
                            </div>
                        </Col> 
                        </>
                    ))}
                </Row>
            <Divider><h4>Fase T</h4></Divider>
              <Row style={{maxWidth: 1500}}>
              {allFase.map((item: any) => (
                        <>
                        <Col xs={4} style={{marginTop: 10, marginLeft: 25}}>
                            <div> 
                               <label> {item.dados.obj_faseT.fasePositivaValue}
                                   <InputNumber placeholder="Digite um numero"/>
                               </label>
                            </div>
                        </Col>
                        <Col xs={4} style={{marginTop: 10, marginLeft: "40px"}}>
                            <div>
                                <div>Unid</div>
                                <SelectPicker data={dataCombo} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
                            </div>
                        </Col>
                        <Col xs={4} style={{marginTop: 10, marginLeft: 10}}>
                            <div>
                               <label> {item.dados.obj_faseT.fasenegativaValue}
                                   <InputNumber placeholder="Digite um numero"/>
                               </label>
                            </div>
                        </Col>
                        <Col xs={4} style={{marginTop: 10, marginLeft: 15}}>
                            <div>
                                <div>Unid</div>
                                <SelectPicker data={dataCombo} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
                            </div>
                        </Col>
                        <Col xs={5} style={{marginTop: 10, marginLeft: 15}}>
                            <div>
                                <div>Condição</div>
                                <SelectPicker data={dadosCondition} style={{width: "180px"}} placeholder="Selecione"></SelectPicker>
                            </div>
                        </Col> 
                        </>
                    ))}
                </Row>
            </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={this.props.loading} onClick={() => this.onCancel()} color="red">
                    Fechar
                </Button>  

                <Button disabled={this.props.loading}  color="green">
                    <i className="fas fa-save"></i> Gravar
                </Button>      
            </Modal.Footer>
        </Modal>


        <div
          style={{ display: "flex", flexDirection: "column" }}
          ref={this.elementRef}
        >
          <Panel bordered bodyFill style={{ padding: 8 }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",marginBottom: 8 }}>
                    {<h3 dangerouslySetInnerHTML={{__html: "Ponte Retificadora"}}></h3>}
                    <div>
                        {this.props.canAdd === true && this.props.readOnly !== true && (
                           <>
                           <label> <i className="fas fa-abacus"></i> 
                            <Input
                            style={{width: "160px", marginRight: "15px", marginLeft: "8px"}} 
                            placeholder={"Digite a quantidade"}
                            onKeyUp={(event: any) => this.setState({quantityNumber: event.target.value})}
                            />
                           </label>
                           
                            <Button color="green" onClick={() => this.openAdd(this.state.quantityNumber)}>
                                <i className="fas fa-fw fa-plus mr-2"></i>
                            </Button>
                            </>
                        )}
                        <Button color="orange" onClick={() => this.setState({ isSettingsModalOpen: true })}  className="mr-4" style={{marginLeft: "5px"}}>
                            <i className="fas fa-fw fa-cog mr-2"></i>
                        </Button>
                    </div>
            </div>
            {/* <div style={{ marginTop: "25px" }}>
              <h3>Ponte Retificadora</h3>
            </div> */}
            <Table
              loading={this.props.loading}
              height={400}
              className={this.props.compact ? "compact" : ""}
              renderEmpty={() => (
                <div className="rs-table-body-info">
                  Nenhum item encontrado.
                </div>
              )}
              data={this.state.formData}
               >
                   {/* {console.log("AQUI BATATA",JSON.stringify(this.state.reviewLogDate))}
                   {JSON.stringify(this.state.reviewLogDate)} */}
                
                {this.renderColumns()}

              {/* <Column width={100}>
                <HeaderCell>Valor</HeaderCell>
                <Cell className="link-group">
                  {(rowData: any) => {
                    return this.openModal(rowData);

                    // return (
                    // <span>
                    //     <a onClick={() => this.openModal(rowData)}> Visualizar </a> |{' '}
                    // </span>
                    // );
                  }}
                </Cell>
              </Column> */}
            </Table>
          </Panel>
        </div>
      </>
    );
  }
}
