import axios from "../../../utilities/axios";
import React from "react";
import {  Button } from "rsuite";
import {HistoricModal} from "../../customModal/HistoricModal";






export class modalHistory extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        historic_sell: '',
        historic_proposal: ''
    };

    alteraSituacao = () => {
        // console.log(this.props)
        this.setState({ showSituation: true, loading: true })
        axios
            .get("/api/v1/psm/productHistorical/" + this.props.values?.psm_product_registration_fk_select_id + "/" + this.props.parentValues.psm_id)
            .then((res => {
                if(res.status === 200) {
                    this.setState({ loading: false });
                    // console.log(res);
                    this.setState({
                        historic_sell:res.data.historic_Sells,
                        historic_proposal: res.data.historic_proposal
                    })
                   
                }
                if(res.status === 403) {
                    this.setState({ loading: false });
                }
                
            }))  
            .finally(() => {
                this.setState({ loading: false });
            }); 
    }

    render() {
        return (<>
            <HistoricModal 
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showSituation: false,
                        })
                    }}
                    // body={<>
                    //     <Input size="md" style={{marginBottom: 20}} placeholder={this.state.situacaoModal} disabled={true}/>
                    //     <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                    //     <Button loading={this.state.loading} color='blue' style={{marginLeft:20}} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                    // </>}
                    cancelColor={"red"}
                    showConfirm={false}
                    cancelText={"Fechar"}
                    onCloseButton={true}
                    content={""}
                    returnText={"Voltar Situação"}
                    advancedText={"Avançar Situação"}
                    title={"Historico de vendas do produto para o cliente"}
                    subTitle={"Propostas em aberto"}
                    dataSell={this.state.historic_sell}
                    dataOpen={this.state.historic_proposal}
                    
                    show={this.state.showSituation}
                />
                {/* {JSON.stringify(this.props.values?.psm_product_registration_fk_select_id)}
                {JSON.stringify(this.props.values?.psm_id)}
                {JSON.stringify(this.props.parentValues.psm_id)} */}
            <Button style={{marginTop: 23, marginRight: 15}} loading={this.state.loading} onClick={() => this.alteraSituacao()} color={"blue"} className="ml-4">
                    <i className="far fa-book fa-fw mr-2"></i>    
            </Button>

        </>
            
        )
    }
}

// this.props.onChange('abc')
