const calcPercent = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0) => {
    var valorTotal = inicial;
    var targetDescontoPercentage = 0;
    var targetDescontoValue = 0;
    if (descontoPercentage > 0) {
        valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
        targetDescontoPercentage = descontoPercentage;
        targetDescontoValue = inicial - valorTotal;
    } else if (descontoValue > 0) {
        valorTotal = valorTotal - descontoValue;
        targetDescontoValue = descontoValue;
        targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
        // console.log({teste});
    }
    // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;

    return {
        valorTotal,
        percentual: targetDescontoPercentage,
        desconto: targetDescontoValue,
    };
};


const roundedFloat = (value: number) => {
    
    let newValue = null;

    newValue = value.toFixed(2);
    newValue = parseFloat(newValue);

    return newValue;
}


export { calcPercent, roundedFloat }