import { lte } from "lodash";
import { Event } from "../Event";

const calculaValorTotal = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0) => {
    var valorTotal = inicial;
    var targetDescontoPercentage = 0;
    var targetDescontoValue = 0;
    if (descontoPercentage > 0) {
        valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
        targetDescontoPercentage = descontoPercentage;
        targetDescontoValue = inicial - valorTotal;
    } else if (descontoValue > 0) {
        valorTotal = valorTotal - descontoValue;
        targetDescontoValue = descontoValue;
        targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
        let teste = targetDescontoPercentage.toFixed(2);
        // console.log({teste});
    }
    // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;

    return {
        valorTotal,
        percentual: targetDescontoPercentage,
        desconto: targetDescontoValue,
    };
};

export class PsmCustomImportValueProductEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        console.log(value[1]);
        var dataPsm: any = this.globalProps.values
        
        let valueService: number = 0;
        let valueProduct: number = 0;
        let valueServiceRecommended: number = 0;

        if (dataPsm.psm_considered_discount_value > 0){
            valueService = dataPsm.psm_considered_discount_value;
        }else{
            valueService = dataPsm.psm_considered_value;
        }
        if (dataPsm.psm_value_product_discount > 0){
            valueProduct = dataPsm.psm_value_product_discount;
        }else{
            valueProduct = dataPsm.psm_total_value_products;
        }
        if (dataPsm.psm_value_service_recommended_discount > 0){
            valueServiceRecommended = dataPsm.psm_value_service_recommended_discount;
        }else{
            valueServiceRecommended = dataPsm.psm_value_service_recommended;
        }
        const calcTotalValue: number = valueService + valueProduct + valueServiceRecommended;

        if(value){

            this.setState("loadings",[
                "psm_total_value_products",
                "psm_total_product_service",
                "psm_refer_equipment_new",
                "psm_final_value",
                "psm_value_service_recommended",
                "psm_value_product_discount",
                "psm_value_service_recommended_discount",
                "psm_total_product_service_discount",
                "psm_import_value"
            ]);
            let ProductValue = value[1].newObject[0].sumValues;
            let serviceAndProductSum =  value[1].newObject[0].sumValues + value[1].newObject[1].valueServiceRecommendedNotDiscount;
            // let serviceAndProductSumDiscount

      

            let productDiscount = value[0].newObjectDiscount[0].sumValuesDiscount
            let serviceAndProductSumDiscount = value[0].newObjectDiscount[0].sumValuesDiscount + value[0].newObjectDiscount[1].valueServiceRecommendedDiscount;

            let valueConsidered: any = "";
            let valueProducts: any = "";
            let valueServices: any = "";

            //If para o Valor Considerado
            if (dataPsm.psm_considered_discount_value > 0){
                valueConsidered = dataPsm.psm_considered_discount_value;
            }else{
                valueConsidered = dataPsm.psm_considered_value;
            }

            //If para o Valor total dos produtos
            if (dataPsm.psm_value_product_discount > 0){
                valueProducts = dataPsm.psm_value_product_discount;
            }else{
                valueProducts = dataPsm.psm_total_value_products;
            }

            //If para o Serviço recomendado
            if (dataPsm.psm_value_service_recommended_discount > 0){
                valueServices = dataPsm.psm_value_service_recommended_discount;
            }else{
                valueServices = dataPsm.psm_value_service_recommended;
            }

            const valueTotalRateio: any = valueConsidered + valueProducts + valueServices;

            
            setField("psm_total_value_products", ProductValue);
            setField("psm_value_service_recommended", value[1].newObject[1].valueServiceRecommendedNotDiscount)
            setField("psm_total_product_service", serviceAndProductSum + dataPsm.psm_considered_value)
            setField("psm_refer_equipment_new" , ((serviceAndProductSum) * dataPsm.psm_new_equipment_value) / 100)

            setField("psm_value_product_discount", productDiscount);
            setField("psm_value_service_recommended_discount", value[0].newObjectDiscount[1].valueServiceRecommendedDiscount)
            setField("psm_total_product_service_discount", (serviceAndProductSumDiscount + dataPsm.psm_considered_discount_value))
            this.setFieldValue("psm_import_value", true)

         
            if(![null,0].includes(dataPsm.psm_discount_percent)){
                let totalValue = serviceAndProductSum + dataPsm.psm_considered_value;
                let result: any = calculaValorTotal(totalValue, dataPsm.psm_discount_percent, 0);
                if (result.valorTotal > valueTotalRateio){
                    this.setFieldValue('psm_final_value', valueTotalRateio);
                }else{
                    this.setFieldValue('psm_final_value', result.valorTotal);
                }
                this.setFieldValue('psm_discount_real', result.desconto)
            }else{
                this.setFieldValue('psm_final_value',valueTotalRateio);
            }
            
            this.setFieldValue('psm_final_value', valueTotalRateio);

     
            this.setState("loadings",[])
        }
    } 
}