import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmClientsFkPersonIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any) => {
        console.log('teste :::> ', this.globalProps.match.params.crud)
        if (value == null) {
            setState([
                "psm_datebase_cnpj",
                "psm_database_citie",
                "psm_states_uf",
                "psm_fk_activity",
                "psm_fk_macro_region_id",
                "psm_microrregiao",
                "psm_internal",
                "psm_external",
                "psm_keyacoount",
                "psm_commercial_fk_person_contact_id",

                "psm_partial_billing",
                "psm_obs_accept_income",

                "psm_receiving_limit_nfse",
                "psm_obs_receiving_limit",

                "psm_measuring_sheet_frs",
                "psm_obs_measuring_sheet"
            ]);
            setField("psm_datebase_cnpj", "");
            setField("psm_database_citie", "");
            setField("psm_states_uf", "");
            setField("psm_fk_activity", "");
            setField("psm_fk_macro_region_id", "");
            setField("psm_microrregiao", "");
            setField("psm_internal", "");
            setField("psm_external", "");
            setField("psm_keyacoount", "");
            setField("psm_commercial_fk_person_contact_id", "");

            setField("psm_partial_billing", "")
            setField("psm_obs_accept_income", "")

            setField("psm_receiving_limit_nfse", "")
            setField("psm_obs_receiving_limit", "")

            setField("psm_measuring_sheet_frs", "");
            setField("psm_obs_measuring_sheet", "");
        }

        if (value) {
            setState("loadings", [
                "psm_datebase_cnpj",
                "psm_database_citie",
                "psm_states_uf",
                "psm_fk_payment_term_id",
                "psm_fk_activity",
                "psm_fk_macro_region_id",
                "psm_microrregiao",
                "psm_internal",
                "psm_external",
                "psm_keyacoount",
                "psm_commercial_fk_person_contact_id",
                "psm_from_fk_seller_id",
                "psm_email_send_nfs",

                "psm_carriers_fk_person_id",

                "psm_partial_billing",
                "psm_obs_accept_income",

                "psm_receiving_limit_nfse",
                "psm_obs_receiving_limit",

                "psm_measuring_sheet_frs",
                "psm_obs_measuring_sheet",
                "psm_client_is_prospect"
            ])

            setField("psm_contact_fk_person_contact_id", undefined);
            setField("psm_commercial_fk_person_contact_id", undefined);
            this.setFieldProperty('psm_commercial_fk_person_contact_id', 'options', []);

            await axios
                .get("/api/v1/people/" + value)
                .then((res => {
                    var data = res.data;
                    //var usersSellers = data.item.person_sellers;

                    if (data) {
                        if(this.globalProps.location.pathname.includes('dibi')){
                            setField("psm_email_send_nfs", res.data?.item.person_email_xml)
                            setField("psm_client_is_prospect", data.item.person_type_is_prospect !== true ? false : true)
                        }
                        setField("psm_datebase_cnpj", data.item.person_cnpj_cpf);
                        setField("psm_database_citie", data.item.city.city_name);
                        setField("psm_states_uf", data.item.state.state_name);
                        setField("psm_fk_activity", data.item.activity_sector.activity_sector_id);
                        setField("psm_fk_macro_region_id", data.item.macro_region.macro_region_id);
                        setField("psm_microrregiao", data.item.person_microregion);
                        
                        setField("psm_partial_billing", data.item.person_accept_income);
                        setField("psm_obs_accept_income", data.item.person_obs_accept_income);

                        setField("psm_receiving_limit_nfse", data.item.person_get_nfse);
                        setField("psm_obs_receiving_limit", data.item.person_obs_get_nfse);

                        setField("psm_measuring_sheet_frs", data.item.person_use_frs);
                        setField("psm_obs_measuring_sheet", data.item.person_obs_use_frs);
                        // for (var k in data.item.person_sellers) {
                        //     var vendedorExterno = data.item.person_sellers[k].seller.seller_name
                        //     var keyaccount = data.item.person_sellers[k].seller.seller_type_of_seller
                        //     if(keyaccount != 'K'){
                        //         if (vendedorExterno === false) {
                        //             setField("psm_internal", data.item.person_sellers[k].seller.seller_name);
                        //             setField("psm_from_fk_seller_id", data.item.person_sellers[k].seller.seller_id);
                        //             setField("psm_external", null);
                        //         } else {
                        //             setField("psm_internal", null);
                        //             setField("psm_external", data.item.person_sellers[k].seller.seller_name);
                        //         }
                        //     }                       


                        //     if (keyaccount === 'K') {
                        //         setField("psm_keyacoount", data.item.person_sellers[k].seller.seller_name);
                        //     }
                        // }

                        for (var k in data.item.person_sellers) {
                            if (data.item.person_sellers[k].seller.seller_type_of_seller !== 'K') {
                                if (data.item.person_sellers[k].seller.seller_is_extern == false) {
                                    this.setFieldValue("psm_internal", data.item.person_sellers[k].seller.seller_name);
                                    this.setFieldValue("psm_from_fk_seller_id", data.item.person_sellers[k].seller.seller_id);
                                }
                                if (data.item.person_sellers[k].seller.seller_is_extern == true) {
                                    this.setFieldValue("psm_external", data.item.person_sellers[k].seller.seller_name);
                                }
                            }
                            if (data.item.person_sellers[k].seller.seller_type_of_seller === 'K') {
                                this.setFieldValue("psm_keyacoount", data.item.person_sellers[k].seller.seller_name);
                            }
                        }

                        if (data.item.person_shipping_fk_person_id !== null) {
                            this.setFieldValue("psm_carriers_fk_person_id", res.data.item.person_shipping_fk_person_id)
                        }

                        if (data.item.payment_term !== null) {
                            this.setFieldValue("psm_fk_payment_term_id", data.item.payment_term.payment_term_id);
                        } else {
                            this.setFieldValue("psm_fk_payment_term_id",)
                        }

                    }
                }))
                .catch((e) => { })
                .finally(() => {
                    
                });
            setState("loadings",[
                "psm_commercial_fk_person_contact_id"
            ])
            await axios
                .get(`/api/v1/commercial/person_contacts/${value}`)
                .then((res) => {
                    this.setFieldProperty("psm_commercial_fk_person_contact_id", 'options', res.data.items)
                })
                .finally(() => {
                    setState("loadings", []);
                })

        }
    }
}