import dayjs from "dayjs";
import { Component, ReactNode } from "react";
import { DatePicker } from 'rsuite';
import { locale } from "../../utilities/locale/DateRangePicker";

interface IDatePickerProps {
    onChange: (value: any) => void;
    value: Date | any;
    readonly?: boolean;
    format?: string;
    disabledDate?: any;
}

interface IDatePickerState {
    value: Date;
}

export class DatePickerComponent extends Component<IDatePickerProps, IDatePickerState> {
    state = {
        value: this.props.value
    }

    componentDidUpdate(props: any, state: any): void {
        if (props.value !== state.value) {
            this.setState({ value: props.value });
        }
    }

    handleOnChange = (value: Date) => {
        const formattedDate = dayjs(value).subtract(3, 'hour').format('YYYY-MM-DD HH:mm:ss');
        this.props.onChange(formattedDate);
        this.setState({ value: value });
    }

    render(): ReactNode {
        return (
            <>
                <DatePicker
                    format={this.props?.format ? this.props?.format : "DD/MM/YYYY"}
                    locale={locale} 
                    value={this.state.value}
                    oneTap
                    onChange={this.handleOnChange}
                    onClean={() => this.props.onChange(null)}
                    disabled={this.props.readonly}
                    disabledDate={this.props.disabledDate}
                    style={{ display: "flex" }}
                />
            </>
        );
    }
}
