import _ from "lodash";
import { Event } from "../Event";
import { Notification } from "rsuite";

export class PurchaseRequisitionRelationEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any) => {
        // console.log ("value", value)
        const typeOpen = this.globalProps.parentValues.purchase_requisition_type_open
        const typePurchase = this.globalProps.parentValues.purchase_requisition_purchase_type
        const typePurpose = this.globalProps.parentValues.purchase_requisition_purpose_product
        // console.log("typePurpose", typePurpose)
        // const typeOpen = '1';

        // console.log("This props", this.globalProps)

        if (typePurchase == 1) {
            this.setField('purchase_requisition_relation_suggestion_link', {
                required: true
            })
        }
        // if (typePurpose === 1){
        //     // console.log("entrei aqui")
        //     this.setField('purchase_requisition_relation_suggestion_link',{
        //         required:true
        //     })
        // }
        if (typeOpen == 3) {
            const fieldsRequired = [
                'purchase_requisition_relation_quantity_total', 'purchase_requisition_relation_quantity_to_buy'              
            ];
            for (const item of fieldsRequired) {
                this.setField(item, {
                    required: true
                })
            }

            const fieldsReadOnly = [
                'purchase_requisition_relation_not_registered', 'purchase_requisition_relation_installed',
                'purchase_requisition_relation_item_description'
            ];
            for (const item of fieldsReadOnly) {
                this.setField(item, {
                    readonly: false
                })
            }           
        }
        if (typeOpen == 1) {
            const fieldsBlocked = [
                'purchase_requisition_relation_not_registered', 'purchase_requisition_relation_installed',
                'purchase_requisition_relation_intern_reference', 'purchase_requisition_relation_item_description',
                'purchase_requisition_relation_manufacturer_code', 'purchase_requisition_relation_quantity_total',
                'purchase_requisition_relation_quantity_to_separate'
            ];
            for (const item of fieldsBlocked) {
                this.setField(item, {
                    readonly: true,
                    disabled: true
                })
            }
        }
        if (typeOpen == 3) {
            const fieldsBlocked = [                
                'purchase_requisition_relation_intern_reference', 'purchase_requisition_relation_manufacturer_code', 'purchase_requisition_relation_quantity_to_separate'
            ];
            for (const item of fieldsBlocked) {
                this.setField(item, {
                    readonly: true,
                    disabled: true
                })
            }
        }

        // console.log("Globalprops", this.globalProps)
        if (this.globalProps.match.url.includes('/add') && typePurchase !== 1) {
            let valueItemsInsideRC = '';
            const globalPropsItemsInside = this.globalProps.parentValues?.purchase_requisition_relations;

            if (globalPropsItemsInside === undefined) {
                this.setState('loadings', 'purchase_requisition_relation_item_rc');
                this.setFieldValue('purchase_requisition_relation_item_rc', 1);
                this.setState('loadings', []);
            } else {
                this.setState('loadings', 'purchase_requisition_relation_item_rc');
                this.setFieldValue('purchase_requisition_relation_item_rc',
                    this.globalProps.values?.purchase_requisition_relation_item_rc ||
                    parseInt(this.globalProps.parentValues.purchase_requisition_relations.length) + 1
                );
                this.setState('loadings', []);
            }
        } else {
            this.setState('loadings', 'purchase_requisition_relation_item_rc');
            this.setFieldValue('purchase_requisition_relation_item_rc',
                this.globalProps.values?.purchase_requisition_relation_item_rc ||
                parseInt(this.globalProps.parentValues.purchase_requisition_relations.length) + 1
            );
            this.setState('loadings', []);
        }
    }
}

export class PurchaseRequisitionRelationQuantityToBuyEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {

        const valueTotal = this.globalProps.values.purchase_requisition_relation_quantity_total;
        const valueToBuy = value


        await this.setState('loadings', [
            'purchase_requisition_relation_quantity_to_buy',
            'purchase_requisition_relation_quantity_to_separate'
        ])
        if (value == "" || value == undefined) {
            Notification.error({
                title: 'Erro!',
                description: 'Nenhum valor atribuido ao campo Quantidade a Comprar!'
            })
            this.setFieldValue('purchase_requisition_relation_quantity_to_buy', 0);
        }
        if (valueToBuy > valueTotal) {
            Notification.error({
                title: 'Erro!',
                description: 'Não é possivel atribuir um valor maior que a Quant. Total!'
            })
            this.setFieldValue('purchase_requisition_relation_quantity_to_buy', 0);
        } else {
            const calcSeparateProducts = valueTotal - valueToBuy;
            this.setFieldValue('purchase_requisition_relation_quantity_to_separate', calcSeparateProducts)
        }

        if (this.globalProps.values.purchase_requisition_relation_quantity_to_separate < 0) {

            this.setFieldValue('purchase_requisition_relation_quantity_to_buy', 0);
            this.setFieldValue('purchase_requisition_relation_quantity_to_separate', 0);
            Notification.error({
                title: 'Erro!',
                description: 'Não é possivel atribuir um valor menor que a Quant. a Separar!'
            })
        }

        else if (this.globalProps.values.purchase_requisition_relation_quantity_to_buy < 0) {

            this.setFieldValue('purchase_requisition_relation_quantity_to_buy', 0);
            this.setFieldValue('purchase_requisition_relation_quantity_to_separate', 0);
            Notification.error({
                title: 'Erro!',
                description: 'Não é possivel atribuir um valor menor que a Quant. a Separar!'
            })
        }

        this.setState('loadings', [])

        // this.setExternalFieldValues()
    }
}

export class PurchaseRequisitionBuyEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any) => {
        let valueItemsInsideRC = '';
        const globalPropsItemsInside = this.globalProps.parentValues ? this.globalProps.parentValues.purchase_requisition_buys : 0;
        if (globalPropsItemsInside === undefined) {
            valueItemsInsideRC = '0';
            const parsedvalueItemsInsideRC = parseInt(valueItemsInsideRC + 1)
            this.setState('loadings', 'purchase_requisition_buy_item_rc');
            this.setFieldValue('purchase_requisition_buy_item_rc', parsedvalueItemsInsideRC);
            this.setState('loadings', [])

        } else {
            valueItemsInsideRC = this.globalProps.parentValues.purchase_requisition_buys.length;
            const parsedvalueItemsInsideRC = parseInt(valueItemsInsideRC + 1)
            this.setState('loadings', 'purchase_requisition_buy_item_rc');
            this.setFieldValue('purchase_requisition_buy_item_rc', parsedvalueItemsInsideRC);
            this.setState('loadings', [])
        }
    }
}

export class PurchaseRequisitionRelationCancelItemEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'purchase_requisition_relation_check_cancel_item'
        ])
        this.setFieldValue('purchase_requisition_relation_check_cancel_item', true);
        this.setState('loadings', [])
    }
}

