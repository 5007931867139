import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col, Whisper, InputGroup, Tooltip } from "rsuite";
import { Event } from "../Event";
import { UserContext } from "../../components/authProvider/main";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal"
import classNames from "classnames";
import { link } from "fs";
import { MailerModal } from "../../components/mailer/MailerModal";


class PurchaseRequisitionFooterComponent extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;
    public state = {
        show: false,
        showEmail: false,
        loading: false,
        showSituationPsms: false,
        showSituationTypeLiberation: false,
        showSituationRm: false,
        situacaoModal: "",
        started: false,
        loadingClockIn: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false
    };

    statusRcButtons = (status: 'aprovar' | 'cancelar' | 'reprovar') => {
        this.setState({ loading: true })
        const idSituation = this.props.values.formValues.material_requisition_fk_material_requisition_situation_id;
        const idRm = this.props.match.params.id;
        const quantityToSeparate = this.props.values.formValues.material_requisition_logistics[0].material_requisition_logistic_quantity_to_separate
        const quantityToBuy = this.props.values.formValues.material_requisition_logistics[0].material_requisition_logistic_quantity_to_buy
        const valueCancelReason = this.props.values.formValues.material_requisition_cancel_reason
        if (status == 'cancelar' && valueCancelReason == null) {
            Notification.error({
                title: "Erro!",
                description: 'Atenção! Não foi possível cancelar RM, favor verificar o motivo do cancelamento.'
            });
            this.setState({ loading: false })
            return;
        }
        if (this.props.values.formValues.material_requisition_logistics.length >= 1) {
            const quantityAlltoSeparate = this.props.values.formValues.material_requisition_logistics
            console.log("Valor do quantityAlltoSeparate", this.props)

            for (const t in quantityAlltoSeparate) {
                for (const y in quantityAlltoSeparate[t].material_approved_grids) {
                    console.log("Valor do quantidades atendidas", quantityAlltoSeparate[t].material_approved_grids[y].material_approved_grid_quantity_met)
                    if (status == 'cancelar' && quantityAlltoSeparate[t].material_approved_grids[y].material_approved_grid_quantity_met >= 1) {
                        Notification.error({
                            title: "Erro!",
                            description: 'Atenção! Não foi possível cancelar RM, pois existem itens que foram atendidos.'
                        });
                        this.setState({ loading: false })
                        return;
                    }
                }
                // if (status == 'cancelar' && quantityAlltoSeparate[t].material_requisition_logistic_quantity_to_separate == 0) {
                //             Notification.error({
                //                 title: "Erro!",
                //                 description: 'Atenção! Não foi possível cancelar RM, pois existem itens que foram atendidos.'
                //             });
                //             this.setState({ loading: false })
                //             return;
                //     }
                if (status == 'cancelar' && quantityAlltoSeparate[t].material_requisition_logistic_quantity_to_buy > 0) {
                    Notification.error({
                        title: "Erro!",
                        description: 'Atenção! Não foi possível cancelar RM, pois existem itens com quantidades a comprar!.'
                    });
                    this.setState({ loading: false })
                    return;
                }
            }
        } else {
            if (status == 'cancelar' && quantityToSeparate == 0) {
                Notification.error({
                    title: "Erro!",
                    description: 'Atenção! Não foi possível cancelar RM, pois existe um item que foi atendido.'
                });
                this.setState({ loading: false })
                return;
            }
            if (status == 'cancelar' && quantityToBuy > 0) {
                Notification.error({
                    title: "Erro!",
                    description: 'Atenção! Não foi possível cancelar RM, pois existe um item com quantidades a comprar!'
                });
                this.setState({ loading: false })
                return;
            }
        }

        if (status == 'aprovar' && idSituation == 5 && quantityToSeparate > 1) {
            Notification.error({
                title: "Erro!",
                description: 'Atenção! Não foi possível finalizar RM, favor verificar as quantidades dos itens atendidos.'
            });
            this.setState({ loading: false })
            return;
        }
        else {
            axios
                .get(`/api/v1/material-requisition/${idSituation}/${idRm}/${status}`)
                .then((res) => {
                    if (res.data.status == 200) {
                        Notification.success({
                            title: 'Sucesso!',
                            description: res.data.message
                        })
                        setTimeout(() => {
                            this.props.history.go(0)
                        }, 3500)
                    }
                }).catch((e) => {
                    Notification.error({
                        title: 'Error!',
                        description: e.response.data.message
                    })
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        }
    }

    trocaStatus = (status: 'avanca' | 'voltar' | 'aguardando' = 'avanca', view: 'dibis' | 'purchase_requisitions') => {
        const idSituation = view === 'dibis' ? this.props.values.formValues.material_requisition_fk_dibi_situation_id : this.props.values.formValues.material_requisition_fk_material_requisition_situation_id;
        const idTypeOrigin = view === 'purchase_requisitions' ? this.props.match.params.id : this.props.values.formValues.material_requisition_fk_psm_id;
        if (!this.props.match.params.id) {
            Notification.error({
                title: "Erro!",

            });
            return;
        }
        if (status == 'voltar' && this.props.values.formValues.psm_status_fk_situation_id == 3) {
            Notification.error({
                title: "Erro!",
                description: 'Não é possivel retornar situação para aguardando análise, para retornar favor DESATRIBUIR'
            });
            return;
        }
        this.setState({ loading: true });
        if (status == 'avanca') {
            axios
                .post(`/api/v1/situation/controller/purchase_requisition/${this.props.match.params.crud}/${this.props.match.params.id}/${this.props.values.formValues.material_requisition_fk_material_requisition_situation_id}`, { data: this.props.values.formValues })
                .then((res) => {
                    if (res.data.status === 201) {
                        let labels: string = "";
                        for (const item of res.data.errors) {
                            labels += `> ${item.label}, `;
                        }
                        labels = labels.slice(0, -2)
                        Notification.error({
                            title: "Campos obrigatorios para avançar situação!",
                            description: labels,
                            placement: "bottomEnd",
                            duration: 10000,
                        });
                        this.setState({ loading: false });
                        return;
                    }
                    axios
                        .get(`/api/v1/situation/${status}/${idSituation}/${idTypeOrigin}/${view}`)
                        .then((async res => {
                            if (res.status === 200 && res.data.status == 200) {
                                Notification.success({
                                    title: "Sucesso!",
                                    description: res.data.message,
                                    duration: 3500,
                                });
                                setTimeout(() => {
                                    this.props.history.push(`/dashboard/${this.props.match.params.module}/${this.props.match.params.crud}/${this.props.match.params.id}?tab=Main`);
                                    this.props.history.go(0)
                                }, 3700)

                            }
                            if (res.data.status == 201) {
                                Notification.warning({
                                    title: "Atenção!",
                                    description: res.data.message,
                                });
                            }
                        }))
                        .finally(() => {
                            this.setState({ loading: false });
                        });

                    // this.props.onSubmit(
                    //     () => {
                    //         // alert("SAVED !");
                    //         var currentRouteSplited = this.props.history.location.pathname.split("/");
                    //         var currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");

                    //         axios
                    //             .get(`/api/v1/situation/${status}/${idSituation}/${idTypeOrigin}/${view}`)
                    //             .then((async res => {
                    //                 if (res.status === 200 && res.data.status == 200) {
                    //                     Notification.success({
                    //                         title: "Sucesso!",
                    //                         description: res.data.message,
                    //                         duration: 3500,
                    //                     });
                    //                     this.props.history.push(`/dashboard/${this.props.match.params.module}/${this.props.match.params.crud}/${this.props.match.params.id}?tab=Main`);
                    //                     this.props.history.go(0)

                    //                 }
                    //                 if (res.data.status == 201) {
                    //                     Notification.warning({
                    //                         title: "Atenção!",
                    //                         description: res.data.message,
                    //                     });
                    //                 }
                    //             }))
                    //             .finally(() => {
                    //                 this.setState({ loading: false });
                    //             });
                    //     },
                    //     () => {
                    //         this.setState({ loading: false });
                    //     },
                    // );
                })
                .catch((err) => {
                    console.log('error :::: ', err)
                })
        }
        if (status == 'voltar') {
            axios
                .get(`/api/v1/situation/${status}/${idSituation}/${idTypeOrigin}/${view}`)
                .then((async res => {
                    if (res.status === 200 && res.data.status == 200) {
                        Notification.success({
                            title: "Sucesso!",
                            description: res.data.message,
                            duration: 3500,
                        });
                        setTimeout(() => {
                            this.props.history.push(`/dashboard/${this.props.match.params.module}/${this.props.match.params.crud}/${this.props.match.params.id}?tab=Main`);
                            this.props.history.go(0)
                        }, 3700)
                    }
                    if (res.data.status == 201) {
                        Notification.warning({
                            title: "Atenção!",
                            description: res.data.message,
                        });
                    }
                }))
                .finally(() => {
                    this.setState({ loading: false });
                });
        }

        // axios
        //     .post(`/api/v1/situation/controller/material_requisition/${this.props.match.params.crud}/${this.props.match.params.id}/${this.props.values.formValues.material_requisition_fk_material_requisition_situation_id}`, { data: this.props.values.formValues })
        //     .then((res) => {
        //         // if (res.data.status === 201) {
        //         //     let labels: string = "";
        //         //     for (const item of res.data.errors) {
        //         //         labels += `> ${item.label}, `;
        //         //     }
        //         //     labels = labels.slice(0, -2)
        //         //     Notification.error({
        //         //         title: "Campos obrigatorios para avançar situação!",
        //         //         description: labels,
        //         //         placement: "bottomEnd",
        //         //         duration: 3000,
        //         //     });
        //         //     this.setState({ loading: false });
        //         //     return;
        //         // }
        //         // if (res.data.status === 200) {
        //         //     this.props.onSubmit(
        //         //         () => {
        //         //             this.setState({ loading: true });
        //         //             // alert("SAVED !");
        //         //             var currentRouteSplited = this.props.history.location.pathname.split("/");
        //         //             var currentRoute = currentRouteSplited.splice(0, currentRouteSplited.length - 1).join("/");

        //         //             // axios
        //         //             //     .get(`/api/v1/situation/${status}/${idSituation}/${idTypeOrigin}/${view}`)
        //         //             //     .then((async res => {
        //         //             //         if (res.status === 200 && res.data.status == 200) {
        //         //             //             Notification.success({
        //         //             //                 title: "Sucesso!",
        //         //             //                 description: res.data.message,
        //         //             //                 duration: 3500,
        //         //             //             });
        //         //             //             this.props.history.push(`/dashboard/${this.props.match.params.module}/${this.props.match.params.crud}/${this.props.match.params.id}?tab=Main`);
        //         //             //             this.props.history.go(0)

        //         //             //         }
        //         //             //         if (res.data.status == 201) {
        //         //             //             Notification.warning({
        //         //             //                 title: "Atenção!",
        //         //             //                 description: res.data.message,
        //         //             //             });
        //         //             //         }
        //         //             //     }))
        //         //             //     .finally(() => {
        //         //             //         this.setState({ loading: false });
        //         //             //     });
        //         //         },
        //         //         () => {
        //         //             this.setState({ loading: false });
        //         //         },
        //         //     );
        //         // }
        //     })
        //     .catch((err) => {
        //         console.log('error :::: ', err)
        //     })
    }

    isToTotalAndPartial = (status: 'parcial' | 'total', view: 'dibis' | 'material_requisitions') => {
        // if( this.props.values.formValues.material_requisition_liberation == 1 && status == 'total'){
        //     Notification.error({
        //         title: "Erro!",
        //         description: 'Não é possivel mudar a situação, pois a divergencia com o campo de liberação!'
        //     })
        //     return;
        // }
        if (this.props.values.formValues.material_requisition_liberation == 2 && status == 'parcial') {
            Notification.error({
                title: "Erro!",
                description: 'Não é possivel mudar a situação, pois a divergencia com o campo de liberação!'
            })
            return;
        }
        for (const i in this.props.values.formValues.material_requisition_logistics) {
            if (this.props.values.formValues.material_requisition_logistics[i].material_requisition_logistic_quantity_to_buy > 0 && status == 'total') {
                Notification.error({
                    title: "Erro!",
                    description: 'Não é possível avançar, pois existem quantidades a comprar!'
                })
                return;
            }
        }
        console.log("Valor do this.props", this.props.values)
        this.setState({ loading: true });
        axios
            .get(`/api/v1/situations/service-type/${view}/${status}/${this.props.values.formValues.material_requisition_fk_psm_id}/${this.props.values.formValues.material_requisition_id}`)
            .then((res => {
                if (res.status === 200 && res.data.status) {
                    Notification.success({
                        title: 'Sucesso!',
                        description: res.data.message
                    })
                    setTimeout(() => {
                        this.props.history.go(0)
                    }, 3500)
                }
            }))
            .catch((e) => {
                Notification.error({
                    title: 'Erro!',
                    description: e.response.data.message
                })
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    printForm = () => {
        this.setState({ loading: true })
        axios
            .get('/api/v1/material/print-form/', {
                params: {
                    idMaterialRequisition: this.props.values.formValues.material_requisition_id
                }
            })
            .then((res) => {
                if (res.data && res.data.url) {
                    window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                    this.props.history.go(0);
                }
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }
    render() {
        return (<>
            {/* <ConfirmModal
                loading={this.state.loading}
                onCancel={() => {
                    this.setState({
                        showSituationPsms: false,
                    })
                }}
                body={<>
                    <Button loading={this.state.loading} color='orange' onClick={() => this.isToTotalAndPartial('parcial', 'dibis')}>ATENDIMENTO PARCIAL</Button>
                    <Button loading={this.state.loading} color='blue' style={{ marginLeft: 20 }} onClick={() => this.isToTotalAndPartial('total', 'dibis')}>ATENDIMENTO TOTAL</Button>
                </>}
                cancelColor={"red"}
                showConfirm={false}
                cancelText={"Fechar"}
                onCloseButton={true}
                content={""}
                returnText={"Voltar Situação"}
                advancedText={"Avançar Situação"}
                title={"Avança situação da RC"}

                show={this.state.showSituationPsms}
            /> */}
            <ConfirmModal
                loading={this.state.loading}
                onCancel={() => {
                    this.setState({
                        showSituationRm: false,
                    })
                }}
                body={<>
                    {/* {[1, 2, 3, 4, 6, 7].includes(this.props.values.formValues.material_requisition_fk_material_requisition_situation_id) && ( */}
                        {/* <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar', 'purchase_requisitions')}>VOLTAR STATUS</Button> */}
                        <Button loading={this.state.loading} color='orange' >VOLTAR STATUS</Button>
                    {/* )} */}
                    {/* {[1, 2].includes(this.props.values.formValues.material_requisition_fk_material_requisition_situation_id) && ( */}
                        {/* <Button loading={this.state.loading} color='blue' style={{ marginLeft: 20 }} onClick={() => this.trocaStatus('avanca', 'purchase_requisitions')}>AVANÇAR STATUS</Button> */}
                        <Button loading={this.state.loading} color='blue' style={{ marginLeft: 20 }} >AVANÇAR STATUS</Button>
                    {/* )} */}
                </>}
                cancelColor={"red"}
                showConfirm={false}
                cancelText={"Fechar"}
                onCloseButton={true}
                content={""}
                returnText={"Voltar Situação"}
                advancedText={"Avançar Situação"}
                title={"Avança situação da RC"}

                show={this.state.showSituationRm}
            />
            <ConfirmModal
                loading={this.state.loading}
                onCancel={() => {
                    this.setState({
                        showSituationTypeLiberation: false,
                    })
                }}
                body={<>
                    <Button loading={this.state.loading} color='orange' onClick={() => this.isToTotalAndPartial('parcial', 'material_requisitions')}>ATENDIMENTO PARCIAL</Button>
                    <Button loading={this.state.loading} color='blue' style={{ marginLeft: 20 }} onClick={() => this.isToTotalAndPartial('total', 'material_requisitions')}>ATENDIMENTO TOTAL</Button>
                </>}
                cancelColor={"red"}
                showConfirm={false}
                cancelText={"Fechar"}
                onCloseButton={true}
                content={""}
                returnText={"Voltar Situação"}
                advancedText={"Avançar Situação"}
                title={"Avança situação de ordem de serviço"}

                show={this.state.showSituationTypeLiberation}
            />

            {/* <Button loading={this.state.loading} color={"cyan"} onClick={() => this.setState({ showSituationRm: true })} className="ml-4"> */}
            <Button loading={this.state.loading} color={"cyan"}  className="ml-4">
                <i className="fas fa-tasks fa-fw mr-2"></i>
                Mudar Situação
            </Button>
            {/* {this.props.values.formValues.material_requisition_fk_material_requisition_situation_id == 5 && ( */}
            {/* <Button loading={this.state.loading} color={"green"} onClick={() => this.statusRcButtons('aprovar')} className="ml-4"> */}
            <Button loading={this.state.loading} color={"green"}  className="ml-4">
                <i className="fas fa-check-double fa-fw mr-2"></i>
                Aprovar
            </Button>
            {/* )} */}
            {/* {![7].includes(this.props.values.formValues.material_requisition_fk_material_requisition_situation_id) && ( */}
            {/* <Button loading={this.state.loading} color={"red"} onClick={() => this.statusRcButtons('cancelar')} className="ml-4"> */}
            <Button loading={this.state.loading} color={"red"} className="ml-4">
                <i className="fas fa-window-close fa-fw mr-2"></i>
                Reprovar
            </Button>
            {/* )} */}
            {/* {[6, 7].includes(this.props.values.formValues.material_requisition_fk_material_requisition_situation_id) && ( */}
            {/* <Button loading={this.state.loading} color={"violet"} onClick={() => this.statusRcButtons('reprovar')} className="ml-4"> */}
            <Button loading={this.state.loading} color={"orange"} className="ml-4">
                <i className="fas fa-window-close fa-fw mr-2"></i>
                Cancelar RC
            </Button>
            {/* )} */}


        </>)

    }
}

const PurchaseRequisitionFooterWithRouter = withRouter(PurchaseRequisitionFooterComponent);

export class PurchaseRequisitionFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <PurchaseRequisitionFooterWithRouter onSubmit={onSubmit} printId={this.globalState.oldValues.psm_id} values={this.globalState} />
            </>
        );
    };
}
