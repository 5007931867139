import React, { ReactNode } from "react";
import { Table, Modal, Button, Input, Icon, Notification, IconButton, ButtonToolbar } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import axios from "../../../utilities/axiosReport";
// import Cell from "./Cell";
import styles from "./customStyles/styles.module.scss";
import { FileInput } from "../../formfields/FileInput";
import TablePagination from "rsuite/lib/Table/TablePagination";
import CustomRedCell from "./CustomRedCell";
import { TRUE } from "sass";

const { Column, HeaderCell, Cell } = Table;

interface ModalImportAttachmentsProps {
    title?: string;
    crud: string;
    show: boolean;
    onCLoseModal: () => void;
    functionNameImport: string;
}

type State = {
    uid: string;
    loading: boolean;
    importedData: any[];
    showDataModal: boolean;
    displayLength: number;
    page: number;
    filter: string;
    statusFilter: string;
    itemsData: any[];
    showConferenceModal: boolean;
    loadingButton: boolean;
    filterColor: string;
    conferenceItems: any[];
    users: any[];
    filteredUsers: any[];
}

const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export class ModalImportAttachmentUser extends React.Component<ModalImportAttachmentsProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            uid: uuidv4(),
            loading: false,
            importedData: [],
            showDataModal: false,
            displayLength: 10,
            page: 1,
            filter: '',
            statusFilter: '',
            itemsData: [],
            showConferenceModal: false,
            loadingButton: false,
            filterColor: '',
            conferenceItems: [],
            users: [],
            filteredUsers: []
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeLength = this.handleChangeLength.bind(this);
    }

    handleChangePage = (dataKey: any) => {
        this.setState({
            page: dataKey,
            loading: true,
        }, () => {
            this.getData();
            this.setState({ loading: false });
        });
    }


    handleChangeLength = (dataKey: any) => {
        this.setState({
            page: 1,
            displayLength: dataKey,
            loading: true,
        }, () => {
            this.getData();
            this.setState({ loading: false });
        });
    }

    handleFilterChange = (value: string) => {
        this.setState({ statusFilter: '' });
        this.setState({ filter: value });
    }

    handleStatusFilterChange = (status: any) => {
        this.setState({ statusFilter: status, page: 1 });
    }

    handleCleanFilter = () => {
        this.setState({ statusFilter: '', filter: '' });
    }

    handleFilterColor = (color: string) => {
        this.setState({ filterColor: color });
    }

    onChangeValues = ({ value, guid }: { value?: any, guid: string }) => {
        this.setState({
            uid: guid
        });
    }

    confirmImport = async () => {
        const users = this.state.users;
        const uid = this.state.uid;

        axios.post(`/api/v1/import/conference/confirm/`, {
            users: users,
            uid: uid,
        }).then((res => {
            if (res.data.status === '200') {
                Notification.success({
                    title: 'Sucesso',
                    description: 'Importação concluída com sucesso',
                    duration: 5000,
                });
                this.setState({ loading: false });

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }));
    }

    onCLoseModal = () => {
        this.props.onCLoseModal();
    }

    cleanConferenceSector = () => {
        axios.get("/api/v1/import/conference/cancel");
    }

    verifyErrors = async () => {
        this.setState({ loading: true });

        const getUsers = this.state.users;

        this.setState(
            {
                users: [],
                filteredUsers: []
            }
        );
        await axios.post(`/api/v1/import/conference/verifyErrors`, {
            users: getUsers,
        })
            .then((res: any) => {
                const users = res.data.users;

                // console.log("valor do res", res)
                this.setState({
                    showDataModal: true,
                    users: users
                });
            }).catch((e => {
                // console.log("valor do e", e)
            })).finally(() => {
                this.setState({
                    loading: false
                });
            });
    }

    excludeFile = () => {
        // axios.get(`/api/v1/import/conference/excludeFile/`, {
        //     params: {
        //         uid: getUid,
        //     }
        // })
    }

    beforeRenderModal = () => {
        this.setState({ loading: true })
        axios.get(`/api/v1/import/conference/users/${this.state.uid}/users`)
            .then((res: any) => {
                const users = res.data.users;

                this.setState({
                    // showConferenceModal: false,
                    showDataModal: true,
                    loading: false,
                    users: users
                });
            });
    }

    deleteRow = async (index: number) => {
        const updatedUsers = this.state.users.filter((_, i) => i !== index);
        this.setState(
            {
                users: [],
                filteredUsers: []
            }
        );
        await axios.post(`/api/v1/import/conference/verifyErrors`, {
            users: updatedUsers,
        })
            .then((res: any) => {
                const users = res.data.users;
                console.log("")
                this.setState({
                    showConferenceModal: false,
                    showDataModal: true,
                    users: users
                });
            })
            .finally(() => {
                this.setState(
                    {
                        loading: false
                    }
                );
            });

    };



    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.page !== this.state.page || prevState.displayLength !== this.state.displayLength) {
            this.forceUpdate();
        }
    }

    // getData() {
    //     const { displayLength, page, filter, statusFilter, users } = this.state;
    //     const filteredData = users.filter((item) => {
    //         const matchesFilter = filter === '' || item.id.toString() === filter;
    //         const matchesStatus = statusFilter === '' || (statusFilter === 'corretos' ? item.error.length === 0 : item.error.length > 0);
    //         return matchesFilter && matchesStatus;
    //     });

    //     const start = displayLength * (page - 1);
    //     const end = start + displayLength;

    //     const data = filteredData.slice(start, end);
    //     console.log(data);

    //     return data;
    // }

    getData() {
        const { displayLength, page, filter, statusFilter, users } = this.state;
        return users.filter((item) => {
            const matchesFilter = filter === '' || item.id.toString().includes(filter);
            const matchesStatus = statusFilter === '' || (statusFilter === 'corretos' ? item.error.length === 0 : item.error.length > 0);
            return matchesFilter && matchesStatus;
        }).slice(displayLength * (page - 1), displayLength * page);
    }

    renderDataModal = () => {
        const { showDataModal, displayLength, page, filter, statusFilter, loading, users } = this.state;
        const data = this.getData();
        const totalFiltered = users.filter((item) => {
            const matchesFilter = filter === '' || item.id.toString().includes(filter);
            const matchesStatus = statusFilter === '' || (statusFilter === 'corretos' ? item.error.length === 0 : item.error.length > 0);
            return matchesFilter && matchesStatus;
        }).length;

        return (
            <Modal
                full
                show={showDataModal}
                onHide={() => this.setState({ showDataModal: false })}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Conferência de Importação de Usuários
                        <br />
                        <span className={styles.redNotice}>*Caso exista algum registro que esteja incorreto, não será possível concluir a importação</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        placeholder="Filtrar por ID..."
                        value={filter}
                        onChange={this.handleFilterChange}
                        style={{ marginBottom: '15px' }}
                    />
                    <ButtonToolbar style={{ marginBottom: '15px' }}>
                        <Button color="red" appearance="ghost" onClick={() => this.handleStatusFilterChange('incorretos')}>Importação Incorreta</Button>
                        <Button color="green" appearance="ghost" onClick={() => this.handleStatusFilterChange('corretos')}>Corretos</Button>
                        <Button color="blue" appearance="ghost" onClick={() => this.handleCleanFilter()}>Limpar Filtros</Button>
                    </ButtonToolbar>
                    <Table
                        key={`table-${filter}-${statusFilter}-${page}`}
                        height={500}
                        autoHeight={false}
                        data={data}
                        locale={{ emptyMessage: "Nenhum registro novo/alterado" }}
                        loading={loading}
                        affixHeader
                        affixHorizontalScrollbar={false}
                    >
                        {/* <Column width={70} align="center">
                            <HeaderCell>Ações</HeaderCell>
                            <Cell>
                                {(rowData: any, rowIndex: number) => (
                                    <div style={{ marginTop: -15 }}>
                                        {this.state.statusFilter === '' && (
                                            <IconButton icon={<Icon icon="trash" />} onClick={() => {
                                                this.setState({ loading: true });
                                                this.deleteRow(rowIndex);
                                            }} />
                                        )}
                                    </div>
                                )}
                            </Cell>
                        </Column> */}
                        <Column width={80} align="center" resizable>
                            <HeaderCell>ID</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"id"} view="id" />
                        </Column>
                        <Column width={210} resizable>
                            <HeaderCell>CNPJ</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"cnpj"} view="cnpj" />
                        </Column>
                        <Column width={200} resizable>
                            <HeaderCell>CLIENTE</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"client"} view="client" />
                        </Column>
                        <Column width={170} resizable>
                            <HeaderCell>CIDADE</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"city"} view="city" />
                        </Column>
                        <Column width={70} resizable>
                            <HeaderCell>UF</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"uf"} view="uf" />
                        </Column>
                        <Column width={100} resizable>
                            <HeaderCell>HUB</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"hub"} view="hub" />
                        </Column>
                        <Column width={130} resizable>
                            <HeaderCell>MICRO REGIÃO</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"microregion"} view="microregion" />
                        </Column>
                        <Column width={180} resizable>
                            <HeaderCell>VE</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"ve"} view="ve" />
                        </Column>
                        <Column width={180} resizable>
                            <HeaderCell>VI</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"vi"} view="vi" />
                        </Column>
                        <Column width={180} resizable>
                            <HeaderCell>KEY</HeaderCell>
                            <CustomRedCell rowData={(rowData: any) => rowData} dataKey={"key"} view="key" />
                        </Column>
                    </Table>
                    <TablePagination
                        lengthMenu={[
                            { value: 10, label: 10 },
                            { value: 100, label: 100 },
                            { value: 500, label: 500 },
                            { value: 2000, label: 2000 }
                        ]}
                        activePage={page}
                        displayLength={displayLength}
                        // total={this.state.users.filter((item) => {
                        //     const matchesFilter = filter === '' || item.id.toString() === filter;
                        //     const matchesStatus = statusFilter === '' || (statusFilter === 'corretos' ? item.error.length === 0 : item.error.length > 0);
                        //     return matchesFilter && matchesStatus;
                        total={totalFiltered}
                        onChangePage={this.handleChangePage}
                        onChangeLength={this.handleChangeLength}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ marginTop: '15px' }} appearance="primary" color="red" onClick={() => {
                        this.setState({ showDataModal: false, users: [] });
                    }}>Cancelar Importação</Button>
                    {this.hasErrors() ? (
                        <Button style={{ marginTop: '15px' }} appearance="primary" color="orange" onClick={() => {
                            this.setState({ loading: true });
                            this.verifyErrors();
                        }}>Verificar Erros</Button>
                    ) : (
                        <Button style={{ marginTop: '15px' }} appearance="primary" color="green" onClick={() => {
                            this.setState({ loading: true });
                            this.confirmImport();
                        }}>Confirmar Importação</Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }

    hasErrors = () => {
        const { users } = this.state;
        return users.some(user => user.error && user.error.length > 0);
    }

    render(): ReactNode {
        return (
            <Modal
                show={this.props.show}
            >
                <Modal.Title>
                    Importação {this.props?.title}
                </Modal.Title>
                <Modal.Body>
                    <FileInput
                        multiple={false}
                        guid={this.state.uid}
                        showQuestions={false}
                        readonly={false}
                        name={`${this.props.functionNameImport}_uploader`}
                        relation={this.props.crud}
                    // onChangeCustom={({value,guid}) => this.onChangeValues({value,guid})}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={this.onCLoseModal}
                        loading={this.state.loading}
                    >Fechar</Button>
                    {this.state.uid !== '' && (
                        <Button
                            color="green"
                            onClick={this.beforeRenderModal}
                            loading={this.state.loading}
                        >
                            Processar
                        </Button>
                    )}
                </Modal.Footer>
                {this.state.showDataModal && this.renderDataModal()}
            </Modal>
        )
    }
}
