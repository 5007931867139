import React from "react";
import { Button, Modal, Panel, Row,Placeholder,Notification } from "rsuite";
import pluralize from "pluralize";
import axios from "../../../utilities/axios";

const { Paragraph } = Placeholder;
export class CustomImportProductsPhoto extends React.Component<any, {}> {
    public state:any = {
        loading: false,
        show: false,
        obj:{},
        files: []
    }

    // getPhotos() {
    //     const { relation } = this.props;
    //     const singular = pluralize.singular(relation) + "_fk_select_id"
    //     if (this.props.values[singular] != undefined) {
    //         this.props.onChange({id:this.props.values[singular],name:relation})
    //     } else {
    //         console.log("Produto Não Selecionado")
    //     }
    // }
    openModal() {     
        this.setState({ loading:true })
        const { relation } = this.props;
        console.log({relation});
        const singular = relation == 'psm_inclusion_product_analysis' ? 'psm_inclusion_product_analysi_fk_select_id' : pluralize.singular(relation) + "_fk_select_id";
        console.log({singular});
        if(!this.props.values[singular]){
            Notification.error({
                title: "Erro",
                description: "Por favor, preencha o campo Descrição do Item.",
            });
            this.setState({ loading:false })
        }
        axios.get("/api/v1/products/" +this.props.values[singular]).then(async (res) => {           
            var newFiles: any = []
            var files = res.data.item["product_attach_photo"];
            var name = {name:res.data.item['product_description'],reference:res.data.item['product_intern_reference']};
            this.setState({ files: files,obj:name })
            this.setState({ show: true })
            this.setState({ loading:false })
        }).catch((e) => {this.setState({ loading:false })}).finally(()=> this.setState({ loading:false }))
    }
    closeModal() {
        this.setState({ show: false })
        this.setState({ files: [],name:null})
    }

    render() {


        return (<>
            {/* {JSON.stringify(this.props.formsValue)} */}
            <Modal overflow={false} full  size={"lg"} show={this.state.show} onHide={() => this.closeModal()}>
                    <Modal.Header>
                        <h3><b><p>{this.state.obj['name']}  -  {this.state.obj['reference']}</p></b></h3>
                    </Modal.Header>
                <Modal.Body>
                    {/* {JSON.stringify(this.state.files)} */}
                    {this.state.loading &&(<>
                        <Paragraph rows={6} style={{ marginTop: 30 }} />
                    </>)}                        
                    <div style={{overflowX:"auto",margin:15,display: 'flex'}}>
                    {!this.state.loading &&(this.state.files.map((photo:any) => {
                        return (<>   
                        <Row>
                            <Panel shaded bordered bodyFill style={{ display: 'flex' }}>
                                <img src={photo.file_path} height="240" style={{display:"flex",maxWidth:"300px",margin:10}} />                               
                                <Panel header={photo.file_name} style={{wordWrap:"break-word",minHeight:"150px",maxWidth:"300px",margin:10}}>
                                <a style={{float:"right"}} rel="noreferrer" target="_blank" className="mr-9" href={photo.file_path}>Visualizar <i className="fas fa-eye"></i></a>
                                </Panel>
                            </Panel>
                         </Row>
                        </>)
                    }))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ margin: 15 }} onClick={() => this.closeModal()} color={"red"}>Fechar</Button>

                </Modal.Footer>

            </Modal>
            <Button style={{ marginTop: 23, marginRight: 15 }} loading={this.state.loading} onClick={() => this.openModal()} color={"blue"} className="ml-4">
                <i className="fas fa-file-import fa-fw mr-2"></i>Visualizar Fotos
            </Button>
        </>

        )
    }
}
