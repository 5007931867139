import axios from "../../../utilities/axios";
import React from "react";
import { Button, Table, Modal, Input, Row, Col, Whisper, Tooltip, Divider } from "rsuite";
import _ from "lodash";
import dayjs from "dayjs";
import { Permission } from "../../Permission";
import { ReveiwModal } from "../../customModal/ReveiwModal";

const { Column, HeaderCell, Cell, ColumnGroup } = Table;

export class ReviewLogTableCommercial extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        showSituation: false,
        reviewLogDate: [],
        nameUser: [],
        dateModal: [],
        dadosModal: [],
        dadosModalGrid: [],
    };


    bucaHistorycReview = () => {
        var dados = this.props.values.psm_review_logs_commercial;
        dados = _.orderBy(dados,'psm_review_log_id', 'desc');
        var psmId = this.props.values.psm_id;

        if (psmId) {
            var output = [];
            for (var i in dados) {
                try {
                    if(dados[i].psm_review_log_initial !== true){
                        let objectsData = dados[i];
                        
                        var dataFormatada = dayjs(dados[i].psm_review_log_updated_at).format("DD/MM/YYYY HH:mm");
                        var dataObject = { FormatDate: dataFormatada };
                        let serviceOldValue = JSON.parse(objectsData['psm_review_log_old_data']).psm_total_value_products;
                        serviceOldValue = !['null',null, undefined].includes(serviceOldValue) ? serviceOldValue.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : serviceOldValue
        
                        let serviceNewValue = JSON.parse(objectsData['psm_review_log_new_data']).psm_total_value_products;
                        serviceNewValue = !['null',null, undefined].includes(serviceNewValue)? serviceNewValue.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : serviceNewValue;
        
        
                        let totalOldValue = JSON.parse(objectsData['psm_review_log_old_data']).psm_value_product_discount;
                        totalOldValue = !['null',null, undefined].includes(totalOldValue) ? totalOldValue.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : totalOldValue;
        
                        let totalNewValue = JSON.parse(objectsData['psm_review_log_new_data']).psm_value_product_discount;
                        totalNewValue = !['null',null, undefined].includes(totalNewValue) ? totalNewValue.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : totalNewValue;
        
                        var mergeDate = _.merge(dados[i],dataObject,{serviceOldValue:serviceOldValue},{serviceNewValue:serviceNewValue},{totalOldValue:totalOldValue},{totalNewValue:totalNewValue});
                        output.push(mergeDate);
                    }
                }catch(e) {console.log('Error :::: ', e)}
            }
            this.setState({
                reviewLogDate: output,
            });
        }
    }

    componentDidMount() {
        this.bucaHistorycReview();
    }

    render() {
        return (<>
            <div>
                <div style={{ marginTop: "25px" }}>
                    <h3>
                        Histórico de Revisões
                    </h3>
                </div>
                <Table
                    loading={this.props.loading}
                    height={400}
                    data={this.state.reviewLogDate}
                    headerHeight={80}
                    bordered
                    cellBordered
                >
                    <Column width={150} resizable>
                        <HeaderCell>Data e Hora</HeaderCell>
                        <Cell dataKey="FormatDate" />
                    </Column>

                    <Column width={180} resizable>
                        <HeaderCell>Usuario</HeaderCell>
                        <Cell dataKey="psm_review_log_user_name" />
                    </Column>

                    <Column width={160} resizable>
                        <HeaderCell>Código antigo PSM</HeaderCell>
                        <Cell dataKey="psm_review_log_old_code" />
                    </Column>

                    <Column width={160} resizable>
                        <HeaderCell>Código novo PSM</HeaderCell>
                        <Cell dataKey="psm_review_log_new_code" />
                    </Column>

                    <ColumnGroup header="valor dos produtos" align="center">
                        <Column width={130} resizable>
                            <HeaderCell>Vlr. Anterior</HeaderCell>
                            <Cell dataKey="serviceNewValue" />
                            
                        </Column>
                        <Column width={130} resizable>
                            <HeaderCell>Vlr. Atual</HeaderCell>
                            <Cell dataKey="serviceOldValue" />
                        </Column>
                    </ColumnGroup>
                    <ColumnGroup header="Valor Total (com rateio)" align="center">
                        <Column width={130} resizable>
                            <HeaderCell>Vlr. Anterior</HeaderCell>
                            <Cell dataKey="totalOldValue" />            
                        </Column>
                        <Column width={130} resizable>
                            <HeaderCell>Vlr. Atual</HeaderCell>
                            <Cell dataKey="totalNewValue" />
                        </Column>

                    </ColumnGroup>

                </Table>
            </div>
        </>)
    }
}

