import React from "react";
import { Button, InputGroup, Notification, Tooltip, Whisper, Input } from "rsuite";
import { UserContext } from "../../../components/authProvider/main";
import { AdvancedSearchModal } from "../../../components/formfields/AdvancedSearchModal";
import { MailerModal } from "../../../components/mailer/MailerModal";
import axios from "../../../utilities/axios";
import { ConfirmModal } from "../../../components/confirmModal/ConfirmModal";

class DibiBrowseFooter extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;

    public state = {
        isOpen: false,
        loading: false,
        modalLoading: false,
        to: [],
        cc: [],
        cco: [],
        files: [],
        isAdvancedModalOpen: false,
        emailSubject: "",
        emailProposituresOsNumbers: '',
        showSituation: false,
        situacaoModal: "",
        psmId: [],
        searchClientBrowselize: {}
    };

    enviarOrcamentos = (type: 'commercials' | 'technical') => {
        //console.log("Olha",this.props.state.checked)
        if (this.props.state.checked.length <= 0) {
            Notification.error({
                title: "Erro!",
                description: "Nenhum orçamento selecionado!",
            });
            return;
        }
        this.setState({ loading: true });
        axios
            .get(`/api/utils/email/validate-before`, {
                params: {
                    ids: this.props.state.checked,
                    origen: 'dibis'
                }
            })
            .then((res) => {
                axios
                    .get(`/api/utils/email/get-dibis/${type}`, {
                        params: {
                            ids: this.props.state.checked
                        }
                    })
                    .then(async (res) => {
                        // console.log(res.data)
                        const files = [];
                        for (var i in res.data.files) {
                            const file = res.data.files[i];
                            files.push({
                                url: file,
                                file: {
                                    name: file.split("/").pop(),
                                },
                                status: "success",
                                name: file.split("/").pop(),
                            });
                        }
                        this.setState({
                            files,
                            emailProposituresOsNumbers: Object.values(res.data.codes).join(", "),
                            emailSubject: "MX DRIVE - Proposta(s) Comercial(is)",
                            cco: [...Object.values(res.data.copyContactOcult) !== undefined ? [...Object.values(res.data.copyContactOcult)] : ["Sem email padrão"]],
                            cc: this.context.data.user_parameters["mail.default.cc"] === undefined ? ["Sem email padrão"] : this.context?.data?.user_parameters["mail.default.cc"],
                            to: [...Object.values(res.data.contacts), this.context.data.user_parameters["mail.default.from"] === undefined ? [""] : this.context.data.user_parameters["mail.default.from"]],
                            isOpen: true,
                            psmId: this.props.state.checked,
                        });
                        // console.log("Testando o TO ::::",this.state.to);
                        // console.log("Testando o cc", this.state.cc);
                        // console.log("Testando o cco", this.state.cco);
                        await this.isSetIdCliente()
                    })
                    .catch((e) => {
                        if (e.response.status == 406) {
                            Notification.error({
                                title: "Erro!",
                                description: e.response.data.message,
                            });
                        } else {
                            console.error(e);
                        }
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            })
            .catch((e) => {
                console.error(e.response)
                if (e.response.status === 419) {
                    for (const index of e.response.data.errors) {
                        Notification.error({
                            title: "Error!",
                            description: index
                        })
                    }
                }
                this.setState({ loading: false })
            })
    };

    afterSend = (ids: any) => {
        axios
            .get('/api/v1/situation/controller/email', {
                params: {
                    ids: ids
                }
            }).then((res) => {
                if (res.data.success.length > 0) {
                    let psmNumbers: string = ''
                    for (const item of res.data.success) {
                        psmNumbers += `, ${item},`
                    }
                    Notification.success({
                        title: "Sucesso!",
                        description: `PSM's atualizadas com sucesso [${psmNumbers}]`
                    })
                }
            }).catch((e) => {

            })
    };

    onSelectContact = (contactData: any) => {
        //console.log("Teste :::::", contactData)
        this.setState({ modalLoading: true })
        /* push contactData.value to state.to */
        axios
            .get("/api/v1/person-contacts/" + contactData.value)
            .then((res) => {
                if (res.data.item.person_contact_mail) {
                    this.setState({
                        to: [...this.state.to, res.data.item.person_contact_mail],
                        isAdvancedModalOpen: false,
                    });
                } else {
                    Notification.error({
                        title: "Erro!",
                        description: "Contato sem email!",
                    });
                }
            })
            .finally(() => {
                this.setState({ isAdvancedModalOpen: false, modalLoading: false });
            });
    };
    isSetIdCliente = async () =>{
        await axios
            .get('/api/v1/commercials/' + this.props.state.checked[0])
            .then((res) => {
                this.setState({
                    searchClientBrowselize: {person_contact_fk_person_id: res.data.item.psm_clients_fk_person_id}
                })
            })
            .catch((e) => {
                console.error(e)
            })

            // 
    }
    
    render() {
        return (
            <>
                <Button color={"blue"} loading={this.state.loading} onClick={() => this.enviarOrcamentos("technical")}>
                    <i className="fas fa-paper-plane fa-fw mr-2"></i>Enviar Proposta
                </Button>
                <MailerModal
                    afterSend={this.afterSend}
                    files={this.state.files}
                    emailSubject={this.state.emailSubject}
                    emailProposituresOsNumbers={this.state.emailProposituresOsNumbers}
                    onHide={() => this.setState({ isOpen: false })}
                    cc={this.state.cc}
                    cco={this.state.cco}
                    loading={this.state.modalLoading}
                    to={this.state.to}
                    isOpen={this.state.isOpen}
                    psmId={this.state.psmId}>
                    <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Selecionar contatos do cliente</Tooltip>}>
                        <InputGroup.Button onClick={() => this.setState({ isAdvancedModalOpen: true })} color="blue">
                            <i className="fas fa-fw fa-search"></i>
                        </InputGroup.Button>
                    </Whisper>
                </MailerModal>
                <AdvancedSearchModal api={'/api/v1/person_contacts/select'} searchValue={this.state.searchClientBrowselize} onSelect={this.onSelectContact} show={this.state.isAdvancedModalOpen} crud="person_contacts" />
            </>
        );
    }
}

export default class DibiBrowseEvents {
    renderFooter = (props: any, state: any) => {
        return <DibiBrowseFooter props={props} state={state} />;
    };
}
