import axios from "../../utilities/axios";
import { Event } from "../Event";


export class PurchaseRequisitionFkCompanyIdEvents extends Event{
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings',[
            'purchase_requisition_os_number'
        ])
        axios.get(`/api/v1/purchase/generate-code/` + value)
        .then((res) => {
            this.setFieldValue('purchase_requisition_os_number',res.data.code);
        })
        .finally(() => {
            this.setState('loadings',[])
        })
    }; 
}


