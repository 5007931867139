import { Event } from "../Event";

export class PsmAnalisysHourEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        if(value){
            console.log({value});
            this.setState('loadings',[
                'psm_analisys_hour'
            ]);

            let newValue;
            let stringValue = JSON.stringify(value)
            if(stringValue.includes('.')){
                let decimalValue: any = stringValue.split('.').pop();
                let primary =  stringValue.split('.').shift();
                let numberDecimal = parseInt(decimalValue);
                console.log({numberDecimal});
                if((numberDecimal !== 5) && (numberDecimal !== 0)){
                    if(numberDecimal >= 1 && numberDecimal <= 4){
                        newValue = `${primary}.5`;
                        newValue = parseFloat(newValue);
                    }
                    if(numberDecimal > 5 && numberDecimal <= 9){
                        newValue = Math.round(value);
                    }
    
                    
                    this.setFieldValue('psm_analisys_hour',newValue);
                    
                }
            }

            this.setState('loadings',[]);
        }
    }
}