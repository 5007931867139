import { toUpper } from "lodash";
import { Event } from "../Event";
import { Notification } from "rsuite";

export class PsmImportObsClientEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {

        await this.setState("loadings",[
            "psm_obs_proposal"
        ]);

        const dataObsCliente: any = this.globalProps.values.psm_combined_analysis_obs_client
        console.log("dataObsCliente", {dataObsCliente})

        if (value == true) {
            const dataObs: any = this.globalProps.values.psm_combined_analysis_obs_client
            if (dataObs == null || dataObsCliente == "<P></P>\n") {
                Notification.warning({
                    title: "Atenção!",
                    description: 'Não é possivel importar pois não existe Observações (Cliente)',
                    duration: 3500,
                });
            }

            let valueImported = dataObs
            this.setFieldValue("psm_obs_proposal", toUpper(valueImported))
        }

        this.setState("loadings", [])

    }
}