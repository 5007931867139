import { Event } from "../Event";

const calculaValorTotal = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0) => {
    var valorTotal = inicial;
    var targetDescontoPercentage = 0;
    var targetDescontoValue = 0;
    if (descontoPercentage > 0) {
        valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
        targetDescontoPercentage = descontoPercentage;
        targetDescontoValue = inicial - valorTotal;
    } else if (descontoValue > 0) {
        valorTotal = valorTotal - descontoValue;
        targetDescontoValue = descontoValue;
        targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
        let teste = targetDescontoPercentage.toFixed(2);
        // console.log({teste});
    }
    // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;

    return {
        valorTotal,
        percentual: targetDescontoPercentage,
        desconto: targetDescontoValue,
    };
};


export class PsmConclusionHoursToFinishEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        var dataPsm: any = this.globalProps.values;
        console.log("percente discount ::: ",dataPsm.psm_discount_percent);
        if(value){
            await this.setState("loadings",[
                "psm_considered_value",
                'psm_total_product_service'
            ]);

            this.setFieldValue('psm_considered_value', dataPsm.psm_hour_value * value);
            this.setFieldValue('psm_total_product_service', (value * dataPsm.psm_hour_value) + dataPsm.psm_total_value_products);

            if((dataPsm.psm_discount_percent !== 0) ||(dataPsm.psm_discount_percent !== null)){
                let totalValue = (value * dataPsm.psm_hour_value) + dataPsm.psm_total_value_products;
                let result: any = calculaValorTotal(totalValue, dataPsm.psm_discount_percent, 0);
                this.setFieldValue('psm_final_value', result.valorTotal);
            }else{
                this.setFieldValue('psm_final_value', (value * dataPsm.psm_hour_value) + dataPsm.psm_total_value_products);
            }
            this.setState("loadings", [])
        }
        if(this.globalProps.location.pathname.includes('laboratorio')){
            if(value){
                console.log({value});
                this.setState('loadings',[
                    'psm_conclusion_hours_to_finish'
                ]);

                let newValue;
                let stringValue = JSON.stringify(value)
                if(stringValue.includes('.')){
                    let decimalValue: any = stringValue.split('.').pop();
                    let primary =  stringValue.split('.').shift();
                    let numberDecimal = parseInt(decimalValue);
                    console.log({numberDecimal});
                    if((numberDecimal !== 5) && (numberDecimal !== 0)){
                        if(numberDecimal >= 1 && numberDecimal <= 4){
                            newValue = `${primary}.5`;
                            newValue = parseFloat(newValue);
                        }
                        if(numberDecimal > 5 && numberDecimal <= 9){
                            newValue = Math.round(value);
                        }
        
                        
                        this.setFieldValue('psm_conclusion_hours_to_finish',newValue);
                        
                    }
                }
                
                
                this.setState('loadings',[]);
            }
        }
    }
}