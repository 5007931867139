import { Event } from "../Event";
import axios from "../../utilities/axios";

export class IhmFaultPhraseFkFaultPhraseIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any) => {
        this.setState("loadings", ['ihm_fault_phrase_fk_service_performed_id']);
        // alert("TESTE")
        //console.log("PASSOU EVENT")
        this.setFieldProperty('ihm_fault_phrase_fk_service_performed_id','options',[])
        axios
        .get("/api/v1/defects/select/" + value)
        .then((res => {
            if (res.status === 200 && res) { 
               // alert(JSON.stringify(res.data))
                this.setFieldProperty('ihm_fault_phrase_fk_service_performed_id','options',res.data.items)
            }

        }))
        .finally(() => {
            this.setState("loadings", []);
        }); 

 
      
    //    this.setState({ihm_fault_phrase_fk_service_performed_id:{items:'teste'}})
      //  setField('ihm_fault_phrase_fk_service_performed_id',{value:'1',label:'teste'})
    }
    
}