import { Notification } from "rsuite";
import { Event } from "../Event";
import _ from "lodash";


const sumByValuesForm = (globalData: any, valueInput: number) => {
    let valid = true;
    const parentTotalValues = globalData.parentValues.material_requisition_logistic_total_quantity;
    const ItensMaterialApproved = _.sumBy(globalData.parentValues.material_approved_grids, function (item: any) {
        let sumValues = 0;
        if (item.material_approved_grid_id !== globalData.values.material_approved_grid_id) {
            sumValues = sumValues + item.material_approved_grid_quantity_met
        }
        return sumValues;
    })
    const allValuesMin = parentTotalValues - ItensMaterialApproved;
    if (valueInput > allValuesMin) {
        valid = false;
    } else {
        valid = true;
    }
    // console.log({allValuesMin})
    return valid;
}

export class MaterialApprovedGridQuantityMetEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        await this.setState('loadings', [
            'material_approved_grid_quantity_met',
        ])
        if (sumByValuesForm(this.globalProps, value) === false) {
            Notification.error({
                title: 'Erro!',
                description: 'Não é possivel atribuir um valor maior que a Quant. Total!'
            })
            this.setFieldValue('material_approved_grid_quantity_met', "0");          
        }    
        this.setState('loadings', [])
        
        // this.setExternalFieldValues()
    }
}

export class MaterialRequisitionManualCreationEvents extends Event {
    // public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
    //     console.log('Passei aqui ::: ',this.globalProps)
    //     this.setFieldProperty('material_requisition_logistic_total_quantity',{
    //         readonly: false
    //     })
    //     // this.setField('material_requisition_logistic_total_quantity',{
    //     //     readonly: false
    //     // })
    // }
}


