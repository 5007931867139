
import { Event } from "../Event";
import axios from "../../utilities/axios";
import dayjs from "dayjs";

export class PsmTechnicianInvolvedFkUserIdEvents extends Event {
    public state = {
        loading: false,
    }
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
       //console.log("HISTORY",values);
       //console.log("Olha o Value", )
       //console.log("VALUE:::", value);
        if(value && value > 0) {
            this.setState ("loadings", [
                "psm_technician_involved_responsible",
                "psm_technician_involved_inclusion_date",
            ]);
            axios
            .get("/api/v1/me")
            .then((res => {
                if (res.status === 200 && res) { 
                   // console.log("Testando o res",res);
                    var dados =  res.data.user;
                    var dataHoje = dayjs().format("DD/MM/YYYY");
                    //console.log("Olha aqui",dataHoje);
                    this.setFieldValue ("psm_technician_involved_responsible", dados.user_name); 
                    this.setFieldValue ("psm_technician_involved_inclusion_date", dataHoje);
                }
            }))
            .finally(() => {
                this.setState("loadings", []);
            });               
        }
    }

}