import React from "react";
import { withRouter } from "react-router";
import { CSSTransition } from "react-transition-group";
import { ModuleContext } from "../../components/authProvider/main";
import Browserlize from "../../components/Browserlize";
import { Modal, Button, Notification, Loader } from "rsuite";
import axios from "../../utilities/axios";
import { Helmet } from "react-helmet";

import { Differencify } from "../../components/differencify/main";
import { WYSIWYG } from "../../components/formfields/WYSIWYG";
import _ from "lodash";
import checkPermissionPage from "../../utilities/checkPermissionPage";

class BrowsePage extends React.Component<any, {}> {
    static contextType = ModuleContext;

    public crud: any = this.props.match.params.crud;
    public state: any = {
        formData: {},
        isMounted: false,
        isModalOpen: false,
        isLoading: false,
        logData: {},
        exclusionMotive: "",
    };

    private getter: Promise<void> | null | undefined = null;

    public nodeRef: any = null;

    constructor(props: any) {
        super(props);
        this.nodeRef = React.createRef();
    }

    componentDidMount = () => {
        // this.setState({ isMounted: true });
        const { crud } = this.props.match.params;
        this.context.setModule(null, null, crud);
        this.loadItems();
    };

    loadItems = () => {
        // //
        // alert("LOADING");
        axios
            .get("/api/v1/" + this.crud + "/fields")
            .then((res) => {
                if (res.data && res.data.fields && res.data.fields.$GLOBALS && res.data.fields.$GLOBALS.table) {
                    this.setState({ formData: res.data.fields });
                }
            })
            .finally(() => { })
            .catch((e) => { });
    };

    // shouldComponentUpdate(props: any, state: any) {
    //     // //
    //     return this.props.location !== props.location || this.state !== state || this.props.match.params.crud !== props.match.params.crud;
    // }

    componentDidUpdate(prevProps: any) {
        // //
        if (this.props.location.pathname !== prevProps.location.pathname && this.props.match.params.crud !== prevProps.match.params.crud) {
            this.crud = this.props.match.params.crud;

            this.context.setModule(null, null, this.crud);
            this.context.setPage(this.crud);
            this.setState({ formData: {} }, () => {
                this.loadItems();
            });
        }
    }

    onEdit = (id: string) => {
        this.props.history.push(this.props.location.pathname + "/" + id);
        localStorage.setItem('searchHistoric', this.props.location.search)
        // console.log('This props edit ::: ', this.props.location.search)
        // alert("EDITANDO "+id)
    };

    onRead = (id: string) => {
        this.props.history.push(this.props.location.pathname + "/view/" + id);
        localStorage.setItem('searchHistoric', this.props.location.search)
        // alert("VISUALIZANDO "+id)
    };

    public deletingId: string | null = null;
    public deleteCallback: Function | null = null;

    onDelete = (id: string, callBack: Function = function () { }) => {
        // alert("DELETANDO "+id)
        this.deletingId = id;
        this.deleteCallback = callBack;
        this.setState({ isModalOpen: "delete" });
    };

    renderLogs = () => {
        // var output: any = [];

        // for (var i in this.state.logData) {
        //     var log = this.state.logData[i];
        //     // var difference = diff(log.log_old_data, log.log_new_data);
        //     output.push(
        //         <Panel bordered className="mb-4">
        return <Differencify fields={this.state.formData} logs={this.state.logData} />;

        //         </Panel>,
        //     );
        // }
        // return output;
    };

    showLog = (id: string) => {
        this.setState({ isModalOpen: "logs", isLoading: true });
        axios
            .get("/api/v1/logs/" + this.crud + "/" + id)
            .then((res) => {
                // //
                if (res && res.data && res.data.logs) {
                    this.setState({ logData: res.data.logs, isLoading: false });
                }
            })
            .catch(function () { });
    };

    onConfirmDelete = () => {
        this.setState({ isLoading: true });
        axios
            .post("/api/v1/" + this.crud + "/delete/" + this.deletingId)
            .then((res) => {
                if (res.data.status === 200) {
                    Notification.success({
                        title: "Sucesso!",
                        description: "Registo excluido com sucesso!",
                        placement: "bottomEnd",
                    });
                }
            })
            .finally(() => {
                this.setState({ isModalOpen: false, isLoading: false }, () => {
                    if (this.deleteCallback) {
                        this.deleteCallback();
                    }
                });
            })
            .catch((e) => {
                if (e.response.data.status === 403) {
                    Notification.error({
                        title: "Erro!",
                        description: e.response.data.errors,
                        placement: "bottomEnd",
                    });
                } else {
                    Notification.error({
                        title: "Erro!",
                        description: "Não foi possivel deleta esse registro!",
                        placement: "bottomEnd",
                    });
                }
            });
    };

    isLoadingClass() {
        if (this.state.isLoading) {
            return { display: "flex", alignItems: "center", justifyContent: "center" };
        }
        return {};
    }

    actions = (id: string) => {
        return <i onClick={() => this.showLog(id)} className="fas fa-fw mr-2 clickable fa-list"></i>;
    };

    onRowDoubleClick = (row: any, index?: any, contextUser?: any) => {
        const redirectUrl: any = window.location.href.split('?').shift()?.split('/').pop();
        if (this.state.formData) {
            const { $GLOBALS } = this.state.formData;
            if ($GLOBALS) {
                const index = $GLOBALS.prefix + "_id";
                if (row && row[index] && ($GLOBALS.edit !== false)) {
                    const id = row[index];
                    // window.open("/dashboard/compras-estoque/" + parsedApiUrl + `${checkPermissionPage(parsedApiUrl, this.context.data) !== false ? '/' : '/view/'}` + MaterialRequisitionPsmId, '_blank');


                    let urlRedirect = `/dashboard/${this.context.name}/${this.crud}/${id}`
                    // if(contextUser && !checkPermissionPage(`${redirectUrl}-add`, contextUser.data)){
                    //     urlRedirect = `/dashboard/${this.context.name}/${this.crud}` + `${checkPermissionPage(`${redirectUrl}-add`, contextUser.data) !== false ? '/' : '/view/'}${id}`

                    //     // urlRedirect =  `/dashboard/${this.context.name}/${this.crud}/` + `${checkPermissionPage(`${redirectUrl}-add`, contextUser.data) !== false ? '/' : '/view/'}``/${id}`
                    // }
                    // if(contextUser && !checkPermissionPage(`${redirectUrl}-edit`, contextUser.data)){
                    //     urlRedirect = `/dashboard/${this.context.name}/${this.crud}` + `${checkPermissionPage(`${redirectUrl}-edit`, contextUser.data) !== false ? '/' : '/view/'}${id}`

                    //     // urlRedirect =  `/dashboard/${this.context.name}/${this.crud}/` + `${checkPermissionPage(`${redirectUrl}-add`, contextUser.data) !== false ? '/' : '/view/'}``/${id}`
                    // }
                    // console.log("diretor master", contextUser)


                    if (contextUser) {
                        const allPerms = checkPermissionPage(`${redirectUrl}`, contextUser.data)
                        const canAddAndEdit = checkPermissionPage(`${redirectUrl}-add`, contextUser.data) && checkPermissionPage(`${redirectUrl}-edit`, contextUser.data);
                        const canAddAndNotEdit = checkPermissionPage(`${redirectUrl}-add`, contextUser.data) && !checkPermissionPage(`${redirectUrl}-edit`, contextUser.data);
                        const notAddAndCanEdit = !checkPermissionPage(`${redirectUrl}-add`, contextUser.data) && checkPermissionPage(`${redirectUrl}-edit`, contextUser.data);
                        if (allPerms == true) {
                            urlRedirect = `/dashboard/${this.context.name}/${this.crud}/${id}`;
                        } else {
                            if (canAddAndEdit == true) {
                                urlRedirect = `/dashboard/${this.context.name}/${this.crud}/${id}`;
                            } else if (canAddAndNotEdit == true) {
                                urlRedirect = `/dashboard/${this.context.name}/${this.crud}/view/${id}`;
                            } else if (notAddAndCanEdit == true) {
                                urlRedirect = `/dashboard/${this.context.name}/${this.crud}/${id}`;
                            }
                        }
                    }

                    if (contextUser) {
                        if (!checkPermissionPage(`${redirectUrl}-add`, contextUser.data) && !checkPermissionPage(`${redirectUrl}-edit`, contextUser.data)) {
                            urlRedirect = `/dashboard/${this.context.name}/${this.crud}/view/${id}`;
                        }
                    }



                    this.props.history.push(urlRedirect);

                }
            }
        }
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>MXDrive {this.state.formData?.$GLOBALS?.singular_name ? "- " + this.state.formData?.$GLOBALS?.singular_name : this.crud}</title>
                </Helmet>
                <Modal overflow={false} show={this.state.isModalOpen === "logs"} size={"lg"} className="centered">
                    <Modal.Header closeButton={false}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-question-circle" style={{ color: "#0096a0", fontSize: 24 }}></i>
                            Logs recentes
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={this.isLoadingClass()}>
                        {this.state.isLoading && <Loader size="md" />}
                        {!this.state.isLoading && this.renderLogs()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.isLoading} type="subtle" onClick={() => this.setState({ isModalOpen: false })}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isModalOpen === "delete"} size={"sm"} className="centered">
                    <Modal.Header closeButton={false}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                            Atenção!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={this.isLoadingClass()}>
                        {this.state.isLoading && <Loader size="md" />}
                        {!this.state.isLoading && <>Deseja mesmo excluir esse registro ? O registro não poderá ser restaurado!</>}
                        {!this.state.isLoading && (
                            <WYSIWYG placeholder={"Digite o motivo"} onChange={(value: string) => this.setState({ exclusionMotive: value })} className="mt-4"></WYSIWYG>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.isLoading} appearance="primary" onClick={this.onConfirmDelete}>
                            <i className="fas fa-check fa-fw mr-2"></i>Confirmar
                        </Button>
                        <Button disabled={this.state.isLoading} color="red" onClick={() => this.setState({ isModalOpen: false })}>
                            <i className="fas fa-times fa-fw mr-2"></i>Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CSSTransition nodeRef={this.nodeRef} in={true} classNames="anim-top" unmountOnExit timeout={200}>
                    <div className="container">
                        {Object.keys(this.state.formData).length > 0 && (
                            <Browserlize
                                onRowDoubleClick={this.onRowDoubleClick}
                                actions={this.actions}
                                onRead={this.onRead}
                                onDelete={this.onDelete}
                                onEdit={this.onEdit}
                                module={this.context.name}
                                form={this.state.formData}
                                name={this.crud}></Browserlize>
                        )}
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default withRouter(BrowsePage);
