import { Event } from "../Event";

const calculaValorTotal = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0) => {
    var valorTotal = inicial;
    var targetDescontoPercentage = 0;
    var targetDescontoValue = 0;
    if (descontoPercentage > 0) {
        valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
        targetDescontoPercentage = descontoPercentage;
        targetDescontoValue = inicial - valorTotal;
    } else if (descontoValue > 0) {
        valorTotal = valorTotal - descontoValue;
        targetDescontoValue = descontoValue;
        targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
        let teste = targetDescontoPercentage.toFixed(2);
        // console.log({teste});
    }
    // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;

    return {
        valorTotal,
        percentual: targetDescontoPercentage,
        desconto: targetDescontoValue,
    };
};


export class PsmCustomImportDibiValueProductEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        var dataPsm: any = this.globalProps.values
        if(value){

            this.setState("loadings",[
                "psm_total_value_products",
                // "psm_total_product_service",
                // "psm_refer_equipment_new",
                "psm_value_product_discount",
                "psm_import_value"
                // "psm_value_service_recommended_discount",
                // "psm_total_product_service_discount"
            ]);

            let ProductValue = value[1].newObject[0].sumValues;

            let productDiscount = value[0].newObjectDiscount[0].sumValuesDiscount;

            setField("psm_total_value_products", ProductValue);

            setField("psm_value_product_discount", (productDiscount == 0 ? ProductValue : productDiscount));

            this.setFieldValue("psm_import_value", true);
            // if(![null,0].includes(dataPsm.psm_discount_percent_value)){
            //     let result: any = calculaValorTotal(ProductValue, dataPsm.psm_discount_percent_value, 0);
            //     this.setFieldValue('psm_value_product_discount', result.valorTotal);
            //     this.setFieldValue('psm_discount_real', result.desconto)
            // }else{
            //     this.setFieldValue('psm_value_product_discount',ProductValue);
            // }

            this.setState("loadings",[])
        }
    } 
}