import { Event } from "../Event";

const calculaValorTotal = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0) => {
    var valorTotal = inicial;
    var targetDescontoPercentage = 0;
    var targetDescontoValue = 0;
    if (descontoPercentage > 0) {
        valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
        targetDescontoPercentage = descontoPercentage;
        targetDescontoValue = inicial - valorTotal;
    } else if (descontoValue > 0) {
        valorTotal = valorTotal - descontoValue;
        targetDescontoValue = descontoValue;
        targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
        // console.log({teste});
    }
    // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;

    return {
        valorTotal,
        percentual: targetDescontoPercentage,
        desconto: targetDescontoValue,
    };
};

export class PsmDiscountPercentValueEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        var dataPsm: any = this.globalProps.values
        console.log({dataPsm});
        console.log({value})

        await this.setState("loadings", [
            "psm_discount_real_value",
            "psm_value_product_discount"
        ]);

        if (value !== 0) {
            let percentValue = value
            let result: any = calculaValorTotal(dataPsm.psm_total_value_products, percentValue, 0);

            this.setFieldValue('psm_discount_real_value', result.desconto);
            this.setFieldValue('psm_value_product_discount', result.valorTotal);
        }
        if (value == 0) {
            this.setFieldValue('psm_discount_real_value', 0);
            this.setFieldValue('psm_value_product_discount', dataPsm.psm_total_value_products);
        }

        this.setState("loadings", [])
    }
}