import { Event } from "../Event";

export class PsmPurposeProductEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        await this.setState("loadings", [
            "psm_purpose_product_copy",
        ]);

        this.setFieldValue('psm_purpose_product_copy', parseInt(value));

        this.setState("loadings", [])
    }
}