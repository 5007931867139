import { Event } from "../Event";
import axios from "../../utilities/axios";

export class UserCpfCnpjEvents extends Event {
    onBlur = (value: any, setField: any, setState: any) => {
        this.setState({ loadings: ["user_cpf_cnpj"] });
        axios
            .get("/api/v1/exists/users/user_cpf_cnpj", { params: { value,id: this.globalState?.formValues?.user_id } })
            .then((res) => {
                this.setState({
                    loadings: [],
                });
                this.setState({
                    errors: {
                        user_cpf_cnpj: "Esse CPF/CNPJ já existe!",
                    },
                });
            })
            .catch((e) => {
                this.setState({
                    loadings: [],
                });
            })
            .finally(() => {});
    };
}
