import React from "react";
import { Whisper, Tooltip, Button, Modal, Table, Notification, DatePicker } from "rsuite";
import 'rsuite/dist/styles/rsuite-default.css'; // Certifique-se de ter os estilos importados
import axios from "../../../utilities/axios";
import TablePagination from "rsuite/lib/Table/TablePagination";
import { UserContext } from "../../../components/authProvider/main";


const { Column, HeaderCell, Cell } = Table;

type State = {
    titleModal: string;
    showModal: boolean;
    functionName: string;
    showLogsModal: boolean;
    data: any[];
    page: number;  // Estado adicionado para controlar a página atual
    displayLength: number;  // Estado adicionado para controlar o número de registros por página
    isLoading: boolean;
}

export class ReceiptBrowserlize extends React.Component<any, State> {
    static contextType?: React.Context<any> | undefined = UserContext;

    public state: State = {
        titleModal: '',
        showModal: false,
        functionName: '',
        showLogsModal: false, // Inicialmente, o modal de logs está fechado
        data: [], // Inicialmente, nenhum dado
        page: 1,  // Estado adicionado para controlar a página atual
        displayLength: 10,  // Estado adicionado para controlar o número de registros por página
        isLoading: false
    }

    componentDidMount(): void {
        // console.log('props ::> ', this.props)
    }

    onOpenModal = ({ title, functionName }: { title: string, functionName: string }) => {
        this.setState({
            showModal: true,
            titleModal: title,
            functionName: functionName
        })
    }

    onOpenLogs = async ({ title, functionName }: { title: string, functionName: string }) => {
        await axios.get(`/api/v1/logs/unlinks/nfs/`)
            .then((res: any) => {
                // console.log("valor do res ::>", res)
                this.setState({
                    showLogsModal: true,
                    data: res.data.data
                });

            })
    }

    getAllSellers = async () => {

    }

    onCloseModal = () => {
        this.setState({
            showModal: false
        })
    }

    onCloseLogsModal = () => {
        this.setState({
            showLogsModal: false
        })
    }

    handleChangePage = (page: number) => {
        this.setState({ page });
    }

    handleChangeLength = (displayLength: number) => {
        this.setState({ page: 1, displayLength });
    }


    render(): React.ReactNode {
        const { page, displayLength, data } = this.state;
        const filteredData = data?.slice((page - 1) * displayLength, page * displayLength);

        console.log("this context :>", this)
        return (
            <>
                {this.context.data.user_id === 12 && (
                    <Whisper
                        trigger="hover"
                        placement="bottom"
                        speaker={<Tooltip placement="bottom">Desvinculos de NF's</Tooltip>}
                    >
                        <Button
                            style={{ color: "snow", backgroundColor: "#4682B4" }}
                            className="mr-2"
                            onClick={() => this.onOpenLogs({ title: "Logs", functionName: "viewLogs" })}
                        >
                            <i className="fa fa-list-alt"></i>
                        </Button>
                    </Whisper>
                )}

                {this.state.showLogsModal && (
                    <Modal full show={true} onHide={this.onCloseLogsModal}>
                        <Modal.Header>
                            <Modal.Title>Notas Desvinculadas</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table
                                height={400}
                                data={filteredData}
                                onRowClick={(data) => {
                                    console.log(data);
                                }}
                            >
                                <Column width={200} align="center" resizable>
                                    <HeaderCell>PSM ID</HeaderCell>
                                    <Cell dataKey="unlink_nf_fk_psm_id" />
                                </Column>
                                <Column width={200} align="center" resizable>
                                    <HeaderCell>Cód. PSM ANTIGA</HeaderCell>
                                    <Cell dataKey="unlink_nf_psm_os_number_old" />
                                </Column>

                                <Column width={200} align="center" resizable>
                                    <HeaderCell>Cód. PSM nova</HeaderCell>
                                    <Cell dataKey="unlink_nf_psm_os_number_new" />
                                </Column>

                                <Column width={200} resizable>
                                    <HeaderCell>Data</HeaderCell>
                                    <Cell dataKey="unlink_nf_created_at" />
                                </Column>

                                <Column width={200} resizable>
                                    <HeaderCell>Chave NF Antiga</HeaderCell>
                                    <Cell dataKey="unlink_nf_old_key_nf" />
                                </Column>

                                <Column width={200} resizable>
                                    <HeaderCell>Chave NF Nova</HeaderCell>
                                    <Cell dataKey="unlink_nf_new_key_nf" />
                                </Column>

                                <Column width={200} resizable>
                                    <HeaderCell>Usuário</HeaderCell>
                                    <Cell dataKey="unlink_nf_user" />
                                </Column>

                            </Table>
                            <TablePagination
                                lengthMenu={[
                                    { value: 10, label: '10 / página' },
                                    { value: 20, label: '20 / página' },
                                    { value: 30, label: '30 / página' }
                                ]}
                                activePage={this.state.page}
                                displayLength={this.state.displayLength}
                                total={this.state.data.length}
                                onChangePage={this.handleChangePage}
                                onChangeLength={this.handleChangeLength}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.onCloseLogsModal} appearance="subtle">Fechar</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </>
        );

    }
}