import { Notification } from "rsuite";
import axios from "../../utilities/axios";
import { Event } from "../Event";
export class PsmInclusionProductCompiledEvents extends Event {
    public onLoad = async () => {
        const dadosPsm = this.globalProps.values;
        const matchParams = this.globalProps.match.params;
        console.log('test :::> ',this.globalProps)
        if(dadosPsm.psm_inclusion_product_compiled_id) {
            await axios.get('/api/v1/check-item/product',{
                params: {
                    crud: matchParams.crud,
                    idPsm: matchParams.id,
                    idProduct: dadosPsm.psm_inclusion_product_compiled_id
                }
            }).then((response: any) => {
                if(response.data.status == 200) {
                    Notification.info({
                        title: 'Permitido Alteração',
                        description: response.data.message
                    })
                }                                                    
            })
            .catch((e) => {
                Notification.error({
                    title: 'Não permitido',
                    description: e.response.data.message
                })
                const fieldColumns = this.globalProps.form;
                if(matchParams.crud !== 'commercials'){
                    for(const i in fieldColumns) {
                        if(!['$GLOBALS','psm_inclusion_product_compiled_instal'].includes(i)){
                            this.setField(i,{readonly: true, disabled: true})
                        }
                    }
                }else {
                    for(const i in fieldColumns) {
                        if(i !== '$GLOBALS' ){
                            this.setField(i,{readonly: true, disabled: true})
                        }
                    }
                }
            })
            .finally(() => {
                // this.setState('loadings',[])
            })
        }
        this.setState('loadings',[
            'psm_inclusion_product_compiled_fk_select_id'
        ])

        this.setState('loadings',[])


    }
}