import React from "react";
import { Button, Modal } from "rsuite";

export class ConfirmModal extends React.Component<any, {}> {
    render() {

        const currentPath = window.location.pathname;
        const shouldShowModal = !(currentPath.includes('/receipts') && currentPath.includes('/add'));

        if (!shouldShowModal || this.props.show === false) {
            return null;
        }

        console.log("this props :>", this)
        return (
            <Modal show={true}>
                <Modal.Header onHide={this.props.funcaoclose}>
                    <h4>{this.props.title ?? "Deseja mesmo sair?"}</h4>
                </Modal.Header>
                <Modal.Body>{this.props.content ?? "Existem dados que não foram salvos e serão perdidos, deseja mesmo continuareeee?"}
                    {this.props.body}
                </Modal.Body>
                <Modal.Footer>

                    {this.props.showConfirm !== false && <Button loading={this.props.loading} onClick={this.props.onConfirm} color={this.props.confirmColor ?? 'red'}>
                        {this.props.confirmText ?? 'Confirmar'}
                    </Button>}
                    <Button disabled={this.props.loading} onClick={this.props.onCancel} color={this.props.cancelColor ?? undefined}>
                        {this.props.cancelText ?? 'Cancelar'}
                    </Button>
                    {this.props.showbotaoclose == true && <Button disabled={this.props.loading} onClick={this.props.funcaoclose} color={"red"}>
                        Fechar
                    </Button>}

                </Modal.Footer>
            </Modal>
        );
    }
}
