import axios from "../../utilities/axios";
import React, { Children } from "react";
import _ from "lodash";
import { Icon, Loader, MultiCascader, Notification } from "rsuite";

export class AxiosCascadePicker extends React.Component<any, {}> {
    public state = {
        data: [],
        uncheckable: [],
        value: this.props.value ?? [],
        loading: true,
    };
    getData = () => {
        return [];
    };

    componentDidMount() {
        // this.onSearch();
        this.onSearchInit();

    }


    checkIfHasChildren = (item: any, outputs: any = []) => {
        if (item.children) {
            outputs = [...outputs, item.value];
            for (var i in item.children) {
                outputs = [...outputs, ...this.checkIfHasChildren(item.children[i])];
            }
        }
        return outputs;
    };

    onChange = (value: string[], event: any) => {
        this.setState({ value })
        if (this.props.onChange) {
            this.props.onChange(value, this.state.data);
        }
    };

    createChildren = (item: Array<Record<string, any>>, callback: any) => {
        const children = [];
        return callback(item)

    }

    handleSelect = async (selectValue: Record<string, any>, activePaths: Record<string, any>, concat: any, event: any) => {
        if (selectValue.children && selectValue.children.length === 0) {
            await axios
                .get(`/api/v1/storage-local-lines/select-line`, {
                    params: { storage_id: selectValue.storage_id, type: selectValue.type }
                })
                .then((response) => {
                    // if (selectValue.type === 'type_storage') {
                    //     const items = response.data.items;
                    //     var uncheckable: string[] = [];
                    //     for (var i in items) {
                    //         var unchecks = this.checkIfHasChildren(items[i]);
                    //         // //console.log({resss});
                    //         uncheckable = [...uncheckable, ...unchecks];
                    //     }
                    // }
                    this.createChildren(response.data.items, (children: any) => {
                        this.setState({ data: concat(this.state.data, children) })
                    })
                })
                .catch((e) => {
                    console.log(e)
                })
        }
        // } else {
        // if (!selectValue.children) {
        //     // Função que recebe o valor do state e o array de objetos
        //     const removerValor = (stateValue: any, activePath: any) => {
        //         const valuesToRemove = new Set(activePath.map((item: any) => item.label));

        //         const filteredStateValue = stateValue.filter((value: any) => !valuesToRemove.has(value));

        //         return filteredStateValue;
        //     }
        //     const concatItens = activePaths.map((item: Record<string, any>, index: number | string) => {
        //         return item.value
        //     })
        //     let novoArray = removerValor(this.state.value, activePaths)
        //     this.setState({ value: [...novoArray, concatItens.toString().split(",").join("-")] }, () => {
        //         this.props.onChange(this.state.value, this.state.data)
        //     })
        // }
        // }
    }
    onSearchInit = () => {
        this.setState({ loading: true })
        axios.get('/api/v1/storage-local-lines/initial')
            .then((response) => {
                const items = response.data.items;
                var uncheckable: string[] = [];
                for (var i in items) {
                    var unchecks = this.checkIfHasChildren(items[i]);
                    // //console.log({resss});
                    uncheckable = [...uncheckable, ...unchecks];
                }
                // console.log('Uncheckable ::::> ', uncheckable);
                this.setState({ data: response.data.items, uncheckable });
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    onSeachOpen = () => {
        this.setState({ loading: true });
        axios.get("/api/v1/storage-local-lines/select-local").then((res) => {
            if (res.data) {
                this.setState({ data: res.data.items });
            }
        }).catch((e) => {

        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    onSearch = (search: string | null = null) => {
        this.setState({ loading: true });
        axios.get(this.props.field?.api?.url ?? "/api/v1/storage-local-lines/select").then((res) => {
            if (res.data) {
                // const items = res.data.items;
                // var uncheckable: string[] = [];
                // for (var i in items) {
                //     var unchecks = this.checkIfHasChildren(items[i]);
                //     // //console.log({resss});
                //     uncheckable = [...uncheckable, ...unchecks];
                // }
                this.setState({ data: res.data.items });
            }
        }).catch((e) => {

        }).finally(() => {
            this.setState({ loading: false });
        });
    };

    // getFullNamePath = (item: string[]) => {
    //     var result = [];
    //     var index = '';
    //     if (this.state.data) {
    //         var activeObj: any = null;
    //         for (var i in item) {
    //             var id = item[i];
    //             console.log(id)
    //             if (activeObj === null) {
    //                 activeObj = _.find(this.state.data, { value: id });
    //                 index += id;
    //                 if (activeObj?.label) {
    //                     result.push(activeObj?.label);
    //                 }
    //                 // //console.log({ activeObj,data: this.state.data,find: _.find(this.state.data, { value: id }),id});
    //             } else {
    //                 index += '-' + id;
    //                 //console.log({activeObj: activeObj?.children,index});
    //                 // //console.log({ activeObj,data: this.state.data,find: _.find(this.state.data, { value: id }),id});
    //                 activeObj = _.find(activeObj?.children ?? [], { value: index })
    //                 if (activeObj?.label) {
    //                     result.push(activeObj?.label)
    //                 }

    //             }

    //         }
    //     }
    //     return result;
    // };

    // getFullNamePath = (selectItem: any, inicializator?: number) => {
    //     if (!selectItem) {
    //         return '';
    //     }

    //     const { label, parent } = selectItem
    //     if (parent) {
    //         const parentLabel: any = this.getFullNamePath(parent);
    //         return `${parentLabel} / ${label}`;
    //     } else {
    //         return label;
    //     }
    // }

    getFullNamePath = (hierarchy: any, valuesToFind: any[]) => {
        const foundLabels: any = [];

        for (const i in valuesToFind) {
            let arrayLine = valuesToFind[i]
            let splitData = arrayLine?.split("-")
            let result: any = []
            let newData = hierarchy
            for (const k in splitData) {
                let indexValue = splitData[k]
                let level: any = parseInt(k)

                if (!isNaN(indexValue) && level <= 2) {

                    let findValue = _.findIndex(newData, {
                        value_fk: parseInt(indexValue)
                    })
                    if (findValue > -1) {
                        result.push(newData[findValue].label)
                        newData = newData[findValue]?.children
                    }


                } else {
                    result.push(indexValue)
                }


            }
            foundLabels.push(result.toString().split(',').join(' / '))

        }
        return foundLabels  //['MX LIMEIRA / REVENDA / QUADRA 1 / 1','MX SERRA / PRATELEIRA / QUADRA BIN / A1',`123`];
    }

    renderValue = (data: any[], selectedItems: any[], selectedElement: React.ReactNode) => {
        if (this.state.loading) {
            return <span style={{ height: 20 }}><Loader style={{ height: 1 }} content={"Carregando..."} /></span>;
        }
        let names: any[] = this.getFullNamePath(this.state.data, data)

        return <span style={{ width: '100%' }}>{names.map((item, index) => {
            return <span key={index} style={{ overflow: 'hidden', textOverflow: "ellipsis", display: 'block' }}>
                {item}
                {/* {index < names.length - 1 && <hr style={{ margin: 0, marginBottom: 4, borderColor: '#008391', opacity: 0.3 }} />} */}
            </span>
        })}
        </span>;
    };

    renderMenu(children: any, menu: any, parentNode: any) {
        if (children.length === 0) {
            return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <Icon icon="spinner" spin /> Loading...
                </p>
            );
        }
        return menu;
    }

    render() {
        return (
            <>
                <MultiCascader
                    disabled={this.props.disabled}
                    loading={this.state.loading}
                    block
                    renderValue={this.renderValue}
                    cascade={false}
                    placeholder={"Selecione..."}
                    value={this.state.value}
                    onChange={this.onChange}
                    preventOverflow={true}
                    // onSelect={this.handleSelect}
                    renderMenu={this.renderMenu}
                    uncheckableItemValues={this.state.uncheckable}
                    // onOpen={this.onSeachOpen}
                    data={this.state.data}
                />
            </>
        );
    }
}
