import axios from "../../../utilities/axios";
import React from "react";
import { Button, Notification } from "rsuite";
import { StockModal } from "../../customModal/StockModal";
import _ from "lodash";


export class Stockhistoric extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        stock: [],
        stock_per_location: [],
        stock_per_psm: [],
        stock_per_product: [],
        cidades: [],
        loadingmodal: true,
        idProdOmie: "",
        values: []
    };

    buscaStock = async () => {
        // console.log(this.props.values)
        this.setState({
            loadingmodal: true,
            loading: true
        })
        let idProd = null
        for (let i in this.props.values) {
            if (i.includes("_fk_select_id")) {
                idProd = this.props.values[i]
            }
        }
        let idPordOmie = ""
        console.log("Valor do IDProd", idProd)
        if (idProd !== null) {
            await axios
                .get(`/api/v1/products/${idProd}/`)
                .then((res: any) => {
                    idPordOmie = res.data.item.product_id_omie
                    this.setState({
                        idProdOmie: res.data.item.product_id_omie
                    })
                    console.log("Valor do res.data", res.data)
                })
        }
        console.log("Valor do idPordOmie", idPordOmie)
        console.log("Valor do this.state.idProdOmie", this.state.idProdOmie)
        if (!["", null, undefined].includes(this.state.idProdOmie)) {
            await axios
                .get(`/api/v1/products/get-products/${this.state.idProdOmie}/omie`)
                .then(async (res: any) => {
                    let valuesProducts = res.data.response;
                    console.log("Valor do response 2 ", res.data)
                    let qtdLimeira = _.find(valuesProducts, { 'localCode': "2398047389" }).amount
                    let qtdMaringa = _.find(valuesProducts, { 'localCode': "4987314829" }).amount
                    let qtdSerra = _.find(valuesProducts, { 'localCode': "2124003533" }).amount
                    this.setState({
                        values: { limeira: qtdLimeira, maringa: qtdMaringa, serra: qtdSerra }
                    })                   
                    await axios
                        .get("api/v1/stock/getAllCompanies/", { params: { product_id: this.props.values.psm_product_registration_fk_select_id } })
                        .then((res => {
                            if (res.status === 200) {
                                // console.log(res)
                                this.setState({
                                    cidades: res.data.result,
                                })
                                // console.log(this.state.stock)

                            }

                        }))
                    this.setState({ showSituation: true })
                }).catch((e) => {
                    Notification.error({
                        title: 'Erro!',
                        description: e.response.data.message
                    })
                })
                .finally(() => {
                    this.setState({ loading: false, loadingmodal: false })
                })
        } else {
            Notification.error({
                title: 'Error!',
                description: 'Não possui código de integração com o OMIE'
            })

            this.setState({ loading: false, loadingmodal: true })
        }
    }

    render() {
        return (<>
            <StockModal
                loading={this.state.loadingmodal}
                onCancel={() => {
                    this.setState({
                        showSituation: false,
                    })
                }}
                // body={<>
                //     <Input size="md" style={{marginBottom: 20}} placeholder={this.state.situacaoModal} disabled={true}/>
                //     <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                //     <Button loading={this.state.loading} color='blue' style={{marginLeft:20}} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                // </>}
                productID={this.props.values.psm_product_registration_fk_select_id}
                //   psmID={this.props.parentValues.psm_id}
                cancelColor={"red"}
                showConfirm={false}
                cancelText={"Fechar"}
                onCloseButton={true}
                content={""}
                title={"Consulta de Estoque"}
                data={this.state.cidades}
                idProduct={this.state.idProdOmie}
                values={this.state.values}
                // dataStock={this.state.stock}
                // dataStock_location={this.state.stock_per_location}
                // dataStock_psm={this.state.stock_per_psm}
                // dataStock_product={this.state.stock_per_product}                    
                // dataOpen={this.state.historic_proposal}

                show={this.state.showSituation}
            />

            {/* {JSON.stringify(this.props.values?.psm_product_registration_fk_select_id)}
                {JSON.stringify(this.props.values?.psm_id)}
                {JSON.stringify(this.props.parentValues.psm_id)} */}

            <Button style={{ marginTop: 23, marginRight: 15 }} loading={this.state.loading} onClick={() => this.buscaStock()} color={"blue"} className="ml-4">
                <i className="fas fa-home fa-fw mr-2"></i>
            </Button>
        </>

        )
    }
}

// this.props.onChange('abc')
