import React from "react";
import { Button, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { CustomModal } from "../../customModal/CustomModal";
import { Console } from "console";


export class MaterialCancelItem extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data: [],
        isLoading: false,
    }

    componentDidMount = async () => {

    }

    isCancelItem = () => {
        // const params = {
        //     material_requisition_logistic_fk_material_requisition_id: this.props.parentValues.material_requisition_logistic_fk_material_requisition_id,
        //     material_approved_grid_quantity_met: this.props.values.material_approved_grid_quantity_met,
        //     material_approved_grid_date: this.props.values.material_approved_grid_date
        // }
        // console.log("Valor do this props", this.props)
        if (!['', null, undefined].includes(this.props.values.material_requisition_logistic_cancel_reason)) {
            axios
            .get(`/api/v1/material-approved/cancel-item/${this.props.values.material_requisition_logistic_id}`)
            .then((res) => {
                if(res.data){
                    Notification.success({
                        title: 'Atualizado com Sucesso!',
                        description: res.data.message
                    })
                    this.props.onChange()
                }
            })
            .catch((err) => {
                Notification.error({
                    title: 'Erro!',
                    description: err.response.data.message
                })
            })
            .finally(() => {
                this.setState({ loading: false });
            });    
        } else {
            Notification.error({
                title: 'Erro!',
                description: 'Atenção! Não foi possível cancelar o item, favor preencher o motivo do cancelamento.'
            })
        }
    }

    render() {
        return (<>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button color="red" size={"lg"} loading={this.state.loading} style={{ marginTop: 20, width: "200px" }} onClick={() => this.isCancelItem()}>
                    <i className="fas fa-window-close"></i> Cancelar Item
                </Button>
            </div>
        </>
        )
    }
}