import React from "react";
import { Button, Notification } from "rsuite";
import { Event } from "../Event";
import axios from "../../utilities/axios";

class ProductFooterComponent extends React.Component<any, {}> {
    public state = {
        loading: false,
    };

    printEtiqueta = () => {
        this.setState({ loading: true });
        axios
            .get("/api/v1/products/etiqueta/" + this.props.id)
            .then((res) => {
                if (res.data && res.data.url) {
                    window.open('http://'+window.location.host.split('25565').join('25567')+res.data.url, "_blank");
                }
            })
            .catch((e) => {
                Notification.error({
                    title: "[" + e.response.status + "] Erro",
                    description: e.response.data.message ? e.response.data.message : e.message,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    validacaoFiscal = () => {
        this.setState({ loading: true });
        axios
            .get("/api/v1/products/validacao-fiscal/" + this.props.id)
            .then((res) => {
                if (res.data && res.data.message) {
                    Notification.success({
                        title: "Sucesso!",
                        description: res.data.message,
                    });
                    if (this.props.onChangeStatus) {
                        if (this.props.status === null) {
                            this.props.onChangeStatus("AGUARDANDO");
                        } else if (this.props.status === "AGUARDANDO") {
                            this.props.onChangeStatus("VALIDADO");
                        }
                    }
                }
            })
            .catch((e) => {
                Notification.error({
                    title: "[" + e.response.status + "] Erro",
                    description: e.response.data.message ? e.response.data.message : e.message,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <div>
                {/* {JSON.stringify(this.props)} */}
                {this.props.status === "AGUARDANDO" && (
                    <Button onClick={this.validacaoFiscal} disabled={!this.props.id} loading={this.state.loading} color={"blue"} className="ml-4">
                        <i className="fas fa-check fa-fw mr-2"></i>
                        Validar fiscalmente
                    </Button>
                )}
                {this.props.status !== "VALIDADO" && this.props.status !== "AGUARDANDO" && (
                    <Button onClick={this.validacaoFiscal} disabled={!this.props.id} loading={this.state.loading} color={"blue"} className="ml-4">
                        <i className="fas fa-exclamation fa-fw mr-2"></i>
                        Requirir validação fiscal
                    </Button>
                )}
                <Button disabled={!this.props.id} onClick={this.printEtiqueta} loading={this.state.loading} color={"violet"} className="ml-4">
                    <i className="fas fa-print fa-fw mr-2"></i>
                    Imprimir etiqueta
                </Button>
            </div>
        );
    }
}

export class ProductFooterEvents extends Event {
    public onRenderEditFooter = () => {
        return (
            <>
            {/* {this.globalState?.formValues?.product_status_validacao} */}
            <ProductFooterComponent
                onChangeStatus={(status: any) => this.setFieldValue("product_status_validacao", status)}
                status={this.globalState?.formValues?.product_status_validacao}
                id={this.globalState?.formValues?.product_id}
            />
            </>
        );
    };

    public onRenderAddFooter = () => {
        return (
            <ProductFooterComponent
                onChangeStatus={(status: any) => this.setFieldValue("product_status_validacao", status)}
                status={this.globalState?.formValues?.product_status_validacao}
                id={this.globalState?.formValues?.product_id}
            />
        );
    }
}
