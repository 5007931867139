import React from "react";


export class HeaderText extends React.Component<any, {}> {
    render() {
        return (<>
            <div>
                <h3>
                    Mestre
                </h3>
            </div>

        </>)
    }
}
