import React from "react";
import * as mask from "inputmask";
import _ from "lodash";
import dayjs from "dayjs";

interface SchemaToParsedCellProps {
    row: any;
    field: any;
}

export class SchemaToParsedCell extends React.Component<SchemaToParsedCellProps, {}> {
    parseMask(value: string | object | number) {
        if (typeof value == "string") {
            if (value.length) {
                return mask.default.format(value, { mask: this.props.field.mask });
            } else {
                return null;
            }
        }
        return value;
    }

    static parseFieldToValue = (field: any, row: any, html: boolean = false) => {
        // Alteração Mateus
        // console.log("Field ::::: ", field);
        // console.log("Row ::::: ", row);

        var value = row[field.name];
        // console.log("field type", field.type)

        // if(value.includes('&NBSP')){
        //     value = value.replace('&NBSP;',"");
        // }
        // console.log("testando value ::::", value);
        var resultadoOutput = null;
        if (field.type === "file") {
            resultadoOutput = "Arquivo";
        } else if (field.type === "checkbox") {
            if (html) {
                resultadoOutput = value ? (
                    <div>
                        <i className="fas fa-fw fa-check text-success"></i> Sim
                    </div>
                ) : (
                    <div>
                        <i className="fas fa-fw fa-times text-danger"></i> Não
                    </div>
                );
            } else {
                resultadoOutput = value ? "Sim" : "Não";
            }
        } else if (field.type === "grid") {
            resultadoOutput = (value ? value.length : 0) + " Itens";
        }
        else if (field.type === "datepicker") {
            resultadoOutput = value?.length > 0 ? dayjs(value).format("DD/MM/YYYY") : "";
        } else if (field.type === "select") {
            //    console.log("assaas aAQUIII",row[field.display],field.type,field.name,field);
            if (field.options && _.isArray(field.options)) {
                if (value) {
                    var foundValue: any = _.first(
                        field.options.filter((item: any) => {
                            if (value !== null && value !== undefined) {
                                return item.value === value.toString();
                            }
                            return undefined;
                        }),
                    );
                    resultadoOutput = (foundValue?.label ?? value)?.toString().split("_").join(" ");
                }
            } else {

                var parsingLabel = field.browseLabel && field.browseLabel.length ? field.browseLabel : field.displayLabel;
                try {
                    //
                    resultadoOutput = parsingLabel.split(".").reduce((o: any, i: any) => o[i], row) ?? "Nenhum";
                } catch (e) {
                    if (value === null || value === undefined) {
                        resultadoOutput = "Nenhum";
                    } else {
                        resultadoOutput = value ?? "#ERR [1] (" + value + ")";
                    }
                }
            }
        } else if (field.type === "display") {
            if (field.display !== undefined) {
                try {
                    resultadoOutput = field.display.split(".").reduce((o: any, i: any) => o[i], row) ?? "#ERR";
                } catch (e: any) {
                    try {
                        if (row[field.display]) {
                            resultadoOutput = (row[field.display] ?? "#ERR").toString();
                        } else {
                            resultadoOutput = field.display;
                        }
                    } catch (e: any) {
                        resultadoOutput = e.message;
                    }
                }
                // if (field?.name !== undefined && typeof field.name == 'string') {
                if (
                    // field?.name?.includes("_created_at") ||
                    typeof resultadoOutput == "string" &&
                    typeof resultadoOutput.includes == "function" &&
                    dayjs(resultadoOutput).isValid() &&
                    resultadoOutput.includes(":") &&
                    resultadoOutput.includes("T") &&
                    (resultadoOutput.includes("Z") || resultadoOutput.includes("-")) &&
                    !resultadoOutput.includes(" ")
                ) {
                    resultadoOutput = dayjs(resultadoOutput).format("DD/MM/YYYY");
                } else {
                    // resultadoOutput = field.display.toString();
                    try {
                        // console.log(row);
                        if (row[field.display] !== null) {
                            if (row.product !== undefined) {
                                resultadoOutput = row.product[field.display].toString();
                            } else if (row.cnae !== undefined) {
                                resultadoOutput = row.cnae[field.display];
                            }
                            else if (row.seller !== undefined) {
                                if (field.name == 'person_seller_person_type_of_seller') {
                                    let valueField = (value: string) => {
                                        if (value == 'R') {
                                            return value = 'REPRESENTANTE'
                                        }
                                        if (value == 'V') {
                                            return value = 'VENDEDOR'
                                        }
                                        if (value == 'K') {
                                            return value = 'KEY ACCOUNT'
                                        }

                                    }
                                    resultadoOutput = row.seller?.tipo ? row.seller['tipo'].toString() : valueField(row.seller[field.display].toString());

                                } else if (field.name == 'person_seller_person_seller_is_extern') {
                                    let valueField = row.seller[field.display].toString() == 'false' ? 'Não' : 'Sim';
                                    resultadoOutput = row.seller?.externo ? row.seller['externo'].toString() : valueField;

                                } else {
                                    resultadoOutput = row.seller[field.display].toString();
                                }
                            }
                            else if (row.status_validacao != undefined) {
                                resultadoOutput = row.status_validacao.toString();
                            }
                            else {

                                resultadoOutput = row[field.display].toString();
                            }
                            //Mateus veloso

                        }
                    } catch (e: any) {
                        resultadoOutput = e.message;
                    }
                }
            }
        } else if (field.type === "richtextbox") {
            value = value?.replace(/<\/?[^>]+(>|$)/g, "");
            // console.log({value})
            if ((value !== null) && (value !== undefined)) {
                value = value.includes('&NBSP') ? value.split('&NBSP;').join("") : value;
            }
            resultadoOutput = value
        }
        // else if (field.type === 'datepicker') {
        //     if (
        //         value.includes(":") &&
        //         value.includes("T") &&
        //         (value.includes("Z") || value.includes("-")) &&
        //         !value.includes(" ")
        //     ) {
        //         resultadoOutput = dayjs(value).format("DD/MM/YYYY");
        //         // console.log("resultadoOutput :>", resultadoOutput)
        //     }
        // }

        //numeric
        else if (field.type === "numeric") {
            if (field.preffix === "R$") {
                var newValue = parseFloat(value?.toString() ?? "0").toLocaleString("pt-BR", { minimumFractionDigits: 2 });
                //alert(newValue)
                resultadoOutput = field.display = field.preffix + ` ` + newValue;
            } else {
                resultadoOutput = value?.toString() ?? "Nenhum";
            }
        } else {
            resultadoOutput = value ?? "Nenhum";
            // if (field?.name !== undefined && typeof field.name == 'string') {
            if (
                // field?.name?.includes("_created_at") ||
                resultadoOutput &&
                typeof resultadoOutput == "string" &&
                typeof resultadoOutput.includes == "function" &&
                dayjs(resultadoOutput).isValid() &&
                resultadoOutput.includes(":") &&
                // field.type !== 'datepicker' &&
                (resultadoOutput.includes("Z") || resultadoOutput.includes("-")) &&
                resultadoOutput.includes("T") &&
                !resultadoOutput.includes(" ")
            ) {
                resultadoOutput = dayjs(resultadoOutput).format("DD/MM/YYYY");
            }
            // }
        }

        if (resultadoOutput == null && (value === null || value === undefined)) {
            resultadoOutput = "Nenhum";
        }
        if (typeof resultadoOutput == "string") {
            //remove html tags
            resultadoOutput = resultadoOutput
                .replace(/<\/?[^>]+(>|$)/g, "")
                .split("R$R$")
                .join("R$");

            if (resultadoOutput.includes("R$ ")) { //cifrao duplicado
                resultadoOutput = resultadoOutput
                    .replace(/<\/?[^>]+(>|$)/g, "")
                    .split("R$")
                    .join("");
            }

        }
        var output = typeof resultadoOutput == "string" || typeof resultadoOutput == "object" ? resultadoOutput : ""; //resultadoOutput?.length >= 0 ? resultadoOutput : "#ERR [2]";
        if (field.preffix) {
            output = field.preffix + output;
        }
        if (field.suffix) {
            output = value ?? "0"; //delcio
            // if (output == "Nenhum" || output == "null" || output == null) {

            //     output = "0";
            // }else{
            //     output = value;
            // }
            output += field.suffix;
        }
        if (field.mask && field.mask?.length > 0 && value && value.length > 0) {
            output = mask.default.format(value, { mask: field.mask });
            // output = "batata"
        }


        function converterData(dataAmericana: string): any {

            if (dataAmericana.includes("-")) {
                const partes: any = dataAmericana?.split('-');

                if (!['', null, undefined].includes(partes)) {

                    const partes = dataAmericana.split('-');
                    const mes = partes[1]
                    const dia = partes[2].substring(0, 2);
                    const ano = partes[0];
                    const dataBrasileira = `${dia}/${mes}/${ano}`;
                    return dataBrasileira;
                }
            } else {
                const partes: any = dataAmericana?.split('/');
                if (!['', null, undefined].includes(partes)) {
                    const mes = partes[2].substring(0, 2);
                    const dia = partes[2].substring(2, 4);
                    const ano = partes[0];
                    const ano2 = partes[1]
                    const dataBrasileira = `${dia}/${mes}/${ano + ano2}`;
                    return dataBrasileira;
                }
            }

        }

        function converterDataHorario(dataAmericana: any) {
            // Extrai partes da data utilizando expressão regular
            const match = dataAmericana.match(/^(\d{4})-(\d{2})-(\d{2})T?(\d{2})?:?(\d{2})?.*$/);

            if (match) {
                const ano = match[1];
                const dia = match[2];
                const mes = match[3];
                const hora = match[4] || '00';  // Define hora padrão como '00' se não for especificado
                const minuto = match[5] || '00';  // Define minuto padrão como '00' se não for especificado

                // Formata a data no padrão brasileiro com hora e minuto
                const dataBrasileira = `${mes}/${dia}/${ano} ${hora}:${minuto}`;
                // console.log("valor do dataBrasileira", dataBrasileira)
                return dataBrasileira;
            } else {
                // Retorna nulo se a data não puder ser parseada
                return null;
            }
        }

        if (field.name === "psm_last_situation_date"
        ) {
            if (!['', null, undefined].includes(value)) {
                const dataCorreta = converterData(value);
                output = dataCorreta
            }
        }

        // if (field.name === "product_box_date_romanian" || field.name === "box_date_romanian" || field.name === "psm_packing_date"
        // ) {
        //     // console.log("valor do value", value)
        //     if (!['', null, undefined].includes(value)) {
        //         const dataCorreta = converterDataHorario(value);
        //         // console.log("dataCorreta", dataCorreta)
        //         output = dataCorreta
        //     }
        // }


        try {
            if (html) {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                if (params.searchAll && typeof output == "string") {
                    const text = params.searchAll.toLocaleUpperCase();
                    const htmlfiedOutput = output.split(text).map((item: any, index: any) => {
                        if (index < output.split(text).length - 1) {
                            return (
                                <>
                                    {item}
                                    <span className="highlight">{text}</span>
                                </>
                            );
                        }
                        return item;
                    });
                    return htmlfiedOutput;
                }
            }

            return output;
        } catch (e: any) {
            return e.message;
        }
    };

    render() {
        return SchemaToParsedCell.parseFieldToValue(this.props.field, this.props.row);
    }
}
