// import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PsmItemDescriptionListSliderSituationEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if(value === true){
            this.setFieldValue ("psm_item_description_list_complement_description","");
            this.setFieldValue ("psm_item_description_list_unit", "");
            this.setFieldValue ("psm_item_description_list_intern_reference", "");
            this.setFieldValue ("psm_item_description_list_fk_products_id", )
           //  this.setFieldValue ("psm_product_registration_code_product", res.data.item.product_manufacturer_code);
            this.setFieldValue ("psm_item_description_list_product_id", "");
        }
             
    }
}