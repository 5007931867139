import { Event } from "../Event";

export class ProductStageEvents extends Event {
    public onChange = (value: string | number | null, setField: any) => {
      //alert(value)  
      if (value) {
            if (value.toString() === "1") {
                setField("product_control_stock", false);
            }
            if (value.toString() === "2") {
                setField("product_control_stock", true);
            }
        }
      
    };
}
