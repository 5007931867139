import React from "react";
import { Table, Button, Toggle } from "rsuite";
import axios from "../../utilities/axios";
import { FilterableCell } from "../customTable/FilterableCell";
import { SchemaToParsedCell } from "../customTable/SchemaToParsedCell";
import _ from "lodash";
import { MaskInput } from "./MaskInput";
import { NumberInput } from "./NumberInput";
import { AxiosSelectPicker } from "./AxiosSelectPicker";
import pluralize from "pluralize";
import { Notification } from "rsuite";
import TablePagination from "rsuite/lib/Table/TablePagination";
const { Column, HeaderCell, Cell } = Table;

export class InlineGrid extends React.PureComponent<any, {}> {
    public state: any = {
        isLoading: true,
        loading: false,
        isModalOpen: false,
        isEdit: null,
        form: {},
        totalWidth: 0,
        hiddenRows: [],
        selectedValues: {},
        searchs: {},
        items: _.cloneDeep(this.props.values) ?? [],
        cachedData: [],
        pagination: false,
        paginationDate: {
            currentPage: 1,
            totalItens: 0,
            totalPages: 0
        },
        page: 1
    };

    public elementRef: any = null;

    componentDidMount(page?: any) {
        const nameParentValues = this.props.globals.prefix;
        const valueParentValues = this.props.parentValues[`${nameParentValues}_id`];
        if (this.props.field?.pagination === true) {
            this.setState({ pagination: true })
        }
        try {
            const cachedData = JSON.parse(localStorage.getItem("grid-cache-" + this.props.name) ?? JSON.stringify([]));
            localStorage.removeItem("grid-cache-" + this.props.name);
            this.setState({ cachedData });
        } catch (e) { }
        this.elementRef = React.createRef();
        var api = this.props.field.api.split("_").join("-");
        axios
            .get(`/api/v1/${this.props.name}/${valueParentValues}/${nameParentValues}/tags-pagination`)
            .then((res) => {
                // if(res.data.status === 200) {
                this.setState({ paginationDate: { ...res.data.pagination,  }, currentPage: 1 })
                // }
            })
        axios
            .get("/api/v1/" + api + "/fields")
            .then((res) => {
                if (res && res.data && res.data.fields) {
                    var fields = res.data.fields;
                    if (this.props.formOverrides) {
                        for (var i in this.props.formOverrides) {
                            fields[i] = { ...fields[i], ...this.props.formOverrides[i] };
                        }
                    }
                    this.setState({ form: fields });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    handleChangePage = async (page?: number) => {
        // console.log("Page", page)
        this.setState({ currentPage: page });
        // var items = this.state.items;
        // this.setState({ loading: true }, () => {
        //     items.push({});
        //     this.setState({ items, loading: false });
        // });
        await this.onSearch(page)

        
        // console.log("valor do page",page)
        
      

    

            // this.setState({ items, loading: false });
        // await axios
        //     .get(`/api/v1/${this.props.name}/${nameParentValues}/${valueParentValues}/${page}/pagination`)
        //     .then((res) => {
        //         if (res.data) {
        //             this.setState({ items: res.data.itens, paginationDate: { ...this.state.paginationDate, currentPage: page } });
        //         }
        //     })
        //     .finally(() => {
        //         this.setState({ isLoading: false })
        //     })
        //     .catch((e) => {
        //         Notification.error({
        //             title: "Error!",
        //             description: 'Erro na Listagem!'
        //         })
        //     })
    };

    // onSearch = (page?: any) => {
    //     // this.setState({ isLoading: true });
    //     const nameParentValues = this.props.globals.prefix;
    //     const valueParentValues = this.props.parentValues[`${nameParentValues}_id`];
    //     axios
    //       .get(`/api/v1/${this.props.name}/${nameParentValues}/${valueParentValues}/${page}/pagination`, {
    //         params: {
    //           searchs: this.state.searchs,
    //         },
    //       })
    //       .then((res) => {
    //         if (res.data) {
    //           console.log("Res do search", res.data);
    //           this.setState({
    //             items: res.data.itens,
    //             paginationDate: {
    //               ...this.state.paginationDate,
    //               currentPage: page,
    //               totalItens: res.data.pagination.totalItens,
    //               totalPages: res.data.pagination.totalPages,
    //             },
    //             isLoading: false, // Remover isLoading para indicar que a operação está concluída
    //           });
    //         }
    //       })
    //       .catch((e) => {
    //         // this.setState({ isLoading: false });
    //         Notification.error({
    //           title: "Error!",
    //           description: "Erro na Listagem!",
    //         });
    //       });
    //   };
 
      
      
      

    onSearch = async (page?:any) => {
        this.setState({ isLoading: true})
        const nameParentValues = this.props.globals.prefix;
        const valueParentValues = this.props.parentValues[`${nameParentValues}_id`];
        await axios
            .get(`/api/v1/${this.props.name}/${nameParentValues}/${valueParentValues}/${page}/pagination`, {
                params: {
                    searchs: this.state.searchs
                }
            })
            .then((res) => {
                if (res.data) {
                    // console.log("Res data do paginatino", res.data)
                    // var items = this.state.items;
                    this.setState({ items: res.data.itens, paginationDate: { ...this.state.paginationDate, currentPage: page, totalItens: res.data.pagination.totalItens, totalPages: res.data.pagination.totalPages } });
                  
                    // console.log("valor do itens", this.state)
                }
            })
            .finally(() => {
                this.setState({ isLoading: false})
                // this.setState({ isLoading: false})
            })
            .catch((e) => {
                Notification.error({
                    title: "Error!",
                    description: 'Erro na Listagem!'
                })
            })
    }

    public cachedApiItems: any = {};

    // onSubmit = async (values: any) => {
    //     let cachedData = this.state.cachedData;
    //     for (var fieldName in values) {
    //         var value = values[fieldName];
    //         var field = this.state.form[fieldName];
    //         if (field) {
    //             if (field.type === "select" && field.hidden !== true && (field.api !== undefined || field.displayLabel !== undefined)) {
    //                 if (field.api && field.api.url) {
    //                     var url = field.api.url;

    //                     try {
    //                         var result = await axios.get(field.api.url.split("/select").join("/" + value), { params: { cache: true } });
    //                         if (result && result.data && result.data.status === 200) {
    //                             cachedData[field.name] = [result.data.item];
    //                             this.setState({cachedData});
    //                             // values[field.displayLabel.split(".").shift()] = result.data.item;
    //                         }
    //                     } catch (e) {
    //                         console.error(e);
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     this.setState(
    //         (oldState: any) => {
    //             var items = [...oldState.items];
    //             if (this.state.isEdit !== null) {
    //                 items[this.state.isEdit] = values;
    //             } else {
    //                 items.push(values);
    //             }
    //             return { items, isModalOpen: false };
    //         },
    //         () => {
    //             if (this.props.onChange) {
    //                 this.props.onChange(this.state.items);
    //             }
    //             if (this.state.isEdit !== null) {
    //                 if (this.props.onEdit) {
    //                     this.props.onEdit(values, values.length);
    //                 }
    //             } else {
    //                 if (this.props.onAdd) {
    //                     this.props.onAdd(values, values.length);
    //                 }
    //             }
    //         },
    //     );
    // };

    componentDidUpdate() {
        // componentDidUpdate(prevProps, prevState) {
        if (this.state.totalWidth === 0 && this.elementRef.current.clientWidth > 0) {
            this.setState({ totalWidth: this.elementRef.current.clientWidth });
        }
        // if (
        //     this.props.paginationPage !== prevProps.paginationPage ||
        //     this.props.data !== prevProps.data
        //   ) {
        //     // Atualize o estado com o novo valor do defaultValue
        //     this.setState({ defaultValue: finalValue });
        //   }
    }

    componentWillUnmount() {
        localStorage.setItem("grid-cache-" + this.props.name, JSON.stringify(this.state.cachedData));
    }

    onExit = (field: string, value: string, operator: string) => {
        this.setState({ isLoading: true });
        var searchs = this.state.searchs;
        if (('').includes(value)) {
            delete searchs[field];
        } else {
            searchs[field] = value;
        }
        this.setState({ searchs: searchs }, () => {
            this.onSearch()
        });
    };

    onChangeInput = (value: any, fieldName: any, index: any, event: any = null) => {
        const items = this.state.items;
        let cachedData = this.state.cachedData;
        var item = items[index];
        if (!item) {
            item = {};
        }
        item[fieldName] = value;
        items[index] = item;
        if (this.state.form[fieldName]) {
            const field = this.state.form[fieldName];
            if (field.type === "select") {
                if (!cachedData[index]) {
                    cachedData[index] = [];
                }
                try {
                    this.setState({ isLoading: true });
                    axios.get(field.api.url, { params: { cache: true, all: true } }).then((result) => {
                        if (result && result.data && result.data.status === 200) {
                            cachedData[index][fieldName] = result.data.items;
                            this.setState({ cachedData, isLoading: false });
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        }
        this.setState({ items });
        if (this.props.onChange) {
            this.props.onChange(items);
        }
    };


    renderField = (field: any, row: any, index: any) => {
        var value = row[field.name] ?? undefined;
        if (value === undefined) {
            if (this.state.cachedData[index] && this.state.cachedData[index][field.name]) {
                value = this.state.cachedData[index][field.name];
            }
        }
        var defaultValue = value;
        switch (field.type) {
            case "checkbox":
                return (
                    <Toggle
                        defaultChecked={undefined}
                        checked={undefined}
                        key={field.name + "_" + index}
                        onChange={(value: boolean) => {
                            this.onChangeInput(value, field.name, index);
                        }}></Toggle>
                );
            case "select":
                var data: any = [];
                var relationName: any = null;
                if (field.name.includes("fk") && defaultValue !== undefined && defaultValue !== null) {
                    relationName = field.name.split("fk_").pop().split("_id").shift();
                    if (row[relationName]) {
                        defaultValue = row[relationName][relationName + "_id"];
                        if (row[relationName] && field.displayLabel) {
                            try {
                                var label = field.displayLabel.split(".").reduce((o: any, i: any) => o[i], row);
                                // console.log ("Valor do label", label)
                                if (label === null) {
                                    label = "NOT FOUND";
                                }
                                data = [{ value: defaultValue.toString(), label }];
                            } catch (e: any) {
                                data = [{ value: -1, label: "Whoops.." + e.message }];
                                throw Error("Não foi possivel encontrar o caminho de " + field.displayLabel);
                            }
                        }
                    }
                }
                var cachedData: any = this.state.cachedData[index] ? (this.state.cachedData[index][field.name] ? this.state.cachedData[index][field.name] : []) : [];
                for (var cachedIndex in cachedData) {
                    cachedData[cachedIndex].value = cachedData[cachedIndex].value.toString();
                }
                var finalValue = value ? value : defaultValue;
                // var finalValue = value ;
                if (finalValue) {
                    finalValue = finalValue.toString();
                }

            
                // {console.log("Valor do this.props ::::>", defaultValue)}
                return (
                    
                    <div style={{ display: "block" }}>
                        <div>
                        {/* {console.log("field.displayLabel ?? undefined",field)} */}
                        {this.state.isLoading == false && (<>
                            <AxiosSelectPicker
                                disabled={field.disabled}
                                defaultValue={value}
                                value={value}
                                isEdit={this.props.isEdit}
                                onChange={(value: string, e: any) => {
                                    this.onChangeInput(value, field.name, index, e);
                                }}
                                key={field.name + "_" + index}
                                // key={`${field.name}_${index}_${this.state.currentPage}`}
                                advanced={field.advancedSearch ?? undefined}
                                searchable={field.searchable ?? undefined}
                                // uses={usesValues}
                                displayLabel={field.displayLabel}
                                // displayLabel={finalValue}
                                api={field.api ? field.api : undefined}
                                options={field.options ? field.options : undefined}
                                placeholder={"Selecione..."}
                                data={[...data]}
                                // data={[...data, ...cachedData]}
                                ></AxiosSelectPicker>
                        </>)}
                        </div>

                    </div>
                );
            case "numeric":
                return (
                    <NumberInput
                        leftPadding={field.leftPadding}
                        rightPadding={field.rightPadding}
                        preffix={field.preffix}
                        suffix={field.suffix}
                        punctuation={field.punctuation}
                        step={field.step}
                        value={value}
                        onChange={(value: string, e: any) => this.onChangeInput(value, field.name, index, e)}
                    // onChange={() => {}}
                    />
                );

            case "text":
                return (
                    <MaskInput
                        key={field.name + "_" + index}
                        allowLowercase={field.allowLowercase}
                        disabled={field.readonly || field.disabled}
                        isEdit={true}
                        password={field.password ? field.password.toString() : "false"}
                        password_character={field.password_character}
                        // value={value ? _.toUpper(String(value)) : '-'} 
                        value={value ? _.toUpper(String(value)) : ''} 
                        name={field.name}
                        maxLength={field.maxlength ?? undefined}
                        minLength={field.minlength ?? undefined}
                        onChangeEnd={(value: string, e: any) => {
                            this.onChangeInput(value, field.name, index, e);
                        }}
                        required={field.password === true && this.props.isEdit ? false : field.required}
                        regex={field.regex}
                        mask={field.mask}></MaskInput>
                );
            default:
                return <div>{field.type}</div>;
        }
    };

    renderColumns = () => {
        var output = [];
        var fields = Object.keys(this.state.form);
        for (var i in this.state.form) {
            let field = this.state.form[i];
            if (field?.prefix) {
                continue;
            }
            let width = _.clamp((this.state.totalWidth - 130) / (fields.length - 1), field.name?.length * 9.6, this.state.totalWidth);
            if (i !== "$GLOBALS" && field.canEdit !== false && field.type !== 'display') {
                output.push(
                    <Column resizable key={i} width={width}>
                        <HeaderCell className="filterable">
                            <FilterableCell
                                field={field} hasAdvanced={false}
                                onExit={(value: any, operator: any) => this.onExit(field.name, value, operator)}
                                operator={field.type === "numeric" ? "eq" : "like"}
                            // onExit={(value: string, operator: string) => this.onExitSearch(browse.name, value, operator)}

                            >
                                <div dangerouslySetInnerHTML={{ __html: field.label ?? field.name }}></div>
                            </FilterableCell>
                        </HeaderCell>
                        <Cell>
                            {(row: any, index: any) => {
                                return this.renderField(field, row, index);
                            }}
                        </Cell>
                    </Column>,
                );
            }
        }
        return output;
    };

    filterData = (searchs: any) => {
        var output = [];

        var data = this.state.items;
        for (var i in data) {
            var row = data[i];
            if (this.state.hiddenRows.includes(i)) {
                continue;
            }
            var includes = true;
            for (var fieldName in this.state.form) {
                var field = this.state.form[fieldName];
                if (field !== undefined) {
                    var parsedValue = SchemaToParsedCell.parseFieldToValue(field, row);
                    if (searchs[fieldName]) {
                        if (!parsedValue.includes(searchs[fieldName])) {
                            includes = false;
                            break;
                        }
                    }
                }
            }
            if (includes) {
                output.push(data[i]);
            }
        }
        return output;
    };

    onClickDelete = (rowIndex: any, rowData: any) => {
        this.setState({ loading: true });
        axios.post("/api/v1/perm-delete", { id: rowData[pluralize.singular(this.props?.field?.name) + "_id"], currentTable: this.props?.field?.name }).then((res) => {
            Notification.error({
                title: "Delete",
                description: "Item Deletado"
            })
        })
        var items = this.state.items;
        items.splice(rowIndex, 1);
        const cachedData = this.state.cachedData ?? [];
        if (_.isArray(cachedData)) {
            cachedData.splice(rowIndex, 1);
        }
        this.setState({ cachedData }, () => {
            if (this.props.onChange) {
                this.props.onChange(items);
            }
            if (this.props.onDelete) {
                this.props.onDelete(items);
            }
            this.setState({ items, loading: false });
        });
    };

    renderActions = (rowData: any, rowIndex: any) => {

        return (
            <div>

                {this.props.readOnly !== true && <i onClick={() => this.onClickDelete(rowIndex, rowData)} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>}

            </div>
        );
    };

    addRow = () => {
        var items = this.state.items;
        this.setState({ loading: true }, () => {
            items.push({});
            this.setState({ items, loading: false });
        });
    };


    render() {
        var $GLOBALS = this.state.form?.$GLOBALS;
        return (
            <div style={{ display: "flex", flexDirection: "column" }} ref={this.elementRef}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h3>{$GLOBALS?.plural_name ?? this.props.label ?? ""}</h3>
                    {this.props.canAdd === true && this.props.readOnly !== true && (
                        <Button color="green" onClick={this.addRow}>
                            <i className="fas fa-fw fa-plus mr-2"></i>
                            Adicionar
                        </Button>
                    )}
                </div>
                {this.state.loading === false && (<>
                    <Table
                        className="inline"
                        renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                        loading={this.state.isLoading}
                        rowHeight={38}
                        virtualized
                        height={400}
                        data={this.state.items}>
                        {this.renderColumns()}
                        <Column fixed={"right"} width={100}>
                            <HeaderCell>Ações</HeaderCell>
                            <Cell className="link-group">
                                {(rowData: any, rowIndex: any) => {
                                    return this.renderActions(rowData, rowIndex);
                                }}
                            </Cell>
                        </Column>
                    </Table>
                    {this.state.pagination === true && (
                        <TablePagination
                            lengthMenu={[
                                {
                                    value: 10,
                                    label: 10
                                }
                            ]}
                            activePage={this.state.paginationDate.currentPage}
                            displayLength={this.state.paginationDate.totalPages}
                            total={this.state.paginationDate.totalItens}
                            onChangePage={this.handleChangePage}
                            pages={this.state.paginationDate.totalPages}
                            // onClick={this.addRow}
                        // onChangeLength={this.handleChangeLength} 
                        />
                    )}
                </>
                )}
                {/* {JSON.stringify(this.state.items,null,2)} */}
                {/* {this.props.readOnly ? 'S': 'N'} */}
                {/* {JSON.stringify(this.state.searchs)} */}
                {/* {JSON.stringify(this.state.items)} */}
            </div>
        );
    }
}
