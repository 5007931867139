import React from "react";
import { Button , Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { CustomModal } from "../../customModal/CustomModal";


export class ImportOptional extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
    }

    componentDidMount = async() =>{
   
    }

    isImportOptional = () => {
        this.setState({loading: true});
        // console.log(this.props)
        let idBox = this.props.values.box_id;
        axios
            .get(`api/v1/boxes/import-optional/${idBox}`)
            .then((res) => {
                console.log(res);
                if(res.data.status == 200){
                    Notification.success({
                        title: "Sucesso!",
                        description: 'Seus opicionais foram importados com sucesso',
                        duration: 6500,
                    });
                    this.setState({loading: false});
                    
                }
                if(res.data.status == 403){
                    Notification.error({
                        title: 'Error',
                        description: res.data.error,
                        duration: 75000,
                    })
                }
            })
            .finally(() => {
                this.props.history.go(0);
            })
    }

    render() {
        return (<>
                <Button color="violet" size={"lg"} loading={this.state.loading} style={{marginTop: 5, width: "200px"}} onClick={() => this.isImportOptional()}>
                    <i className="fas fa-file-import"></i> Importar Opcionais
                </Button>
        </>
        )
    }
}