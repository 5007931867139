import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import ModulesPage from "../ModulesPage";
import DefaultDashboardPage from "../DefaultDashboardPage";

export default function DashboardRoutes() {
    let { path } = useRouteMatch();
    // let { module }: any = useParams();
    let location = useLocation();

    return (
        <div>
            {/* <TransitionGroup>
             */}
             {/* <TransitionGroup>
            <CSSTransition key={location.key} classNames="anim-fade" timeout={200} unmountOnExit> */}
                <Switch location={location}>
                    <Route exact path={path} component={() => <ModulesPage />}></Route>
                    <Route path={`${path}/:module`}>                        
                        <DefaultDashboardPage />
                    </Route>
                </Switch>
            {/* </CSSTransition>
            </TransitionGroup> */}
            {/* </CSSTransition>
            </TransitionGroup> */}
        </div>
    );
}
