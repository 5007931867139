import { isNull, toLower } from "lodash";
import React from "react";
import { Button, Checkbox, Icon, IconButton, Input, Notification, Panel, Table } from "rsuite";
import axios from "../../../utilities/axios";
import TablePagination from "rsuite/lib/Table/TablePagination";

const { Column, HeaderCell, Cell } = Table;
const rowKey = 'material_requisition_id';

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }: any) => (
    <Cell {...props}>
        <IconButton
            size="xs"
            appearance="subtle"
            onClick={() => {
                onChange(rowData);
            }}
            icon={
                <Icon
                    icon={
                        expandedRowKeys.some((key: any) => key === rowData[rowKey])
                            ? 'minus-square-o'
                            : 'plus-square-o'
                    }
                />
            }
        />
    </Cell>
)
export class AllocatedPackageProduct extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        isLoading: false,
        value: null,
        isModalSelect: false,
        data: [],
        dataChecked: [],
        checkedKeys: [],
        dataFormPsms: [],
        oldData: [],
        expandedRowKeys: [],
        paginationDate: {
            currentPage: 1,
            totalItens: 0,
            totalPages: 0
        },
        textFilter: ''
    }

    componentDidMount = async () => {

    }

    onChange = (_item: any, _valuesOmie: any) => {
        this.props.onChange()
    };


    getValuesPsms = async () => {
        // for (var i in this.props.values) {
        //     var dados = this.props.values[i];
        //     // console.log("Dados  ::::: ",i)
        //     if (i == 'box_inclusions') {
        //         this.setState({
        //             checkedKeys: dados
        //         })
        //     }
        // }
        console.log("Valor do this props no Atribuir produto", this.props)
        this.setState({ loading: true })
        await axios
            .get("/api/v1/product-box/list/" + this.props.guid + "/" + 1)
            .then((res) => {
                if (res.data.status == 200) {
                    this.setState({ data: res.data.response.itens, isModalSelect: true, paginationDate: { ...res.data.response.pagination } })
                    console.log("Valor do data response", res.data.response.itens)
                    console.log("Valor do data response 2", res.data.response)
                } else {
                    Notification.error({
                        title: "error!",
                        description: 'Não foi possivel listar Psms',
                        duration: 6500,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
            .catch((_e) => {
                Notification.error({
                    title: "Error!",
                    description: `Sem RM's ou Produtos para vincular!`
                })
            })

    }

    isCheckedItem = (value: any = null, checked: any = null) => {
        const checkedKeys = this.state.checkedKeys;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);
        // console.log({nextCheckedKeys});
        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    isSelectPsms = async () => {
        const keysCheckds: Array<Record<string, any>> = this.state.checkedKeys;

        let numberOs: number | null = null;
        let valid: boolean = true;
        
        
        for (const i in keysCheckds) {

            if(this.props.values.product_box_inclusions){
                // console.log("Entrei dentro do if 12122")
                if(this.props.values.product_box_inclusions[i] !== null || this.props.values.product_box_inclusions[i] !== "" ){
                    if (this.props.values.product_box_inclusions[i] !== keysCheckds[i].material_requisition_number_psm_os_number) {
    
                        valid = false;
                        Notification.error({
                            title: "Error!",
                            description: `PSM's divergentes para abertura!`  
                        })
    
                        return;
    
                    }
                }
            }
            
            if (numberOs !== null) {
                if (numberOs !== keysCheckds[i].material_requisition_number_psm_os_number) {
                    valid = false;
                    Notification.error({
                        title: "Error!",
                        description: `PSM's divergentes para abertura!`
                    })
                    return;
                }
            }
            numberOs = keysCheckds[i].material_requisition_number_psm_os_number;
        }
        if (!valid) {
            return;
        }
        this.props.onChange(this.state.checkedKeys);
        // await axios
        //     .post("/api/v1/packing/validate-add", { data: this.state.checkedKeys, guid: this.props.guid })
        //     .then((res) => {
        //         if (res.data.validate == false) {
        //             Notification.error({
        //                 title: res.data.message,
        //                 duration: 4000,
        //             })
        //             return
        //         }
        //         this.setState({
        //             // isModalSelect: false,
        //             checkedKeys: []
        //         })
        //         this.props.onChange(res.data.data);

        //     }).catch((e) => {
        //         Notification.error({
        //             description: e,
        //             duration: 4000,
        //         })
        //     })
    }

    changeFilter = async () => {

        // console.log("Entrei Aqui ::: Rafael ==> ", this.state.textFilter) //Passa o Valor que foi digitado no input

        await axios
            .get("/api/v1/product-box/list/" + this.props.guid + "/" + 1, {
                params: {
                    search: this.state.textFilter.trim()
                }
            })
            .then((res) => {
                // console.log("Resposta de API ::: Rafael ==> ",res.data.response);
                if (res.data.status == 200) {
                    // console.log('Resposta da API 2 ::: Rafael ==> ', res.data)
                    this.setState({ data: res.data.response.itens, isModalSelect: true, paginationDate: { ...res.data.response.pagination } })
                } else {
                    Notification.error({
                        title: "error!",
                        description: 'Não foi possivel listar Psms',
                        duration: 6500,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
            .catch((e) => {
                Notification.error({
                    title: "Error!",
                    description: 'Erro na Listagem!'
                })
            })
        // let data = this.state.data
        // let filtered: any = []
        // if (stringFilter.length > 0) {
        //     for (var i in data) {
        //         let item = data[i]
        //         let key = Object.keys(item)
        //         let value = Object.values(item)

        //         let contain = false
        //         for (var k in item) {
        //             var res: any = item[k]
        //             if (toLower(res).includes(toLower(stringFilter))) {
        //                 contain = true;
        //             }
        //         }
        //         if (contain) {
        //             filtered.push(item)
        //             continue;
        //         }
        //     }
        //     this.setState({
        //         data: filtered,
        //         oldData: data
        //     })
        // } else {
        //     this.getValuesPsms()
        //     this.setState({ data: this.state.oldData ?? [] })
        // }

    }
    handleChangePage = async (page: number) => {
        this.setState({ loading: true })
        await axios
            .get("/api/v1/product-box/list/" + this.props.guid + "/" + page)
            .then((res) => {
                // console.log(res.data.response);
                if (res.data.status == 200) {
                    // console.log(res.data);
                    this.setState({ data: res.data.response.itens, isModalSelect: true, paginationDate: { ...res.data.response.pagination } })
                } else {
                    Notification.error({
                        title: "error!",
                        description: 'Não foi possivel listar Psms',
                        duration: 6500,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
            .catch((e) => {
                Notification.error({
                    title: "Error!",
                    description: 'Erro na Listagem!'
                })
            })

    };
    // handleChangeLength: ((size: number) => void) | undefined;


    constructor(props: any) {
        super(props);
        // this.state = {
        //   data: [],
        //   expandedRowKeys: []
        // };
        this.handleExpanded = this.handleExpanded.bind(this);
    }

    handleExpanded(rowData: any, _dataKey: any) {
        const { expandedRowKeys } = this.state;

        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }
        this.setState({
            expandedRowKeys: nextExpandedRowKeys
        });
    }

    isCheckedProduct = (_product: Record<string, any>) => {

    }

    isRenderContentExpandle(rowDataProducts: Array<Record<string, any>>, _rowData: Array<Record<string, any>>) {
        const output: Array<any> = [];

        for (let product in rowDataProducts) {
            // console.log(rowDataProducts[product]);
            const itemProduct = rowDataProducts[product]
            for(let item in rowDataProducts[product].items){
                const itemLine = rowDataProducts[product].items[item]
                output.push(
                    <tr>
                        <td>
                            <Checkbox
                                value={itemLine}
                                onChange={this.isCheckedItem}
                                checked={this.state.checkedKeys.some(item => item === itemLine)}
                            ></Checkbox>
                        </td>
                        <td>{itemProduct['material_requisition_logistic_item_description']}</td>
                        <td>{itemProduct['material_requisition_logistic_intern_reference']}</td>
                        <td>{itemLine['material_approved_grid_quantity_met']}</td>
                        <td>{itemLine['material_approved_grid_series']}</td>
                    </tr>
                )
            }

            
        }
        return output;
    }

    render() {
        const { expandedRowKeys, data } = this.state;
        return (<>
            {/* {this.renderModalAllocated()} */}
            {/* <Modal size="lg" show={this.state.isModalSelect} onHide={() => this.setState({ isModalSelect: false })}>
                <Modal.Header>
                    <h4>Selecione quais Psm's deseja atribuir a essa embalagem</h4>
                </Modal.Header>
                <ModalBody> */}
            <Button color="violet" size={"lg"} loading={this.state.loading} style={{ marginTop: 15, width: "200px", justifyContent: 'space-around' }} onClick={() => this.getValuesPsms()}>
                <i className="fas fa-folder-tree"></i> Selecionar Produtos
            </Button>
            {/* {JSON.stringify(this.state.checkedKeys)}

            {JSON.stringify(this.state.dataChecked)} */}
            {this.state.isModalSelect && (
                <div style={{ height: "auto" }} >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Input onChange={(value) => this.setState({ textFilter: value })} style={{ margin: 12 }} placeholder="Filtrar..." ></Input>
                        <Button onClick={() => this.changeFilter()}>Pesquisar</Button>
                    </div>
                    <Panel bordered style={{ padding: 8 }} >
                        <Table
                            // fillHeight
                            // fillWidth
                            // autoHeight
                            // wordWrap
                            loading={this.state.loading}
                            height={600}
                            // width={1000}
                            autoWidth
                            rowKey={rowKey}
                            expandedRowKeys={this.state.expandedRowKeys}
                            data={this.state.data}
                            onRowClick={data => {
                                // console.log(data);
                            }}
                            rowExpandedHeight={700}
                            renderRowExpanded={rowData => {
                                return (
                                    <div>
                                        <table className="table-custom-standart">
                                            <tr>
                                                <th> # </th>
                                                <th> Descrição do Item </th>
                                                <th> Referencia Interna  </th>
                                                <th> Quantidade solicitada </th>
                                                <th> Série  </th>
                                            </tr>
                                            {this.isRenderContentExpandle(rowData.products, rowData)}
                                        </table>
                                    </div>

                                )
                            }
                            }
                            renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                        >
                            <Column width={70} align="center">
                                <HeaderCell>#</HeaderCell>
                                <ExpandCell
                                    dataKey="material_requisition_id"
                                    expandedRowKeys={expandedRowKeys}
                                    onChange={this.handleExpanded}
                                />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>N° da RM</HeaderCell>
                                <Cell dataKey="material_requisition_os_number" />
                            </Column>
                            <Column width={120} resizable>
                                <HeaderCell>Solicitante</HeaderCell>
                                <Cell dataKey="material_requisition_request_user" />
                            </Column>
                            <Column width={140} resizable>
                                <HeaderCell>PSM atrelada</HeaderCell>
                                <Cell dataKey="material_requisition_number_psm_os_number" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Urgente</HeaderCell>
                                <Cell dataKey="material_requisition_urgent_requisition" />
                            </Column>
                            <Column width={200} resizable>
                                <HeaderCell>Destino do Produto</HeaderCell>
                                <Cell dataKey="material_requisition_fk_product_destination_id_requisition" />
                            </Column>
                            <Column width={200} resizable>
                                <HeaderCell>Situação da RM</HeaderCell>
                                <Cell dataKey="material_requisition_fk_material_requisition_situation_id_requisition" />
                            </Column>
                        </Table>
                        <TablePagination
                            lengthMenu={[
                                {
                                    value: 10,
                                    label: 10
                                }
                            ]}
                            activePage={this.state.paginationDate.currentPage}
                            displayLength={this.state.paginationDate.totalPages}
                            total={this.state.paginationDate.totalItens}
                            onChangePage={this.handleChangePage}
                            // onChangeLength={this.handleChangeLength} 
                        />
                        <Button disabled={this.props.loading} onClick={() => this.isSelectPsms()} color={"green"}>
                            Concluir
                        </Button>
                    </Panel>
                </div>
            )}
        </>)
    }
}