
import { Event } from "../Event";
import { Notification } from "rsuite";
// import toastify from "toas"
const calculaValorTotal = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0) => {
    var valorTotal = inicial;
    var targetDescontoPercentage = 0;
    var targetDescontoValue = 0;
    if (descontoPercentage > 0) {
        valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
        targetDescontoPercentage = descontoPercentage;
        targetDescontoValue = inicial - valorTotal;
    } else if (descontoValue > 0) {
        valorTotal = valorTotal - descontoValue;
        targetDescontoValue = descontoValue;
        targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
    }
    // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;

    return {
        valorTotal,
        percentual: targetDescontoPercentage,
        desconto: targetDescontoValue,
    };
};

// window.calculaValorTotal = calculaValorTotal;

export class PsmProductRegistrationValueUnitiEvents extends Event {
    public onBlur = (value: any) => {
        // console.log("testando o value :::::", value)
        var porcentagem = parseFloat(this.globalState.formValues.psm_product_registration_discount_percent ?? "0");
        var inicial = parseFloat(value ?? "0");
        var desconto = parseFloat(this.globalState.formValues.psm_product_registration_discount ?? "0");
        var resultado = calculaValorTotal(inicial, porcentagem, desconto);
        if (resultado.valorTotal > 0) {
            this.setFieldValue("psm_product_registration_value_all", resultado.valorTotal);
            this.setFieldValue("psm_product_registration_discount", resultado.desconto);
            // this.setFieldValue("psm_discount_percentage", value.toFixed(2));
            // this.setFieldValue("psm_value", value.toFixed(2));
        } else {
            Notification.error({
                title: "Percentual de desconto inválido",
            });
            this.setFieldValue("psm_product_registration_discount_percent", 0);
        }
        // this.setFieldValue("psm_final_value", value);
    };
}

export class PsmProductRegistrationDiscountPercentEvents extends Event {
    public onBlur = (value: any) => {
        var porcentagem = parseFloat(value);
        var inicial = parseFloat(this.globalState.formValues.psm_product_registration_value_uniti ?? "0");
        var desconto = parseFloat(this.globalState.formValues.psm_product_registration_discount ?? "0");
        var resultado = calculaValorTotal(inicial, porcentagem, 0);
        if (resultado.valorTotal > 0) {
            this.setFieldValue("psm_product_registration_value_all", resultado.valorTotal);
            this.setFieldValue("psm_product_registration_discount", resultado.desconto);
            // this.setFieldValue("psm_discount_percentage", value.toFixed(2));
        } else {
            Notification.error({
                title: "Percentual de desconto inválido",
            });
            this.setFieldValue("psm_product_registration_discount_percent", 0);
            this.setFieldValue("psm_product_registration_discount", 0);
            this.setFieldValue("psm_product_registration_value_all", this.globalState.formValues.psm_product_registration_value_uniti);
        }
    };
}

export class PsmProductRegistrationDiscountEvents extends Event {
    public onBlur = (value: any) => {
        var desconto = parseFloat(value);
        var inicial = parseFloat(this.globalState.formValues.psm_product_registration_value_uniti ?? "0");
        // var desconto = parseFloat(this.globalState.formValues.psm_discount ?? '0');
        var resultado = calculaValorTotal(inicial, 0, desconto);
        // console.log("DESCONTO REAL",resultado);
        if (resultado.valorTotal > 0) {
            this.setFieldValue("psm_product_registration_value_all", resultado.valorTotal);
            this.setFieldValue("psm_product_registration_discount_percent", resultado.percentual);
            // this.setFieldValue("psm_discount", value.toFixed(2));
        } else {
            Notification.error({
                title: "Valor do desconto inválido",
            });
            this.setFieldValue("psm_product_registration_discount_percent", 0);
            this.setFieldValue("psm_product_registration_discount", 0);
            this.setFieldValue("psm_product_registration_value_all", this.globalState.formValues.psm_product_registration_value_uniti);
        }
    };
}

