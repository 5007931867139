import { Event } from "../Event";


export class PsmFkCommercialSituationIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) =>  {
        console.log("entrei aqui ::>", value)
        if(this.globalProps.location.pathname.includes('commercials')){
            await this.setState("loadings", [
                "psm_reason"
            ]);

            if(value == 5){
                this.setField('psm_reason',{
                    required: true,
                    readonly: false
                })
            }
            if(value !== 5){
                this.setFieldValue('psm_reason',);
            }
            if(value == null){
                this.setField('psm_reason',{
                    required: false
                })
            }
            this.setState("loadings", []);
        }
    }
}