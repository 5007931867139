import React from "react";
import { Loader, Tooltip, Whisper, Button, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import _ from "lodash";
import { CustomModal } from "../../customModal/CustomModal"
import { NfOmieModal } from "../../customModal/NfOmieModal";


export class NfIncorporateItem extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data: [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        isImportModalOmie: false,
        omieData: [],
        OmieItens: [],
        infosUnity: [],
        confirmCancelModal: false,
        omieSelectitem: [],
        omieDataSelectDelete: [],
        propsPsm: null,
        selectCompanie: [],
        companyData: []
    }

    componentDidMount() {
        axios.get('/api/v1/companies/select?cache=false&search=&concat=companies.company_fantasy').then((res) => {
            this.setState({ companyData: res.data.items })
        })

    }

    public select: any = {};


    onChange = (item: any, valuesOmie: any) => {
        let isImportNf = { isImport: true }
        var value = _.merge(item, { dadosXml: valuesOmie }, isImportNf)
        this.setState({ OmieItens: [] })

        axios
            .get("/api/v1/receipts/check-client/" + valuesOmie[1].CNPJ)
            .then((res) => {
                if (res.data.response.length >= 1) {
                    // console.log("Transportadora ::::: ",valuesOmie[1].cnpjTransp )
                    if (valuesOmie[1].cnpjTransp == null) {
                        if (this.props.values.psm_fk_business_unity_id !== undefined) {
                            Notification.error({
                                title: "error!",
                                description: "Não existe Transportadora no XML, por favor incluir uma Transportadora",
                                duration: 5600,
                            });
                        }
                        this.props.onChange(value);
                        this.setState({ isImportModalOmie: false });
                    } else if (valuesOmie[1].cnpjTransp !== null) {
                        // console.log(`Entrou aqui ${valuesOmie[1].cnpjTransp}`)
                        axios
                            .get("/api/v1/receipts/check-transp/" + valuesOmie[1].cnpjTransp)
                            .then((res) => {
                                var dadosTransp = res.data.response == null ? null : res.data.response;
                                // console.log(dadosTransp)
                                if (dadosTransp == null) {
                                    if (this.props.values.psm_fk_business_unity_id !== undefined) {

                                        Notification.error({
                                            title: "error!",
                                            description: `Transportadora com CNPJ ${valuesOmie[1].cnpjTransp}, não está cadastrado, por favor realizar o cadastro.`,
                                            duration: 5600,
                                        });
                                    }
                                    this.setState({ isImportModalOmie: false });
                                }
                                this.props.onChange(value);
                                this.setState({ isImportModalOmie: false });
                            })
                    }
                } else if (res.data.response.length == 0) {
                    if (this.props.values.psm_fk_business_unity_id !== undefined) {
                        // console.log("04");
                        Notification.error({
                            title: "error!",
                            description: `Cliente com CNPJ ${valuesOmie[1].CNPJ}, não está cadastrado, por favor realizar o cadastro.`,
                            duration: 4300,
                        });
                    }
                    this.setState({ isImportModalOmie: false });
                    return

                }
            })
        this.setState({ value: value });


        // this.setState({  })
    };



    searchNotes = (data: [] = []) => {
        this.setState({
            loading: true
        })
        // console.log('seacrNotes ::: ', data)
        axios
            .post('/api/v1/omie/filters', {
                data: data
            })
            .then((res) => {
                if (res.data.status === 201) {
                    Notification.error({
                        title: "Erro Omie!",
                        description: res.data.message,
                        duration: 4500,
                    })
                    this.setState({
                        omieData: [],

                    })
                    return;
                }
                this.setState({
                    omieData: res.data.OmieResponse,
                })
            })
            .catch((err) => { console.log(err) })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    }

    isImportOmieData = () => {
        this.setState({
            omieData: [],
            OmieItens: []
        })
        let psmProps = this.props.values;
        if (psmProps.psm_manual_note == true) {
            Notification.error({
                title: "Verificar entrada manual",
                description: "Não é possivel importar a nota com a entrada manual selecionada!",
                duration: 4500,
            });
            return
        }
        if (psmProps.psm_fk_business_unity_id == undefined) {
            Notification.error({
                title: "Verificar BU",
                description: "Para selecionar uma NF, favor preencher o campo BU!",
                duration: 4500,
            });
            return
        }
        this.setState({ isImportModalOmie: true, loading: true });
        var dados: any = [];
        axios
            .get("/api/v1/omie/listar-notas")
            .then((res => {
                if (res.data.status === 201) {
                    Notification.error({
                        title: res.data.message,
                        duration: 4500,
                    })
                    this.setState({
                        omieData: [],

                    })
                    return;
                }
                // console.log('response ::::: ',res)
                this.setState({
                    omieData: res.data.OmieResponse,

                })

            }))
            .finally(() => {
                this.setState({
                    loading: false,
                    LoadingModal: false
                });
            });
    }

    onClickNfe = (nfe: any = "1") => {
        this.setState({ loading: true })
        axios
            .post(`/api/v1/omie/listar-recebimento`, {
                data: {
                    idNfe: nfe.chaveNfe,
                    company_app_key: nfe.company_app_key,
                    company_app_secret_key: nfe.company_app_secret_key,
                    dateIni: nfe.dateStart,
                    endDate: nfe.dateEnd
                }
            })
            .then((res => {
                // console.log("Response data ::::",res.data)
                const data = res.data.OmieResponse
                data.push(nfe)
                if (this.props.values.psm_id){
                    data.push(this.props.values.psm_id)
                }
                this.setState({
                    OmieItens: data
                })
            }))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    isMododelCancel = (item: any, values: any) => {
        this.setState({
            loading: true,
            confirmCancelModal: true,
            omieSelectitem: item,
            omieDataSelectDelete: values,
        })
    }

    isCancelItem = () => {
        axios
            .post("/api/v1/omie/insert-cancelItem", { item: this.state.omieSelectitem, data: this.state.omieDataSelectDelete })
            .then((res) => {
                if (res.data.response == true) {
                    Notification.success({
                        title: "Anulado!",
                        description: "Item Anulado",
                        duration: 1500,
                    });
                    this.isImportOmieData();
                    this.setState({
                        confirmCancelModal: false,
                        isImportModalOmie: false
                    })
                } else if (res.data.response == false) {
                    Notification.error({
                        title: "Item já anulado!",
                        description: "Esse item já foi anulado",
                        duration: 1500,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    closeModalOmie = () => {
        this.setState({
            isImportModalOmie: false
        })
    }

    closeModalCancel = () => {
        this.setState({
            confirmCancelModal: false,
            loading: false
        })
    }


    render() {
        const data = this.state.data;
        const valueProps = this.props.value;
        //console.log("Olha o teste",this.state.valueProps)

        const renderMenu = (menu: any) => {
            if (data.length === 0) {
                return (
                    <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                        <Loader spin />
                    </p>
                );
            }
            return menu;
        };

        return (<>
            <CustomModal
                show={this.state.confirmCancelModal}
                tableOmie={false}
                closeModal={this.closeModalCancel}
                title={"Deseja realmente anular esse item da nota?"}
                onCancel={() => {
                    this.setState({
                        confirmCancelModal: false,
                        loading: false
                    })
                }}
                body={<>
                    Após anular esse item não será mais possivel recuperar novamente.
                </>}
                onConfirm={() => this.isCancelItem()}

            />
            <NfOmieModal
                show={this.state.isImportModalOmie}
                loading={this.state.loading}
                closeModal={this.closeModalOmie}
                onCancel={() => {
                    this.setState({
                        isImportModalOmie: false,
                    })
                }}
                // body={<>
                //     <Input size="md" style={{marginBottom: 20}} placeholder={this.state.situacaoModal} disabled={true}/>
                // </>}
                companyData={this.state.companyData}
                widthModal={"rs-modal-full"}
                omieData={this.state.omieData}
                confirmCancel={() => { this.setState({ confirmCancelModal: true }) }}
                OmieItens={this.state.OmieItens}
                tableOmie={true}
                onChange={this.onChange}
                searchNotes={this.searchNotes}
                // selectCompanie={this.state.companyData}
                isCancelItem={this.isMododelCancel}
                cancelColor={"red"}
                showConfirm={false}
                cancelText={"Fechar"}
                onCloseButton={true}
                title={"Importação do Omie"}
                subTitle={"Propostas em aberto"}
                onClickNfe={this.onClickNfe}
            />
            <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Adicionar via Omie</Tooltip>}>
                <Button color="violet" className="mr-2" style={{ marginTop: 25 }} onClick={() => this.isImportOmieData()}>
                    <i className="fas fa-play-circle"></i>
                </Button>
            </Whisper>
        </>

        )
    }
}
