import axios from "../../../utilities/axios";
import React from "react";
import {  Button } from "rsuite";
import {ObservationClient} from "../../customModal/ObservationClient";

export class ObservationsCommercialClient extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        showSituation: false,
        personValues: "",
        loadedselect: ""
    };

    observationModal = () => {
        // console.log(this.props)
        var id = this.props.values.psm_clients_fk_person_id;
     //   console.log(this.props);
        this.setState({ showSituation: true, loading: true })
        axios
            .get("/api/v1/person/" + id)
            .then((res => {
                if(res.status === 200) {
                   // console.log("Meu response", res)
                   this.setState({
                       personValues: res.data.item
                   })

                    this.SearchPrincipalSelect(res.data.item['person_shipping_fk_person_id']);
                    
                  //  console.log("Testando state", this.state.personValues)
                }
            }))  
            .finally(() => {
                this.setState({ loading: false });
            }); 
            
    }
    SearchPrincipalSelect(id : any){      
        axios.
        get("/api/v1/people/select?cache=false&search=&concat=person.person_fantasy&id="+id)
        .then((res)=>{
            if(res.data.items[0]){
                this.setState({loadedselect: res.data.items[0]})
            }else{
                this.setState({loadedselect: {label:""}})
            }
        })
    }

    render() {
        return (<>
            <ObservationClient 
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showSituation: false,
                            personValues: "",
                            loadedselect: ""
                        })
                    }}
                    // body={<>
                    //     <Input size="md" style={{marginBottom: 20}} placeholder={this.state.situacaoModal} disabled={true}/>
                    //     <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                    //     <Button loading={this.state.loading} color='blue' style={{marginLeft:20}} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                    // </>}
                    cancelColor={"red"}
                    showConfirm={false}
                    cancelText={"Fechar"}
                    onCloseButton={true}
                    content={""}
                    returnText={"Voltar Situação"}
                    advancedText={"Avançar Situação"}
                    title={"Dados para Visualização"}
                    dataModal={this.state.personValues}
                    show={this.state.showSituation}
                    loadedselect={this.state.loadedselect}
                    //this.state.showSituation 

                />
                {/* {JSON.stringify(this.props.values?.psm_product_registration_fk_select_id)}
                {JSON.stringify(this.props.values?.psm_id)}
                {JSON.stringify(this.props.parentValues.psm_id)} */}
            <Button style={{marginTop: 24, marginRight: 25}} loading={this.state.loading} onClick={() => this.observationModal()} color={"blue"} className="ml-4">
                    <i className="far fa-dolly-flatbed fa-fw mr-2"></i>    
            </Button>
            

        </>
            
        )
    }
}

// this.props.onChange('abc')
