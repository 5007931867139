import { Event } from '../Event';
import axios from "../../utilities/axios";
import  {Notification}  from "rsuite";

export class PsmThirdDataFkPersonIdEvents extends Event {
    public state = {
        loading: false
    }
    
    public onChange = async (value:any, setField: any, setState: any, values: string) => {
        this.setState("loadings",[
            "psm_third_cpfcnpj",
            "psm_third_city",
            "psm_third_state"
        ]);
        if(value){
        axios
            .get(`api/v1/people/${value}`)
            .then((res) => {
                // console.log({res});
                this.setFieldValue("psm_third_cpfcnpj", res.data.item.person_cnpj_cpf);
                this.setFieldValue("psm_third_city", res.data.item.city.city_name);
                this.setFieldValue("psm_third_state", res.data.item.state.state_name);
            })
            .catch((e) => {
                Notification.error({
                    title: "error!",
                    description: e,
                    duration: 5600,
                });
            })
            .finally(() => {
                setTimeout(()=> {
                    this.setState('loadings',[]);
                }, 3000)
            });
        }else{
            this.setFieldValue("psm_third_cpfcnpj", "");
            this.setFieldValue("psm_third_city", "");
            this.setFieldValue("psm_third_state", "");
            this.setState('loadings',[]);
        }

    }
}

