import React from "react";
import { Route, Switch, withRouter } from "react-router";
import { ModuleContext } from "../components/authProvider/main";
import BrowsePage from "./crud/BrowsePage";
import EditPage from "./crud/EditPage";
import ReadPageLabs from "./labs/ReadPageLabs"
import config, { getModule } from "../globals";
import ReadPage from "./crud/ReadPage";
import { Helmet } from "react-helmet";
import DashboardPage from "./DashboardPage";
import { Page404 } from "./404";
import EstimatesEditAddPage from "./estimates/EstimatesEditAddPage";
import PSMEditAddPage from "./psm/PSMEditAddPage";
import ParametersPage from "./ParametersPage";
import { DevRoom } from "./dev-room/DevRoom";
import ViewProfilePage from "./profile/ViewProfilePage";
import LabsEditAddPage from "./labs/LabsEditAddPage";
import developmentPage from "./development/developmentPage";
import ReportPage from "./reports/ReportPage";
import IntegrationPage from './logistic/IntegrationPage';

class DefaultDashboardPage extends React.Component<any, {}> {
    static contextType = ModuleContext;
    public state = {
        isLoading: false,
        usuario: null,
    };

    componentDidUpdate(props: any) {
        const { module } = this.props.match.params;
        if (!config.module_colors[module]) {
            this.props.history.push("/dashboard");
        }
        if (this.context.name !== module) {
            var color = null;
            if (config.module_colors[module]) {
                color = config.module_colors[module];
            }
            this.context.setModule(module, color);
            this.setState({ isLoading: false });
        }
    }

    componentDidMount = () => {
        const { module }: any = this.props.match.params;
        if (!config.module_colors[module]) {
            this.props.history.push("/dashboard");
        }
        if ((this.context.name !== module || this.context.color === null) && config.module_colors[module]) {
            var color = null;
            if (config.module_colors[module]) {
                color = config.module_colors[module];
            }
            this.context.setModule(module, color);
        }
    };

    render() {
        const { path } = this.props.match;
        const { module } = this.props.match.params;
        const moduleConfig = getModule(module);
        if(!moduleConfig) {
            this.props.history.replace('/dashboard/configuracoes/404');
            return null;
        }
        if (this.state.isLoading === true) {
            return null;
        }
        // console.log("Props ::: ", this.props.location);
        // console.log("path :: ", path);
        // alert(path + '/parameters');
        return (
            <div className={module + "-page"}>
                <Helmet>
                    <title>MXDrive - {moduleConfig.fullName ?? moduleConfig.label}</title>
                </Helmet>
                <Switch>
                    <Route exact path={path} component={DashboardPage} />
                    <Route exact path={path + '/parameters'} component={ParametersPage} />
                    <Route exact path={path + '/dev-room'} component={DevRoom} />
                    <Route exact path={path + '/profile'} component={ViewProfilePage} />
                    {/* <Route exact path={path + '/estimates/:id'} component={EstimatesEditAddPage} /> */}
                    {/* <Route exact path={path + '/psm/add'} component={PSMEditAddPage} /> */}
                    {/* <Route exact path={path + '/estimates'} component={EstimatesBrowsePage} /> */}                  
                    {/* POSSIVEL ROTA DE EDICAO*/}
                    {/* <Route exact path={path + "/labs/add"} component={EditPage} /> */}
                    <Route exact path={path + "/404"} component={Page404} />
                    {(this.props.location.pathname).includes("developmen") && (<Route exact path={path + "/:crud"} component={developmentPage} />)}
                    {(this.props.location.pathname).includes("report") && (<Route exact path={path + "/:crud"} component={ReportPage} />)}
                    {/* {(this.props.location.pathname).includes("integration") && (<Route exact path={path + "/:crud"} component={IntegrationPage} />)} */}

                    <Route exact path={path + "/:crud"} component={BrowsePage} />

                   {module == 'laboratorio' &&(<Route exact path={path + "/:crud/:templatetype/add"} component={LabsEditAddPage} />)}
                    <Route exact path={path + "/:crud/add"} component={EditPage} />


                   {module == 'laboratorio' && (this.props.location.pathname).includes("labs") &&(<Route exact path={path + "/:crud/:id"} component={LabsEditAddPage} />)}
                   {module == 'laboratorio' && (this.props.location.pathname).includes("labs") &&(<Route exact path={path + "/:crud/view/:id"} component={ReadPageLabs} />)}
                    <Route exact path={path + "/:crud/:id"} component={EditPage} />

                    <Route exact path={path + "/:crud/view/:id"} component={ReadPage} />
                    
                </Switch>
            </div>
        );
    }
}

export default withRouter(DefaultDashboardPage);
