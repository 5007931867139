import { Event } from "../Event"
import axios from "../../utilities/axios";
import dayjs from "dayjs";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
import { isNull } from "lodash";


export class PurchaseRequisitionTypeOpenEvents extends Event {
 
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value != null || value != '' || value != undefined) {
            this.setField('purchase_requisition_type_open', {
                disabled: true,
                readOnly: true
            })
        }
        if (value == 1) {
            this.setField('purchase_requisition_allocatade_requisition_psm', {
                hidden: false
            })
        }
    }
}


