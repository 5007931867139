import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmFkPersonIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, setFieldValue: any) => {

        if(value == null) {
            setState([
                "psm_person_city",
                "psm_person_state",
                "psm_person_cnpj_cpf",
                "psm_clients_fk_person_id"
            ]);
            setField("psm_person_city", "");
            setField("psm_clients_fk_person_id", );
            setField("psm_person_state", "");
            setField("psm_person_cnpj_cpf", "");
            setField("psm_hub", "");
            setField("psm_microrregiao", "");
            setField("psm_activity", "");
            setField("psm_internal", "");
            setField("psm_external", "");
            setField("psm_keyacoount", "");
        }
        
        if (value) {
            console.log("Meu value", value)
            setState("loadings", [
                "psm_person_street",
                "psm_person_district",
                "psm_person_city",
                "psm_person_state",
                "psm_person_cnpj_cpf",
                "psm_user",
                "psm_date",
                "psm_time",
                "psm_fk_payment_term_id",
                "psm_from_fk_seller_id",
                "psm_hub",
                "psm_microrregiao",
                "psm_activity",
                "psm_internal",
                "psm_external",
                "psm_keyacoount",
                "psm_clients_fk_person_id",
                "psm_fk_company_id"
            ]);
            setField("psm_fk_person_contact_id", undefined);
            // setField("psm_fk_company_id", undefined); //irá ficar para a próxima atualização
            setField("psm_fk_comercial_contact_id", undefined);
            //this.setFieldValue("psm_clients_fk_person_id", value);
            axios
                .get("/api/v1/people/" + value)
                .then( (res) => {
                    var data = res.data;
                    var usersSellers = data.item.person_sellers;

                    if (data) {
                       //console.log("Testando data",data)
                        setField("psm_person_street", data.item.person_street);
                        setField("psm_person_district", data.item.person_district);
                        setField("psm_person_city", data.item.city.city_name);
                        setField("psm_person_state", data.item.state.state_name);
                        setField("psm_person_cnpj_cpf", data.item.person_cnpj_cpf);
                        if(!this.globalProps.location.pathname.includes('receipts')){
                            setField("psm_fk_company_id", {value:data.item.company.company_id, label:data.item.company.company_fantasy});
                        }

                        
                        setField("psm_datebase_cnpj", data.item.person_cnpj_cpf);
                        setField("psm_database_citie", data.item.city.city_name);
                        setField("psm_states_uf", data.item.state.state_abbreviation);
                        setField("psm_clients_fk_person_id", {value: data.item.person_id, label: data.item.person_fantasy});

                        axios
                        .get("/api/v1/find-hub/" + data.item.region.region_fk_macro_region_id)
                        .then((res => {
                            if(res.status == 200) {
                                var dados = res.data.dados[0];
                                console.log("Dados teste", dados);
                                if(dados == "V") {
                                    setField("psm_hub", "Verificar dados cadastrais")
                                }else{
                                    setField("psm_hub", dados.macro_region_name);
                                }
                                // if(res.data.dados){
                                //     setField("psm_hub", dados.macro_region_name);
                                // }
                                
                            }
                            
                        }))


                        // console.log("testando",data.item.city.city_name);
                        
                        // axios
                        // .get("/api/v1/find-hub/" + data.item.region.region_fk_macro_region_id)
                        // .then((res => {
                        //     var dados = res.data.dados[0];
                        //     console.log(dados);
                        //     setField("psm_hub", dados.macro_region_name);
                        // }))

                        setField("psm_microrregiao", data.item.region.region_name);
                        setField("psm_activity", data.item.activity_sector.activity_sector_name);
                        //console.log("testando",data.item.city.city_name);

                        if(data.item.payment_term && data.item.payment_term.payment_term_id) {
                            setField("psm_fk_payment_term_id", data.item.payment_term.payment_term_id);
                        }
                        if (data.item.payment_method) {
                            
                            //TODO: PRECISA PREENCHER O CAMPO DE CONDICAO DE PAGAMENTO COM OS DO CLIENTE
                        }
                        for (let i in usersSellers) {

                           // console.log("testando Seller", usersSellers)
                            var externo = usersSellers[i].seller.seller_is_extern;
                            var externo2 = usersSellers[i].seller.externo;
                            console.log(externo)
                            console.log("testando externo2::::", externo2)
                            if(externo2 === "Não") {
                                console.log("passou aqui::", usersSellers[i].seller.seller_name);
                                let sellerPerson = usersSellers[i].seller.seller_name;
                                //let sellerPersonId = usersSellers[i].seller.seller_id;
                                //console.log("testando ID",sellerPersonId)  ;
                                //console.log("testando ID",sellerPerson)                             
                                //setField("psm_from_fk_user_id",  { label: sellerPerson, value: sellerPersonId });
                            }
                            if(externo === false) {

                               
                                let sellerPerson = usersSellers[i].seller.seller_name;   
                                const seller = usersSellers[i].seller;                     
                               //  console.log("passou aqui::", seller.seller_id);
                                setField("psm_from_fk_seller_id", seller.seller_id);
                                setField("psm_internal", sellerPerson);

                            }else {
                                setField("psm_internal", "Não existe vendedor do tipo Interno");
                            }
                            if(externo === true ) {
                                setField("psm_external", usersSellers[i].seller.seller_name)
                            }else {
                                setField("psm_external", "Não existe vendedor do tipo Externo");
                            }
                            if(usersSellers[i].seller.seller_type_of_seller == "K") {
                                setField("psm_keyacoount", usersSellers[i].seller.seller_name)
                            }else {
                                setField("psm_keyacoount", "Não existe vendedor do tipo Key-Account");
                            }
                        }
                    }
                })
                .catch((e) => {})
                .finally(() => {
                    setState("loadings", []);
                });
                //this.setState("loadings", []);
                setField("psm_clients_fk_person_id", value);
        }
    };
}