import React from "react";
import { Button, Table, DatePicker, SelectPicker, Alert, Progress, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import _, { filter } from "lodash";
import { CustomModal } from "../../customModal/CustomModal"
import TablePagination from "rsuite/lib/Table/TablePagination";
import dayjs from "dayjs";
import { CheckPicker } from 'rsuite';
import axiosReport from "../../../utilities/axiosReport";


const { Column, HeaderCell, Cell } = Table;

export class ReportPsmCustom extends React.Component<any, {}> {
    public state: any = {
        selectSchemaData: [],
        showFieldsReport: [
            "psm_os_number",
            "psm_fk_person_id",
            "psm_receipt_equipment_description",
            "psm_equipment_manufacturer_id",
            "psm_equipment_type_fk_type_equipment_id",
            "psm_equipment_serie_fk_product_id",
            "psm_receipt_equipment_manufacturer",
            "psm_warranty_requested",
            "psm_conclusion_evaluation",
            "psm_conclusion_conditional_test",
            "psm_conclusion_repair_conditional",
            "psm_deffects",
            "psm_defeat_informed_by_client",
            "psm_fk_commercial_situation_id",
            "psm_final_date_delivery"          
        ],
        filterValues: {
            psm_created_at_end: {
                "field": "psm_created_at",
                "op": "lte",
                "value": dayjs().format('YYYY-MM-DD HH:mm:ss.sss')
            },
            psm_created_at_init: {
                "field": "psm_created_at",
                "op": "gte",
                "value": dayjs().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss.sss')
            }
        },
        loading: false,
        dateIni: dayjs().subtract(30, 'days').format('MM/DD/YYYY'),
        endDate: dayjs().format('MM/DD/YYYY'),
        percent: 0,
        color: '#3385ff',
        status: null,
    }

    public locale = {
        sunday: "DON",
        monday: "SEG",
        tuesday: "TER",
        wednesday: "QUA",
        thursday: "QUI",
        friday: "SEX",
        saturday: "SAB",
        ok: "Ok",
        today: "Hoje",
        yesterday: "Ontem",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
        formatDate: (date: any) => dayjs(date).format('DD/MM/YYYY') // Formato do título com o mês abreviado
    };

    componentDidMount = async () => {
        await axios
            .get(`api/v1/commercials/fields`)
            .then((res) => {
                // console.log(res.data.fields)
                if (res.data.status == 200) {
                    delete res.data.fields['$GLOBALS']
                    let objKeys = Object.keys(res.data.fields)
                    this.setState({
                        selectSchemaData: objKeys.map(
                            item => ({ label: res.data.fields[item]?.['label'] ?? item, value: item }))
                    })
                }
            })
    }

    onChange = (alias: any, field: any, op: any, value: any) => {
        this.setState({ filterValues: { ...this.state.filterValues, [alias]: { field: field, op: op, value: value } } })
    };


    submitSearch = async () => {
        this.setState({ loading: true, percent: 30 })
        // console.log(this.state.filterValues)
        console.log("iemrrwme here :::>", this.state.showFieldsReport)
        await axiosReport
            .post(`/api/v1/reports/generate`, { params: Object.values(this.state.filterValues), crud: "commercials", type: "kpi", showFieldsReport: this.state.showFieldsReport })
            .then((res) => {
                this.setState({percent: 70, status: 'active'})
                // console.log('Respons :::: ', res.data);
                setTimeout(() => { 
                    window.open('https://' + window.location.host.split('25565').join('25567') + res.data.outputUrl)
                    this.setState({percent: 100, status: 'success', color: 'succes'})
                 }, 13000);


            })
            .catch((e) => {
                
                Notification.error({
                    title: 'Erro!',
                    description: e.response.data.message
                })
                this.setState({ isDownloadingFile: false });
            })
            .finally(() => {
                // this.setState({ loading: false })
                setTimeout(() => { this.setState({ loading: false }) }, 13000)
            })
    }


    render() {
        return (<>
            <div style={{ display: 'Flex', flexDirection: "row", width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {/* {JSON.stringify(this.state.dateIni)} */}
                        Data inicial (Data de Abertura da PSM)
                        <DatePicker
                            defaultValue={this.state.dateIni}
                            style={{ width: 224 }}
                            locale={this.locale}
                            format="DD/MM/YYYY"
                            onChange={(date: any) => this.onChange("psm_created_at_init", "psm_created_at", "gte", dayjs(date).format('YYYY-MM-DD HH:mm:ss.sss'))}
                            placeholder="DD/MM/AAAA"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                        Data Final (Data de Abertura da PSM)
                        <DatePicker
                            defaultValue={this.state.endDate}
                            style={{ width: 224 }}
                            locale={this.locale}
                            format="DD/MM/YYYY"
                            onChange={(date: any) => this.onChange("psm_created_at_end", "psm_created_at", "lte", dayjs(date).format('YYYY-MM-DD HH:mm:ss.sss'))}
                            placeholder="DD/MM/AAAA"
                        />
                    </div>
                    {/* <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', width: '400px' }}>
                        <Progress.Line percent={this.state.percent} strokeColor='success' status={this.state.status} />
                    </div> */}
                    {/* <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                        Campos Para Mostrar no Relatorio
                        <CheckPicker defaultValue={this.state.showFieldsReport} onChange={(value) => this.setState({ showFieldsReport: value })} data={this.state.selectSchemaData} style={{ width: 600 }} />
                    </div> */}
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                    Selecione a Unidade Mx
                    <SelectPicker
                        defaultValue={this?.context?.data?.user_fk_company_id}
                        data={this.props.companyData}
                        // onChange={(value: any) => this.setState({ searchData: { ...this.state.searchData, companie_id: value } })}
                        style={{ width: 224 }}
                        placeholder="Selecione..."
                    />
                </div> */}
                <div style={{ marginTop: '20px', flexDirection: 'column' }}>
                    <Button color={"green"} loading={this.state.loading} onClick={(e) => this.submitSearch()} >Gerar Relatório</Button>
                    <Progress.Line percent={this.state.percent} status={this.state.status} />
                </div>
            </div>
        </>)
    }
}
