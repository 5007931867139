import axios from "../../utilities/axios";
 
export const requiredColumns = async (idSituation: string | number, functionThis: any, prefix: string) => {
    axios
        .get(`/api/v1/situation/${idSituation}/${prefix}`)
        .then((res) => {
            let columns = res.data?.columns;
            for (let i in res.data?.columns) {
                if(res.data?.columns[i]?.readonly){
                    functionThis.setField(i, {
                        readonly: columns[i].readonly
                    })
                }
                if(res.data?.columns[i]?.disabled){
                    functionThis.setField(i, {
                        disabled: columns[i].disabled
                    })
                }
            }
        })
}