const checkPermissionPage = (key: string,user:any) => {

    if(user?.role?.role_permissions) {
        // console.log("Entrei no checkPermissionPage")
        // alert(user?.role?.role_id);
        
        if(user?.role?.role_id === 2) {
        // console.log("Entreia qui 313212", user?.role?.role_id)
            return true;
        }
        var perms : string[] = user?.role?.role_permissions.map((item : any) => {
            return item.role_permission_value;
        })
        // console.log(" valor do perms ", perms)
        // console.log("Entreia qui 313212", user?.role?.role_id)


        // console.log('Primeiro console',{perms})
        var perm: any = [];
        for(var index in perms) {
            perm = perms[index].split('-').join('_');
            // if(!perm.includes('-')) {
            //     perms = [...perms,perm+'-browse',perm+'-read',perm+'-edit',perm+'-add',perm+'-delete']
            // }
        }
        // console.log({perms})
        // console.log('teste includes ::: ', perms.includes(key))
        // perms.map((value:any,index:any) => {
        //     return console.log('novo teste ::: ', {value},{index})


        
        let permissionIsValid = false
        const keyClear = key.replace("-add", "");
        // console.log("keyClear ::?>", keyClear)
        for( let i in perms){
            // console.log(perms[i])
            if([key, keyClear].includes(perms[i]) ){
                // console.log("entrei aqui")
                permissionIsValid = true;
                continue
            }
        }
        // console.log("Entreia qui 2", permissionIsValid) 
        return permissionIsValid
    }
    return false;
}

// export {checkPermissionPage}
export default checkPermissionPage;

