import React from "react";
import { Button , Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { CustomModal } from "../../customModal/CustomModal";


export class PrintRomanian extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
    }

    componentDidMount = async() =>{
   
    }

    isPrintRomanian = (type: string) => {
        const typeCrud = this.props.match.params.crud === 'product_boxes' ?
         {crud: 'product_boxes', url: 'product-box', singularCrud: 'product_box'}
         : {crud: 'boxes', url: 'boxes', singularCrud: 'box'};
        if(this.props.values[`${typeCrud.singularCrud}_inclusions`].length == 0){
            Notification.error({
                title: "error!",
                description: 'Não Existem Psms nessa Embalagem!!',
                duration: 6500,
            });
            return
        }

        axios
            .get(`/api/v1/${typeCrud.url}/print/${this.props.values[`${typeCrud.singularCrud}_id`]}/${type}/${this.props.values[`${typeCrud.singularCrud}_volume`]}`)
            .then((res) => {
                if (res.data && res.data.url) {
                    if (res.data && res.data.url) {
                        window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (<>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Button color="blue" size={"lg"} loading={this.state.loading} style={{marginTop: 20, width: "200px"}} onClick={() => this.isPrintRomanian('romaneio')}>
                    <i className="fas fa-file-import"></i> Imprimir Romaneio
                </Button>
                <Button color="blue" size={"lg"} loading={this.state.loading} style={{marginTop: 20, width: "200px", marginLeft: '10px'}} onClick={() => this.isPrintRomanian('etiqueta')}>
                    <i className="fas fa-file-import"></i> Imprimir Etiqueta
                </Button>
            </div>
        </>
        )
    }
}