import { Event } from "../../Event";
export class PsmInclusionProductIhmRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_ihm_item_description", "");
            this.setFieldValue ("psm_inclusion_product_ihm_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_ihm_product_id", "");
            this.setFieldValue ("psm_inclusion_product_ihm_fk_select_id",);

            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductMecRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_mec_item_description", "");
            this.setFieldValue ("psm_inclusion_product_mec_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_mec_product_id", "");
            this.setFieldValue ("psm_inclusion_product_mec_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductRetifierRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_retifier_item_description", "");
            this.setFieldValue ("psm_inclusion_product_retifier_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_retifier_product_id", "");
            this.setFieldValue ("psm_inclusion_product_retifier_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};
export class PsmInclusionProductCapacitorRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_capacitor_item_description", "");
            this.setFieldValue ("psm_inclusion_product_capacitor_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_capacitor_product_id", "");
            this.setFieldValue ("psm_inclusion_product_capacitor_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};
export class PsmInclusionProductResistenceRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_resistence_item_description", "");
            this.setFieldValue ("psm_inclusion_product_resistence_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_resistence_product_id", "");
            this.setFieldValue ("psm_inclusion_product_resistence_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};
export class PsmInclusionProductCountRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_count_item_description", "");
            this.setFieldValue ("psm_inclusion_product_count_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_count_product_id", "");
            this.setFieldValue ("psm_inclusion_product_count_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductRespreloadRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_respreload_item_description", "");
            this.setFieldValue ("psm_inclusion_product_respreload_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_respreload_product_id", "");
            this.setFieldValue ("psm_inclusion_product_respreload_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductIgbtbreakRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_igbtbreak_item_description", "");
            this.setFieldValue ("psm_inclusion_product_igbtbreak_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_igbtbreak_product_id", "");
            this.setFieldValue ("psm_inclusion_product_igbtbreak_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductLinkdcRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_linkdc_item_description", "");
            this.setFieldValue ("psm_inclusion_product_linkdc_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_linkdc_product_id", "");
            this.setFieldValue ("psm_inclusion_product_linkdc_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductPotencyRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_potency_item_description", "");
            this.setFieldValue ("psm_inclusion_product_potency_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_potency_product_id", "");
            this.setFieldValue ("psm_inclusion_product_potency_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductTcRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_tc_item_description", "");
            this.setFieldValue ("psm_inclusion_product_tc_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_tc_product_id", "");
            this.setFieldValue ("psm_inclusion_product_tc_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductFanRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_fan_item_description", "");
            this.setFieldValue ("psm_inclusion_product_fan_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_fan_product_id", "");
            this.setFieldValue ("psm_inclusion_product_fan_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductControlRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_control_item_description", "");
            this.setFieldValue ("psm_inclusion_product_control_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_control_product_id", "");
            this.setFieldValue ("psm_inclusion_product_control_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductCardRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_card_item_description", "");
            this.setFieldValue ("psm_inclusion_product_card_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_card_product_id", "");
            this.setFieldValue ("psm_inclusion_product_card_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductFibercableRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_fibercable_item_description", "");
            this.setFieldValue ("psm_inclusion_product_fibercable_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_fibercable_product_id", "");
            this.setFieldValue ("psm_inclusion_product_fibercable_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductOptionalRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_optional_item_description", "");
            this.setFieldValue ("psm_inclusion_product_optional_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_optional_product_id", "");
            this.setFieldValue ("psm_inclusion_product_optional_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductBypaRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_bypa_item_description", "");
            this.setFieldValue ("psm_inclusion_product_bypa_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_bypa_product_id", "");
            this.setFieldValue ("psm_inclusion_product_bypa_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductTemperatureRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_temperature_item_description", "");
            this.setFieldValue ("psm_inclusion_product_temperature_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_temperature_product_id", "");
            this.setFieldValue ("psm_inclusion_product_temperature_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductAnalysiRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_analysi_item_description", "");
            this.setFieldValue ("psm_inclusion_product_analysi_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_analysi_product_id", "");
            this.setFieldValue ("psm_inclusion_product_analysi_item_description", "");
            this.setFieldValue ("psm_inclusion_product_analysi_fk_select_id",);
    
            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductCompiledRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_compiled_item_description", "");
            this.setFieldValue ("psm_inclusion_product_compiled_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_compiled_product_id", "");
            this.setFieldValue ("psm_inclusion_product_compiled_fk_select_id",);

            this.setState("loadings",[])
        };
    };
};

export class PsmInclusionProductCircuitRegisteredEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            this.setState("loadings", [
                
            ]);
            this.setFieldValue ("psm_inclusion_product_circuit_item_description", "");
            this.setFieldValue ("psm_inclusion_product_circuit_intern_reference", "");
            this.setFieldValue ("psm_inclusion_product_circuit_product_id", "");
            this.setFieldValue ("psm_inclusion_product_circuit_fk_select_id",);

            this.setState("loadings",[])
        };
    };
};