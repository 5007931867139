import React from "react";
import { Loader, Modal, Tooltip, Whisper, Button, SelectPicker } from "rsuite";
import axios from "../../../utilities/axios";
import Browserlize from "../../Browserlize";
import { Permission } from "../../Permission";
import { AdvancedSearchModal } from "../../customModal/AdvancedSearchProductsModal";
import { AxiosSelectPicker } from "../AxiosSelectPicker";
import { PsmProductRegistrationFkSelectIdEvents } from "../../../../src/events/psm_product/PsmProductRegistrationFkSelectIdEvents"


export class AdvancedSearchModalButton extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data: [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        disabled: true,
        showModalIntegration: false,
    }

    componentDidMount = async () => {
        let params: any = { concat: 'product.product_description', cache: false, currentValueSearch: this.props.value }
        for (const i in this.props.form) {
            if (this.props.form[i].component == "AdvancedSearchModalButton") {
                this.setState({ disabled: this.props.form[i].disabled })
            }
        }

        await axios.get("/api/v1/products/select", { params }).then((res) => {
            this.setState({ data: res.data.items })

        })
    }
    // componentDidUpdate = () => {

    //     // for(const i in this.props.form) {
    //     //     if(this.props.form[i].component == "AdvancedSearchModalButton"){
    //     //         this.setState({disabled: this.props.form[i].disabled})
    //     //     }
    //     // }
    // }
    updateData = async (value: any) => {
        await axios.get("/api/v1/products/select?search=" + value).then((res) => {

            this.setState({ data: res.data.items })

        })
    }

    public select: any = {};



    openModal = () => {
        //   console.log(this.props)
        this.setState({ show: true, loading: true })
    }

    onAdvancedSelect = (value: any) => {
        this.setState({ data: [value], value:  value["value"], show: false, loading: false });
        //alert("ADV "+value['value'])    
        // console.log("onAdvancedSelect", { value })
        if (['psm_relation_products', 'psm_relation_services'].includes(this.props.relation)) {
            this.props.onChange({ valueFk: value['value'] });

            return
        }
        // this.props.onChange({ valueFk: value['value']});
        // console.log(" ims efe ::>", value["value"])
        this.props.onChange(value["value"], value["value"]);
        // this.setState({  })
    };
    onClean = (value: any) => {
        this.setState({ value: [], show: false, loading: false });
        this.props.onChange(value[""], [""]);
        //alert("Clickou-me")
        // this.setState({  })
    };
    onSelect = (value: any) => {
        this.setState({ value: value, show: false, loading: false });
        // console.log("onSelect", { value })
        // this.setState({  })
    };
    onOpen = async () => {
        let params: any = { cache: false, currentValueSearch: this.props.value, searchValue: { product_equipment_type: 1 } }
        await axios.get("/api/v1/products/select", { params }).then((res) => {
            // console.log("aqui:: ", res.data.items);
            this.setState({ data: res.data.items })

        })
    }

    // onChange = (value: any) => {
    //     console.log("change", { value })
    //     this.setState({ value: value });
    //     this.props.onChange(value, value);
    //     // this.setState({  })
    // };

    onChange = (value: any) => {
        this.setState({ value: value });
        if (['psm_relation_products', 'psm_relation_services'].includes(this.props.relation) && this.props.values.psm_relation_product_product_id) {
            this.setState({ showModalIntegration: true, loading: true })
        // console.log("Entrei aqui tbm ::>")

            return
        }
        if (['psm_relation_products', 'psm_relation_services'].includes(this.props.relation)) {
            this.props.onChange({ valueFk: value });
            // console.log("Entrei aqui tbm ::>")

            return
        }
        // console.log("Entrei aqui tbm ::>")
        this.props.onChange(value, value);
        return
    };


    onSelectIfIntegration = (type: boolean) => {
        this.props.onChange({ valueFk: this.state.value, isExchangeValue: type }, 'teste');
        this.setState({ showModalIntegration: false, loading: false })
    }


    renderModalIfIntegration = () => {
        return (<>
            <Modal
                overflow={false}
                show={this.state.showModalIntegration}
                size="sm"
            >
                <Modal.Header>
                    <Modal.Title>Nova Precificação?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja que haja uma nova precificação com os valores do novo produto?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        appearance="primary"
                        color="green"
                        onClick={() => this.onSelectIfIntegration(true)}
                    >
                        Sim
                    </Button>
                    <Button
                        appearance="primary"
                        color="red"
                        onClick={() => this.onSelectIfIntegration(false)}
                    >
                        Não
                    </Button>
                </Modal.Footer>
            </Modal>
        </>)
    }


    render() {
        const data = this.state.data;
        const valueProps = this.props.value;
        //console.log("Olha o teste",this.state.valueProps)

        const renderMenu = (menu: any) => {
            if (data.length === 0) {
                return (
                    <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                        <Loader spin />
                    </p>
                );
            }
            return menu;
        };
        //   console.log("PROPS BUTTON::::::" , this.props.name)
        return (<>
            {this.renderModalIfIntegration()}
            <AdvancedSearchModal
                // body={<>
                //     <Input size="md" style={{marginBottom: 20}} placeholder={this.state.situacaoModal} disabled={true}/>
                //     <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                //     <Button loading={this.state.loading} color='blue' style={{marginLeft:20}} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                // </>} 
                value={this.props.value}
                onSelect={this.onAdvancedSelect}
                crud={'products'}
                show={this.state.show}
                onHide={() => this.setState({ show: false, loading: false })}
            />
            {/* {JSON.stringify(this.props.values?.psm_product_registration_fk_select_id)}
                {JSON.stringify(this.props.values?.psm_id)}
                {JSON.stringify(this.props.parentValues.psm_id)} */}
            {/* {console.log(this.state.data)} */}
            {/* <SelectPicker data={data} style={{ width: 224 }} /> */}
            {/* {console.log("aqui",JSON.stringify(this.state.value))} */}
            {/* {JSON.stringify(this.state.value)} */}
            <label style={{ display: "flex" }}>Descrição do Item</label>
            <div style={{ display: "flex", marginTop: 4 }}>
                <SelectPicker disabled={this.state.disabled} size="md" style={{ width: 1000, borderRight: '1px solid' }} placeholder={"Pesquisar por..."} onOpen={this.onOpen} onClean={this.onClean} data={data} onSelect={(value) => this.onChange(value)} renderMenu={renderMenu} value={this.state.value == null ? this.props?.value : this.state.value} onSearch={(value) => this.updateData(value)} />


                <Button style={{ width: 40, height: 35, marginTop: '0.5px', marginLeft: -5 }} loading={this.state.loading} onClick={() => this.openModal()} color={"blue"} className="ml-4">
                    <i className="fas fa-search fa-fw mr-2"></i>
                </Button>


            </div>
        </>

        )
    }
}
