import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PurchaseRequisitionRelationFkSelectIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'purchase_requisition_relation_intern_reference',
            'purchase_requisition_relation_manufacturer_code'
        ])

        await axios
            .get(`/api/v1/products/${value}`)
            .then((res) => {
                if (res.data.status == 200) {
                    const fieldValue = res.data.item;
                    this.setFieldValue('purchase_requisition_relation_intern_reference', fieldValue.product_intern_reference);
                    this.setFieldValue('purchase_requisition_relation_manufacturer_code', fieldValue.product_manufacturer_code);
                    this.setFieldValue("purchase_requisition_relation_item_description", res.data.item.product_description);
                }
            })
            .finally(() => {
                this.setState('loadings', []);
            })
            .catch((e) => {
                console.error(e);
            })
    }
}
