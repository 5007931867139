import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PsmItemDescriptionListProductIdEvents extends Event {
    public state = {
        loading: false,
    }
    public onChange  = (value: any, setFieldValue: any, setState: any,values: any,...args : any) => {
        // console.log("HISTORY",values);
        // console.log("testando value::::", value);
        if(value.length <= 0) {
            this.setState ([
                "psm_item_description_list_intern_reference",
                "psm_item_description_list_product_id",
                "psm_item_description_list_unit",
                "psm_item_description_list_complement_description",
                "psm_item_description_list_fk_select_id"
            ]);
            this.setFieldValue ("psm_item_description_list_complement_description", "");
                     this.setFieldValue ("psm_item_description_list_unit", "");
                     this.setFieldValue ("psm_item_description_list_intern_reference", "");
                    //  this.setFieldValue ("psm_product_registration_code_product", res.data.item.product_manufacturer_code);
                     this.setFieldValue ("psm_item_description_list_product_id", "");
                     this.setFieldValue("psm_item_description_list_fk_select_id", );
        }
        
        if(value && value.length > 0 ) {
            this.setState ("loadings",[
                "psm_item_description_list_intern_reference",
                "psm_item_description_list_product_id",
                "psm_item_description_list_unit",
                "psm_item_description_list_complement_description",
                "psm_item_description_list_fk_select_id",

            ]);
            
            axios
            .get("/api/v1/product/" + value)
            .then((res => {
                if (res.status === 200 && res) { 
                    //console.log("Value", res.data.item.product_description)
                    this.setFieldValue ("psm_item_description_list_complement_description", res.data.item.product_observation);
                    this.setFieldValue ("psm_item_description_list_unit", res.data.item.measure_unit.measure_unit_name);
                    this.setFieldValue ("psm_item_description_list_intern_reference", res.data.item.product_intern_reference);
                   //  this.setFieldValue ("psm_product_registration_code_product", res.data.item.product_manufacturer_code);
                    this.setFieldValue ("psm_item_description_list_product_id", JSON.stringify(res.data.item.product_id));
                    this.setFieldValue("psm_item_description_list_fk_select_id", {value: value, label: res.data.item.product_description});
                    this.setFieldValue ("psm_item_description_list_fk_select_id", res.data.item.product_id);
                }
            }))
            .finally(() => {
                this.setState("loadings", []);
            }); 
            this.setState("loadings", []);
            
            
        }
    }

}