import React from "react";
import { Loader, Modal, Tooltip, Whisper,Button } from "rsuite";
import axios from "../../../utilities/axios";
import Browserlize from "../../Browserlize";
import { Permission } from "../../Permission";
import classNames from "classnames";

export class AppointmentHours extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        started: false,
        loadingClockIn: false,
    }

    //public select: any = {};
    componentDidMount(){
        if(this.props.values.psm_id){
            axios
                .get("/api/v1/clock-in/status/" + this.props.values.psm_id)
                .then((response) => {
                    // console.log("response",response.data.type == "started");
                    var status = response.data.type == "started" ? true : false;
                    this.setState({ started: status, loading: false });
                })
                .finally(() => {
                    this.setState({ loading: false });
                })
        }
    }

    clockIn = (id: any) => {
        this.setState({ loading: true });
        //TODO: chamar api ao em vez disso aqui né porra
        // setTimeout(() => {
        axios
            .get("/api/v1/clock-in/" + id)
            .then((response) => {
                this.setState({ started: !this.state.started, loading: false });
                this.props.history.go(0);
            })
            .finally(() => {
                this.setState({ loading: false });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
        // }, 2000);
    };

    render() {
        return (        
            <Button loading={this.state.loadingClockIn} color={this.state.started ? "red" : "green"} onClick={() => this.clockIn(this.props.values.psm_id)} className="ml-4">
                    <i className={classNames("fas fa-fw mr-2", this.state.started ? "fa-pause" : "fa-play")}></i>
                    Apontar horas
            </Button>  
        )
    }
}
