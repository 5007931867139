import { result } from "lodash";
import React from "react";
import { Button, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { CustomModal } from "../../customModal/CustomModal";


export class ApportionmentDibiButton extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data: [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        isImportModalOmie: false,
        omieData: [],
        OmieItens: [],
        isModalConfirm: false
    }

    calculaValorTotal = (inicial: number, descontoPercentage: number = 0, descontoValue: number = 0) => {
        var valorTotal = inicial;
        var targetDescontoPercentage = 0;
        var targetDescontoValue = 0;
        if (descontoPercentage > 0) {
            valorTotal = valorTotal - (valorTotal * descontoPercentage) / 100;
            targetDescontoPercentage = descontoPercentage;
            targetDescontoValue = inicial - valorTotal;
        } else if (descontoValue > 0) {
            valorTotal = valorTotal - descontoValue;
            targetDescontoValue = descontoValue;
            targetDescontoPercentage = 100 - (valorTotal * 100) / (valorTotal + descontoValue);
            let teste = targetDescontoPercentage.toFixed(2);
            // console.log({teste});
        }
        // const valorTotal = inicial - (inicial * descontoPercentage / 100) - descontoValue;
    
        return {
            valorTotal,
            percentual: targetDescontoPercentage,
            desconto: targetDescontoValue,
        };
    };

    public select: any = {};

    onChange = (item: any, valuesOmie: any) => {
        this.props.onChange()
    };


    isApportionmentFuntion = () => {
        this.setState({ loading: true })

        let dataPsm: any = this.props.values;
        
        if (dataPsm.psm_discount_real_value == null && dataPsm.psm_discount_percent_value == null) {
            Notification.warning({
                title: "Ops..",
                description: 'Conferir os campos nescessários para o rateio',
                duration: 3500,
            });
            return
        }
        let resultDiscount = this.calculaValorTotal(dataPsm.psm_total_value_products,dataPsm.psm_discount_percent_value, dataPsm.psm_discount_real_value);

        console.log("Valor do dataPsmm", dataPsm)
        axios
            .post("/api/v1/dibi/apportionment/", {
                id: this.props.values.psm_id, psm: this.props.values.psm_os_number,
                percent: resultDiscount.percentual, valueDiscount: resultDiscount.desconto, 
                valueAllSum: resultDiscount.valorTotal
            })
            .then((res) => {
                if (res.data.status == 200) {
                    Notification.success({
                        title: "Rateio realizado com sucesso!!!",
                        //description: 'Conferir os campos nescessários para o rateio',
                        duration: 4500,
                    });
                    // let objValues: any = {resultDisc : resultDiscount, response: res.data.valueAllProducts}
                    // this.props.onChange(objValues);
                }
            })
            .finally(() => {
                this.setState({ loading: false });
                setTimeout(this.props.history.go(0), 5000)
            });
    }

    renderModalConfirm = () => {
        return (<>
            <CustomModal
                show={this.state.isModalConfirm}
                tableOmie={false}
                onCancel={() => {
                    this.setState({
                        isModalConfirm: false,
                    })
                }}
                title={"Realizar rateio"}
                body={<>
                    <div style={{ fontSize: '18px' }}>Lembre-se de salvar todas as alterações, antes de realizar o rateio</div>
                </>}
                loading={this.state.loading}
                confirmColor={"green"}
                onConfirm={() => this.isApportionmentFuntion()}
            />
        </>)
    }

    render() {
        return (<>
            {this.renderModalConfirm()}
            <Button color="green" size={"md"} loading={this.state.loading} style={{ marginTop: 24, width: "200px" }} onClick={() => this.setState({ isModalConfirm: true })}>
                <i className="fas fa-balance-scale" style={{ marginRight: '10px' }}></i>Realizar Rateio
            </Button>
        </>

        )
    }
}