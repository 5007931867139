import React from "react";
import { Loader, Modal, Tooltip, Whisper,Button ,SelectPicker } from "rsuite";
import axios from "../../../utilities/axios";
import Browserlize from "../../Browserlize";
import { Permission } from "../../Permission";
import { AdvancedSearchModal } from "../../customModal/AdvancedSearchProductsModal";
import { AxiosSelectPicker } from "../AxiosSelectPicker";
import {PsmProductRegistrationFkSelectIdEvents } from "../../../../src/events/psm_product/PsmProductRegistrationFkSelectIdEvents"


export class AdvancedSearchModalButtonProduct extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
    }

    componentDidMount = async() =>{
        await axios.get("/api/v1/products/select").then((res)=>{
           // console.log("aqui:: ", res.data.items);
            this.setState({ data: res.data.items })

        })
    }
    updateData = async(value : any) =>{
        await axios.get("/api/v1/products/select?search="+value).then((res)=>{
            // console.log("aqui:: ", res.data.items);
             this.setState({ data: res.data.items })
 
         })
    }

    public select: any = {};

    

    openModal = () => {
     //   console.log(this.props)
        this.setState({ show: true, loading: true })
    }

    onAdvancedSelect = (value: any) => {
        this.setState({ data: [value], value: value["value"], show: false ,loading: false});
        //alert("ADV "+value['value'])        
        this.props.onChange(value["value"], [value]);
        // this.setState({  })
    };
    onClean = (value: any) => {
        this.setState({ value: [], show: false ,loading: false});
        this.props.onChange(value[""], [""]);
        //alert("Clickou-me")
        // this.setState({  })
    };
    onSelect = (value: any) => {
        this.setState({ value: value, show: false ,loading: false});
        // this.setState({  })
    };
    onOpen = async() =>{
        await axios.get("/api/v1/products/select").then((res)=>{
           // console.log("aqui:: ", res.data.items);
            this.setState({ data: res.data.items })

        })
    }

    onChange = (value: any) => {
        this.setState({ value: value});
        this.props.onChange(value, value);
        // this.setState({  })
    };


    render() {
        const data =  this.state.data;
        const valueProps = this.props.value;
        //console.log("Olha o teste",this.state.valueProps)

        const renderMenu = (menu:any) => {
            if (data.length === 0) {
              return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                  <Loader spin />
                </p>
              );
            }
            return menu;
          };

        return (<>
            <AdvancedSearchModal 
                    // body={<>
                    //     <Input size="md" style={{marginBottom: 20}} placeholder={this.state.situacaoModal} disabled={true}/>
                    //     <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                    //     <Button loading={this.state.loading} color='blue' style={{marginLeft:20}} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                    // </>} 
                    value={this.props.value}
                    onSelect={this.onAdvancedSelect}   
                    crud={'products'}
                    show={this.state.show}
                    onHide={() => this.setState({ show: false, loading: false  })}
                />
                {/* {JSON.stringify(this.props.values?.psm_product_registration_fk_select_id)}
                {JSON.stringify(this.props.values?.psm_id)}
                {JSON.stringify(this.props.parentValues.psm_id)} */}
                {/* {console.log(this.state.data)} */}
                {/* <SelectPicker data={data} style={{ width: 224 }} /> */}
                {/* {console.log("aqui",JSON.stringify(this.state.value))} */}
                {/* {JSON.stringify(this.state.value)} */}
                <label style={{display: "flex"}}>Descrição do Item</label>
           <div style={{display:"flex",marginTop:4}}>
                <SelectPicker size="md" style={{width:450,borderRight:'1px solid'}} placeholder={"Pesquisar por..."} onOpen={this.onOpen} onClean={this.onClean} data={data} onSelect={(value) => this.onChange(value)} renderMenu={renderMenu} value={this.state.value == null ? this.props?.value:this.props.value}  onSearch={(value) => this.updateData(value)}/>
                <Button style={{width:40,height:35,marginTop:'0.5px', marginLeft:-5}} loading={this.state.loading} onClick={() => this.openModal()} color={"blue"} className="ml-4">
                        <i className="fas fa-search fa-fw mr-2"></i>                    
                </Button>
           </div>
        </>
            
        )
    }
}
