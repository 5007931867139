import React from "react";
import { Button, Modal, Table , List , Loader} from "rsuite";
import axios from "../../utilities/axios";

const { Column, HeaderCell, Cell } = Table;

export class StockSubModal extends React.Component<any, {}> {
    public state = {
        cidade: [],
    } 
 
    render(){
        if (this.props.Show === false) { 
            return null;
        }

    return(
        <Modal show={true} style={{width:900,margin:"30px auto 0 auto"}}>                
          <Modal.Body>
            <Modal.Header closeButton={false} >
                <h4>{this.props.title ?? "Consulta"}</h4>
            </Modal.Header> 
            <div style={{height:800,marginTop:30,padding:5}}> 
                  <table className="rs-table-cell">                       
                    <tr className="rs-table-row-header">
                    <th className="" style={{width:500}}>Localidade</th>  
                    <th className="" style={{width:300}}>{this.props.title_op ?? ""}</th> 
                    </tr>
                    {/* {console.log(this.props)} */}
                    {this.props.data.map((item:any) => (
                    <tr>
                        <td style={{borderBottom:"1px solid", borderColor:"#E8E8E8"}}>{item.company_fantasy?? item.company_name}</td>
                        <td className="rs-table-cell-content" style={{borderBottom:"1px solid", borderColor:"#E8E8E8",textAlign:"center"}}>0</td>
                        <td><Button color="green" style={{padding:5,float:"right"}} onClick={()=> alert(item.company_fantasy?? item.company_name)}><i className="fas fa-plus fa-fw mr-2"></i></Button></td>
                    </tr>
                     ))} 
                    {/* {this.props.data.map((item:any) => (
                      <tr className="">
                          <td className="rs-table-cell-content" style={{borderBottom:"1px solid", borderColor:"#E8E8E8"}}>{item.city_name}</td>
                          <td></td>
                          <td className="rs-table-cell-content" style={{borderBottom:"1px solid", borderColor:"#E8E8E8",textAlign:"center"}}>{item.quantity_disponivel}</td>
                          <td><Button disabled={item.quantity_disponivel > 0 ? false : true} color="blue" style={{padding:5,float:"right"}}><i className="fas fa-search fa-fw mr-2"></i></Button></td>
                          <td className="rs-table-cell-content" style={{borderBottom:"1px solid", borderColor:"#E8E8E8",textAlign:"center"}}>{item.alocado_psm}</td>
                    </tr>
          
                  ))}  */}

                   </table>
            </div>
           </Modal.Body>
        <Modal.Footer style={{marginTop:30}}>                
                    <Button onClick={this.props.onCancel} color={"red"} >
                        Fechar
                    </Button>
                    
                </Modal.Footer>
        </Modal>
    )
 }
}