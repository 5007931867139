import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col } from "rsuite";
import { Event } from "../Event";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal"
import classNames from "classnames";
import { link } from "fs";


class BoxFooterComponent extends React.Component<any, {}> {

    public state = {
        show: false,
        showEmail: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        started: false,
        loadingClockIn: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false,
        to: [],
        cc: [],
        cco: [],
        files: [],
        isOpen: false,
        emailSubject: "",
        psmId: [],
        modalLoading: false,
    };

    isPrintRomanian = async (type: string) => {
        const typeCrud = this.props.match.params.crud === 'product_boxes' ?
            { crud: 'product_boxes', url: 'product-box', singularCrud: 'product_box' }
            : { crud: 'boxes', url: 'boxes', singularCrud: 'box' };
        if (this.props.values.formValues[`${typeCrud.singularCrud}_inclusions`].length == 0) {
            Notification.error({
                title: "error!",
                description: 'Não Existem Psms nessa Embalagem!!',
                duration: 6500,
            });
            return
        }

        axios
            .get(`/api/v1/${typeCrud.url}/print/${this.props.values.formValues[`${typeCrud.singularCrud}_id`]}/${type}/${this.props.values.formValues[`${typeCrud.singularCrud}_volume`]}`)
            .then((res) => {
                if (res.data && res.data.url) {
                    if (res.data && res.data.url) {
                        window.open('https://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }


    trocaStatus = async (status: 'aguardando' | 'finalizado' | 'desfazer' | 'reabrir' = 'aguardando') => {
        // console.log(this.props.state.checked)
        if (!this.props.match.params.id) {
            Notification.error({
                title: "Erro!",
            });
            return;
        }
        if (['desfazer', 'reabrir'].includes(status)) {
            await axios
                .get(`/api/v1/situation/${status}/${this.props.match.params.id}`)
                .then((async res => {
                    if (res.status === 200 && res.data.status == 200) {
                        setTimeout(async () => {
                            await Notification.success({
                                title: "Sucesso!",
                                description: res.data.message,
                                duration: 5000,
                            });
                        }, 2500);
                        setTimeout(() => {
                            this.props.history.go(0)
                        }, 2500);
                    }
                    if (res.data.status == 201) {
                        Notification.warning({
                            title: "Atenção!",
                            description: res.data.message,
                        });
                    }
                }))
                .finally(() => {
                    this.setState({ loading: false });
                });
            return
        }
        this.setState({ loading: true });
        this.props.onSubmit(async () => { // Marcar esta função como async
            try {
                const postResponse = await axios.post(`/api/v1/situation/controller/box/${this.props.match.params.crud}/${this.props.match.params.id}/${this.props.values.formValues.box_fk_box_situation_id}`, {
                    data: this.props.values.formValues
                });

                if (postResponse.data.status === 201) {
                    let labels = "";
                    for (const item of postResponse.data.errors) {
                        labels += `> ${item.label}, `;
                    }
                    labels = labels.slice(0, -2);
                    Notification.error({
                        title: "Campos obrigatórios para avançar situação!",
                        description: labels,
                        placement: "bottomEnd",
                        duration: 5000,
                    });
                    this.setState({ loading: false });
                    return;
                }

                if (postResponse.data.status === 200) {
                    this.setState({ loading: true });

                    const getResponse = await axios.get(`/api/v1/situation/${status}/${this.props.match.params.id}`);
                    if (getResponse.status === 200 && getResponse.data.status === 200) {
                        setTimeout(() => {
                            Notification.success({
                                title: "Sucesso!",
                                description: getResponse.data.message,
                                duration: 5000,
                            });
                        }, 2500);

                        setTimeout(() => {
                            this.props.history.go(0);
                        }, 2500);
                    }
                    if (getResponse.data.status === 201) {
                        Notification.warning({
                            title: "Atenção!",
                            description: getResponse.data.message,
                        });
                    }
                    this.setState({ loading: false });
                }
            } catch (err) {
                console.error('error :::: ', err);
                this.setState({ loading: false });
            }
        });


    }
    render() {
        return (<>
            <ConfirmModal
                loading={this.state.loading}
                onCancel={() => {
                    this.setState({
                        showSituation: false,
                    })
                }}
                cancelColor={"red"}
                showConfirm={false}
                cancelText={"Fechar"}
                onCloseButton={true}
                content={""}
                returnText={"Voltar Situação"}
                advancedText={"Avançar Situação"}
                title={"Avança situação de ordem de serviço"}

                show={this.state.showSituation}
            />
            {this.props.values.formValues.box_fk_box_situation_id == 1 && (<>
                <Button loading={this.state.loading} color={"violet"} onClick={() => this.trocaStatus('aguardando')} className="ml-4">
                    <i className="fas fa-tasks fa-fw mr-2"></i>
                    AGUARDANDO COLETA/ENTREGA
                </Button>
            </>)}

            {this.props.values.formValues.box_fk_box_situation_id == 2 && (<>
                <Button loading={this.state.loading} color={"violet"} onClick={() => this.trocaStatus('desfazer')} className="ml-4">
                    <i className="fas fa-undo-alt fa-fw mr-2"></i>
                    Desfazer Coleta
                </Button>
                <Button loading={this.state.loading} onClick={() => this.trocaStatus('finalizado')} color={"red"} className="ml-4">
                    <i className="fas fa-exclamation-triangle fa-fw mr-2"></i>
                    FINALIZADO
                </Button>
            </>)}

            {this.props.values.formValues.box_fk_box_situation_id > 2 && (<>
                <Button loading={this.state.loading} onClick={() => this.trocaStatus('reabrir')} color={"green"} className="ml-4">
                    <i className="fas fa-undo-alt fa-fw mr-2"></i>
                    Reabrir Embalagem
                </Button>
            </>)}


            <Button loading={this.state.loading} color={"blue"} className="ml-4" onClick={() => this.isPrintRomanian('romaneio')}>
                <i className="fas fa-file-import"></i> Imprimir Romaneio
            </Button>
            <Button loading={this.state.loading} color={"blue"} className="ml-4" onClick={() => this.isPrintRomanian('etiqueta')}>
                <i className="fas fa-file-import"></i> Imprimir Etiqueta
            </Button>

        </>)

    }
}

const BoxFooterWithRouter = withRouter(BoxFooterComponent);

export class BoxFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <BoxFooterWithRouter onSubmit={onSubmit} printId={this.globalState.oldValues.psm_id} values={this.globalState} />
            </>
        );
    };
}
