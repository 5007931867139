// import axios from "../../utilities/axios";
import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmItemDescriptionListFkProductsIdEvents extends Event { 
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        // console.log("HISTORY",values);
        // console.log("VALUE:::", value);
         if(value && value > 0) {
             this.setState ("loadings", [
                "psm_item_description_list_intern_reference",
                "psm_item_description_list_product_id",
                "psm_item_description_list_unit",
                "psm_item_description_list_complement_description"
             ]);
             axios
             .get("/api/v1/product/" + value)
             .then((res => {
                 if (res.status === 200 && res) { 
                     //console.log("Meu response", res.data)
                     this.setFieldValue ("psm_item_description_list_complement_description", res.data.item.product_observation);
                     this.setFieldValue ("psm_item_description_list_unit", res.data.item.measure_unit.measure_unit_name);
                     this.setFieldValue ("psm_item_description_list_intern_reference", res.data.item.product_intern_reference);
                    //  this.setFieldValue ("psm_product_registration_code_product", res.data.item.product_manufacturer_code);
                     this.setFieldValue ("psm_item_description_list_product_id", JSON.stringify(res.data.item.product_id));
                     // this.setFieldValue ("psm_registration_product_NCM_product", res.data.product_fk_fiscal_classification_id);
                 }
             }))
             .finally(() => {
                 this.setState("loadings", []);
             }); 
         }
         if(value == null ){
            this.setFieldValue ("psm_item_description_list_complement_description", "");
            this.setFieldValue ("psm_item_description_list_unit", "");
            this.setFieldValue ("psm_item_description_list_intern_reference", "");
           //  this.setFieldValue ("psm_product_registration_code_product", res.data.item.product_manufacturer_code);
            this.setFieldValue ("psm_item_description_list_product_id", "");
        }
     }

}