import React from "react";
import { Button, Modal, Table } from "rsuite";
import _ from "lodash";


const { Column, HeaderCell, Cell } = Table;


export class HistoricModal extends React.Component<any, {}> {
    public state = {
        loading: true,
    }

    filterDataOpen = () => {
        var output = [];

        var data = this.props.dataOpen;
        //console.log("Testando o data grid", data)
        for (var i in data) {
            //console.log("Meu data", data[i])
            var row = data[i].psm_product_registration_obs_client;
            
            //console.log("Meu Row", row)
            var resultadoOutput = row?.replace(/<\/?[^>]+(>|$)/g, "");
            output.push({convertText:resultadoOutput});

        }
        //Construção para o tipo de merge de dados (Mateus)
        var dadosMerge = _.merge(output,data);
        //console.log("testando Merge",dadosMerge);
        return output;
    }
    
    filterData = () => {
        var output = [];

        var data = this.props.dataSell;
        //console.log("Testando o data grid", data)
        for (var i in data) {
            //console.log("Meu data", data[i])
            var row = data[i].psm_product_registration_obs_client;
            
            //console.log("Meu Row", row)
            var resultadoOutput = row?.replace(/<\/?[^>]+(>|$)/g, "");
            output.push({convertText:resultadoOutput});

        }
        //Construção para o tipo de merge de dados (Mateus)
        var dadosMerge = _.merge(output,data);
        //console.log("testando Merge",dadosMerge);
        return output;
    }
    
    render() {
        var dataOpen = this.filterDataOpen()
        var data = this.filterData();
        // console.log(data);
        if (this.props.show === false) {
            return null;
        }
        return (
            <Modal show={true} className="rs-modal-full">
                
                <Modal.Body>
                <Modal.Header closeButton={false} >
                    <h4>{this.props.title ?? "Deseja mesmo sair?"}</h4>
                </Modal.Header>
                <div>
                    <Table loading={this.props.loading} wordWrap height={400} data={data}>
                        <Column width={70} align="left" resizable>
                            <HeaderCell>Id</HeaderCell>
                            <Cell dataKey="psm_product_registration_id" />
                        </Column>

                        <Column width={130} resizable>
                            <HeaderCell>PSM Referente</HeaderCell>
                            <Cell dataKey="psm_product_registration_fk_psm_id" />
                        </Column>

                        <Column width={130} resizable>
                            <HeaderCell>Produto</HeaderCell>
                            <Cell dataKey="psm_product_registration_description" />
                        </Column>

                        <Column width={200} resizable>
                            <HeaderCell>Obs do Pedido</HeaderCell>
                            <Cell dataKey="convertText" />
                        </Column>

                        <Column width={200} resizable>
                            <HeaderCell>Quantidade</HeaderCell>
                            <Cell dataKey="psm_product_registration_amount" />
                        </Column>

                        <Column width={200} resizable>
                            <HeaderCell>Unidade</HeaderCell>
                            <Cell dataKey="psm_product_registration_unit_product" />
                        </Column>

                        <Column width={250} resizable>
                            <HeaderCell>NCM</HeaderCell>
                            <Cell dataKey="psm_product_registration_ncm" />
                        </Column>

                        </Table>
                        {/* <hr/> */}
                    </div>
                    <Modal.Header closeButton={false} style={{marginTop:30}}>
                        <h4>{this.props.subTitle ?? "Deseja mesmo sair?"}</h4>
                    </Modal.Header>
                    <div>
                        <Table loading={this.props.loading} wordWrap height={400} style={{marginTop:20}} data={dataOpen}>
                            <Column width={70} align="left" resizable>
                                    <HeaderCell>Id</HeaderCell>
                                    <Cell dataKey="psm_product_registration_id" />
                            </Column>

                            <Column width={130} resizable>
                                <HeaderCell>PSM Referente</HeaderCell>
                                <Cell dataKey="psm_product_registration_fk_psm_id" />
                            </Column>

                            <Column width={130} resizable>
                                <HeaderCell>Produto</HeaderCell>
                                <Cell dataKey="psm_product_registration_description" />
                            </Column>

                            <Column width={200} resizable>
                                <HeaderCell>Obs do Pedido</HeaderCell>
                                <Cell dataKey="convertText" />
                            </Column>

                            <Column width={200} resizable>
                                <HeaderCell>Quantidade</HeaderCell>
                                <Cell dataKey="psm_product_registration_amount" />
                            </Column>

                            <Column width={200} resizable>
                                <HeaderCell>Unidade</HeaderCell>
                                <Cell dataKey="psm_product_registration_unit_product" />
                            </Column>

                            <Column width={250} resizable>
                                <HeaderCell>NCM</HeaderCell>
                                <Cell dataKey="psm_product_registration_ncm" />
                            </Column>
                        </Table>
                        {/* <hr/> */}
                    </div>
                </Modal.Body>
            
                <Modal.Footer style={{marginTop:30}}>
                    {this.props.showConfirm !== false && <Button loading={this.props.loading} onClick={this.props.onConfirm} color={this.props.confirmColor ?? 'red'}>
                        {this.props.confirmText ?? 'Confirmar'}
                    </Button>}
                    <Button onClick={this.props.onCancel} color={this.props.cancelColor ?? undefined} >
                        {this.props.cancelText ?? 'Cancelar'}
                    </Button>
                    
                </Modal.Footer>
            </Modal>
        );
    }
}
