
import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmProductRegistrationFkSelectIdEvents extends Event {
    public state = {
        loading: false,
    }
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
       // console.log("HISTORY",values);
       // console.log("VALUE:::", value);
        if(value && value > 0) {
            this.setState ("loadings", [
                "psm_product_registration_description",
                "psm_product_registration_unit_product",
                "psm_product_registration_ncm",
                "psm_product_registration_reference",
                "psm_product_registration_code_product",
                "psm_product_registration_base_price",
                "psm_product_registration_margin",
                "psm_product_registration_politic",
                "psm_product_registration_ipi",
                "psm_product_registration_number_idregistration",

            ]);
            axios
            .get("/api/v1/product/" + value)
            .then((res => {
                if (res.status === 200 && res) { 

                    var margin = res.data.item.product_ideal_margin ;
                    // console.log(margin)
                    var marginDiscount = (margin/1000)*300+(margin/100*100);
                    // var impostSum = (pis: number, cofins: number,) => {
                        
                    // }
                    // var sellPrice =  (margin: number, price: number) => {
                        
                    // }
                    // console.log("testando ipi::::",res.data);
                    // console.log(res.data.product_fk_fiscal_classification_id);
                    var Idfiscal = res.data.item.product_fk_fiscal_classification_id;
                    // console.log(Idfiscal);
                    // var series = res.data.item.product_serie
                    this.setFieldValue ("psm_product_registration_description", res.data.item.product_description);
                    this.setFieldValue ("psm_product_registration_unit_product", res.data.item.measure_unit.measure_unit_name);
                    this.setFieldValue ("psm_product_registration_fk_unit_id", res.data.item.measure_unit.measure_unit_id);
                    this.setFieldValue ("psm_product_registration_reference", res.data.item.product_intern_reference);
                    this.setFieldValue ("psm_product_registration_code_product", res.data.item.product_manufacturer_code);
                    this.setFieldValue ("psm_product_registration_base_price", res.data.item.product_base_price_tax);
                    this.setFieldValue ("psm_product_registration_politic", margin);
                    this.setFieldValue ("psm_product_registration_margin", marginDiscount);
                    this.setFieldValue ("psm_product_registration_ipi", res.data.item.product_ipi);
                    this.setFieldValue ("psm_product_registration_number_idregistration", JSON.stringify(res.data.item.product_id));
                    // this.setFieldValue ("psm_registration_product_NCM_product", res.data.product_fk_fiscal_classification_id);
                    axios
                        .get("/api/v1/fiscal-classifications/" + Idfiscal)
                        .then((res => {
                            if(res.status === 200 && res) {
                                if (res.data.item == null || res.data.item === "null") {
                                    this.setFieldValue ("psm_product_registration_ncm", "Não existe NCM para esse produto");
                                }else {
                                    // console.log("testando NCM",res)
                                    var classificacaoAbreviada = res.data.item.fiscal_classification_name ? res.data.item.fiscal_classification_abbreviation + "-" + res.data.item.fiscal_classification_name : "Não existe"
                                    // console.log(classificacaoAbreviada);
                                    this.setFieldValue ("psm_product_registration_ncm", classificacaoAbreviada);
                                }
                                
                            }
                        }))
                        .finally(() => {
                            this.setState("loadings", []);
                        }); 
                }
            }))
            .finally(() => {
                this.setState("loadings", []);
            }); 
            
            // axios
            // .get("/api/vi/estimates/" + sessionStorage.getItem("currentParentIndex"))
            // .then((res => {
            //     if(res.status === 200 && res) {
            //         console.log("testando psm :::::", res);
            //     }
            // }))
        //    console.log(this);
        //     axios
        //     .get("/api/v1/psm/productHistorical/" + value + "/" + sessionStorage.getItem("currentParentIndex"))
        //     .then((res => {
        //         if(res.status === 200) {
        //             console.log(res);
        //         }
                
        //     }))

            
        }
    }

}