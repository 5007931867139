import axios from "../../utilities/axios";
import { Event } from "../Event";

export class MaterialRequisitionLogisticFkSelectIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        // console.log("Valor do this global props", this.globalProps)
        this.setState('loadings', [
            'material_requisition_logistic_intern_reference',
            'material_requisition_logistic_manufacture_code',
            // 'material_requisition_logistic_fk_select_id'
        ])

        await axios
            .get(`/api/v1/products/${value}`)
            .then((res) => {
                if (res.data.status == 200) {
                    // console.log(res.data.item.product_intern_reference);
                    const fieldValue = res.data.item;
                    this.setFieldValue('material_requisition_logistic_intern_reference', fieldValue.product_intern_reference);
                    this.setFieldValue('material_requisition_logistic_manufacture_code', fieldValue.product_manufacturer_code);
                    this.setFieldValue("material_requisition_logistic_item_description", res.data.item.product_description);
                }
            })
            .finally(() => {
                this.setState('loadings', []);
            })
            .catch((e) => {
                console.error(e);
            })
    }
}