import { Event } from "../Event";
import axios from "../../utilities/axios";
import dayjs from "dayjs";

export class PsmFollowupEvents extends Event {
    public onInit = () => {
        const loadings: string[] = [...(this.globalState.loadings ?? [])];
        loadings.push("psm_user");
        this.setState({ loadings });
        this.setFieldValue('psm_followup_created_at',dayjs().format('YYYY-MM-DD\THH:mm:ss\Z'));
        axios
            .get("/api/v1/me")
            .then((subRes) => {
                var subData = subRes.data;
                //console.log(subData);
                if (subData) {
                    this.setFieldValue("psm_followup_user_name", subData.user.user_name);
                }
            })
            .catch((error) => {})
            .finally(() => {
                const loadings = [...this.globalState.loadings];
                loadings.splice(loadings.indexOf("psm_user"));
                this.setState({ loadings });
            });
    }
}