import React from "react";
import { Loader, Modal, Tooltip, Whisper, Button } from "rsuite";
import axios from "../../../utilities/axios";
import Browserlize from "../../Browserlize";
import { Permission } from "../../Permission";
import classNames from "classnames";

export class ConnectGmail extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        started: false,
        loadingClockIn: false,
    }

    public select: any = {};
    componentDidMount() {
        axios.get('/api/v1/companies/select?cache=false&search=&concat=companies.company_fantasy').then((res) => {
            this.setState({ companyData: res.data.items })
        })

    }
    handleWindowClosed() {
        window.location.reload();
    }

    generateKeys = () => {
        const idUser: number = this.props.values.user_id;
        this.setState({ loading: true });
        
        axios.get(`/api/v1/gmail/get-url/${idUser}`).then((res) => {
            const newWindow: any = window.open(res.data.url + '&teste=123', "_blank", "toolbar=0, location=0, menubar=0");

            const intervalId = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(intervalId);
                    this.handleWindowClosed();
                }
            }, 500); 
        })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <Button loading={this.state.loading} color={"violet"} style={{ marginTop: '24px' }} onClick={this.generateKeys} className="ml-4">
                <i className={classNames("fas fa-fw mr-2", this.state.started ? "fa-pause" : "fa-play")}></i>
                Conectar ao gmail
            </Button>
        )
    }
}
