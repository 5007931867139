import React from "react";
import { Button } from "rsuite";

export class ImportObsClient extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        data: [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
    }

    componentDidMount = async () => {

    }

    onChange = (item: any) => {
        this.props.onChange()
    };

    ImportObs = () => {
        this.props.onChange(true);
    }

    render() {
        return (
            <>
                <Button color="violet" size={"lg"} loading={this.state.loading} style={{ width: "200px", marginTop: "40px" }}
                    onClick={() => this.ImportObs()}>
                    <i className="fas fa-file-import"></i> Importar Obs. Cliente
                </Button>
            </>
        )
    }
}