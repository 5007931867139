import { Notification } from "rsuite";
import axios from "../../utilities/axios";
import { Event } from "../Event";

export class UserManagerFkUserIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        const userId = this.globalProps.values.user_id;
        axios
            .get('/api/v1/users/' + value)
            .then((response: any) => {
                const userManager = response.data.item;
                if(userManager.user_manager_fk_user_id == userId) {
                    Notification.warning({
                        title: "Atenção!",
                        description: 'Seu usuário já é Gestor desse usuário!',
                    });
                    this.setFieldValue('user_manager_fk_user_id', );
                }
            }).catch((e) => {
                console.log(e)
            })
    }
}