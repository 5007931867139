// import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PsmItemDescriptionProductSliderSituationEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if(value == true){
            this.setFieldValue ("psm_item_description_product_complement_description", "");
            this.setFieldValue ("psm_item_description_product_unit", "");
            this.setFieldValue ("psm_item_description_product_intern_reference", "");
           //  this.setFieldValue ("psm_product_registration_code_product", res.data.item.product_manufacturer_code);
            this.setFieldValue ("psm_item_description_product_product_id", "");
            this.setFieldValue ("psm_item_description_product_campo_fk_product_id",);
        }
             
    }
}