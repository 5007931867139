import axios from "../../../utilities/axios";
import React from "react";
import _ from "lodash";
import { ControlLabel, FormGroup, Input, InputGroup } from "rsuite";

export class Reviewer extends React.Component<any, {}> {
    public state: any = {
        loading: true,
        fields: {},
        apiOldResults: {},
        apiNewResults: {},
        loadingFields: [],
    };

    componentDidMount() {
        const { $GLOBALS } = this.props.parent ?? {};
        if ($GLOBALS) {
            axios.get("/api/v1/" + $GLOBALS.table + "/fields").then((res) => {
                if (res.data?.fields) {
                    this.setState({ fields: res.data.fields, loading: false }, this.loadEveryRelationField);
                }
            });
        }
        // axios
    }

    loadEveryRelationField = () => {
        const diffs = this.getDiff();
        // console.log({ diffs });
        // this.setState({loading: true});
        for (var i in diffs) {
            const item = diffs[i];
            const field = this.state.fields[item.field];
            if (field?.type == "select") {
                var newresult = item.new;
                var oldresult = item.old;
                try {
                    if (field.displayLabel) {
                        if (field.api) {
                            this.loadApiFromField(newresult, field);
                            this.loadApiFromField(oldresult, field, "old");
                        }
                    }
                } catch (e) {}
            }
        }
    };

    getFirstLevel = (newData = true) => {
        const data = JSON.parse(newData ? this.props.values.psm_review_log_new_data : this.props.values.psm_review_log_old_data);
        const keys = Object.keys(data);
        const flattenOnly = _.filter(keys, (item: any, index: any) => {
            return typeof data[item] !== "object";
        });
        // return _.omit(data, flattenOnly);
        return JSON.parse(newData ? this.props.values.psm_review_log_new_data : this.props.values.psm_review_log_old_data);
    };

    getDiff = () => {
        const oldData: any = this.getFirstLevel(false);
        const newData: any = this.getFirstLevel(true);
        const diff = [];
        for (var i in oldData) {
            if (oldData[i] !== newData[i]) {
                if (typeof newData[i] !== "object") {
                    diff.push({
                        old: oldData[i],
                        new: newData[i],
                        field: i,
                    });
                }
            }
        }
        // const diff = _.differenceWith(oldData, newData, _.isEqual);
        const skips: string[] = ["psm_id", "psm_created_at", "psm_updated_at", "psm_date"];
        return _.filter(diff, (item: any) => {
            return !skips.includes(item.field);
        });
    };

    loadApiFromField = (value: string | number, field: any, target: "old" | "new" = "new") => {
        this.setState({ loadingFields: [...this.state.loadingFields, field.name+target] });
        axios
            .get(field.api.url, {
                params: {
                    id: value,
                },
            })
            .then((res) => {
                if (res.data.items && res.data.items[0]) {
                    var targetName = 'api'+(target == 'new' ? 'NewResults' : 'OldResults');
                    var apiResult = { ...this.state.apiNewResults };
                    if (target == "old") {
                        apiResult = { ...this.state.apiOldResults };
                    }
                   
                    apiResult[field.name] = res.data.items[0].label;

                    console.log({ apiResult });
                    this.setState({
                        [targetName]: apiResult,
                    });
                }
            })
            .finally(() => {
                this.setState({ loadingFields: this.state.loadingFields.filter((item: any) => item !== field.name+target) });
            });
    };

    renderDiff = () => {
        const output = [];
        const diffs = this.getDiff();
        // this.setState({loading: true});
        for (var i in diffs) {
            const item = diffs[i];
            const field = this.state.fields[item.field];
            if (field) {
                var newresult = item.new;
                var oldresult = item.old;
                if (field?.type == "select") {
                    try {
                        if (field.displayLabel) {
                            if (field.api) {
                                if(this.state.loadingFields.includes(field.name)) {
                                    newresult = 'Carregando...'
                                } else {
                                // newresult = field.name;
                                    newresult = this.state.apiNewResults[field.name]// ? this.state.apiResults[field.name] : "Carregando...";
                                    oldresult = this.state.apiOldResults[field.name]// ? this.state.apiResults[field.name] : "Carregando...";
                                }
                                // oldresult = this.state.apiOldResults[field.name] ? this.state.apiResults[field.name] : "Carregando...";
                                // this.loadApiFromField(newresult, field.api);
                                // this.loadApiFromField(oldresult, field.api,'old');
                                // newresult = JSON.stringify(field.api);
                            } else {
                                newresult = field.displayLabel.split(".").reduce((o: any, i: any) => o[i], this.getFirstLevel(true));
                                oldresult = field.displayLabel.split(".").reduce((o: any, i: any) => o[i], this.getFirstLevel(false));
                            }
                        } else {
                            if (field.options) {
                                newresult = _.find(field.options, { value: item.new.toString() }).label;
                                oldresult = _.find(field.options, { value: item.old.toString() }).label;
                            }
                        }
                    } catch (e: any) {}
                }
                output.push(
                    <div className="col-md-12">
                        <FormGroup>
                            <ControlLabel>
                                <span dangerouslySetInnerHTML={{ __html: field.label }}></span>
                            </ControlLabel>
                            <InputGroup style={{ width: "100%" }} className="col-md-12 w-100">
                                <Input defaultValue={oldresult} />
                                <InputGroup.Addon>
                                    <i className="fas fa-arrow-right"></i>
                                </InputGroup.Addon>
                                <Input defaultValue={newresult} />
                            </InputGroup>
                        </FormGroup>
                    </div>,
                );
            }
        }
        return <div className="col-md-12 row">{output}</div>;
    };
    render() {
        return (
            <div className="row">
                {this.state.loadingFields.length <= 0 && this.renderDiff()}
            </div>
        );
    }
}
