import React from "react";
import "./style/App.css";
import "./style/rsuite.css";
import Topbar from "./components/topbar/main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { AuthContext, ModuleContext, UserContext } from "./components/authProvider/main";
import DashboardRoutes from "./pages/routes/DashboardRoutes";
import Sidebar from "./components/sidebar/main";
import axios from "./utilities/axios";
import ProtectedRoute from "./components/protectedRoute/main";
import { Button, Modal, Notification } from "rsuite";
import { onError } from "./utilities/errorHandler";
import { PrettyError } from "./components/pretty-error/main";
import { io } from "./utilities/io";
import SocketIoEvents from "./components/socketIoEvents/SocketIoEvents";
// import { MainChat } from "./components/chat/MainChat";
// import { Helmet } from "react-helmet";

export class App extends React.Component<any, {}> {
    public state: any = {
        hasError: false,
        errorMessage: '',
        auth: {
            token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
            isAuthenticated: localStorage.getItem("token") ? true : false,
            setAuth: (token: string | null) => {
                if (token == null) {
                    localStorage.removeItem("token");
                    this.setState((old: any) => {
                        var newModule = { ...old.auth };
                        newModule.token = token;
                        newModule.isAuthenticated = false;
                        return { auth: newModule };
                    }, () => {
                        this.state.user.setUser(null);
                    });
                    // this.setState({ auth: { token, isAuthenticated: false } });
                } else {
                    localStorage.setItem("token", token);
                    this.setState((old: any) => {
                        var newModule = { ...old.auth };
                        newModule.token = token;
                        newModule.isAuthenticated = true;
                        // console.log("new module", newModule)
                        return { auth: newModule };
                    }, () => {
                        axios.get('/api/v1/me').then((res) => {
                            if (res.data && res.data.user) {
                                this.state.user.setUser(res.data.user);
                            }
                        }).catch((e) => {
                            this.state.user.setUser(null);
                        })
                    });
                    // this.setState({ auth: { token, isAuthenticated: true } });
                }
            },
        },
        user: {
            data: null,
            setUser: (data: any) => {
                this.setState((old: any) => {
                    var newModule = { ...old.user };
                    newModule.data = data;
                    return { user: newModule };
                });
            },
        },
        module: {
            name: null,
            crud: null,
            page: null,
            color: null,
            setPage: (name: string | null = null) => {
                this.setState((old: any) => {
                    var newModule = { ...old.module };
                    // console.log("newModule", newModule)
                    if (name != null) {
                        newModule.page = name;
                    }
                    // console.log("newModule", newModule)
                    return { module: newModule };
                });
            },
            setModule: (name: string | null = null, color: string | null = null, crud = null) => {
                this.setState((old: any) => {
                    var newModule = { ...old.module };
                    if (name != null) {
                        newModule.name = name;
                    }
                    if (color) {
                        newModule.color = color;
                    }
                    if (crud) {
                        newModule.crud = crud;
                    }
                    return { module: newModule };
                });
                // this.setState({ module: { name,setModule: this.state.module.setModule } });
            },
        },
    };

    componentDidMount() {
        if (this.state.auth.token != null) {
            this.state.auth.setAuth(this.state.auth.token);
        }
        // window.location.reload()
        onError('teste', (err: any) => {
            // alert(err);
            this.setState({ hasError: true, errorMessage: err.data })
        })


    }


    copyToClipboard = () => {
        const textToCopy = window.location.host.split('25565').join('25567')+this.state.errorMessage.file; // Substitua 'textoParaCopiar' pelo ID do elemento que contém o texto

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                Notification.success({
                    title: 'Copiado',
                    description: 'Texto copiado para a área de transferência!'
                })
            })
            .catch(err => {
                Notification.error({
                    title: 'Erro',
                    description: `Erro ao copiar texto: ${err}`
                })
            });
    };

    render() {
        return (
            <div>

                <Modal show={this.state.hasError} size={"md"} >
                    <Modal.Body>
                        <PrettyError error={this.state.errorMessage} />
                    </Modal.Body>
                    <Modal.Footer className="mt-4">
                        <Button 
                            onClick={() => this.copyToClipboard()}
                            color="green"
                        ><i className="far fa-copy" style={{ marginRight: '5px' }}></i>Copiar</Button>
                        <Button onClick={() => this.setState({ hasError: false })}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
                <Router>
                    <AuthContext.Provider value={this.state.auth}>
                        <ModuleContext.Provider value={this.state.module}>
                            <UserContext.Provider value={this.state.user}>
                                <SocketIoEvents user={this.state.user} />
                                <Sidebar />
                                <Topbar />
                                {/* <MainChat /> */}
                                <Switch>
                                    <Route exact path="/" component={LoginPage}></Route>
                                    <Route exact path="/login" component={LoginPage}></Route>
                                    <ProtectedRoute path="/dashboard" component={DashboardRoutes}></ProtectedRoute>
                                </Switch>
                            </UserContext.Provider>
                        </ModuleContext.Provider>
                    </AuthContext.Provider>
                </Router>
            </div>
            // <div className="App">
            //         <div className="container">
            //             <div className="row">
            //                 <div className="col-md-12 mb-4">
            //                     <Button color="violet" onClick={this.load}>
            //                         Atualizar
            //                     </Button>
            //                 </div>
            //                 <div className="col-md-12">
            //                     <List>{this.renderList()}</List>
            //                 </div>
            //             </div>
            //         </div>
            //     <Sidebar />
            //     <Topbar />

            //     {Object.keys(this.state.formulario).length > 0 && <Formalize name={this.state.name} values={{}} form={this.state.formulario} />}
            //     {Object.keys(this.state.formulario).length > 0 && <Browserlize name={this.state.name} values={{}} form={this.state.formulario} />}
            // </div>
        );
    }
}

// export default App;
