
import axios from "../../utilities/axios";
import { Event } from "../Event";


export class ProductSupplyFkPersonIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {

        this.setState("loadings", ['product_supply_fantasy', 'product_supply_cnpj'])
        axios 
        .get("/api/v1/products/get-supplier-fantasy/", {
            params : {
                id: this.globalProps?.values?.product_supply_fk_person_id
            }
        }).then((res) => {
            if (res.data.status == 200) {
                // console.log(res.data.item.product_intern_reference);
                const fieldValue = res?.data?.data;
                this.setFieldValue('product_supply_fantasy', fieldValue?.person_fantasy)
                this.setFieldValue('product_supply_cnpj', fieldValue?.person_cnpj_cpf)
                // console.log("wfiaioefjaofie :::>", fieldValue)
            }
        })
        .finally(() => {
            this.setState('loadings', []);
        })
        .catch((e) => {
            console.error(e);
        })
     };
}