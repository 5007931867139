
import { Event } from "../Event";
import axios from "../../utilities/axios";
import dayjs from "dayjs";

export class PsmProductRegistrationSliderEvents extends Event {

    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if( value == true) {
            console.log("Entrou")
            this.setState ("loadings",[
                "psm_product_registration_ipi",
                "psm_product_registration_reference",
                "psm_product_registration_unit_product",
                "psm_product_registration_ncm",
                "psm_product_registration_description",
                "psm_product_registration_number_idregistration",
                "psm_product_registration_base_price",

                
    
            ]);
            this.setFieldValue("psm_product_registration_ipi", "");
            this.setFieldValue("psm_product_registration_reference", "")
            this.setFieldValue("psm_product_registration_unit_product", "")
            this.setFieldValue("psm_product_registration_ncm", "")
            this.setFieldValue("psm_product_registration_description", "")
            this.setFieldValue("psm_product_registration_number_idregistration", "")
            this.setFieldValue("psm_product_registration_base_price", "")


            this.setState("loadings", []);
        }
        if( value == false) {
            this.setState ("loadings",[
                "psm_product_registration_ipi"
                
    
            ]);
            this.setFieldValue("psm_product_registration_ipi", null);
            this.setState("loadings", []);
        }
    }
}