import React from "react";
import { Button, Loader, Modal, Table } from "rsuite";
import { Event } from "../Event";
import axios from "../../utilities/axios";
import { masker } from "../../utilities/masker";

class SellerCardsModal extends React.Component<any, {}> {
    render() {
        return (
            <Modal overflow={false} show={this.props.show}>
                <Modal.Header closeButton={false} onHide={this.props.onClose}></Modal.Header>
                <Modal.Body>
                    {this.props.loading && (
                        <div className="d-flex justify-content-center">
                            <Loader size={"md"}></Loader>
                        </div>
                    )}
                    {!this.props.loading && <div>{this.props.message ?? "Esse cliente já possui um vendedor desse tipo!"}</div>}
                </Modal.Body>
                {!this.props.loading && (
                    <Modal.Footer>
                        <Button onClick={this.props.onCancel}>Cancelar</Button>
                        <Button onClick={this.props.onConfirm} color={"green"}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        );
    }
}

export class SellerCardsEvents extends Event {
    public lastAddedIndex: number = -1;
    public lastAddedItem: any = null;
    public onAdd = (value: any, index: any) => {
        if (this.globalState.formValues.seller_cards) {
            this.setFieldState("seller_cards", {
                show: true,
                value,
                index,
                loading: true,
            });

            axios
                .post("/api/v1/sellers-clients/availability", {
                    data: value,
                    id: this.globalState?.formValues?.seller_id ?? undefined,
                    is_extern: this.globalState?.formValues?.seller_is_extern ?? false,
                })
                .then((res) => {
                    this.setFieldState("seller_cards", {
                        show: false,
                    });
                })
                .catch((e) => {
                    if (e.response.status !== 403) {
                        if (e.response.data.seller) {
                            this.setFieldState("seller_cards", {
                                show: true,
                                value,
                                index,
                                loading: false,
                                message: (
                                    <div>
                                        Esse cliente já está vinculado ao vendedor:
                                        <br />
                                        <Table data={[e.response.data.seller]}>
                                            <Table.Column flexGrow={1}>
                                                <Table.HeaderCell>ID</Table.HeaderCell>
                                                <Table.Cell>
                                                    {(rowData: any) => {
                                                        return rowData["seller_id"];
                                                    }}
                                                </Table.Cell>
                                            </Table.Column>
                                            <Table.Column flexGrow={1}>
                                                <Table.HeaderCell>Nome</Table.HeaderCell>
                                                <Table.Cell>
                                                    {(rowData: any) => {
                                                        return rowData["seller_name"];
                                                    }}
                                                </Table.Cell>
                                            </Table.Column>
                                            <Table.Column flexGrow={1}>
                                                <Table.HeaderCell>CPF/CNPJ</Table.HeaderCell>
                                                <Table.Cell>
                                                    {(rowData: any) => {
                                                        return masker.apply(rowData["seller_cnpj_cpf"], ["999.999.999-99"]);
                                                    }}
                                                </Table.Cell>
                                            </Table.Column>
                                        </Table>
                                        Deseja trocar ?
                                    </div>
                                ),
                            });
                        } else {
                            this.setFieldState("seller_cards", {
                                show: false,
                            });
                        }
                    } else {
                        this.setFieldState("seller_cards", {
                            show: false,
                        });
                    }
                });
        }
    };
    public onDelete = () => {
        // alert("DELETE");
    };
    public onEdit = () => {
        console.log("EDIT");
    };
    public onConfirm = (index: any) => {
        // //console.log(this.globalState.crud);
        const prefix = this.globalState?.originalForm?.$GLOBALS?.prefix;
        if (prefix) {
            // console.log("GLOBAL STATE",);
            const id = this.globalState.formValues[prefix + "_id"];
            // alert(JSON.stringify(this.state.value));
            axios
                .post("/api/v1/events/add", {
                    id,
                    crud: this.globalState.crud,
                    type: "update-client-sellers",
                    name: "onSave",
                    values: {
                        index: this.globalState.formValues.seller_cards.length - 1,
                        data: this.globalState.formValues.seller_cards[this.globalState.formValues.seller_cards.length - 1],
                    },
                })
                .then((res) => {})
                .catch((e) => {})
                .finally(() => {
                    this.setFieldState("seller_cards", {
                        show: false,
                    });
                });
        }
        // axios.post('')
    };
    public onCancel = () => {
        if (this.lastAddedIndex) {
            var oldSellerCards = this.globalState.formValues.seller_cards;
            this.setFieldValue("seller_cards", oldSellerCards.splice(this.lastAddedIndex, 1));
            this.setFieldState("seller_cards", {
                show: false,
            });
        }
    };
    public onRender = (fieldStates: any) => {
        return (
            <>
                <SellerCardsModal
                    message={fieldStates.message}
                    loading={fieldStates.loading ?? true}
                    onConfirm={() => this.onConfirm(fieldStates.index)}
                    onCancel={this.onCancel}
                    onClose={() => this.setFieldState("seller_cards", { show: false })}
                    show={fieldStates.show}></SellerCardsModal>
            </>
        );
    };
}
