import { type } from "os";
import React from "react";
import { Button, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import _ from "lodash";
import { roundedFloat } from "../../../utilities/CalcUtils";


export class ImportValueProduct extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data: [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        isImportModalOmie: false,
        omieData: [],
        OmieItens: [],
    }

    componentDidMount = async () => {
        await axios.get("/api/v1/products/select").then((res) => {
            // console.log("aqui:: ", res.data.items);
            this.setState({ data: res.data.items })

        })
        // console.log("Meu props :::: ", this.props.name)
    }
    updateData = async (value: any) => {
        await axios.get("/api/v1/products/select?search=" + value).then((res) => {
            // console.log("aqui:: ", res.data.items);
            this.setState({ data: res.data.items })

        })
    }

    public select: any = {};

    onChange = (item: any, valuesOmie: any) => {
        this.props.onChange()
    };

    isImportValueProducts = () => {
        this.setState({ loading: true })
        let sumValues: any = null;
        let sumValuesDiscount: any = null;
        let valueServiceRecommendedDiscount: any = null;
        let valueServiceRecommendedNotDiscount: any = null;

        const sumByFunction = function (object: any, nameTable: string) {
            let newValue: any = _.sumBy(object, function (product: any) {
                let value: number = 0;
                if (product[`${nameTable}_value_all`] !== undefined) {
                    if (product[`${nameTable}_reproving`] !== true) {
                        value = product[`${nameTable}_value_all`] !== null ? product[`${nameTable}_value_all`] : 0;
                    }
                }
                return value;
            });

            let newValueDiscount = _.sumBy(object, function (product: any) {
                let valueDiscount: number = 0;
                if (product[`${nameTable}_value_all`] !== undefined) {
                    if (product[`${nameTable}_reproving`] !== true) {
                        if (product[`${nameTable}_discount_value_all`] > 0) {
                            valueDiscount = product[`${nameTable}_discount_value_all`] !== null ? product[`${nameTable}_discount_value_all`] : 0;
                        }
                    }
                }
                return valueDiscount;
            });


            return ({
                newValue,
                newValueDiscount
            });
        }





        let valueProductRequired: any = sumByFunction(this.props.values.psm_relation_products, 'psm_relation_product');
        let valueProductRecommended: any = sumByFunction(this.props.values.psm_relation_services, 'psm_relation_service');
        let valueServiceRecommended: any = sumByFunction(this.props.values.psm_recommended_services, 'psm_recommended_service');


        sumValues = valueProductRequired.newValue + valueProductRecommended.newValue;
        sumValues = roundedFloat(sumValues);

        try {
            valueServiceRecommendedNotDiscount = valueServiceRecommended.newValue;
            valueServiceRecommendedNotDiscount = valueServiceRecommendedNotDiscount.toFixed(2);
            valueServiceRecommendedNotDiscount = parseFloat(valueServiceRecommendedNotDiscount);

            sumValuesDiscount = valueProductRequired.newValueDiscount + valueProductRecommended.newValueDiscount;
            sumValuesDiscount = sumValuesDiscount.toFixed(2);
            sumValuesDiscount = parseFloat(sumValuesDiscount);



            valueServiceRecommendedDiscount = valueServiceRecommended.newValueDiscount;
            valueServiceRecommendedDiscount = valueServiceRecommendedDiscount.toFixed(2);
            valueServiceRecommendedDiscount = parseFloat(valueServiceRecommendedDiscount);

            let newObjectDiscount = [{ sumValuesDiscount }, { valueServiceRecommendedDiscount }];

            let newObject = [{ sumValues }, { valueServiceRecommendedNotDiscount }];


            let mergedObjcts = [{ newObjectDiscount }, { newObject }]


            this.props.onChange(mergedObjcts)

            this.setState({ loading: false })
        } catch (e) { console.log('Error ', e) }

    }


    render() {
        return (<>
            <Button color="green" size={"md"} loading={this.state.loading} style={{ marginTop: 25, width: "200px" }} onClick={() => this.isImportValueProducts()}>
                <i className="fas fa-funnel-dollar"></i> Importar valor
            </Button>
        </>

        )
    }
}