import React from "react";
import { Button, DatePicker, FlexboxGrid, List, Tag, Tooltip, Whisper,Loader, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { Modal } from "rsuite";
import _ from "lodash";
import dayjs from "dayjs";
// import humanize from "humanize-duration";

export class ClockInPsm extends React.Component<any, {}> {
    public state = {
        loading: true,
        items: [],
        edit: null as null | number,
        startDate: null as Date | null,
        endDate: null as Date | null,
        dataInicial: null
    };

    componentDidMount() {
        if(this.props.values.psm_id){
            this.loadLogsFromEquipment();
        }
    }
    loadData = () => {
        this.setState({ loading: true });
        axios
            .get("/api/v1/clock-in/list/"+ this.props.values.psm_id) //list/"+ this.props.values.psm_id
            .then((res) => {
                this.setState({ items: res.data.items ?? [], loading: false });
            })
            .finally(() => {
                this.setState({ loading: false });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };
    componentDidUpdate(prevProps: any) {
        if (this.props.item !== undefined && this.props.item !== null && this.props.item !== prevProps.item) {
            alert("Entrou")
            this.loadLogsFromEquipment();
        }
    }

    loadLogsFromEquipment = () => {
        this.setState({ loading: true });
        axios
            .get("/api/v1/clock-in/list/" + this.props.values.psm_id )
            // {
            //     params: {
            //         psm: this.props.item,
            //     },
            // })
            .then((res) => {
                this.setState({
                    items: res.data.items,
                    loading: false,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    saveEdit = () => {
        if(this.state.startDate !== null && this.state.endDate !== null ){
            axios
            .post("/api/v1/clock-in/edit/" + this.state.edit, {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            })
            .then((res) => {
                this.setState({ edit: null });
                this.loadLogsFromEquipment();
            })
            .catch(() => {});
        }else{
            Notification.error({
                title: "Erro!",
                description: "Favor alterar antes de salvar",
            });
        }
        // console.log("Testando o date edit ::: ",this.state.edit);
        // console.log("Tesntando o startDate", this.state.startDate);
        // console.log("Tesntando o endDate", this.state.endDate)
        
    };
    cancelEdit= () => {
        this.setState({ edit: null });
        this.loadLogsFromEquipment();
    };

    getGroupedItems = (statusItems: any) => {
        const result = [];
        const items = _.orderBy(statusItems, ["clock_in_status_created_at"]);
        
        if (items) {
            const stops = _.filter(items, (item) => item?.clock_in_status_type == "stopped");
            const matched: any[] = [];
            for (var i in stops) {
                const stop = stops[i];
                const match = _.find(items, (item) => item.clock_in_status_id == stop.clock_in_status_ref_fk_clock_in_status_id);
               // console.log({stop});
                if(match) {
                    matched.push(match?.clock_in_status_id);
                    result.push([stop, match]);
                }
            }
            const unmatched = _.filter(items, (item) => !matched.includes(item?.clock_in_status_id) && item?.clock_in_status_type == "started");
            for (i in unmatched) {
                result.push([unmatched[i], null]);
            }
        }
        // console.log({result});
        return result.reverse();
    };

    public locale = {
        sunday: "DON",
        monday: "SEG",
        tuesday: "TER",
        wednesday: "QUA",
        thursday: "QUI",
        friday: "SEX",
        saturday: "SAB",
        ok: "Ok",
        today: "Hoje",
        yesterday: "Ontem",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
    };

    render() {
        return (
            <>         
              <div className="widget clock-in-psm">
                <div className="row w-100">
                    <List bordered className="w-100">
                        <List.Item className="header">
                            <FlexboxGrid align="middle">
                                <FlexboxGrid.Item colspan={2}>Usuário</FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={5}>Data de inicio</FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={5}>Data de término</FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}>Tempo</FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}>Status</FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={3}>Ações</FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                       {this.state.loading && ( <Loader backdrop />)}  
                                     
                        {this.state.items.map((item: any) => {
                            return _.map(this.getGroupedItems(item.status), (chunk: any) => {
                                // console.log({ chunk });
                                var subItem = chunk[1] ?? chunk[0];
                                //console.log("Testando chunk",subItem);
                                var dataPushFinal = [];
                                var datasPushInitial = [];
                                for(var i in subItem) {
                                    datasPushInitial.push(subItem[i]);
                                    dataPushFinal.push(subItem[i])
                                }
                               // console.log("Novo",datasPushInitial);
                                // this.setState({
                                //     dataInicial: datasPushInitial[3]
                                // })
                                var lastItem = chunk[1] ? chunk[0] : undefined;
                                return (
                                    <List.Item key={subItem?.clock_in_status_id}>                                     
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>{subItem?.user?.user_name}</FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={5}>
                                                {this.state.edit !== subItem?.clock_in_status_id && dayjs(subItem?.clock_in_status_created_at).format("DD/MM/YYYY HH:mm")}
                                                {this.state.edit === subItem?.clock_in_status_id && (
                                                    <DatePicker
                                                        locale={this.locale}
                                                        preventOverflow
                                                        value={this.state.startDate ?? datasPushInitial[3]}
                                                        onChange={(startDate) => this.setState({ startDate })}
                                                        format="DD/MM/YYYY HH:mm"></DatePicker>
                                                )}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={5}>
                                                {this.state.edit !== subItem?.clock_in_status_id && lastItem && dayjs(lastItem?.clock_in_status_created_at).format("DD/MM/YYYY HH:mm")}
                                                {this.state.edit === subItem?.clock_in_status_id && lastItem && (
                                                    <DatePicker
                                                        locale={this.locale}
                                                        preventOverflow
                                                        value={this.state.endDate ?? dataPushFinal[4]}
                                                        onChange={(endDate) => this.setState({ endDate })}
                                                        format="DD/MM/YYYY HH:mm"></DatePicker>
                                                )}
                                            </FlexboxGrid.Item>
                                            {/* <FlexboxGrid.Item colspan={4}>
                                                {lastItem &&
                                                    humanize(dayjs(lastItem?.clock_in_status_created_at).diff(dayjs(subItem?.clock_in_status_created_at), "s") * 1000, {
                                                        language: "pt",
                                                    })}
                                            </FlexboxGrid.Item> */}
                                            <FlexboxGrid.Item colspan={4}>
                                                <Tag color={lastItem ? "green" : "blue"}>
                                                    {lastItem ? "Finalizado" : "Iniciado"}
                                                    {/* {subItem?.clock_in_status_type == "started" ? "Iniciado" : "Pausado"} */}
                                                </Tag>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={3}>
                                                {/* <Button color="orange" size="sm"> */}
                                                {this.state.edit !== subItem?.clock_in_status_id && (
                                                    <Whisper speaker={<Tooltip>Editar</Tooltip>} placement="top">
                                                        <i
                                                            onClick={() =>
                                                                this.setState({
                                                                    date: dayjs(subItem?.clock_in_status_created_at).toDate(),
                                                                    edit: subItem?.clock_in_status_id == this.state.edit ? null : subItem?.clock_in_status_id,
                                                                })
                                                            }
                                                            className="fas text-warning clickable fa-pencil-alt fa-fw mr-2"></i>
                                                    </Whisper>
                                                )}
                                                {this.state.edit === subItem?.clock_in_status_id && (<>
                                                    <Whisper speaker={<Tooltip>Salvar</Tooltip>} placement="top">
                                                        <i onClick={() => this.saveEdit()} className="fas text-success clickable fa-check fa-fw mr-2"></i>
                                                    </Whisper>
                                                          <Whisper speaker={<Tooltip>Cancelar</Tooltip>} placement="top">
                                                          <i onClick={() => this.cancelEdit()} className="fas text-danger clickable fa-ban fa-fw mr-2"></i>
                                                      </Whisper>
                                                      </>
                                                )}
                                                {/* </Button> */}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                );
                            });
                        })}
                    </List>                  
                    </div>                    
       
            </div>
            </>
        );
    }
}
