
import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PurchaseRequisitionFkPsmIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        // console.log("Rodei aqui no ON CHANGE CGARAIO ", value)
 
        this.setState('loadings', [
            'purchase_requisition_number_psm_os_number',
            'purchase_requisition_fk_business_unity_id',
            'purchase_requisition_fk_person_id',
            'purchase_requisition_fk_situation_id',
            'purchase_requisition_fk_dibi_situation_id',
            'purchase_requisition_comercial_status',
            'purchase_requisition_priority',
            'purchase_requisition_fk_company_id',
            'purchase_requisition_os_number',
            'purchase_requisition_relations'

        ])

        axios.get(`api/v1/purchase/get-products/` + value)
            .then(async (res) => {
                let itens = res.data.response.itens;
                let newObject = [];        
                for (const t in itens) {
                    // console.log("itens", itens[t])
                    const isTypeTableProduct = itens[t].psm_relation_product_item_description ? 'product' : 'service';
                    console.log({isTypeTableProduct})
                        newObject.push({
                            purchase_requisition_relation_item_rc: Number(t) + 1,
                            purchase_requisition_relation_not_registered: itens[t][`psm_relation_${isTypeTableProduct}_registered`],
                            purchase_requisition_relation_installed: itens[t][`psm_relation_${isTypeTableProduct}_instal`],
                            purchase_requisition_relation_fk_select_id: itens[t][`psm_relation_${isTypeTableProduct}_fk_select_id`],
                            purchase_requisition_relation_intern_reference: itens[t][`psm_relation_${isTypeTableProduct}_intern_reference`],
                            purchase_requisition_relation_item_description: itens[t][`psm_relation_${isTypeTableProduct}_item_description`],
                            purchase_requisition_relation_manufacturer_code: itens[t][`psm_relation_${isTypeTableProduct}_manufacture_code`],
                            purchase_requisition_relation_quantity_total: itens[t][`psm_relation_${isTypeTableProduct}_quantity`]
                            // purchase_requisition_relation_total_quantity: value[i][`psm_relation_${isTypeTableProduct}_quantity`],
                            // purchase_requisition_relation_table_product_id: value[i][`psm_relation_${isTypeTableProduct}_id`],
                            // purchase_requisition_relation_product_table_name: `psm_relation_${isTypeTableProduct}s`,
                            // purchase_requisition_relation_observation: value[i][`psm_relation_${isTypeTableProduct}_obs`],
                            // purchase_requisition_relation_fk_select_id: value[i][`psm_relation_${isTypeTableProduct}_fk_select_id`],
                            // purchase_requisition_relation_psm_id: value[0][`psm_id`]
                        })                                  
                    }
                    // console.log("Vallor do newObject", newObject)
                    this.setFieldValue('purchase_requisition_relations', newObject)
            }).catch((e) => {
                console.log(e)
            })

        // axios.get(`/api/v1/purchase/` + value)
        // .then(async (res) => {
        //     let itens = res.data.item;
        //     if (!itens.psm_os_number) {
        //         await axios.get(`/api/v1/dibis/` + value)
        //             .then((res) => {
        //                 itens = res.data.item;
        //             })
        //             .catch((e) => {
        //                 console.log(e)
        //             })
        //     }
        //     let isTypePsm = itens.psm_os_number.substring(0,2);
        //     isTypePsm = isTypePsm.substring(1);
        //     const isCrud = isTypePsm == 'L' ? 'commercials' : 'dibi';
        //     const isSelectSituationPsm = isTypePsm == 'L' ? 'situation' : 'dibi_situation';

        //     this.setFieldValue('material_requisition_number_psm_os_number', itens.psm_os_number);
        //     this.setFieldValue('material_requisition_fk_business_unity_id', itens.psm_fk_business_unity_id);
        //     if(itens.business_unity.business_unity_abbreviation == 'LAB'){
        //         this.setFieldValue('material_requisition_fk_person_id', itens.psm_fk_person_id);
        //     }else{
        //         this.setFieldValue('material_requisition_fk_person_id', itens.person.person_id);
        //     }
        //     if (itens.psm_comercial_status !== null) {
        //         this.setFieldValue('material_requisition_comercial_status', itens.psm_comercial_status);
        //     }
        //     if (itens.psm_purpose_product !== null) {
        //         this.setFieldValue('material_requisition_purpose_product', itens.psm_purpose_product);
        //     }

        //     this.setFieldValue('material_requisition_priority', itens.psm_priority);
        //     this.setFieldValue('material_requisition_deadline', itens.psm_final_date_delivery);
        //     this.setFieldValue(`material_requisition_fk_${isCrud === 'commercials' ? 'situation' : 'dibi_situation'}_id`, itens[`${isSelectSituationPsm}`][`${isSelectSituationPsm}_id`]);
        //     // this.setFieldValue('material_requisition_fk_psm_id', value)
        //     this.setFieldValue('material_requisition_fk_company_id', itens.psm_fk_company_id)

        //     await axios.get(`/api/v1/material/generate-code/` + itens[`psm_fk_company_id`])
        //         .then((res) => {
        //             this.setFieldValue('material_requisition_os_number', res.data.code);
        //         })
        // })




        // const newObject = [];
        // for (const i in value) {
        //     let isValidType = false;
        //     for (const t in value[i]) {
        //         if (t.includes('_relation_product')) {
        //             isValidType = true;
        //             continue;
        //         }
        //     }

        //     const isTypeTableProduct = isValidType ? 'product' : 'service';
        //     newObject.push({
        //         purchase_requisition_relation_item_rc: Number(i) + 1,
        //         purchase_requisition_relation_not_registered: value[i][`psm_relation_${isTypeTableProduct}_registered`],
        //         purchase_requisition_relation_installed: value[i][`psm_relation_${isTypeTableProduct}_instal`],
        //         purchase_requisition_relation_intern_reference: value[i][`psm_relation_${isTypeTableProduct}_intern_reference`],
        //         purchase_requisition_relation_item_description: value[i][`psm_relation_${isTypeTableProduct}_item_description`],
        //         purchase_requisition_relation_manufacturer_code: value[i][`psm_relation_${isTypeTableProduct}_manufacture_code`]
        //         // purchase_requisition_relation_total_quantity: value[i][`psm_relation_${isTypeTableProduct}_quantity`],
        //         // purchase_requisition_relation_table_product_id: value[i][`psm_relation_${isTypeTableProduct}_id`],
        //         // purchase_requisition_relation_product_table_name: `psm_relation_${isTypeTableProduct}s`,
        //         // purchase_requisition_relation_observation: value[i][`psm_relation_${isTypeTableProduct}_obs`],
        //         // purchase_requisition_relation_fk_select_id: value[i][`psm_relation_${isTypeTableProduct}_fk_select_id`],
        //         // purchase_requisition_relation_psm_id: value[0][`psm_id`]
        //     })
        //     // console.log ("Eu to aqui 1 ::::::::",  value[i][`psm_relation_product_id`]);
        //     // console.log ("Eu to aqui 2 ::::::::", value[i]);
        // }
        // console.log("Valur do newObject", newObject)
        // this.setFieldValue('purchase_requisition_relations', newObject)



        if (this.globalProps.values.purchase_requisition_purpose_product !== null) {
            this.setFieldValue("purchase_requisition_purpose_product",)
        }
        axios.get(`/api/v1/commercials/` + value)
            .then(async (res) => {
                let itens = res.data.item;
                if (!itens.psm_os_number) {
                    await axios.get(`/api/v1/dibis/` + value)
                        .then((res) => {
                            itens = res.data.item;
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                }
                let isTypePsm = itens.psm_os_number.substring(0, 2);
                isTypePsm = isTypePsm.substring(1);
                const isCrud = isTypePsm == 'L' ? 'commercials' : 'dibi';
                const isSelectSituationPsm = isTypePsm == 'L' ? 'situation' : 'dibi_situation';

                this.setFieldValue('purchase_requisition_number_psm_os_number', itens.psm_os_number);
                this.setFieldValue('purchase_requisition_fk_business_unity_id', itens.psm_fk_business_unity_id);
                if (itens.business_unity.business_unity_abbreviation == 'LAB') {
                    this.setFieldValue('purchase_requisition_fk_person_id', itens.psm_fk_person_id);
                } else {
                    this.setFieldValue('purchase_requisition_fk_person_id', itens.person.person_id);
                }
                if (itens.psm_comercial_status !== null) {
                    this.setFieldValue('purchase_requisition_comercial_status', itens.psm_comercial_status);
                }
                this.setFieldValue('purchase_requisition_priority', itens.psm_priority);
                this.setFieldValue(`purchase_requisition_fk_${isCrud === 'commercials' ? 'situation' : 'dibi_situation'}_id`, itens[`${isSelectSituationPsm}`][`${isSelectSituationPsm}_id`]);
                // this.setFieldValue('purchase_requisition_fk_psm_id', value)
                this.setFieldValue('purchase_requisition_fk_company_id', itens.psm_fk_company_id)

                await axios.get(`/api/v1/purchase/generate-code/` + itens[`psm_fk_company_id`])
                    .then((res) => {
                        this.setFieldValue('purchase_requisition_os_number', res.data.code);
                    })
            })
            .finally(() => {
                this.setState('loadings', [])
            })
    }
}