import React from "react";
import { Table, Button, Toggle, Input, InputNumber, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { FilterableCell } from "../../customTable/FilterableCell";
import { SchemaToParsedCell } from "../../customTable/SchemaToParsedCell";
import _ from "lodash";
import { MaskInput } from "../MaskInput";
import { NumberInput } from "../NumberInput";
import { AxiosSelectPicker } from "../AxiosSelectPicker";

const { Column, HeaderCell, Cell } = Table;

export class GridCustomTemperatureSensorTen extends React.Component<any, {}> {


    public state: any = {
        isLoading: true,
        loading: false,
        isModalOpen: false,
        isEdit: null,
        form: {},
        totalWidth: 0,
        hiddenRows: [],
        selectedValues: {},
        searchs: {},
        items: _.cloneDeep(this.props.values.psm_custom_temperature_sensor_ten) ?? [],
        cachedData: [],
        rowQuantity: null
    };

    public elementRef: any = null;

    componentDidMount() {
        console.log('props :::> ', this.props.values)
        console.log(' aaaaaaaaaaaa :::: ', this.state.items)
        try {
            const cachedData = JSON.parse(localStorage.getItem("grid-cache-" + this.props.name) ?? JSON.stringify([]));
            localStorage.removeItem("grid-cache-" + this.props.name);
            this.setState({ cachedData });
        } catch (e) { }
        this.elementRef = React.createRef();
        var api = 'psm_steps_temperature_sensor_ten_tables'//'storage_local_lines'//this.props.field.api.split("_").join("-");
        axios
            .get("/api/v1/" + api + "/fields")
            .then((res) => {
                if (res && res.data && res.data.fields) {
                    var fields = res.data.fields;
                    fields = _.orderBy(res.data.fields,"order","asc")
                    if (this.props.formOverrides) {
                        for (var i in this.props.formOverrides) {
                            fields[i] = { ...fields[i], ...this.props.formOverrides[i] };
                        }
                    }

                    
                    this.setState({ form: fields });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    public cachedApiItems: any = {};

    // onSubmit = async (values: any) => {
    //     let cachedData = this.state.cachedData;
    //     for (var fieldName in values) {
    //         var value = values[fieldName];
    //         var field = this.state.form[fieldName];
    //         if (field) {
    //             if (field.type === "select" && field.hidden !== true && (field.api !== undefined || field.displayLabel !== undefined)) {
    //                 if (field.api && field.api.url) {
    //                     var url = field.api.url;

    //                     try {
    //                         var result = await axios.get(field.api.url.split("/select").join("/" + value), { params: { cache: true } });
    //                         if (result && result.data && result.data.status === 200) {
    //                             cachedData[field.name] = [result.data.item];
    //                             this.setState({cachedData});
    //                             // values[field.displayLabel.split(".").shift()] = result.data.item;
    //                         }
    //                     } catch (e) {
    //                         console.error(e);
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     this.setState(
    //         (oldState: any) => {
    //             var items = [...oldState.items];
    //             if (this.state.isEdit !== null) {
    //                 items[this.state.isEdit] = values;
    //             } else {
    //                 items.push(values);
    //             }
    //             return { items, isModalOpen: false };
    //         },
    //         () => {
    //             if (this.props.onChange) {
    //                 this.props.onChange(this.state.items);
    //             }
    //             if (this.state.isEdit !== null) {
    //                 if (this.props.onEdit) {
    //                     this.props.onEdit(values, values.length);
    //                 }
    //             } else {
    //                 if (this.props.onAdd) {
    //                     this.props.onAdd(values, values.length);
    //                 }
    //             }
    //         },
    //     );
    // };

    componentDidUpdate() {
        if (this.state.totalWidth === 0 && this.elementRef.current.clientWidth > 0) {
            this.setState({ totalWidth: this.elementRef.current.clientWidth });
        }
    }

    componentWillUnmount() {
        localStorage.setItem("grid-cache-" + this.props.name, JSON.stringify(this.state.cachedData));
    }

    onExit = (field: string, value: string, operator: string) => {
        this.setState({ isLoading: true });
        var searchs = this.state.searchs;
        if (value === undefined) {
            delete searchs[field];
        } else {
            searchs[field] = value;
        }
        this.setState({ searchs: searchs }, () => {
            this.setState({ isLoading: false });
        });
    };

    onChangeInput = (value: any, fieldName: any, index: any, event: any = null) => {
        console.log({index});
        const items = this.state.items;
        console.log({items})
        console.log({value})
        let cachedData = this.state.cachedData;
        var item = items[index];
        if (!item) {
            item = {};
        }
        item[fieldName] = (value === undefined ? null : value);
        items[index] = item;
        console.log({items})
        // if (this.state.form[fieldName]) {
        //     const field = this.state.form[fieldName];
        //     if (field.type === "select") {
        //         if (!cachedData[index]) {
        //             cachedData[index] = [];
        //         }
        //         try {
        //             this.setState({ isLoading: true });
        //             axios.get(field.api.url, { params: { cache: true, all: true } }).then((result) => {
        //                 if (result && result.data && result.data.status === 200) {
        //                     cachedData[index][fieldName] = result.data.items;
        //                     this.setState({ cachedData, isLoading: false });
        //                 }
        //             });
        //         } catch (e) {
        //             console.error(e);
        //         }
        //     }
        // }
        items.sort((a: { psm_custom_temperature_sensor_ten_id: number; }, b: { psm_custom_temperature_sensor_ten_id: number; }) => a.psm_custom_temperature_sensor_ten_id - b.psm_custom_temperature_sensor_ten_id);
        console.log({items})
        const theGrid = this.elementRef.current.children[1].children[1] // Seleciona o elemento com scroll
        const scroll = theGrid.scrollWidth //Pega o valor do scroll antes da alteração
        this.setState({ items }, () => {
            theGrid.scrollTo(theGrid.scrollWidth-scroll, 0) //Calcula o scroll e aplica-se após alteração
        });
        if (this.props.onChange) {
            this.props.onChange(items);
        }
    };

    renderField = (field: any, row: any, index: any) => {
        var value = row[field.name] ?? undefined;
        if (value === undefined) {
            if (this.state.cachedData[index] && this.state.cachedData[index][field.name]) {
                value = this.state.cachedData[index][field.name];
            }
        }
        var defaultValue = value;
        switch (field.type) {
            case "checkbox":
                return (
                    <Toggle
                        defaultChecked={undefined}
                        checked={undefined}
                        key={field.name + "_" + index}
                        onChange={(value: boolean) => {
                            this.onChangeInput(value, field.name, index);
                        }}></Toggle>
                );
            case "select":
                var data: any = [];
                var relationName: any = null;

                if (field.name.includes("fk") && defaultValue !== undefined && defaultValue !== null) {
                    relationName = field.name.split("fk_").pop().split("_id").shift();
                    if (row[relationName]) {
                        defaultValue = row[relationName][relationName + "_id"];
                        if (row[relationName] && field.displayLabel) {
                            try {
                                var label = field.displayLabel.split(".").reduce((o: any, i: any) => o[i], row);
                                if (label === null) {
                                    label = "NOT FOUND";
                                }
                                data = [{ value: defaultValue.toString(), label }];
                            } catch (e: any) {
                                data = [{ value: -1, label: "Whoops.." + e.message }];
                                throw Error("Não foi possivel encontrar o caminho de " + field.displayLabel);
                            }
                        }
                    }
                }
                var cachedData: any = this.state.cachedData[index] ? (this.state.cachedData[index][field.name] ? this.state.cachedData[index][field.name] : []) : [];
                for (var cachedIndex in cachedData) {
                    cachedData[cachedIndex].value = cachedData[cachedIndex].value.toString();
                }
                var finalValue = value ? value : defaultValue;
                if (finalValue) {
                    finalValue = finalValue.toString();
                }
                // console.log("ASASASAS",field.options)
                var selectOp;
                if (field.options) {
                    selectOp = field?.options

                } else {
                    selectOp = [...data, ...cachedData]

                }
                return (
                    <div style={{ display: "block" }}>
                        <div>
                          <AxiosSelectPicker
                                typesCustom={true}
                                disabled={field.disabled || field.name == 'psm_steps_ten_type'}
                                defaultValue={value}
                                value={value}
                                isEdit={this.props.isEdit}
                                onChange={(value: string, e: any) => {
                                    this.onChangeInput(value, field.name, index, e);
                                }}
                                key={field.name + "_" + index}
                                advanced={field.advancedSearch ?? undefined}
                                searchable={field.searchable ?? undefined}
                                //  uses={usesValues}
                                displayLabel={field.displayLabel ?? undefined}
                                api={field.api ? field.api : undefined}
                                options={field.options ? field.options : undefined}
                                placeholder={"Selecione..."}
                                data={selectOp}
                            // data={[...data, ...cachedData]}
                            ></AxiosSelectPicker>
                        </div>

                    </div>
                );
            case "numeric":
                return (
                    <NumberInput
                        leftPadding={field.leftPadding}
                        rightPadding={field.rightPadding}
                        preffix={field.preffix}
                        suffix={field.suffix}
                        punctuation={field.punctuation}
                        step={field.step}
                        value={value}
                        // onChange={() => {}}
                        onChange={(value: string, e: any) => {
                            this.onChangeInput(value, field.name, index, e);
                        }}
                    />
                );

            case "text":
                return (
                    <MaskInput
                        key={field.name + "_" + index}
                        allowLowercase={field.allowLowercase}
                        disabled={field.readonly || field.disabled || field.name == 'psm_steps_ten_phase'}
                        isEdit={true}
                        password={field.password ? field.password.toString() : "false"}
                        password_character={field.password_character}
                        value={value}
                        name={field.name}
                        maxLength={field.maxlength ?? undefined}
                        minLength={field.minlength ?? undefined}
                        preffix={field.preffix}
                        suffix={field.suffix}
                        onChangeEnd={(value: string, e: any) => {
                            this.onChangeInput(value, field.name, index, e);
                        }}
                        required={field.password === true && this.props.isEdit ? false : field.required}
                        regex={field.regex}
                        mask={field.mask}></MaskInput>
                );
            default:
                return <div>{field.type}</div>;
        }
    };

     renderColumns = () => {
        var output = [];
        var fields = Object.keys(this.state.form);
        for (var i in this.state.form) {
            let field = this.state.form[i];
            if(field?.prefix){
                continue;
            }
            // console.log({ field })
            let width = _.clamp((this.state.totalWidth - 130) / (fields.length - 1), field.name?.length * 9.6, this.state.totalWidth);
            if (field.name == 'psm_steps_ten_resistence' || 'psm_steps_ten_resistence_sensor') {
                width = 115
            }
            if (field.name == 'psm_steps_ten_unity_unity_fk_eletronic_unit_id' || 'psm_steps_ten_status') {
                width = 250
            }
            if (field.name == 'psm_steps_ten_type') {
                width = 100
            }
            // console.log({ width })
            if (i !== "$GLOBALS" && field.canEdit !== false && field.name != 'psm_steps_ten_sequence' && field.name != 'psm_steps_ten_type') { // coluna sequence
                output.push(
                    <Column resizable key={i} width={width}>
                        <HeaderCell className="filterable">
                            <FilterableCell field={field} hasAdvanced={false} onExit={(value: any, operator: any) => this.onExit(field.name, value, operator)}>
                                <div dangerouslySetInnerHTML={{ __html: field.label ?? field.name }}></div>
                            </FilterableCell>
                        </HeaderCell>
                        <Cell>
                            {(row: any, index: any) => {
                                return this.renderField(field, row, index);
                            }}
                        </Cell>
                    </Column>,
                );
            }
        }
        return output;
    };

    filterData = (searchs: any) => {
        var output = [];

        var data = this.state.items;
        for (var i in data) {
            var row = data[i];
            if (this.state.hiddenRows.includes(i)) {
                continue;
            }
            var includes = true;
            for (var fieldName in this.state.form) {
                var field = this.state.form[fieldName];
                if (field !== undefined) {
                    var parsedValue = SchemaToParsedCell.parseFieldToValue(field, row);
                    if (searchs[fieldName]) {
                        if (!parsedValue.includes(searchs[fieldName])) {
                            includes = false;
                            break;
                        }
                    }
                }
            }
            if (includes) {
                output.push(data[i]);
            }
        }
        return output;
    };

    onClickDelete = (rowIndex: any) => {
        this.setState({ loading: true });
        var items = this.state.items;
        items.splice(rowIndex, 1);
        const cachedData = this.state.cachedData ?? [];
        if (_.isArray(cachedData)) {
            cachedData.splice(rowIndex, 1);
        }
        this.setState({ cachedData }, () => {
            if (this.props.onChange) {
                this.props.onChange(items);
            }
            if (this.props.onDelete) {
                this.props.onDelete(items);
            }
            this.setState({ items, loading: false });
        });
    };

    renderActions = (rowData: any, rowIndex: any) => {

        return (
            <div>

                {this.props.readOnly !== true && <i onClick={() => this.onClickDelete(rowIndex)} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>}

            </div>
        );
    };

    addRow = (quantidade: any) => {
        var items = this.state.items;
        var somaQtd = quantidade * 3
        var lastNumber1 = 1;
        var lastNumber2 = 1;
        var lastNumber3 = 1;
        var sequence = 1;
        // for(var i=0; i < somaQtd; i++){
        for (var i = 0; i < quantidade; i++) {
            this.setState({ loading: true }, () => {
                items.push({
                    psm_steps_ten_phase: "R" + lastNumber1++,
                    psm_steps_ten_sequence: sequence++,
                    psm_steps_ten_type: 'R'
                });
                this.setState({ items, loading: false });
            });
        }
        for (var i = 0; i < quantidade; i++) {
            this.setState({ loading: true }, () => {
                items.push({
                    psm_steps_ten_phase: "S" + lastNumber2++,
                    psm_steps_ten_sequence: sequence++,
                    psm_steps_ten_type: 'S'
                });
                this.setState({ items, loading: false });
            });
        }
        for (var i = 0; i < quantidade; i++) {
            this.setState({ loading: true }, () => {
                items.push({
                    psm_steps_ten_phase: "T" + lastNumber3++,
                    psm_steps_ten_sequence: sequence++,
                    psm_steps_ten_type: 'T'
                });
                this.setState({ items, loading: false });
            });
        }

        // }
    };

    removeRow = () => {
        var items: any = [];

        axios.post("/api/v1/perm-delete", { isCustom: true, customName: 'psm_custom_temperature_sensor_ten', psm_id: this.props.match.params.id, currentTable: 'psm_custom_temperature_sensor_ten' }).then((res) => {
            Notification.error({
                title: "Delete",
                description: "Item Deletado"
            })
        })
        this.setState({ loading: true }, () => {
            if (this.props.onChange) {
                this.props.onChange(items);
            }
            if (this.props.onDelete) {
                this.props.onDelete(items);
            }
            this.setState({ items, loading: false });
        });
    }

    render() {
        var $GLOBALS = this.state.form?.$GLOBALS;
        var items = this.state.items;
        return (
            <div style={{ display: "flex", flexDirection: "column" }} ref={this.elementRef}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <h3>{$GLOBALS?.plural_name ?? this.props.label ?? ""}</h3> */}
                    {this.props.canAdd === true && this.props.readOnly !== true && (
                        <>
                            <div style={{ display: "flex" }}>
                                {this.state.items == 0 && (<>
                                    <label style={{ margin: "15px 10px 0px", fontSize: "15px", fontWeight: 600 }}>  Quant. de Sensor por Fase </label>
                                    <input style={{ width: 150, margin: 3 }} maxLength={2} className="rs-input" onChange={(e) => this.setState({ rowQuantity: e.target.value })} />
                                    <Button style={{ margin: 5 }} color="green" onClick={() => this.addRow(this.state.rowQuantity)}>
                                        <i className="fas fa-fw fa-plus mr-2"></i>
                                        Adicionar
                                    </Button> </>)}
                                {this.state.items != 0 && (<>
                                    <label style={{ margin: "15px 10px 0px", fontSize: "15px", fontWeight: 600 }}>  Quant. de Sensor por Fase </label>
                                    <Button style={{ margin: 5 }} color="red" onClick={() => this.removeRow()}>
                                        <i className="fas fa-fw fa-trash mr-2"></i>
                                        Limpar
                                    </Button> </>)} 
                            </div>
                        </>
                    )}
                </div>
                {/* {console.log(this.props.values)} */}
                {this.state.loading === false && (
                    <Table
                          rowClassName={(rowData) => 'red'}
                        className="inline"
                        renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                        loading={this.state.isLoading}
                        rowHeight={38}
                        virtualized
                        autoHeight={true}
                        data={_.orderBy(this.state.items, ['psm_custom_bridge_rectifier_id'],['asc'])}>
                        {this.renderColumns()}
                        {/* <Column fixed={"right"} width={100}>
                            <HeaderCell>Ações</HeaderCell>
                            <Cell className="link-group">
                                {(rowData: any, rowIndex: any) => {
                                    return this.renderActions(rowData, rowIndex);
                                }}
                            </Cell>
                        </Column> */}
                    </Table>
                )}
                {/* {JSON.stringify(this.state.items,null,2)} */}
                {/* {this.props.readOnly ? 'S': 'N'} */}
                {/* {JSON.stringify(this.state.searchs)} */}
                {/* {JSON.stringify(this.state.items)} */}
            </div>
        );
    }

}