import { Reviewer } from "./Reviewer";
import { modalHistory } from "./HistoricComponent";
import { Stockhistoric } from "./StockComponents";
import { AdvancedSearchModalButton } from "./AdvancedSearchModalButton";
import { AdvancedSearchModalButtonProduct } from "./AdvancedSearchModalButtonProduct";
import { PrintProducts } from "./PrintProducts";
import { AppointmentHours } from "./AppointmentHours";
import { ClockInPsm } from "./ClockInPsm";
import { HeaderText } from "./HeaderText";
import { ReviewLogTable } from "./ReviewLogTable";
import { ObservationsCommercialClient } from "./ObservationsCommercialClient";
import { BridgeRectifier } from "./BridgeRectifier";
import { GridCustomBridgeRectifier } from "./GridCustomBridgeRectifier";
import { NfIncorporateItem } from "./NfIncorporateItem";
import { ImportAdd } from "./ImportAdd";
import { PsmRequestAlteration } from "./PsmRequestAlteration";
import { OrderPart } from "./OrderPart";
import { ApportionmentButton } from "./ApportionmentButton";
import { ApportionmentDibiButton } from './ApportionmentDibiButton'
import { AllocatedPackage } from './AllocatedPackage';
import { ImportOptional } from './ImportOptional';
import { CreateTablesComplement } from './CreateTablesComplement';
import { TableGridLambda } from './TableGridLambda';
import { CreateLambdaComplement } from './CreateLambdaComplement';
import { ConnectGmail } from './ConnectGmail'
import { ReportPsmSituationCustom } from './ReportPsmSituationCustom';
import { PortIntegration } from './PortIntegration'

import { ImportObsClient } from './ImportObsClient'

/* ProductBox */
import { AllocatedPackageProduct } from './AllocatedPackageProduct'

/* Material Requisition */
import { PrintTagProductRequisition } from './PrintTagProductRequisition'
import { MaterialCancelItem } from './MaterialCancelItem'

/* Purchase Requisition */
import { PurchaseCancelItem } from './PurchaseCancelItem'

/* Reports */
import { ReportPsmCustom } from './ReportPsmCustom';
import { ReportPsmSituations } from './ReportPsmSituations'

//Grid Defeitos Encontrados - LAB
import { DefectsAndServicesCustom } from "./DefectsAndServicesCustom";
import { DefectsAndServicesCustomMechanic } from "./DefectsAndServicesCustomMechanic";
import { DefectsAndServicesCustomRectification } from "./DefectsAndServicesCustomRectification";
import { DefectsAndServicesCustomLinkdc } from "./DefectsAndServicesCustomLinkdc";
import { DefectsAndServicesCustomPotency } from "./DefectsAndServicesCustomPotency";
import { DefectsAndServicesCustomTcs } from "./DefectsAndServicesCustomTcs";
import { DefectsAndServicesCustomFans } from "./DefectsAndServicesCustomFans";
import { DefectsAndServicesCustomCards } from "./DefectsAndServicesCustomCards";
import { DefectsAndServicesCustomCardsFonte } from "./DefectsAndServicesCustomCardsFonte";
import { DefectsAndServicesCustomCardsDisparo } from "./DefectsAndServicesCustomCardsDisparo";
import { DefectsAndServicesCustomCardsOutros } from "./DefectsAndServicesCustomCardsOutros";
import { DefectsAndServicesCustomCardsFcf } from "./DefectsAndServicesCustomCardsFcf";
import { DefectsAndServicesCustomOptionals } from "./DefectsAndServicesCustomOptionals";
import { DefectsAndServicesCustomBypass } from "./DefectsAndServicesCustomBypass";
import { DefectsAndServicesCustomTemperatureTen } from "./DefectsAndServicesCustomTemperatureTen"
import { DefectsAndServicesCustomAnalysis } from "./DefectsAndServicesCustomAnalysis";
import { DefectsAndServicesCustomLinkdcCapacitor } from "./DefectsAndServicesCustomLinkdcCapacitor";
import { DefectsAndServicesCustomLinkdcResistence } from "./DefectsAndServicesCustomLinkdcResistence"
import { DefectsAndServicesCustomLinkdcCount } from "./DefectsAndServicesCustomLinkdcCount"
import { DefectsAndServicesCustomLinkdcIgbtBrake } from "./DefectsAndServicesCustomLinkdcIgbtBrake"
import { DefectsAndServicesCustomLinkdcPreLoad } from "./DefectsAndServicesCustomLinkdcPreLoad"
import { DefectsAndServicesCustomEletronicAnalise } from "./DefectsAndServicesCustomEletronicAnalise";
import { DefectsAndServicesCustomCircuitPotencyTen } from "./DefectsAndServicesCustomCircuitPotencyTen";
import { ImportValueProduct } from "./ImportValueProduct";
import { PrintRomanian } from "./PrintRomanian";
import { TableComplement } from "./TableComplement";
import { ReviewLogTableCommercial } from "./ReviewLogTableCommercial";
import { ReviewLogTableCommercials } from './ReviewLogTableCommercials';
import { AllocatadeRequisitionMaterialPsm } from './AllocatadeRequisitionMaterialPsm'
import { AllocatadeRequisitionPurchasePsm } from './AllocatadeRequisitionPurchasePsm';
/* GRID'S Retificação */
import { GridCustomTc } from "./GridCustomTc";
import { GridCustomPotency } from "./GridCustomPotency";
import { GridCustomExternalFan } from "./GridCustomExternalFan";
import { GridCustomInternalFan } from "./GridCustomInternalFan";
import { GridCustomBridgeRectifierTwo } from "./GridCustomBridgeRectifierTwo";
import { GridCustomBridgeRectifierThree } from "./GridCustomBridgeRectifierThree";
import { GridCustomBridgeRectifierFour } from "./GridCustomBridgeRectifierFour";
import { GridCustomBridgeRectifierFive } from "./GridCustomBridgeRectifierFive";
import { GridCustomBridgeRectifierSeven } from "./GridCustomBridgeRectifierSeven";
import { GridCustomBridgeRectifierEight } from "./GridCustomBridgeRectifierEight";
import { GridCustomBridgeRectifierNine } from "./GridCustomBridgeRectifierNine";
import { GridCustomBridgeRectifierTen } from "./GridCustomBridgeRectifierTen";
import { GridCustomTemperatureSensorTen } from "./GridCustomTemperatureSensorTen";
import { GridCustomBridgeRectifierTwelve } from "./GridCustomBridgeRectifierTwelve";
import { DefectsAndServicesCustomTemperatureTwelve } from "./DefectsAndServicesCustomTemperatureTwelve"
import { GridCustomExternalFanTen } from './GridCustomExternalFanTen'
/******************/
/* Grids Potência */
import { GridCustomPotencyPhaseOne } from "./GridCustomPotencyPhaseOne";
import { GridCustomPotencyPhaseTwo } from "./GridCustomPotencyPhaseTwo";
import { GridCustomPotencyTwelve } from "./GridCustomPotencyTwelve"
/************* */
import { GridCustomCircuitPotencyTen } from "./GridCustomCircuitPotencyTen"

import { CustomImportProductsPhoto } from "./CustomImportProductsPhoto"
import { ImportValueProductDibi } from "./ImportValueProductDibi";



export default {
    CustomImportProductsPhoto,
    MaterialCancelItem,
    DefectsAndServicesCustomTemperatureTwelve,
    GridCustomCircuitPotencyTen,
    ImportAdd,
    ApportionmentButton,
    ApportionmentDibiButton,
    NfIncorporateItem,
    ImportValueProduct,
    AllocatedPackage,
    ImportOptional,
    PrintRomanian,
    CreateTablesComplement,
    TableComplement,
    TableGridLambda,
    CreateLambdaComplement,
    ReviewLogTableCommercial,
    ImportValueProductDibi,
    ConnectGmail,
    ReportPsmSituationCustom,
    ReviewLogTableCommercials,
    PortIntegration,
    AllocatadeRequisitionMaterialPsm,
    AllocatadeRequisitionPurchasePsm,
    AllocatedPackageProduct,
    PrintTagProductRequisition,
    // reports
    ReportPsmCustom,
    ReportPsmSituations,
    //
    GridCustomTc,
    DefectsAndServicesCustomAnalysis,
    GridCustomBridgeRectifierTen,
    DefectsAndServicesCustomTemperatureTen,
    GridCustomTemperatureSensorTen,
    GridCustomBridgeRectifierNine,
    GridCustomBridgeRectifierFive,
    GridCustomBridgeRectifierEight,
    GridCustomBridgeRectifierSeven,
    GridCustomBridgeRectifierFour,
    GridCustomBridgeRectifierThree,
    GridCustomBridgeRectifierTwo,
    GridCustomBridgeRectifierTwelve,
    GridCustomExternalFanTen,
    OrderPart,
    // Potência
    GridCustomPotency,
    GridCustomPotencyPhaseOne,
    GridCustomPotencyPhaseTwo,
    GridCustomPotencyTwelve,
    // Final Potencia
    GridCustomExternalFan,
    GridCustomInternalFan,
    DefectsAndServicesCustomBypass,
    DefectsAndServicesCustomOptionals,
    DefectsAndServicesCustomCardsFcf,
    DefectsAndServicesCustomCardsOutros,
    DefectsAndServicesCustomCardsDisparo,
    DefectsAndServicesCustomCardsFonte,
    DefectsAndServicesCustomCards,
    DefectsAndServicesCustomFans,
    DefectsAndServicesCustomTcs,
    DefectsAndServicesCustomPotency,
    DefectsAndServicesCustomLinkdc,
    DefectsAndServicesCustomRectification,
    DefectsAndServicesCustomMechanic,
    DefectsAndServicesCustom,
    DefectsAndServicesCustomLinkdcCapacitor,
    DefectsAndServicesCustomLinkdcResistence,
    DefectsAndServicesCustomLinkdcCount,
    DefectsAndServicesCustomLinkdcIgbtBrake,
    DefectsAndServicesCustomLinkdcPreLoad,
    DefectsAndServicesCustomEletronicAnalise,
    DefectsAndServicesCustomCircuitPotencyTen,
    GridCustomBridgeRectifier,
    Reviewer,
    modalHistory,
    Stockhistoric,
    AdvancedSearchModalButton,
    PrintProducts,
    AppointmentHours,
    ClockInPsm,
    HeaderText,
    ReviewLogTable,
    BridgeRectifier,
    ObservationsCommercialClient,
    AdvancedSearchModalButtonProduct,
    PsmRequestAlteration,
    ImportObsClient,
    //purchase
    PurchaseCancelItem
};
