import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { NULL } from "sass";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
dayjs.extend(weekday);



export class CompanyEvents extends Event {
    //  static onSave = (value: any) => {        
    // // }
    // public onLoad = (value: any) => {
    //     console.log("teste", this)
    //     // if (this.globalProps.match.url.includes('/add') == true) {
    //     //     this.setField("company_cnpj", {
    //     //         unique: true,
    //     //         required: true
    //     //     })
    //     // }
    // }

}