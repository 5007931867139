import React from "react";
import { Button, Modal, Table, List, Loader } from "rsuite";
import axios from "../../utilities/axios";
import { StockSubModal } from "./StockSubModal";

const { Column, HeaderCell, Cell } = Table;


export class StockModal extends React.Component<any, {}> {
    public state = {
        loading: this.props.loadingmodal,
        cidades: [],
        data: [],
        showNewModal: false,
        dados_modal: [],
        title_op: "",
        title_cidade: "",
        qtdLimiera: 0,
        qtdMaringa: 0,
        qtdSerra: 0
    }

    // componentDidUpdate(){
    //     if(this.props.show === true && this.state.loading === true){
    //     //   this.localidade();
    //  }
    // }
    // componentDidMount(){
    //     //this.localidade();
    // }


    //    localidade = async() => {
    //      axios 
    //     .get("api/v1/stock/getAllCompanies/", {params: { product_id:this.props.productID, psm_id: this.props.psmID }})    
    //     .then((res => {
    //         if(res.status === 200) { 
    //             console.log(res)
    //             this.setState({ loading: false });
    //             this.setState({
    //                 cidades : res.data.result,
    //                 per_location: res.data.stock.per_location

    //             })
    //             // console.log(this.state.stock)

    //         }
    //         if(res.status === 403) {
    //             this.setState({ loading: false });
    //         }

    //     }))  
    //}  

    openModal = (localizacao: any, localizacao_id: any, choose: any) => {
        this.setState({ showNewModal: true, title_op: choose, title_cidade: localizacao });
        axios
            .get("api/v1/stock/getCompaniePerCity/" + localizacao_id)
            .then((res => {
                if (res.status === 200) {
                    this.setState({
                        dados_modal: res.data.result,
                        //    loadingmodal: false

                    })
                    // console.log(this.state.stock)

                }
                //    if(res.status === 403) {
                //        this.setState({ loading: false });
                //    }

            }))

        //  alert(choose);
    }


    // atualizaEstoque = async () => {
    //     console.log(this.props.idProduct)
    //     this.setState({
    //         qtdLimeira: 1417
    //     })
    //     if (this.props.idProduct !== undefined) {
    //         await axios
    //             .get(`/api/v1/products/get-products/${this.props.idProduct}`)
    //             .then((res: any) => {
    //                 console.log('response ::::: ', res.data)
    //                 for (let i in res.data) {
    //                     if (res.data[i] !== null) {

    //                         this.setState({

    //                             qtdMaringa: res.data[i].codigo_local_estoque == 4420707727 ? res.data[i].saldo : 0,
    //                             qtdSerra: res.data[i].codigo_local_estoque == 4420707727 ? res.data[i].saldo : 0,
    //                         })
    //                     }
    //                 }
    //             })
    //     }
    // }

    render() {
        if (this.props.show === false) {
            return null;
        }

        return (<>

{/* {console.log("iem heres", this )} */}
            <StockSubModal
                Show={this.state.showNewModal}
                onCancel={() => {
                    this.setState({
                        showNewModal: false,
                    })
                }}
                title_op={this.state.title_op}
                data={this.state.dados_modal}
                title={"Consulta de estoque " + this.state.title_op + " em " + this.state.title_cidade}
            />




            <Modal show={true} className="rs-modal-medium">

                <Modal.Body>
                    <Modal.Header closeButton={false} style={{ display: "flex", flexDirection: "row" }}>
                        <h4>{this.props.title ?? "Deseja mesmo sair?"}</h4>
                        {/* <Button onClick={(e) => this.atualizaEstoque()} color="orange" style={{ marginLeft: '10px' }}>Atualizar estoque</Button> */}
                    </Modal.Header>
                    <div style={{ height: 800, marginTop: 15 }}>
                        <table className="rs-table-cell">
                            <tr className="rs-table-row-header">
                                <th className="rs-table-cell-content">Localidade</th>
                                <th></th>
                                <th className="rs-table-cell-content">Disponivel</th>
                                {/* <th></th>
                    <th className="rs-table-cell-content">Alocado</th> 
                    <th></th>
                    <th className="rs-table-cell-content">Vendido</th> 
                    <th></th>
                    <th className="rs-table-cell-content">Comprado</th>  
                    <th></th>
                    <th className="rs-table-cell-content">Transferido</th>
                    <th></th>
                    <th className="rs-table-cell-content">QTD. OMIE</th> */}
                            </tr>


                            {/* <Table onRowClick={this.onClick} loading={this.props.loading} height={400}  style={{marginTop:20,padding:5}} data={this.props.data}>
                        <Column width={200} align="center">
                            <HeaderCell>Localidades</HeaderCell> 
                            <Cell dataKey="city_name"/>   
                        </Column>
                        <Column width={130} align="center">
                            <HeaderCell>Disponivel</HeaderCell>
                            <Cell dataKey="quantity_disponivel"/>
                        </Column>
                        <Column width={130} align="center">
                            <HeaderCell>Alocado</HeaderCell>
                            <Cell dataKey="alocado_psm"/>
                        </Column>
                        <Column width={130} align="center">
                            <HeaderCell>Vendido</HeaderCell>
                            <Cell dataKey="vendido_psm"/>
                        </Column>
                        <Column width={130} align="center">
                            <HeaderCell>Comprado</HeaderCell>
                            <Cell dataKey="comprado_psm"/>
                        </Column>
                        <Column width={130} align="center">
                            <HeaderCell>Transferido</HeaderCell>
                            <Cell dataKey="transferido"/>
                        </Column>
                    </Table>   */}


                            {/* {console.log(this.props.data)} */}
                            {/* {this.props.data.map((item: any) => (
                                <tr className="">
                                    <td className="rs-table-cell-content" style={{ borderBottom: "1px solid", borderColor: "#E8E8E8" }}>{item.city_name}</td>
                                    <td></td>
                                    <td className="rs-table-cell-content" style={{ borderBottom: "1px solid", borderColor: "#E8E8E8", textAlign: "center" }}>{item.quantity_disponivel}</td>
                                    <td><Button disabled={item.quantity_disponivel > 0 ? false : true} color="blue" style={{ padding: 5, float: "right" }} onClick={() => this.openModal(item.city_name, item.city_id, 'disponivel')}><i className="fas fa-search fa-fw mr-2"></i></Button></td>
                                    <td className="rs-table-cell-content" style={{borderBottom:"1px solid", borderColor:"#E8E8E8",textAlign:"center"}}>{item.alocado_psm}</td>
                                    <td><Button disabled={item.alocado_psm > 0 ? false : true} color="blue" style={{padding:5,float:"right"}} onClick={() =>this.openModal(item.city_name,item.city_id,'alocado')}><i className="fas fa-search fa-fw mr-2"></i></Button></td>
                                    <td className="rs-table-cell-content" style={{borderBottom:"1px solid", borderColor:"#E8E8E8",textAlign:"center"}}>{item.vendido_psm}</td>
                                    <td><Button disabled={item.vendido_psm > 0 ? false : true} color="blue" style={{padding:5,float:"right"}} onClick={() =>this.openModal(item.city_name,item.city_id,'vendido')}><i className="fas fa-search fa-fw mr-2"></i></Button></td>
                                    <td className="rs-table-cell-content" style={{borderBottom:"1px solid", borderColor:"#E8E8E8",textAlign:"center"}}>{item.comprado_psm}</td>
                                    <td><Button disabled={item.comprado_psm > 0 ? false : true} color="blue" style={{padding:5,float:"right"}} onClick={() =>this.openModal(item.city_name,item.city_id,'comprado')}><i className="fas fa-search fa-fw mr-2"></i></Button></td>
                                    <td className="rs-table-cell-content" style={{borderBottom:"1px solid", borderColor:"#E8E8E8",textAlign:"center"}}>{item.transferido}</td>
                                    <td><Button disabled={item.transferido > 0 ? false : true} color="blue" style={{padding:5,float:"right"}} onClick={() =>this.openModal(item.city_name,item.city_id,'transferido')}><i className="fas fa-search fa-fw mr-2"></i></Button></td>  
                                    <td className="rs-table-cell-content" style={{borderBottom:"1px solid", borderColor:"#E8E8E8",textAlign:"center"}}>{item.quantity_disponivel}</td>
                                    <td><Button disabled={item.quantity_disponivel > 0 ? false : true} color="blue" style={{padding:5,float:"right"}} onClick={() =>this.openModal(item.city_name,item.city_id,'disponivel')}><i className="fas fa-search fa-fw mr-2"></i></Button></td>
                                </tr>

                            ))} */}
                            {/* {console.log("valor od this props", this.props)} */}
                            <tr className="">
                                <td className="rs-table-cell-content" style={{ borderBottom: "1px solid", borderColor: "#E8E8E8" }}>Limeira</td>
                                <td></td>
                         
                                <td className="rs-table-cell-content" style={{ borderBottom: "1px solid", borderColor: "#E8E8E8", textAlign: "center" }}>{this.props.values.limeira}</td>
                                {/* <td className="rs-table-cell-content" style={{ borderBottom: "1px solid", borderColor: "#E8E8E8", textAlign: "center" }}>{this.props.data.length > 0 ? this.props.data[2].quantity_disponivel : 0}</td> */}
                            </tr>
                            <tr>
                                <td className="rs-table-cell-content" style={{ borderBottom: "1px solid", borderColor: "#E8E8E8" }}>Serra</td>
                                <td></td>
                                <td className="rs-table-cell-content" style={{ borderBottom: "1px solid", borderColor: "#E8E8E8", textAlign: "center" }}>{this.props.values.serra}</td>
                            </tr>
                            <tr>
                                <td className="rs-table-cell-content" style={{ borderBottom: "1px solid", borderColor: "#E8E8E8" }}>Maringa</td>
                                <td></td>
                                <td className="rs-table-cell-content" style={{ borderBottom: "1px solid", borderColor: "#E8E8E8", textAlign: "center" }}>{this.props.values.maringa}</td>
                            </tr>
                        </table>





                    </div>
                    {/* <div>
                    <Table loading={this.props.loading} height={400} data={this.props.datacell}>
                        <Column width={70} align="center" fixed>
                            <HeaderCell>Id</HeaderCell>
                            <Cell dataKey="3" />
                        </Column>

                        <Column width={130}>
                            <HeaderCell></HeaderCell>
                            <Cell dataKey="psm_product_registration_fk_psm_id" />
                        </Column>

                        <Column width={130}>
                            <HeaderCell>Produto</HeaderCell>
                            <Cell dataKey="psm_product_registration_description" />
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Obs do Pedido</HeaderCell>
                            <Cell dataKey="psm_product_registration_obs_client" />
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Quantidade</HeaderCell>
                            <Cell dataKey="psm_product_registration_amount" />
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Unidade</HeaderCell>
                            <Cell dataKey="psm_product_registration_unit_product" />
                        </Column>

                        <Column width={250}>
                            <HeaderCell>NCM1</HeaderCell>
                            <Cell dataKey="psm_product_registration_ncm" />
                        </Column>

                        </Table>
                    </div> */}

                </Modal.Body>

                <Modal.Footer style={{ marginTop: 30 }}>
                    {this.props.showConfirm !== false && <Button loading={this.props.loading} onClick={() => this.setState({ loading: false })} color={this.props.confirmColor ?? 'red'}>
                        {this.props.confirmText ?? 'Confirmar'}
                    </Button>}
                    <Button onClick={this.props.onCancel} color={this.props.cancelColor ?? undefined} >
                        {this.props.cancelText ?? 'Cancelar'}
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
        );
    }
}
