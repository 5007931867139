import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmApportinmentEvents extends Event {
    public state = {
        loading: false,
    }
    public onChange = async (value: any, setField: any, setState: any, values: string) => {
        if(value){
            // //console.log({value});
            // var dataPsm: any = this.globalProps.values
            // let resultConsideredValue: any = dataPsm.psm_considered_value - (dataPsm.psm_considered_value * value.resultDisc.percentual) / 100;
            // let percentRef = (dataPsm.psm_new_equipment_value * 100) / (value.response + resultConsideredValue);
            // //console.log({value});
            // await this.setState("loadings",[
            //     "psm_total_product_service",
            //     "psm_considered_value",
            //     "psm_total_value_products"
            // ]);

            // this.setFieldValue("psm_considered_value", resultConsideredValue);
            // this.setFieldValue("psm_total_value_products", value.response);
            // this.setFieldValue("psm_total_product_service", (value.response + resultConsideredValue));
            // this.setFieldValue("psm_final_value",  (value.response + resultConsideredValue));
            // this.setFieldValue("psm_refer_equipment_new", percentRef)


            // this.setState("loadings",[]);
            
        }
    }
}