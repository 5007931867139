import axios from "../../../utilities/axios";
import React from "react";
import {  Button, Table, Modal, Input, Row, Col, Whisper, Tooltip, Divider  } from "rsuite";
import _ from "lodash";
import dayjs from "dayjs";
import { Permission } from "../../Permission";
import {ReveiwModal} from "../../customModal/ReveiwModal";

const { Column, HeaderCell, Cell } = Table;

export class ReviewLogTable extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        showSituation: false,
        reviewLogDate: [],
        nameUser: [],
        dateModal: [],
        dadosModal: [],
        dadosModalGrid: [],
    };

    // buscaReviewLogs = () => {
    //     console.log("Meu console :::",this.props.values.psm_id);
    //     var psmId = this.props.values.psm_id;
    //     if(psmId){
    //         axios
    //         .get("/api/v1/review-logs/" + psmId)
    //         .then((res => {
                
    //             var dados = res.data.result;
    //             console.log("Meu response", dados);
    //             if(dados){
    //                 this.setState({
    //                     reviewLogDate: dados,
    //                 })
    //                 console.log(this.state.reviewLogDate)
    //                 var testeDados: any = []
    //                 for(var i in dados){
    //                     axios
    //                     .get("/api/v1/user/" + dados[i].psm_review_log_updated_by)
    //                     .then((res => {
    //                         var userName = res.data.item;
    //                         testeDados.push(userName);
    //                         //console.log("Meu usuario", userName);
    //                         this.setState({
    //                             nameUser: userName,
    //                         })
    //                     }))
    //                 }
    //                 var dadosObj = {testeDados}
    //                 //console.log(dadosObj)
    //                 var dadosMerge = _.merge(testeDados,dados);
    //                 //console.log("Dados merge", dadosMerge);

    //             }
    //         }))
    //     }
    // }

    bucaHistorycReview  = () => {
        var dados = this.props.values.psm_review_logs;
       // console.log("Variavel dados",dados);
        var psmId = this.props.values.psm_id;
        if(psmId){
            var output = [];
            for(var i in dados){
                //console.log("Dados originais", dados);
                //console.log(dados[i].psm_review_log_updated_at);
                var dataFormatada = dayjs(dados[i].psm_review_log_updated_at).format("DD/MM/YYYY HH:mm");
                var dataObject = {FormatDate : dataFormatada};
                var mergeDate = _.merge(dados[i],dataObject);
                output.push(mergeDate);
            }
            //console.log("Meu output",output);
            this.setState({
                reviewLogDate: output,
            });
            //console.log("Meu state",this.state.reviewLogDate);
            // var testeDados: any = [];
            // for(var i in dados){
            //     axios
            //     .get("/api/v1/user/" + dados[i].psm_review_log_updated_by)
            //     .then((res => {
            //         var userName = res.data.item;
            //         testeDados.push(userName);
            //         //console.log("Meu usuario", userName);
            //         this.setState({
            //             nameUser: userName,
            //         })
            //     }))
            // }
            // var dadosMerge = _.merge(testeDados,dados);
            // console.log("Dados merge", dadosMerge);
        }
    }

    isSearchLabelTables = async (values: any) => {
        var tablesCrud: any = [];
        for(var i in values){
            //console.log("Function",values[i].valueGrid);
            let objects = values[i].valueGrid;
            for(var obj in objects){
                //console.log("Batata",objects[obj].tablesIds);
                let columns = objects[obj].tablesIds
                for(var indices in columns){
                    await axios
                        .post("/api/v1/psm/find-tables", {data: columns[indices]})
                        .then((res) => {
                            tablesCrud.push(JSON.parse(res.data.response))
                        })

                }
            }
            //console.log("Teste",columnsTable);
        }
        //console.log(tablesCrud)
        return tablesCrud
    };

    onRead = async (rowReview: any) => {
        //console.log("rowReview ::: ", rowReview.psm_review_log_changed_values)
        var rowPage = this.props.form;
        var rowView = JSON.parse(rowReview.psm_review_log_changed_values);
        var tablesCrud = await this.isSearchLabelTables(rowView);
        console.log("Teste",tablesCrud);

        if(rowReview){
            var arrayInicial = [];
            for(var k in rowView[0].valueForm){
                var indiceDados: any = Object.keys(rowView[0].valueForm[k]);
                //console.log("Idice Dados ::::: ", indiceDados);
                if(rowPage[indiceDados[0]]){
                    var nomeCampoPage = rowPage[indiceDados[0]].label;
                    var valorInicial = [rowView[0].valueForm[k]];
                    var valorFinal: any = Object.values(valorInicial[0]);
                    indiceDados = nomeCampoPage;
                    arrayInicial.push({Indice:indiceDados,valueOld:valorFinal[0].valueOld,valueNew:valorFinal[0].valueNew})
                    //var union = _.union([nomeCampoPage],[valorFinal])
                }
            };

            var funtionTable = async function(value: any = null){
                for(var i in tablesCrud){
                    //console.log(tablesCrud[i])
                    for(var itens in tablesCrud[i]){
                        //console.log(itens);
                        if(itens == value){
                            //console.log(tablesCrud[i][itens].label)
                            if(tablesCrud[i][itens].label){
                                return tablesCrud[i][itens].label
                            }
                        }
                    }
                }
            }
            
            var arrayGrids: any = [];
            for(var indice in rowView[1].valueGrid){
                let unifiedDate = rowView[1].valueGrid[indice].dadosUnificados;
                for( var obj in unifiedDate){
                    for(var columns in unifiedDate[obj]){
                        //console.log(unifiedDate[obj][columns]);
                        if(
                            !columns.includes('fk_psm_id') &&
                            !columns.includes('created_at') &&
                            !columns.includes('updated_at') &&
                            !columns.includes('guid') &&
                            !columns.includes('created_by') &&
                            !columns.includes('updated_by') &&
                            !columns.includes('file') &&
                            !columns.includes('attachment')
                        ){
                            console.log(columns);
                            var IndiceGrid = await funtionTable(columns);
                            //console.log("Meu teste",teste)
                            //console.log(unifiedDate[obj][columns]);
                            let valueInitial = unifiedDate[obj][columns].valueOld;
                            let valueEnd = unifiedDate[obj][columns].valueNew;
                            console.log("Old",typeof(valueInitial))
                            console.log("New", typeof(valueEnd))
                            if(IndiceGrid !== undefined && IndiceGrid !== null){
                                if(typeof(valueEnd) !== "object"){
                                    arrayGrids.push({Indice:IndiceGrid,valueOld:valueInitial,valueNew:valueEnd})
                                }
                            }
                        }
                    }
                };
            };
            this.setState({
                dadosModalGrid: arrayGrids,
                dadosModal: arrayInicial,
                show: true
            })
        }
    };

    openModal = (rowData: any) => {
        var { table: prefix } = this.props.form.$GLOBALS;
        if (this.props.overrideActions) {
            return this.props.overrideActions(rowData);
        }
        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Permission name={prefix + "-read"}>
                    <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Visualizar</Tooltip>}>
                        <i onClick={() => this.onRead(rowData)} className="fas text-primary fa-fw mr-2 clickable fa-eye"></i>
                    </Whisper>
                </Permission>
            </div>
        );
    }

    componentDidMount(){
        this.bucaHistorycReview();
    }

    render() {
        var dadosCampos = this.state.dadosModal;
        // dadosCampos.map((item:any) => {
        //     console.log("valores",item)
        // })
        //console.log(dadosCampos);
        return (<>
            <ReveiwModal
            widthModal={"rs-modal-full"}
            show={this.state.show}
            onCancel={() => {
                this.setState({
                    show: false,
                })
            }}
            cancelColor={"red"}
            showConfirm={false}
            cancelText={"Fechar"}
            onCloseButton={true}
            title={"Campos Alterados"}
            content={""}
            body={<>
                {/* <Row style={{maxWidth: 1500}}>
                    {dadosCampos.map((item: any) => (
                        <Col xs={4} style={{marginTop: 20}}>
                            <div>
                                <h1>{JSON.stringify(item.dados.Indice)}</h1>
                                <Input disable={true} value={JSON.stringify(item.dados.valores.valueOld)}/>
                                <Input disable={true} value={JSON.stringify(item.dados.valores.valueNew)}/>
                            </div>
                        </Col>
                    ))}
                </Row> */}
                <Divider>Principal</Divider>
                <Table wordWrap loading={this.props.loading} height={400} data={dadosCampos}>
                    <Column width={220} resizable>
                        <HeaderCell>Campos Alterados</HeaderCell>
                        <Cell dataKey="Indice" />
                    </Column>

                    <Column width={280} resizable>
                        <HeaderCell>Valor Antigo</HeaderCell>
                        <Cell dataKey="valueOld" />
                    </Column>

                    <Column width={280} resizable>
                        <HeaderCell>Valor Novo</HeaderCell>
                        <Cell dataKey="valueNew" />
                    </Column>
                </Table>
                <Divider>Grids</Divider>
                <Table wordWrap loading={this.props.loading} height={400} data={this.state.dadosModalGrid}>
                    <Column width={220} resizable>
                        <HeaderCell>Campos Alterados</HeaderCell>
                        <Cell dataKey="Indice" />
                    </Column>

                    <Column width={280} resizable>
                        <HeaderCell>Valor Antigo</HeaderCell>
                        <Cell dataKey="valueOld" />
                    </Column>

                    <Column width={280} resizable>
                        <HeaderCell>Valor Novo</HeaderCell>
                        <Cell dataKey="valueNew" />
                    </Column>
                </Table>
            </>}
            
            />

            <div>
                <div style={{marginTop: "25px"}}>
                    <h3>
                        Histórico de Revisões
                    </h3>
                </div>
                <Table loading={this.props.loading} height={400} data={this.state.reviewLogDate}>
                    <Column width={150} resizable>
                        <HeaderCell>Data e Hora</HeaderCell>
                        <Cell dataKey="FormatDate" />
                    </Column>

                    <Column width={180} resizable>
                        <HeaderCell>Situação Anterior</HeaderCell>
                        <Cell dataKey="psm_review_log_old_code" />
                    </Column>

                    <Column width={130} resizable>
                        <HeaderCell>Situação Nova</HeaderCell>
                        <Cell dataKey="psm_review_log_new_code" />
                    </Column>

                    <Column width={180} resizable>
                        <HeaderCell>Usuário</HeaderCell>
                        <Cell dataKey="psm_review_log_user_name" />
                    </Column>

                    <Column width={100}>
                        <HeaderCell>Campos</HeaderCell>
                        <Cell className="link-group">
                            {(rowData: any) => {

                                return this.openModal(rowData);
                                
                                // return (
                                // <span>
                                //     <a onClick={() => this.openModal(rowData)}> Visualizar </a> |{' '}
                                // </span>
                                // );
                            }}
                        </Cell>
                    </Column>
                </Table>
            </div>
        </>)
    }
}

