import React from "react";
import { Loader, Modal, Tooltip, Whisper,Button } from "rsuite";
import axios from "../../../utilities/axios";
import Browserlize from "../../Browserlize";
import { Permission } from "../../Permission";

export class PrintProducts extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
    }

    //public select: any = {};
 
    imprimir() {
    axios
    .get("/api/v1/products/impressaoproduto/"+ this.props.values.psm_id)
    .then((res) => {
        if (res.data && res.data.url) {
            window.open('http://'+window.location.host.split('25565').join('25567')+res.data.url, "_blank");
        }
    })
 }

    render() {
        return (        
            <Button style={{marginTop: 23, marginRight: 15}} loading={this.state.loading} onClick={() => this.imprimir()} color={"blue"} className="ml-4">
                    <i className="far fa-print-search fa-fw mr-2"></i>                  
                   
            </Button> 
        
            
        )
    }
}
