import React from "react";
import { Button , Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { CustomModal } from "../../customModal/CustomModal"


export class OrderPart extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        showModalImport: false,
        omieData: [],
        OmieItens: [],
    }

    componentDidMount = async() =>{
        // console.log("Props ::: ", this.props)
    }
    

    onChange = (item: any, valuesOmie: any) => {
        this.props.onChange()
    };

    closeModalCancel = () => {
        this.setState({
            confirmCancelModal: false,
            loading: false
        })
    }



    render() {
        return (<>
            <CustomModal
                show={this.state.showModalImport}
                tableOmie={false}
                closeModal={this.closeModalCancel}
                title={"Deseja realmente anular esse item da nota?"}
                onCancel={() => {
                    this.setState({
                        showModalImport: false,
                        loading: false
                    })
                }}
                body={<>
                    Em construção pela NEC Brasil.....
                </>}
            />
            <Button style={{marginTop: 23, marginRight: 15, width: 200}} onClick={() => this.setState({showModalImport: true})} loading={this.state.loading} color={"blue"} className="ml-4">
                <i className="fas fa-bell-plus mr-2"></i> Requisitar Peça                           
            </Button>
        </>
            
        )
    }
}