import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PsmInclusionProductIhmFkSelectIdEvents extends Event {

    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if(value){
            this.setState ("loadings", [
                "psm_inclusion_product_ihm_intern_reference",
                "psm_inclusion_product_ihm_photo_product",
                "psm_inclusion_product_ihm_item_description",
                "psm_inclusion_product_ihm_product_id"

            ]);
            axios.get("/api/v1/products/"+value).then((res)=>{
                var newFiles:any = []
                var files = res.data.item["product_attach_photo"]; 
                for(var i in files){
                    var file = files[i]  
                    var obj = {
                        file_id:res.data.file_id,
                        blobFile: {},
                        name: file.file_name,
                        status: "success",
                        fileKey: "bbl2aqni"+file.file_name,
                        url: file.file_path,
                        file: {
                          name: file.file_name
                        },
                        dont_save:true
                        
                      
                    }
                    newFiles.push(obj)
                }
                
                if (res && res.data && res.data.item) {
                    this.setFieldValue ("psm_inclusion_product_ihm_intern_reference", res.data.item.product_intern_reference);
                    this.setFieldValue("psm_inclusion_product_ihm_photo_product", newFiles);
                    this.setFieldValue("psm_inclusion_product_ihm_product_id", JSON.stringify(res.data.item.product_id))
                    this.setFieldValue("psm_inclusion_product_ihm_item_description", res.data.item.product_description);      
                    
                }
    
            })
            .catch((e) => {})
            .finally(() => {
                this.setState("loadings", []);
            }); 

        }
    }
}