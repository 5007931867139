
import { Event } from "../Event";
export class PersonUseFrsEvents extends Event {
    public onChange  = (value: any, setField: any, setState: any,values: any,...args : any) => {
        if(value == false) {
            setField("person_obs_use_frs", "");

        }
    }

}