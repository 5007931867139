import React from "react";
import { Loader, Modal, Tooltip, Whisper } from "rsuite";
import axios from "../../utilities/axios";
import BrowserlizeProductsSimilar from "../BrowserlizeProductsSimilar";
import { Permission } from "../Permission";
import { AxiosCascadePicker } from "../formfields/AxiosCascadePicker";

export class AdvancedSearchModal extends React.Component<any, {}> {
    public state = {
        isLoading: true,
        form: undefined,
        select: null
    };

    public select: any = {};


    componentDidUpdate(props: any) {
        if (props.show === false && this.props.show === true) {
            var targetApi = ''

            if (this.props.crud) {
                targetApi = this.props.crud;
            } else {
                targetApi = this.props.api.split("/api/v1/").pop().split("/").shift();
            }
            // alert(this.props.crud);
            axios
                .get("/api/v1/" + targetApi + "/fields")
                .then((res) => {
                    if (res && res.data) {
                        this.select = res.data.select;
                        this.setState({ form: res.data.fields, isLoading: false });
                        //console.log(res.data);
                    }
                })
                .catch((e) => { });
        }
        // //console.log(props.show,this.props.show);
    }

    onSelectItem = (rowData: any) => {
        if (this.select?.label && this.select?.value) {
            if (rowData[this.select.label] && rowData[this.select.value]) {
                //  alert(JSON.stringify(rowData[this.select.label])+ ' - '+ JSON.stringify(rowData[this.select.value]));
                if (this.props.onSelect) {
                    this.props.onSelect({
                        label: rowData[this.select.label],
                        value: rowData[this.select.value],
                    });
                }
            }
        }
    };

    actions = (rowData: any) => {
        return (
            <div>
                <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Selecionar</Tooltip>}>
                    <i onClick={() => this.onSelectItem(rowData)} className="fas text-success fa-fw mr-2 clickable fa-check-circle"></i>
                </Whisper>
            </div>
        );
    };

    render() {
        return (
            <>
                {/* {JSON.stringify(this.props)} */}
                <Modal overflow={false} full show={this.props.show}>
                    <Modal.Header onHide={this.props.onHide}></Modal.Header>
                    <Modal.Body>
                        {this.state.isLoading === true && (
                            <div className="d-flex justify-content-center">
                                <Loader size={"md"} />
                            </div>
                        )}
                        {this.state.isLoading === false && this.state.form !== undefined && <BrowserlizeProductsSimilar modal={true} overrideActions={this.actions} headers={false} uses={this.props.uses} form={this.state.form}></BrowserlizeProductsSimilar>}
                        {/* {JSON.stringify(this.props.uses)} */}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
