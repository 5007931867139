import React from "react";
import { Button, Modal, HelpBlock, Notification } from "rsuite";
// import { DatePickerComponent } from "../../../formfields/DatePicker";
import dayjs from "dayjs";
import axios from "../../../../utilities/axios";
import { AxiosResponse } from "axios";
import { AxiosSelectPicker } from "../../../formfields/AxiosSelectPicker";


type operators = 'gte' | 'lte' | 'where' | 'orWhere';

interface optionsFormValues {
    field: string;
    op: operators;
    value: string;
}

interface ModalExportProps {
    showModal: boolean;
}

interface ModalExportState {
    attributes: {
        created_at: optionsFormValues,
        finally_at: optionsFormValues,
        accounting_parameter_id: any
    }
    loading: boolean;
    data: any[]
}

export class ModalExportHours extends React.Component<ModalExportProps, ModalExportState> {
    state: ModalExportState = {
        attributes: {
            created_at: {
                field: "import_hour_journey_day",
                op: "gte",
                value: dayjs().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss.sss')
            },
            finally_at: {
                field: "import_hour_journey_day",
                op: "lte",
                value: dayjs().format('YYYY-MM-DD HH:mm:ss.sss')
            },
            accounting_parameter_id: []
        },
        loading: false,
        data: []
    }

    onChangeInput = (params: { field?: string, value: any, op?: operators, alias: string }) => {
        const { field, value, op, alias } = params;
        this.setState(
            {
                attributes: {
                    ...this.state.attributes, [alias]: { field: field, value: value, op: op }
                }
            }
        )
    }


    onCreatedExport = () => {
        this.setState({ loading: true })
        axios
            .get('/api/v1/autotrack/generate-txt', {
                params: {
                    attributes: {
                        ...this.state.attributes
                    }
                }
            })
            .then((response: AxiosResponse<{
                status: number,
                message: string,
                downloadUrl: string,
            }>) => {
                const { message, downloadUrl } = response.data;
                const url = downloadUrl.split('./').pop()
                Notification.success(
                    {
                        title: "sucesso",
                        description: message
                    }
                )
                window.open('https://' + window.location.host.split('25565').join('25567') + `/${url}`, "_blank");
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal
                    show={this.props.showModal}
                >
                    <Modal.Header
                        closeButton={false}
                    >
                        <h4>
                            Exclusivo para exportação de dados
                        </h4>
                    </Modal.Header>
                    <Modal.Body
                        style={
                            {
                                display: "flex",
                                flexDirection: "column",
                                gap: 8,
                            }
                        }
                    >
                        <div>
                            <label>
                                Selecione a Contabilidade
                            </label>
                            <div>
                                <AxiosSelectPicker
                                    data={this.state.data}
                                    api={
                                        {
                                            method: "GET",
                                            url: "/api/v1/accounting_parameters/select"
                                        }
                                    }
                                    onChange={(value: string, e: any) => {
                                        if (value) {
                                            this.onChangeInput(
                                                {
                                                    alias: 'accounting_parameter_id',
                                                    value: value,
                                                }
                                            )
                                        }
                                    }}
                                    value={this.state.attributes.accounting_parameter_id}
                                />
                                <HelpBlock>Obrigatório*</HelpBlock>
                            </div>
                        </div>
                        <div
                            style={
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    gap: 5
                                }
                            }
                        >
                            <div
                                style={
                                    {
                                        width: "100%"
                                    }
                                }
                            >
                                <label>Data de Inicio</label>
                               
                            </div>
                            <div
                                style={
                                    {
                                        width: "100%"
                                    }
                                }
                            >
                                <label>Data de Finalização</label>
                             
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color="red"
                            loading={this.state.loading}
                        >
                            Cancelar Exportação
                        </Button>
                        <Button
                            color="green"
                            onClick={this.onCreatedExport}
                            loading={this.state.loading}
                        >
                            Exportar TXT
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}