import axios from "../../utilities/axios";
import { Event } from "../Event";
import dayjs from "dayjs";

export class PsmEvents extends Event {
    public settings = {
        saveButton: false,
    };
    public onEvents = {
        onChange: {
            listeners: ["psm_fk_business_unity_id", "psm_fk_company_id"],
            action: "montaCodigo",
        },
    };

    public montaCodigo = (value: any) => {
        if (this.values.psm_fk_business_unity_id && this.values.psm_fk_company_id) {
            const loadings: string[] = [...this.globalState.loadings];
            loadings.push("psm_os_number");
            this.setState({ loadings });
            axios
                .get("/api/v1/psm/next-code", {
                    params: {
                        bu: this.values.psm_fk_business_unity_id,
                        unidade: this.values.psm_fk_company_id,
                    },
                })
                .then((res) => {
                    if (res.data?.code) {
                        this.setFieldValue("psm_os_number", res.data?.code);
                    }
                })
                .finally(() => {
                    this.setState({ loadings: [] });
                });
        }
        // this.setFieldValue('psm_os_number','LPA0100000')
        // alert(this.values.psm_fk_business_unit_id)
        // alert(value);
    };

    componnentDidiMount(){
        // console.log("OOAISODJIANDNSJDNJAN")
    }

    public onLoad = () => {
        var dadosPsm = this.globalProps.values;

        console.log("Meus dados via Psm", dadosPsm);

        axios
        .get("/api/v1/psm/horasTrabalhadas/" + this.globalProps.values.psm_id)
        .then(res => {
            if(res.status === 200 && res.data.status) {
                //console.log("Testando calculo",res.data);
                this.setFieldValue("psm_displacement_worked_hours", res.data.calculo);
            }
        }) 
        .catch(() => {});

        // alert(JSON.stringify(activeModule));
        // alert(JSON.stringify(this.history));
    //     var dadosPsm = this.globalProps.values;
    //    if(!dadosPsm.psm_fk_business_unity_id){
    //        this.setState("loadings",['psm_fk_business_unity_id'])
    //    }
        // if (this.globalProps.isEdit == false) {
        //     this.addTab("test", "Teste", <div>ABA DE TESTE :)</div>);
        //     this.setField("psm_status_fk_situation_id", {
        //         label: "TESTEEE",
        //         required: false,
        //         disabled: true,
        //         options: [
        //             {
        //                 value: "1",
        //                 label: "TROQUEI A LABEL DO VALOR",
        //             },
        //         ],
        //     });
        // }


       // console.log("aaaa::: ",this.globalProps.values.psm_id);
      
    };

    public onInit = () => {
        // alert("LOADED!");
        const loadings: string[] = [];        

        console.log("Meu dados do lab");
        

        const { module: activeModule } = this.history.match.params;
        // alert(JSON.stringify(activeModule));
        // alert(JSON.stringify(this.history));
        if (activeModule === "preventiva") {
            // var valueBU;
            //  // alert(JSON.stringify(this.history));
            //  //this.setFieldValue("psm_fk_business_unity_id", { value: 0, label:"N" });
            // this.setState("loadings",['psm_fk_business_unity_id'])
            //  axios
            // .get("api/v1/psm/getbu/" + activeModule)
            // .then(res => {
            //     if(res.status === 200 && res.data.status) {
            //         this.setFieldValue("psm_fk_business_unity_id", {value: res.data.result.value, label:res.data.result.label});
            //         valueBU = res.data.result.value;
            //     }
            // }).finally(() =>{
            //     this.setState("loadings",[]);
            // })
            // .catch(() => {});
            // this.setState("loadings",[]);
            // this.setFieldValue("psm_fk_business_unity_id", valueBU);
            
       
        this.setFieldValue("psm_fk_business_unity_id", { value: "7", label: "PREV" }); //sempre ligar ao id do banco (business_unities)
        // this.setFieldValue("psm_prev_type", { value: "1", label: "INTERNA" });
        this.setFieldValue("psm_prev_type", { value: "2", label: "EXTERNA" });
            // this.setFieldProperty("psm_fk_business_unit_id", "disabled", true);
      //  }
          //  this.setFieldValue("psm_fk_business_unity_id", { value: "7", label: "PREV" });

            // this.setFieldValue("psm_prev_type", { value: "1", label: "INTERNA" });
            // this.setFieldProperty("psm_fk_business_unit_id", "disabled", true);
        }
        this.setFieldValue("psm_shipment_type", { value: "1", label: "CIF" });
        this.setFieldValue("psm_status", { value: "1", label: "EM ORÇAMENTO" });
        // this.sectFieldValue("psm_sub_status", {value: "10", label: "AGUARDANDO SEPARAÇÃO DOS PRODUTOS"});
        this.setFieldValue("psm_taxes", "TODOS OS IMPOSTOS ESTÃO INCLUSOS");

        this.setFieldValue("psm_proposal_due_date", "10 DIAS");
        loadings.push("psm_user");

        this.setState({ loadings });

        axios
            .get("/api/v1/me")
            .then((subRes) => {
                var subData = subRes.data;
                //console.log(subData);
                if (subData) {
                    this.setFieldValue("psm_user", subData.user.user_name);
                }
            })
            .catch((error) => {})
            .finally(() => {
                const loadings = [...this.globalState.loadings];
                loadings.splice(loadings.indexOf("psm_user"));
                this.setState({ loadings });
            });
        this.setFieldValue("psm_date", dayjs().format("DD/MM/YYYY HH:mm"));

        axios
        .get("/api/v1/psm/horasTrabalhadas/" + this.globalProps.values.psm_id)
        .then(res => {
            if(res.status === 200 && res.data.status) {
                this.setFieldValue("psm_displacement_worked_hours", res.data.calculo);
            }
        })
        .catch(() => {});
    };
    
}
export class EstimateEvents extends PsmEvents {}
