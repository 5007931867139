import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router";
import { Button, Panel, Table, Loader, Modal, Whisper, Tooltip, Notification, Form, FormGroup, Input, InputGroup, Checkbox, Header } from "rsuite";
import TablePagination from "rsuite/lib/Table/TablePagination";
import qs from "query-string";
import axios from "../utilities/axios";
import { error } from "./errorList/main";
import { FilterableCell } from "./customTable/FilterableCell";
// import { diff } from "json-diff";
import { UserContext } from "./authProvider/main";
import _ from "lodash";
import { ErrorPopover } from "./errorPopover/main";
import * as mask from "inputmask";
import { TModal } from "./modalTabs/main";
import { ExportImportSteps } from "./customTable/ExportImportSteps";
import { SchemaToParsedCell } from "./customTable/SchemaToParsedCell";
import { Permission } from "./Permission";
import { NecTable } from "./customTable/NecTable";
import { ColumnEditor } from "./ColumnEditor";
import pluralize from "pluralize";
import { CustomExtensions } from "../custom/CustomExtensions";
const { Column, HeaderCell, Cell } = Table;
// import * as Events from "../events/EventHolder";

// interface IBrowserlize {
//     form: any;
//     values?: any;
//     name: string;
//     activeModule: any;
// }
const defaultPerPage = 10;
class BrowserlizeProductsSimilar extends React.Component<any, {}> {
    static contextType = UserContext;
    public searchRef: any = null;
    public browserable: any = [];
    public state: any = {
        data: [],
        filters: [],
        selectedFilters: [],
        orders: [],
        checked: [],
        searchs: {},
        operators: {},
        totalWidth: 0,
        loading: true,
        columnEditorOpen: false,
        genericSearchValue: "",
        sortColumn: null,
        sortType: null,
        isModalOpen: false,
        isExportModalOpen: false,
        importStep: 0,
        perPage: defaultPerPage,
        totalPages: 4,
        totalItems: 0,
        page: 1,
        eventsClass: null,
        use: {product_equipment_type: 1}
    };

    public elementRef: any = null;
    public eventsClass: any = null;

    updateEventsClass = () => {
        const $GLOBALS = this.props.form.$GLOBALS;
        if ($GLOBALS) {
            const eventName = _.upperFirst(pluralize.singular(_.camelCase($GLOBALS.table))) + "BrowseEvents";
            const eventHolder: any = CustomExtensions;
            if (eventHolder[eventName]) {
                this.eventsClass = new eventHolder[eventName]();
                this.setState({ eventsClass: this.eventsClass });
            }
        }
    };

    componentDidMount() {
        // console.log("Props do browserlize :::: ", this)
        this.elementRef = React.createRef();
        this.browserable = Object.values(this.props.form).filter((item: any) => {
            return item.canBrowse === true;
        });
        this.searchRef = React.createRef();
        this.updateEventsClass();
        // alert("MOUNTED");

        var params = qs.parse(this.props.history.location.search);
        if (params) {
            var searchs: any = {};
            var operators: any = {};
            for (var i in params) {
                var param = params[i];
                if (i !== "page" && i !== "perPage" && i !== "sortType" && i !== "sortColumn" && i.substr(i.length - 3, i.length) !== "_op") {
                    searchs[i] = param;
                }
                // //
                if (i.substr(i.length - 3, i.length) === "_op") {
                    // //
                    operators[i.replace("_op", "")] = param;
                }
                if (i === "sortType") {
                    this.setState({ sortType: params[i] });
                }
                if (i === "sortColumn") {
                    this.setState({ sortColumn: params[i] });
                }
            }
            this.setState({ searchs, operators }, () => {
                // this.lastData = {searchs: this.state.searchs,operators: this.state.operators};
                if (params && params.page && typeof params.page === "string") {
                    if (params.perPage && typeof params.perPage === "string") {
                        this.search(params.page, params.perPage);
                    } else {
                        this.search(params.page);
                    }
                } else {
                    this.search();
                }
            });
        }

        // this.search();
    }

    componentDidUpdate(props: any, state: any) {
        if (this.props.location.search !== props.location.search) {
            //
            // alert("a");
            var params = qs.parse(this.props.history.location.search);
            this.setState({ sortType: null, sortColumn: null });
            if (params) {
                var searchs: any = {};
                var operators: any = {};
                for (var i in params) {
                    var param = params[i];
                    if (i !== "page" && i !== "perPage" && i !== "sortType" && i !== "sortColumn" && i.substr(i.length - 3, i.length) !== "_op") {
                        searchs[i] = param;
                    }
                    if (i.substr(i.length - 3, i.length) === "_op") {
                        operators[i.replace("_op", "")] = param;
                    }
                    if (i === "sortType") {
                        this.setState({ sortType: params[i] });
                    }
                    if (i === "sortColumn") {
                        this.setState({ sortColumn: params[i] });
                    }
                }
            }
            // this.setState({})
            // this.search(1, 20, false);
        }
        if (this.state.totalWidth === 0 && this.elementRef.current.clientWidth > 0) {
            this.setState({ totalWidth: this.elementRef.current.clientWidth });
        }

        if (Object.keys(props.form).length > 0 && this.state.filters.length <= 0 && props.form?.$GLOBALS?.table && this.props.form) {
            // let cachedFilters: undefined | string[] | null = _.flatten(
            //     this.context?.data?.user_settings
            //         ?.filter((item: any) => {
            //             return item.user_setting_key === props.form.$GLOBALS.table + "-filters";
            //         })
            //         .map((item: any) => {
            //             return item.user_setting_parsed_values;
            //         }),
            // );
            // let cachedOrders: undefined | string[] | null = _.flatten(
            //     this.context?.data?.user_settings
            //         ?.filter((item: any) => {
            //             return item.user_setting_key === props.form.$GLOBALS.table + "-order";
            //         })
            //         .map((item: any) => {
            //             return item.user_setting_parsed_values;
            //         }),
            // );
            // // //
            // //({ cachedFilters, cachedOrders, userSettings: this.context?.data?.user_settings });
            // let filters = [];
            // if (cachedFilters) {
            //     try {
            //         filters = cachedFilters;
            //     } catch (e) {
            //         filters = [];
            //         cachedFilters = undefined;
            //     }
            // }
            // if (cachedFilters === null || cachedFilters === undefined || cachedFilters.length === 0) {
            //     this.browserable = Object.values(this.props.form).filter((item: any) => {
            //         return item.canBrowse === true;
            //     });
            //     if (cachedOrders && cachedOrders.length > 0) {
            //         this.browserable = [];
            //         for (var cachedIndex in cachedOrders) {
            //             this.browserable.push(this.props.form[cachedOrders[cachedIndex]]);
            //         }
            //         // this.browserable = this.browserable.sort((a: any, b: any) => {
            //         //     return a.order < b.order ? -1 : 1;
            //         // });
            //     } else {
            //         this.browserable = this.browserable.sort((a: any, b: any) => {
            //             return a.order < b.order ? -1 : 1;
            //         });
            //     }
            //     filters = this.browserable
            //         .map((item: any) => {
            //             if (item) {
            //                 return item.name;
            //             }
            //             return undefined;
            //         })
            //         .filter((item: any) => {
            //             return item !== undefined;
            //         });
            // }
            // if (filters.length) {
            //     this.setState({ filters,selectedFilters: filters });
            // }
        }
    }

    onRead = (id: string) => {
        // alert(id);
        if (this.props.onRead) {
            this.props.onRead(id);
        }
    };
    onDelete = (id: string) => {
        if (this.props.onDelete) {
            this.props.onDelete(id, () => {
                this.search();
            });
        }
    };
    onEdit = (id: string) => {
        if (this.props.onEdit) {
            // alert(id);
            this.props.onEdit(id);
        }
    };

    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (this.props.form !== nextProps.form) {
            return true;
        }
        if (this.state.searchs !== nextState.searchs) {
            return true;
        }
        if (this.state.operators !== nextState.operators) {
            return true;
        }
        if (this.state.sortType !== nextState.sortType) {
            return true;
        }
        if (this.state.sortColumn !== nextState.sortColumn) {
            return true;
        }
        if (this.state.perPage !== nextState.perPage) {
            return true;
        }
        if (this.state.page !== nextState.page) {
            return true;
        }
        if (this.state.totalWidth !== nextState.totalWidth) {
            return true;
        }
        if (this.state.filters !== nextState.filters) {
            return true;
        }
        if (this.state.selectedFilters !== nextState.selectedFilters) {
            return true;
        }
        if (this.state.loading !== nextState.loading) {
            return true;
        }
        if (this.state.data !== nextState.data) {
            return true;
        }
        return false;
    }

    renderActions = (rowData: any) => {
        var idColumnName = this.props.form.$GLOBALS.prefix.toLocaleLowerCase() + "_id";
        const { edit: canEdit } = this.props.form.$GLOBALS;
        var { table: prefix } = this.props.form.$GLOBALS;
        if (this.props.overrideActions) {
            return this.props.overrideActions(rowData);
        }
        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Permission name={prefix + "-read"}>
                    <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Visualizar</Tooltip>}>
                        <i onClick={() => this.onRead(rowData[idColumnName])} className="fas text-primary fa-fw mr-2 clickable fa-eye"></i>
                    </Whisper>
                </Permission>

                {canEdit !== false && (
                    <Permission name={prefix + "-edit"}>
                        <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Editar</Tooltip>}>
                            <i onClick={() => this.onEdit(rowData[idColumnName])} className="fas text-warning fa-fw mr-2 clickable fa-pencil-alt"></i>
                        </Whisper>
                        {this.props.actions ? this.props.actions(rowData[idColumnName], rowData) : null}
               
                    </Permission>
                )}

                {this.props.form.$GLOBALS.delete !== false && rowData.is_delete_safe !== true && (
                    <Permission name={prefix + "-delete"}>
                        <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Excluir</Tooltip>}>
                            <i onClick={() => this.onDelete(rowData[idColumnName])} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>
                        </Whisper>
                    </Permission>
                )}
            </div>
        );
    };

    public lastData = { searchs: this.state.searchs, operators: this.state.operators };

    onExitSearch = (key: string, value: string, operator: string) => {
        // alert(key,)

        let { searchs, operators } = this.state;
        if (value === undefined) {
            delete searchs[key];
            if (operators[key]) {
                delete operators[key];
            }
        } else {
            searchs[key] = value;
            operators[key] = operator;
        }
        // var hasDiff = false;
        // if (this.lastData.searchs !== searchs) {
        //     hasDiff = true;
        // }

        // if (this.lastData.operators !== operators) {
        //     hasDiff = true;
        // }
        // var hasOnlyLike = true;
        // for (var i in operators) {
        //     if (operators[i] !== "like") {
        //         hasOnlyLike = false;
        //         break;
        //     }
        // }
        // var hasOnlyEmptyText = true;
        // for (var sIndex in searchs) {
        //     if (searchs[sIndex].length > 0) {
        //         hasOnlyEmptyText = false;
        //         break;
        //     }
        // }

        this.setState({ searchs, operators: operators }, () => {
            // if (hasOnlyEmptyText == false) {
            this.search();
            // }
        });

        // before(props: any, state: any) {
        //     //(this.state.searchs, state.searchs,this.state.operators, state.operators);
        //     if (diff(this.state.searchs, state.searchs) !== undefined || diff(this.state.operators, state.operators) !== undefined) {
        //         this.search();
        //     }
        //     return true;
        // }
        // this.setState(
        //     (oldValues: any) => {
        //         var searchs = { ...oldValues.searchs };
        //         var operators = { ...oldValues.operators };
        //         oldState = oldValues.searchs;
        //         if (value && value.length) {
        //             searchs[key] = value;
        //         } else if (searchs[key] !== undefined) {
        //             delete searchs[key];
        //         }
        //         return { searchs };
        //     },
        //     () => {
        //         if (diff(this.state.searchs, oldState) !== undefined) {
        //             this.search();
        //         }
        //     },
        // );
    };

    getVisibleHeadersWithOrder = (showHidden = true) => {
        if (this.state.filters && this.state.filters.length > 0) {
            return _.map(this.state.filters, (item) => {
                
                return this.props.form[item];
            });
        }
        return _.filter(this.props.form, (item, index) => {
            // console.log({item})
            return index !== "$GLOBALS" && item.canBrowse === true;
        });
    };

    onResize = (size: any, name: string) => {
        // alert(JSON.stringify({size,name}));
        localStorage.setItem(this.props.form.$GLOBALS.prefix + "-size-" + name, size);
    };

    onCheckAll = () => {
        var checked = [];
        var value = this.state.checked.length == 0 ? false : this.state.checked.length == this.state.data.length;
        if (value == false) {
            for (var i in this.state.data) {
                checked.push(this.state.data[i][this.props.form.$GLOBALS.prefix.toLocaleLowerCase() + "_id"]);
            }
        }
        this.setState({ checked: checked });
    };

    onToggleCheck = (id: any) => {
        var checked = this.state.checked;
        var index = checked.indexOf(id);
        if (index > -1) {
            checked.splice(index, 1);
        } else {
            checked.push(id);
        }
        this.updateEventsClass();
        this.setState({ checked: checked });
    };

    renderHeaders = () => {
        var output = [];
        if (this.props.modal !== true) {
            output.push(
                <Column key={"start"} align="left" width={40} fixed="left">
                    <HeaderCell>#{/* <Checkbox onChange={this.onCheckAll} /> */}</HeaderCell>
                    <Cell>
                        {(rowData: any) => {
                            var rowId = rowData[this.props.form.$GLOBALS.prefix.toLocaleLowerCase() + "_id"];
                            return (
                                <div>
                                    <Checkbox onChange={() => this.onToggleCheck(rowId)} defaultChecked={this.state.checked.includes(rowId)} />
                                </div>
                            );
                        }}
                    </Cell>
                </Column>,
            );
        }
        var filteredBrowserable = this.getVisibleHeadersWithOrder();
        // console.log("I'm heris :::::::>", filteredBrowserable)
        // ////
        var indexName = this.props.form.$GLOBALS.prefix + "_id";
        // console.log ("I'm Heris 2 ::::>",  this.state.searchs)
        output.push(
            <Column align="right" width={100}>
                <HeaderCell>Similaridade</HeaderCell>
                <Cell>
                    {(rowData: any) => {
                        // console.log({rowData})
                        let text = ''
                        if(rowData?.product_is_similarity == true){
                            text = 'Similar'
                        }else{
                            text = 'Origem'
                        }
                        return text
                    }}
                </Cell>
            </Column> 
        )
        for (let i in filteredBrowserable) {
            let browse: any = filteredBrowserable[i];
            // console.log('Filer browser',filteredBrowserable[i]);
            //(this.state.totalWidth / this.browserable.length) - (12 * this.browserable.length);
            if (!browse) {
                continue;
            }
            
            if (this.state.filters.includes(browse.name) || this.state.filters.filter((item: any) => item != null).length <= 0) {
                let width: any = _.clamp((this.state.totalWidth - 130) / filteredBrowserable.length, browse.name.length * 9.4, this.state.totalWidth);
                if (localStorage.getItem(this.props.form.$GLOBALS.prefix + "-size-" + browse.name)) {
                    width = parseInt(localStorage.getItem(this.props.form.$GLOBALS.prefix + "-size-" + browse.name) ?? "0");
                }
                output.push(
                    <Column
                        key={i}
                        align="left"
                        resizable
                        onResize={(size) => {
                            this.onResize(size, browse.name);
                        }}
                        width={width}>
                        <HeaderCell className="filterable">
                            <FilterableCell
                                field={browse}
                                isIndex={browse.name === indexName}
                                sortType={this.state.sortType !== undefined && this.state.sortColumn === browse.name ? this.state.sortType : null}
                                operator={this.state.operators[browse.name] ?? (browse.type === "numeric" ? "eq" : "like")}
                                onSortColumn={(value: string, operator: string = "like") => this.onSortColumn(browse.name, value, operator)}
                                value={this.state.searchs[browse.name] ?? undefined}
                                onExit={(value: string, operator: string) => this.onExitSearch(browse.name, value, operator)}>
                                <div dangerouslySetInnerHTML={{ __html: filteredBrowserable[i].label }}></div>
                            </FilterableCell>
                        </HeaderCell>

                        <Cell dataKey={browse.name.toLocaleLowerCase()}>
                            {(rowData: any) => {
                                return SchemaToParsedCell.parseFieldToValue(browse, rowData, true);
                            }}
                        </Cell>
                    </Column>,
                );
            }
        }
       
        output.push(
            <Column key={"end"} align="left" width={100} fixed="right">
                <HeaderCell>Ações</HeaderCell>
                <Cell className="link-group">
                    {(rowData: any) => {
                        return this.renderActions(rowData);
                    }}
                </Cell>
            </Column>,
        );
        return output;
    };

    search = (page: number | string = 1, perPage: string | number | null = null, apply: boolean = true) => {
        if (perPage === null) {
            perPage = localStorage.getItem("perPage") ?? defaultPerPage;
        }
        var operators: any = {};
        for (var i in this.state.operators) {
            operators[i + "_op"] = this.state.operators[i];
        }
        let uses = _.transform(this.props.uses ?? {}, function (result: any, val: any, key: any) {
            result[key.toLowerCase()] = val;
        });
        // //
        //
        this.setState({ loading: true });
        var queryObj = {
            page,
            perPage,
            ...this.state.searchs,
            ...operators,
            sortType: this.state.sortType,
            sortColumn: this.state.sortColumn,
        };
        //
        for (var queryIndex in queryObj) {
            if (queryObj[queryIndex] === null) {
                delete queryObj[queryIndex];
            }
        }
        localStorage.setItem("perPage", typeof perPage == "number" ? perPage.toString() : perPage);

        var urlQuery = qs.stringify(queryObj);
        //
        if (apply) {
            if (this.props.modal !== true) {
                this.props.history.push(this.props.history.location.pathname + "?" + urlQuery);
            }
        }
        if (this.props.form.$GLOBALS && this.props.form.$GLOBALS.table) {
            axios
                .get("/api/v1/" + this.props.form.$GLOBALS.table + "/list/", {
                    params: {
                        ...this.props.uses,
                        page,
                        perPage,
                        ...this.state.searchs,
                        ...operators,
                        sortType: this.state.sortType,
                        sortBy: this.state.sortColumn,
                        searchValue: this.state.use,
                    },
                })
                .then((res) => {
                    // console.log(res.data.items)
                    axios
                        .get("/api/v1/me")
                        .then((meResponse) => {
                            const filterSetting = _.find(meResponse.data.user.user_settings, {
                                user_setting_key: this.props.form.$GLOBALS.table + "-filters",
                            });
                            var filters: string[] = [];
                            if (filterSetting && filterSetting.user_setting_value) {
                                filters = JSON.parse(filterSetting.user_setting_value);
                            }
                            // alert(JSON.stringify(filters));
                            this.setState({
                                loading: false,
                                data: res.data.items,
                                totalItems: res.data.pagination.total,
                                perPage: res.data.pagination.perPage ?? perPage,
                                page: res.data.pagination.page,
                                filters,
                            });
                        })
                        .catch(() => {});
                })
                .catch((e) => {
                    console.error(e);
                    error.add(e.message);
                    // //
                    if (e.response) {
                        Notification.error({
                            title: "Erro!",
                            description: e.response.data?.errors ? e.response.data.errors.join(", ") : e.message,
                            placement: "bottomEnd",
                        });
                    }
                    this.setState({ loading: false, data: [], perPage });
                })
                .finally(function () {});
        }
    };

    openEditor = () => {
        this.setState({ columnEditorOpen: true},this.onSubmitSearch);
    }

    closeEditor = () => {
        this.setState({ columnEditorOpen: false},this.onSubmitSearch);
    }

    header = () => {
        let $GLOBALS = this.props.form.$GLOBALS;
        const { add } = $GLOBALS;
        // console.log("This ::::: ", this)
        return (
            <div className="d-flex justify-contents-between align-items-center">
                <div></div>
                <div>
                    <h5 className="d-flex align-items-center">
                        {$GLOBALS.icon ? <i className={classNames("fas fa-fw mr-4", "fa-" + $GLOBALS.icon)}></i> : <ErrorPopover title="Missing icon">icon</ErrorPopover>}
                        Listagem de {$GLOBALS.plural_name ? $GLOBALS.plural_name : <ErrorPopover title="Missing singular/plural name">{this.props.form.$GLOBALS.table}</ErrorPopover>}
                    </h5>
                </div>
                    <div>
                    {$GLOBALS.canImport === true && (
                        <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Importar</Tooltip>}>
                            <Button color="violet" className="mr-2" onClick={() => this.setState({ isExportModalOpen: true })}>
                                <i className="fas fa-fw fa-file-import  "></i>
                            </Button>
                        </Whisper>
                    )}
                    {/* Mateus: Retirando o configurações */}
                    <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Configurações</Tooltip>}>
                        <Button color="blue" className="mr-2" onClick={() => this.openEditor()}>
                            <i className="fas fa-fw fa-cogs"></i>
                        </Button>
                    </Whisper>
                    {this.props.headers !== false && add !== false && (
                        <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Adicionar</Tooltip>}>
                            <Button color="green" onClick={() => this.props.history.push(`/dashboard/${this.props.module}/${this.props.name}/add`)}>
                                <i className="fas fa-fw fa-plus"></i>
                            </Button>
                        </Whisper>
                    )}
                </div>
            </div>
        );
    };

    filterData = () => {
        var mappedData = this.browserable.map((item: any) => {
            return {
                label: item.label
                    ? item.label
                          .replace(/<[^>]*>?/gm, "")
                          .split("&nbsp;")
                          .join("")
                    : item.name,
                value: item.name,
            };
        });

        return mappedData;
    };

    // saveFilters = (value: any) => {
    //     this.setState({ loading: true });
    //     axios
    //         .post("/api/v1/me/" + this.props.form.$GLOBALS.table + "-filters", {
    //             value,
    //         })
    //         .then(function (res) {})
    //         .finally(() => {
    //             // this.setState({isModalOpen: false});
    //             setTimeout(() => {
    //                 window.location.reload();
    //             }, 1000);
    //         })
    //         .catch((e) => {
    //             this.setState({ loading: false });
    //         });
    // };

    // saveOrders = (order: any) => {
    //     this.setState({ orders: order, loading: true });
    //     axios
    //         .post("/api/v1/me/" + this.props.form.$GLOBALS.table + "-order", {
    //             value: order,
    //         })
    //         .then(function (res) {})
    //         .finally(() => {
    //             this.setState({ loading: false });
    //         })
    //         .catch((e) => {});
    // };

    // selectFilter = (value: any) => {
    //     this.setState(
    //         (oldValues: any) => {
    //             var newFilters = [...oldValues.selectedFilters];
    //             if (newFilters.includes(value.value)) {
    //                 if (newFilters.length <= 1) {
    //                     Notification.error({
    //                         title: "Erro!",
    //                         description: "É obrigatório ao menos uma coluna ativa!",
    //                         placement: "bottomEnd",
    //                     });
    //                 } else {
    //                     newFilters.splice(newFilters.indexOf(value.value), 1);
    //                 }
    //             } else {
    //                 newFilters.push(value.value);
    //             }
    //             return { selectedFilters: newFilters };
    //         },
    //         () => {
    //             // this.saveFilters();
    //         },
    //     );
    // };

    onSortColumn = (sortColumn: string | null, sortType: string | null, operator: string = "like") => {
        // alert(sortColumn+ sortType)
        if (sortType === null) {
            sortType = "asc";
        }
        if (this.state.sortType === "desc" && sortType === "asc" && sortColumn === this.state.sortColumn) {
            sortType = null;
            sortColumn = null;
        }
        // ////
        this.setState({ sortColumn, sortType }, () => {
            this.search();
        });
    };
    
    applyMask = () => {
        var rows = this.state.data;
        var data = this.filterData();
        for (var i in rows) {
            //// console.log({rows})
            //// console.log("Primeiro :::: ",{rows})
            var row = rows[i];

            // console.log(row)
            for (let name in row) {
                // // console.log("Entrei aqui >>>> ");
                // // console.log(name)
                var isBrowsable =
                    data.filter((item: any) => {
                        return item.value === name;
                    }).length > 0;
                
                    //// console.log({isBrowsable});
                if (isBrowsable) {
                    // // console.log("Entrei aqui 2 ::::: ")
                    var field = this.props.form[name];
                    //// console.log(field)
                    try {
                        if (field.mask && field.mask.length > 0) {
                            row[name] = mask.default.format(row[name], { mask: field.mask });
                            // console.log(row[name] = mask.default.format(row[name], { mask: field.mask }))
                        }
                    } catch (e) {}
                }
            }
        }
        let rowsTecnhical: any = [];
        for(let itens in rows){
            var checkedRevend: boolean = false
            
            for(let lines in rows[itens]){
                if(lines === 'product_equipment_type'){
                    
                    checkedRevend = rows[itens][lines] === 1 ? true : false
                }
            }
            if(checkedRevend === true) {
                rowsTecnhical.push(rows[itens])
            }
        }
        //Mateus (função para retornar apenas os produtos para os técnicos)
        // console.log('context :::: ', this.context.data.user_fk_role_id);
        // console.log('rows :::: ',rowsTecnhical);
        
        // rows = this.context.data.user_fk_role_id == 16 ? rowsTecnhical : rows;
        return rows;
    };

    onRowDoubleClick = (row: any, index: any) => {
        if (this.props.onRowDoubleClick) {
            localStorage.setItem('searchHistoric',this.props.location.search)
            this.props.onRowDoubleClick(row, index);
        }
        // const $GLOBALS = this.props.form.$GLOBALS;
        // this.props.history.push('/')
    };

    renderTable = () => {
        if (this.state.loading === true) {
            return (
                <div style={{ height: 400, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Loader size={"lg"} />
                </div>
            );
        }
        var maskedData = this.applyMask();
        return (
            <div>
                <NecTable
                    height={400 + 25 * (this.state.perPage - 10)}
                    sortColumn={this.state.sortColumn}
                    sortType={this.state.sortType}
                    // minHeight={600}#0096a0
                    onRowDoubleClick={this.onRowDoubleClick}
                    data={maskedData}
                    renderEmpty={() => <div className="rs-table-body-info o-2">Nenhum item encontrado.</div>}>
                    {this.renderHeaders()}
                </NecTable>
                {this.eventsClass && this.eventsClass["renderFooter"] && <div className="table-bottom-items">{this.eventsClass["renderFooter"](this.props, this.state)}</div>}
                <TablePagination
                    onChangePage={(p: any) => this.search(p, this.state.perPage)}
                    onChangeLength={(p: any) => this.search(1, p)}
                    lengthMenu={[
                        {
                            value: 10,
                            label: 10,
                        },
                        {
                            value: 15,
                            label: 15,
                        },
                        {
                            value: 20,
                            label: 20,
                        },
                        {
                            value: 40,
                            label: 40,
                        },
                    ]}
                    activePage={this.state.page}
                    displayLength={this.state.perPage}
                    total={this.state.totalItems}></TablePagination>
            </div>
        );
    };

    closeAndSaveFilters = (filters: any) => {
      //  this.setState({ filters, columnEditorOpen: false });
        // this.saveFilters(this.state.selectedFilters);
        // //// console.log(this.state.selectedFilters);
        // this.setState({ isModalOpen: false });
        // setTimeout(() => {
        //     window.location.reload();
        // }, 500);
    };

    onSubmitSearch = () => {
        const ref: any = this.searchRef;
        var searchs: any = {};
        // const data = this.filterData();
        // for(var i in data) {
        //     searchs[data[i].value] = ref.current.value; 
        // }
      //  searchs["searchAll"] = ref.current.value;
        searchs["ProductSimilar"] = ref.current.value;
        this.setState(
            {
                searchs,
                use: null
            },
            () => {
                this.search();
            }
        );
        // alert(ref.current.value);
    };

    render() {
        return (
            <div className="w-100" ref={this.elementRef}>
                <Panel bordered className="rs-no-body mb-4">
                    {this.header()}
                </Panel>
                <Panel bordered className="rs-no-body mb-4">
                    <Form onSubmit={this.onSubmitSearch}>
                        <FormGroup>
                            <InputGroup style={{ width: "100%" }}>
                                <Input inputRef={this.searchRef} placeholder={"Pesquisar por..."} />
                                <InputGroup.Button onClick={this.onSubmitSearch}>
                                    <i className="fas fa-search fa-fw mr-2"></i>
                                    Pesquisar
                                </InputGroup.Button>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                </Panel>
                <Panel bordered bodyFill className="rs-no-body">
                    {this.renderTable()}
                </Panel>
                {/* {this.state.isExportModalOpen} */}
                <Modal show={this.state.isExportModalOpen} size={"lg"} className="centered">
                    <Modal.Header onHide={() => this.setState({ isExportModalOpen: false })} closeButton={true}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-cog" style={{ color: "#0096a0", fontSize: 24 }}></i>
                            Exportação/Importação
                        </Modal.Title>
                    </Modal.Header>
                    <TModal.Tabs
                        tabs={[
                            { key: "importacao", label: "Importação" },
                            // { key: "exportacao", label: "Exportação" },
                        ]}>
                        <TModal.Tab tab={"importacao"}>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ExportImportSteps
                                            onComplete={() => {
                                                this.setState({ isExportModalOpen: false });
                                                this.search();
                                            }}
                                            crud={this.props.form.$GLOBALS.table}
                                            step={this.state.importStep}
                                            onStepChange={(step: number) => this.setState({ importStep: step })}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                        </TModal.Tab>
                        <TModal.Tab tab={"exportacao"}>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-12">exportacao</div>
                                </div>
                            </Modal.Body>
                        </TModal.Tab>
                    </TModal.Tabs>
                </Modal>

                <ColumnEditor
                    onHide={() => this.closeEditor()}
                    onSave={this.closeAndSaveFilters} 
                    page={this.props.form.$GLOBALS.table}
                    isOpen={this.state.columnEditorOpen}></ColumnEditor>
            </div>
        );
        
    }
}

export default withRouter(BrowserlizeProductsSimilar);
