import { isNull, toLower } from "lodash";
import React from "react";
import { Button, Checkbox, Icon, IconButton, Input, Notification, Panel, Table } from "rsuite";
import axios from "../../../utilities/axios";
import TablePagination from "rsuite/lib/Table/TablePagination";

const { Column, HeaderCell, Cell } = Table;
const rowKey = 'psm_id';

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }: any) => (
    <Cell {...props}>
        {rowData.products.length > 0 && (
        <IconButton
            size="xs"
            appearance="subtle"
            onClick={() => {
                onChange(rowData);
            }}
            icon={
                <Icon
                    icon={
                        expandedRowKeys.some((key: any) => key === rowData[rowKey])
                            ? 'minus-square-o'
                            : 'plus-square-o'
                    }
                />
            }
        />
        )}
    </Cell>
)
export class AllocatadeRequisitionPurchasePsm extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        isLoading: false,
        value: null,
        isModalSelect: false,
        data: [],
        dataChecked: [],
        checkedKeys: [],
        dataFormPsms: [],
        oldData: [],
        expandedRowKeys: [],
        paginationDate: {
            currentPage: 1,
            totalItens: 0,
            totalPages: 0
        },
        textFilter: ''
    }

    componentDidMount = async () => {

    }

    onChange = (_item: any, _valuesOmie: any) => {
        this.props.onChange()
    };


    getValuesPsms = async () => {
        // for (var i in this.props.values) {
        //     var dados = this.props.values[i];
        //     // console.log("Dados  ::::: ",i)
        //     if (i == 'box_inclusions') {
        //         this.setState({
        //             checkedKeys: dados
        //         })
        //     }
        // }
        this.setState({ loading: true })
        await axios
            .get("/api/v1/purchase/list-psms/" + this.props.guid + "/" + 1)
            .then((res) => {
                console.log(res.data.response);
                if (res.data.status == 200) {
                    this.setState({ data: res.data.response.itens, isModalSelect: true, paginationDate: { ...res.data.response.pagination } })
                } else {
                    Notification.error({
                        title: "error!",
                        description: 'Não foi possivel listar Psms',
                        duration: 6500,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
            .catch((_e) => {
                Notification.error({
                    title: "Error!",
                    description: 'Erro na Listagem!'
                })
            })

    }

    isCheckedItem = (value: any = null, checked: any = null) => {
        const checkedKeys = this.state.checkedKeys;
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        this.setState({
            checkedKeys: nextCheckedKeys
        });
    }

    isSelectPsms = async () => {
        const keysCheckds: Array<Record<string,any>> = this.state.checkedKeys;
        // console.log("Valor do keysCheckeds", keysCheckds)
        let numberOs: number | null = null;
        // console.log("Valor do numberOs", numberOs)
        let valid: boolean = true;
        for(const i in keysCheckds) {
            if(numberOs !== null){
                if(numberOs !== keysCheckds[i].psm_id){
                    valid = false;
                    Notification.error({
                        title: "Error!",
                        description: `PSM's divergentes para abertura!`
                    })
                    return;
                }
            }
            numberOs = keysCheckds[i].psm_id;
        }
        if(!valid){
            return;
        }
        this.props.onChange(this.state.checkedKeys);
        // await axios
        //     .post("/api/v1/packing/validate-add", { data: this.state.checkedKeys, guid: this.props.guid })
        //     .then((res) => {
        //         if (res.data.validate == false) {
        //             Notification.error({
        //                 title: res.data.message,
        //                 duration: 4000,
        //             })
        //             return
        //         }
        //         this.setState({
        //             // isModalSelect: false,
        //             checkedKeys: []
        //         })
        //         this.props.onChange(res.data.data);

        //     }).catch((e) => {
        //         Notification.error({
        //             description: e,
        //             duration: 4000,
        //         })
        //     })
    }
    changeFilter = async () => {
        this.setState({loading: true})
        await axios
        .get("/api/v1/purchase/list-psms/" + this.props.guid + "/" + 1, {
            params: {
                search: this.state.textFilter
            }
        })
        .then((res) => {
            console.log(res.data.response);
            if (res.data.status == 200) {
                console.log('response ::: ', res.data)
                this.setState({ data: res.data.response.itens, isModalSelect: true, paginationDate: { ...res.data.response.pagination } })
            } else {
                Notification.error({
                    title: "error!",
                    description: 'Não foi possivel listar Psms',
                    duration: 6500,
                });
                this.setState({data: []})
            }
        })
        .finally(() => {
            this.setState({ loading: false })
        })
        .catch((e) => {
            Notification.error({
                title: "Error!",
                description: 'Erro na Listagem!'
            })
            this.setState({data: []})
        })
        // let data = this.state.data
        // let filtered: any = []
        // if (stringFilter.length > 0) {
        //     for (var i in data) {
        //         let item = data[i]
        //         let key = Object.keys(item)
        //         let value = Object.values(item)

        //         let contain = false
        //         for (var k in item) {
        //             var res: any = item[k]
        //             if (toLower(res).includes(toLower(stringFilter))) {
        //                 contain = true;
        //             }
        //         }
        //         if (contain) {
        //             filtered.push(item)
        //             continue;
        //         }
        //     }
        //     this.setState({
        //         data: filtered,
        //         oldData: data
        //     })
        // } else {
        //     this.getValuesPsms()
        //     this.setState({ data: this.state.oldData ?? [] })
        // }

    }
    handleChangePage  = async (page: number) => {
        this.setState({loading: true})
        await axios
            .get("/api/v1/purchase/list-psms/" + this.props.guid + "/" + page)
            .then((res) => {
                // console.log(res.data.response);
                if (res.data.status == 200) {
                    // console.log(res.data);
                    this.setState({ data: res.data.response.itens, isModalSelect: true, paginationDate: { ...res.data.response.pagination } })
                } else {
                    Notification.error({
                        title: "error!",
                        description: 'Não foi possivel listar Psms',
                        duration: 6500,
                    });
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
            .catch((e) => {
                Notification.error({
                    title: "Error!",
                    description: 'Erro na Listagem!'
                })
            })

    };
    // handleChangeLength: ((size: number) => void) | undefined;

    constructor(props: any) {
        super(props);
        // this.state = {
        //   data: [],
        //   expandedRowKeys: []
        // };
        this.handleExpanded = this.handleExpanded.bind(this);
    }

    handleExpanded(rowData: any, _dataKey: any) {
        const { expandedRowKeys } = this.state;

        let open = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }
        this.setState({
            expandedRowKeys: nextExpandedRowKeys
        });
    }

    isCheckedProduct = (_product: Record<string, any>) => {

    }

    isRenderContentExpandle(rowDataProducts: Array<Record<string, any>>, _rowData: Array<Record<string,any>>) {
        const output: Array<any> = [];
     
        for (let i in rowDataProducts) {
            // let namefon52 = rowDataProducts[i]['psm_relation_product_id'];
    

            let namefon = rowDataProducts[i]['psm_relation_product_registered'];
            if (namefon === false || namefon === null){
                 namefon = "Não";
            }else {
                 namefon = "Sim"
            };

            let namefon2 = rowDataProducts[i]['psm_relation_product_instal'];
            if (namefon2 === false || namefon2 === null){
                 namefon2 = "Não";
            }else {
                 namefon2 = "Sim"
            };
            let typeProduct: boolean = false;
            for (const t in rowDataProducts[i]) {
                if (t.includes('relation_product')) {
                    typeProduct = true
                    continue
                }
            }
            if (typeProduct == true) {
                output.push(
                    <tr>
                        <td style={{width:40}}>
                            <Checkbox
                                value={rowDataProducts[i]}
                                onChange={this.isCheckedItem}
                                checked={this.state.checkedKeys.some(item => item === rowDataProducts[i])}
                            ></Checkbox>
                        </td>
                         {/* {rowDataProducts[i]['psm_relation_product_registered']} */}
                        {/* <td>{namefon}</td>
                        <td>{namefon2}</td> */}
                        <td style={{width:140}}>{rowDataProducts[i]['psm_relation_product_intern_reference']}</td>
                        <td style={{width:280}}>{rowDataProducts[i]['psm_relation_product_item_description']}</td>
                        <td style={{width:140}}>{rowDataProducts[i]['psm_relation_product_manufacture_code']}</td>
                        {/* <td>{rowDataProducts[   ]['psm_relation_product_quantity']}</td> */}
                    </tr>
                )
            } else {
                output.push(
                    <tr>
                        <td style={{width:40}}>
                            <Checkbox
                                value={rowDataProducts[i]}
                                onChange={this.isCheckedItem}
                                checked={this.state.checkedKeys.some(item => item === rowDataProducts[i])}
                            ></Checkbox>
                        </td>                       
                        {/* {rowDataProducts[i]['psm_relation_service_registered']} */}
                        {/* <td>{namefon}</td>
                         <td>{namefon2}</td> */}
                        <td style={{width:140}}>{rowDataProducts[i]['psm_relation_service_intern_reference']}</td>
                        <td style={{width:280}}>{rowDataProducts[i]['psm_relation_service_item_description']}</td>
                        <td style={{width:140}}>{rowDataProducts[i]['psm_relation_service_manufacture_code']}</td>
                        {/* <td>{rowDataProducts[i]['psm_relation_service_quantity']}</td> */}
                    </tr>
                )
            }
        }
        return output;
    }

    render() {
        const { expandedRowKeys, data }: any = this.state;
    //     for (let i in data) {
    //     let psmComercialStatus: any = data[i]['psm_comercial_status'];
    //     // console.log("i'm here ::::::>", psmComercialStatus)
    //     if (psmComercialStatus === 1){
    //         psmComercialStatus = "Normal";
    //     }
    //     if(psmComercialStatus === 2) {
    //         psmComercialStatus = "Urgente";
    //     }
    //     if(psmComercialStatus === 3) {
    //         psmComercialStatus = "Máquina Parada";
    //    }
       // }
        return (<>
            {/* {this.renderModalAllocated()} */}
            {/* <Modal size="lg" show={this.state.isModalSelect} onHide={() => this.setState({ isModalSelect: false })}>
                <Modal.Header>
                    <h4>Selecione quais Psm's deseja atribuir a essa embalagem</h4>
                </Modal.Header>
                <ModalBody> */}
            <Button color="violet" size={"lg"} loading={this.state.loading} style={{ marginTop: 15, width: "200px", justifyContent: 'space-around' }} onClick={() => this.getValuesPsms()}>
                <i className="fas fa-folder-tree"></i>&nbsp; Atrelar PSM
            </Button>
            {/* {JSON.stringify(this.state.checkedKeys)}

            {JSON.stringify(this.state.dataChecked)} */}
            {this.state.isModalSelect && (
                <div style={{ height: "auto" }} >
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Input onChange={(value) => this.setState({textFilter:value})} style={{ margin: 12 }} placeholder="Filtrar..." ></Input>
                        <Button onClick={() => this.changeFilter()}>Pesquisar</Button>
                    </div>
                    <Panel bordered style={{ padding: 8 }} >
                        <Table
                            // fillHeight
                            // fillWidth
                            // autoHeight
                            // wordWrap
                            loading={this.state.loading}
                            height={600}
                            // width={1000}
                            autoWidth
                            rowKey={rowKey}
                            expandedRowKeys={this.state.expandedRowKeys}
                            data={this.state.data}
                            onRowClick={data => {
                                console.log(data);
                            }}
                            rowExpandedHeight={700}
                            renderRowExpanded={rowData => {
                                return (
                                    <div>
                                        <table className="table-custom-standart">
                                            <tr>
                                                <th> # </th>
                                                {/* <th> Não cadastrado </th>
                                                <th> Instalado </th> */}
                                                <th> Referência Interna (RI) </th>
                                                <th> Descrição do Item </th>
                                                <th> Código do Fabricante </th>
                                                {/* <th> Quantidade </th> */}
                                            </tr>
                                            {this.isRenderContentExpandle(rowData.products, rowData)}
                                        </table>
                                    </div>

                                )
                            }
                            }
                            renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                        >
                            {/* <Column width={90} align='center' fixed>
                                <HeaderCell>Selecionar</HeaderCell>
                                <Cell className="link-group" align='center' style={{ alignItems: 'center' }}>
                                    {(rowData: any) => {
                                        return (
                                            <div style={{ alignContent: 'center' }}>
                                                <Checkbox
                                                    value={rowData}
                                                    inline
                                                    onChange={this.isCheckedItem}
                                                    checked={this.state.checkedKeys.some(item => item === rowData)}
                                                />
                                            </div>
                                        );
                                    }}
                                </Cell>
                            </Column> */}
                            <Column width={70} align="center">
                                <HeaderCell>#</HeaderCell>
                                
                                {/* {console.log("Iem hereis 4", this.state)} */}
                                 <ExpandCell
                                    dataKey="psm_id"
                                    expandedRowKeys={expandedRowKeys}
                                    onChange={this.handleExpanded}
                                />                                
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>PSM</HeaderCell>
                                <Cell dataKey="psm_os_number" />
                            </Column>
                            <Column width={70} resizable>
                                <HeaderCell>BU</HeaderCell>
                                <Cell dataKey="psm_fk_business_unity_id_requisition" />
                            </Column>
                            <Column width={200} resizable>
                                <HeaderCell>Cliente</HeaderCell>
                                <Cell dataKey="psm_clients_fk_person_id_requisition" />
                            </Column>
                            <Column width={170} resizable>
                                <HeaderCell>Unidade</HeaderCell>
                                <Cell dataKey="psm_fk_company_id_requisition" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Prioridade</HeaderCell>
                                <Cell dataKey="psm_priority_requisition" />
                            </Column>
                            <Column width={200} resizable>
                                <HeaderCell>Situação</HeaderCell>
                                <Cell dataKey="psm_status_fk_situation_id_requisition" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Situação Comercial</HeaderCell>
                                <Cell dataKey="psm_comercial_status_requisition" />
                            </Column>

{/*                             
                            <Column width={100} resizable>
                                <HeaderCell>N° NF</HeaderCell>
                                <Cell dataKey="psm_num_nf_id" />
                            </Column>                     
                            <Column width={100} resizable>
                                <HeaderCell>Frete</HeaderCell>
                                <Cell dataKey="psm_shipment_fk_type_frete_id_requisition" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Histórico de Situação</HeaderCell>
                                <Cell dataKey="psm_status" />
                            </Column>                            
                            <Column width={100} resizable>
                                <HeaderCell>Tipo do Equipamento</HeaderCell>
                                <Cell dataKey="psm_equipment_manufacturer_id" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Cód. do Fabricante</HeaderCell>
                                <Cell dataKey="psm_fk_business_unity_id_requisition" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Redespacho</HeaderCell>
                                <Cell dataKey="psm_resend_fk_person_id_requisition" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Descrição do Equipamento</HeaderCell>
                                <Cell dataKey="psm_receipt_equipment_description" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Observações Comerciais (Interno)</HeaderCell>
                                <Cell dataKey="psm_obs_internal" />
                            </Column>                          
                            
                            <Column width={100} resizable>
                                <HeaderCell>Transportadora</HeaderCell>
                                <Cell dataKey="psm_carriers_fk_person_id_requisition" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Frete</HeaderCell>
                                <Cell dataKey="psm_shipment_type" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>DIBI</HeaderCell>
                                <Cell dataKey="psm_status_fk_dibi_situation_id_requisition" />
                            </Column> */}


                            {/* <Column width={70} resizable>
                                <HeaderCell>BU</HeaderCell>
                                <Cell dataKey="box_inclusion_business_unity" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>psm</HeaderCell>
                                <Cell dataKey="box_inclusion_os_number" />
                            </Column>
                            <Column width={250} resizable>
                                <HeaderCell>Cliente</HeaderCell>
                                <Cell dataKey="box_inclusion_person" />
                            </Column>
                            <Column width={150} resizable>
                                <HeaderCell>CNPJ/CPF</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        const cnpjCpf = (value: any) => {
                                            if (value.length > 11) {
                                                value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                                                return value
                                            }
                                            if (value.length <= 11) {
                                                value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$5")
                                                return value
                                            }
                                        }
                                        return (<>
                                            {cnpjCpf(rowData.box_inclusion_cnpj)}
                                        </>)
                                    }}
                                </Cell>
                            </Column>
                            <Column width={120} resizable>
                                <HeaderCell>Cidade</HeaderCell>
                                <Cell dataKey="box_inclusion_city" />
                            </Column>
                            <Column width={120} resizable>
                                <HeaderCell>Uf</HeaderCell>
                                <Cell dataKey="box_inclusion_state" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Frete</HeaderCell>
                                <Cell dataKey="box_inclusion_shipment_type" />
                            </Column>
                            <Column width={100} resizable>
                                <HeaderCell>Transportadora</HeaderCell>
                                <Cell dataKey="box_inclusion_shipping_company" />
                            </Column>
                            <Column width={210} resizable>
                                <HeaderCell>Situação</HeaderCell>
                                <Cell dataKey="box_inclusion_status" />
                            </Column>
                            <Column width={210} resizable>
                                <HeaderCell>Situação Comercial</HeaderCell>
                                <Cell dataKey="box_inclusion_comercial_status" />
                            </Column>
                            <Column width={70} resizable>
                                <HeaderCell>Data de entrega final</HeaderCell>
                                <Cell dataKey="psm_os_number" />
                            </Column>  */}
                        </Table>
                        <TablePagination
                            lengthMenu={[
                                {
                                    value: 10,
                                    label: 10
                                }
                            ]}
                            activePage={this.state.paginationDate.currentPage}
                            displayLength={this.state.paginationDate.totalPages}
                            total={this.state.paginationDate.totalItens}
                            onChangePage={this.handleChangePage}
                            // onChangeLength={this.handleChangeLength} 
                        />
                        <Button disabled={this.props.loading} onClick={() => this.isSelectPsms()} color={"green"}>
                            Concluir
                        </Button>
                    </Panel>
                </div>
            )}
        </>)
    }
}