import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PsmProductRegistrationNumberIdregistrationEvents extends Event {
    public state = {
        loading: false,
    }
    public onChange  = (value: any, setFieldValue: any, setState: any,values: any,...args : any) => {
        // console.log("HISTORY",values);
        //console.log("testando value::::", value);
        if(value.length <= 0) {
            this.setState ([
                "psm_product_registration_description",
                "psm_product_registration_unit_product",
                "psm_product_registration_ncm",
                "psm_product_registration_reference",
                "psm_product_registration_code_product",
                "psm_product_registration_base_price",
                "psm_product_registration_margin",
                "psm_product_registration_politic",
                "psm_product_registration_ipi",
                "psm_product_registration_number_idregistration",
                "psm_product_registration_description_item_text",
            ]);
            this.setFieldValue("psm_product_registration_description", "");
            this.setFieldValue("psm_product_registration_unit_product", "");
            this.setFieldValue("psm_product_registration_ncm", "");
            this.setFieldValue("psm_product_registration_reference", "");
            this.setFieldValue("psm_product_registration_code_product", "");
            this.setFieldValue("psm_product_registration_base_price", "");
            this.setFieldValue("psm_product_registration_margin", "");
            this.setFieldValue("psm_product_registration_politic", "");
            this.setFieldValue("psm_product_registration_ipi", "");
            this.setFieldValue("psm_product_registration_number_idregistration", "");
            this.setFieldValue("psm_product_registration_description_item_text", "");
        }
        
        if(value && value.length > 0 ) {
            this.setState ([
                "psm_product_registration_description",
                "psm_product_registration_unit_product",
                "psm_product_registration_ncm",
                "psm_product_registration_reference",
                "psm_product_registration_code_product",
                "psm_product_registration_base_price",
                "psm_product_registration_margin",
                "psm_product_registration_politic",
                "psm_product_registration_ipi",
                "psm_product_registration_number_idregistration",
                "psm_product_registration_description_item_text",

            ]);
            axios
            .get("/api/v1/product/" + value)
            .then((res => {
                if (res.status === 200 && res) { 
                    // console.log("Testando o response",res);
                    var margin = res.data.item.product_ideal_margin ;
                    // console.log(margin)
                    var marginDiscount = (margin/1000)*300+(margin/100*100);
                    // var impostSum = (pis: number, cofins: number,) => {
                        
                    // }
                    // var sellPrice =  (margin: number, price: number) => {
                        
                    // }
                    console.log("testando data::::",res.data);
                    // console.log(res.data.product_fk_fiscal_classification_id);
                    var Idfiscal = res.data.item.product_fk_fiscal_classification_id;
                    // console.log(Idfiscal);
                    // var series = res.data.item.product_serie
                    //console.log("Olha meu value", value)
                    this.setFieldValue ("psm_product_registration_fk_select_id", {value: value, label:res.data.item.product_description});
                    this.setFieldValue ("psm_product_registration_unit_product", res.data.item.measure_unit.measure_unit_name);
                    this.setFieldValue ("psm_product_registration_reference", res.data.item.product_intern_reference);
                    this.setFieldValue ("psm_product_registration_code_product", res.data.item.product_manufacturer_code);
                    this.setFieldValue ("psm_product_registration_base_price", res.data.item.product_base_price_tax);
                    this.setFieldValue ("psm_product_registration_politic", margin);
                    this.setFieldValue ("psm_product_registration_margin", marginDiscount);
                    this.setFieldValue ("psm_product_registration_ipi", res.data.item.product_ipi);
                    this.setFieldValue ("psm_product_registration_fk_select_id", res.data.item.product_id);
                    this.setFieldValue ("psm_product_registration_number_idregistration", JSON.stringify(res.data.item.product_id));
                    this.setFieldValue ("psm_registration_product_NCM_product", res.data.product_fk_fiscal_classification_id);
                    this.setFieldValue("psm_product_registration_description_item_text", res.data.product_description)
                    axios
                        .get("/api/v1/fiscal-classifications/" + Idfiscal)
                        .then((res => {
                            if(res.status === 200 && res) {
                                if (res.data.item == null || res.data.item === "null") {
                                    this.setFieldValue ("psm_product_registration_ncm", "Não existe NCM para esse produto");
                                }else {
                                    // console.log("testando NCM",res)
                                    var classificacaoAbreviada = res.data.item.fiscal_classification_name ? res.data.item.fiscal_classification_abbreviation + "-" + res.data.item.fiscal_classification_name : "Não existe"
                                    // console.log(classificacaoAbreviada);
                                    this.setFieldValue ("psm_product_registration_ncm", classificacaoAbreviada);
                                }
                                
                            }
                        }))
                        .finally(() => {
                            this.setState("loadings", []);
                        }); 
                }
            }))
            .finally(() => {
                this.setState("loadings", []);
            }); 
            
        }
    }

}