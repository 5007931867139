import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from "rsuite";
import { withRouter, RouteComponentProps } from 'react-router-dom';

export class PsmCustomNfIncorporateEvents extends Event {
    public state = {
        loading: false,
        show: false,
    }
    static converterData(dataBrasileira: string | any) {
        // console.log("entrei aqui :>", dataBrasileira)
        if (dataBrasileira) {
            const dataBrasileiraSplit = dataBrasileira.split("/")
            // console.log("entrei aqui :2>", dataBrasileiraSplit)

            const mes = dataBrasileiraSplit[1];
            const dia = dataBrasileiraSplit[0];
            const ano = dataBrasileiraSplit[2];
            // const dataToTimestamp = `${dia}/${mes}/${ano}`;
            const dataToTimestamp = `${ano}-${mes}-${dia} 03:00:000`;
            return dataToTimestamp;
        }
    }
    public onChange = async (value: any, setField: any, setState: any, values: any) => {
        if (value) {
            let data = []
            data.push(this.values)
            data.push(value.dadosXml[2])
            if (value.dadosXml[3]){
                data.push(value.dadosXml[3])
            }
            let isValid: any;
            if (this.values.psm_fk_business_unity_id == undefined) {
                Notification.error({
                    title: 'Erro',
                    description: `Para selecionar uma NF, favor preencher o campo BU`,
                    duration: 10000,
                })
            } else {
                this.setState({ show: false });
                await axios
                    .post("/api/v1/receipts/check-nf/" + `0000${value.dadosXml[1].nNf}` + '/' + value.dadosXml[1].chaveNfe + '/' + value.numItem, { data })
                    .then(async (res) => {
                        isValid = res.data
                    })
                    .catch((e) => {
                        console.log("valor do error", e);
                    });
                // console.log("valor do xml", value)
                if (isValid.status == 200) {
                    Notification.error({
                        title: 'Erro',
                        description: `${isValid.message}`,
                        duration: 10000,
                    })
                } else {
                    if (isValid.status == 201 && isValid.id) {
                        const targetUrl = `/dashboard/compras-estoque/receipts/${isValid.id}?tab=Main`;
                        const { history } = this.globalProps;

                        history.push(targetUrl);

                        window.location.href = targetUrl;
                    }
                }
            }
        }
    }
}

