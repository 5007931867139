import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmInternReferenceFkProductIdEvents extends Event {
    public state = {
        loading: false,
    }

    public onLoad = () => {
        let valueReceipts = this.globalProps.values;
        console.log("Meu OnLoad :::: ", valueReceipts);
    };    

    public onChange = async (value: any, setField: any, setState: any, values: string) => {
        //console.log("Meu valor ::::: ",value)
        if(value == null){
            await this.setState("loadings",[
                "psm_receipt_equipment_description",
                "psm_equipment_manufacturer_id",
                "psm_receipt_equipment_manufacturer",
                // "psm_equipment_serie_fk_product_id"
            ])

            this.setFieldValue("psm_receipt_equipment_description","");
            this.setFieldValue("psm_equipment_manufacturer_id", "");
            this.setFieldValue("psm_receipt_equipment_manufacturer", "");
            this.setState("loadings",[
                "psm_equipment_serie_fk_product_id"
            ]);
            this.setFieldValue("psm_equipment_serie_fk_product_id",);
            this.setState("loadings", []);
        }

        if(value > 0){
            this.setState("loadings",[
                "psm_receipt_equipment_description",
                "psm_equipment_manufacturer_id",
                "psm_receipt_equipment_manufacturer",
                "psm_equipment_serie_fk_product_id"
            ]);

            axios
                .get("/api/v1/product/" + value)
                .then((res) => {
                    var item = res.data.item;
                    console.log({item});
                    if(item){
                        this.setFieldValue("psm_receipt_equipment_description", item.product_description);
                        this.setFieldValue("psm_equipment_manufacturer_id", item.product_manufacturer_code);
                        this.setFieldValue("psm_receipt_equipment_manufacturer", item.manufacturer.manufacturer_name);
                        this.setState("loadings",[
                            "psm_equipment_serie_fk_product_id"
                        ]);
                        this.setFieldValue("psm_equipment_serie_fk_product_id", item.product_id);
                        this.setState("loadings", []);
                    }
                })
                .finally(() => {
                    this.setState("loadings", []);
                });


            // evento para que haja o calculo da garantia da PSM.
            

        }
    }
}