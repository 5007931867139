import { Event } from "../Event"
import axios from "../../utilities/axios";
import dayjs from "dayjs";
import { requiredColumns } from "../../utilities/situations/situationsFunctions";
import { isNull } from "lodash";



export class PurchaseRequisitionEvents extends Event {

    public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
    


        if (this.globalProps.match.url.includes('/add') !== true) {
            const numberPsm = this.globalProps.values.purchase_requisition_fk_psm_id
            const typeOpen = this.globalProps.values.purchase_requisition_type_open;
            if(numberPsm !== null || numberPsm !== '' || numberPsm !== undefined ){
                this.setField('purchase_requisition_fk_psm_id', {
                    hidden:true
                    // readonly: true
                })
                this.setField('purchase_requisition_number_psm_os_number', {
                    hidden:false,
                    readonly: true
                })
            }
            if (typeOpen != null || typeOpen != '' || typeOpen != undefined) {
                this.setField('purchase_requisition_type_open', {
                    disabled: true,
                    readOnly: true
                })               
            }
        }
        // console.log("Valor do this.props", this.globalProps) 
        if (this.globalProps.match.url.includes('/add')) {
            this.setState('loadings', [
                'purchase_requisition_requisition_date',
                'purchase_requisition_request_user',
                'purchase_requisition_fk_purchase_requisition_situation_id'
            ])
            let currentDate = dayjs().format('DD-MM-YYYY hh:mm:ss.sss');
            this.setFieldValue('purchase_requisition_requisition_date', currentDate);
            axios
                .get("/api/v1/me")
                .then((subRes) => {
                    var subData = subRes.data;
                    //console.log(subData);
                    if (subData) {
                        this.setFieldValue("purchase_requisition_request_user", subData.user.user_name);
                    }
                })
                .catch((error) => { })
                .finally(() => {
                    this.setState('loadings', [])
                });
            this.setFieldValue('purchase_requisition_fk_purchase_requisition_situation_id', 1);
        }
    }
}
