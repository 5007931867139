import axios from "../../utilities/axios";
import { Button, Modal } from "rsuite";
import { Event } from "../Event";

export class PsmCombinedAnalysisInclusionProductFkSelectIdEvents extends Event {

    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        axios.get("/api/v1/products/"+value).then((res)=>{
            console.log(res.data.product_description)
            if (res && res.data && res.data.item) {
                setField("psm_combined_analysis_inclusion_product_item_description", res.data.item.product_description);
            }

        }).catch((e) => {})

    }
}