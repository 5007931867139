import { Event } from "../Event";
import axios from "../../utilities/axios";

export class PsmEquipmentFkProductIdEvents extends Event {
    public state = {
        loading: false,
    }
    public onChange  = (value: any, setField: any, setState: any) => {
        if(value && value.length > 0 ) {
            this.setState ("loadings", [
                "psm_equipment_series"

            ]);
            axios
            .get("/api/v1/product/" + value)
            .then((res => {
                if (res.status === 200 && res) { 
                    // console.log(res.data.item.product_serie);
                    var series = res.data.item.product_serie
                    this.setFieldValue ("psm_equipment_series", series);
                }
            }))
            .finally(() => {
                this.setState("loadings", []);
            }); 
        }
    }
}